<app-splash-screen
	*ngIf="(showSplashScreenSubject | async) !== 'CLOSED'" [text]="showSplashScreenSubject
| async" (close)="closeSplashScreen()" @slideInOutDown
></app-splash-screen>
<main [@routeAnimations]="prepareRoute(outlet)" (scroll)="setScroll($event)" #main id="main">
	<router-outlet #outlet="outlet"></router-outlet>
</main>
<div
	class="bottom-nav"
	*ngIf="pagesWithoutNavbar.includes(currentPageObservable | async) === false"
	@slideInOutDown
>

	<a
		[routerLink]="['/discover']"
		clickAnimation
		routerLinkActive="active"
		(click)="closeSplashScreen(); clearTilesCache(); resetPageState('SEARCH-PAGE')"
		title="Go to discover"
		i18n-title="Anchor title discover|The title of the discover anchor tag@@BasicLayoutComponentDiscoverAnchorTitle"
	>
		<mat-icon>explore</mat-icon>
	</a>
	<a
		[routerLink]="['/']"
		routerLinkActive="active"
		class="home-button"
		[routerLinkActiveOptions]="{exact: true}"
		(click)="closeSplashScreen(); clearTilesCache(); resetPageState('SEARCH-PAGE')"
		clickAnimation
		title="Go to home"
		i18n-title="Anchor title home|The title of the home anchor tag@@BasicLayoutComponentHomeAnchorTitle"
	>
		<div>
			<mat-icon>home</mat-icon>
		</div>
	</a>
	<a
		[routerLink]="['/chats']"
		routerLinkActive="active"
		clickAnimation
		(click)="clearTilesCache(); resetPageState('SEARCH-PAGE')"
		title="Go to chats"
		i18n-title="Anchor title chats|The title of the chats anchor tag@@BasicLayoutComponentChatsAnchorTitle"
	>
		<mat-icon>email</mat-icon>
	</a>
</div>

