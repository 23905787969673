import { transition, trigger } from "@angular/animations";
import { fadeAnimation, slideDownAnimation, slideInAnimation, slideOutAnimation, slideUpAnimation } from "@twly/common";

export const routeAnimation = trigger("routeAnimations", [
	transition("LoginPage => RegisterPage", slideOutAnimation),
	transition("RegisterPage => LoginPage", slideInAnimation),
	transition("LoginPage => RequestPasswordResetPage", slideInAnimation),
	transition("RequestPasswordResetPage => LoginPage", slideOutAnimation),

	transition("SearchPage => ActivityPage", slideInAnimation),
	transition("ActivityPage => SearchPage", slideOutAnimation),

	transition("HomePage => SearchPage", slideInAnimation),
	transition("SearchPage => HomePage", slideOutAnimation),

	transition("DiscoverPage => SearchPage", slideInAnimation),
	transition("SearchPage => DiscoverPage", slideOutAnimation),


	transition("HomePage => ActivityPage", slideInAnimation),
	transition("ActivityPage => HomePage", slideOutAnimation),

	transition("DiscoverPage => ActivityPage", slideInAnimation),
	transition("ActivityPage => DiscoverPage", slideOutAnimation),

	transition("HomePage => TileSectionDetailPage", slideInAnimation),
	transition("TileSectionDetailPage => HomePage", slideOutAnimation),
	transition("UserGroupSettingsPage => TileSectionDetailPage", slideInAnimation),
	transition("TileSectionDetailPage => UserGroupSettingsPage", slideOutAnimation),

	transition("* => UserGroupPage", slideInAnimation),
	transition("UserGroupPage => *", slideOutAnimation),

	transition("* => AccountPage", slideInAnimation),
	transition("AccountPage => *", slideOutAnimation),

	transition("ChatOverviewPage => ChatDetailPage", slideInAnimation),
	transition("ChatDetailPage => ChatOverviewPage", slideOutAnimation),

	transition("UserGroupSettingsPage => UserGroupLocationPage", slideInAnimation),
	transition("UserGroupLocationPage => UserGroupSettingsPage", slideOutAnimation),
	transition("UserGroupSettingsPage => UserGroupInterestsPage", slideInAnimation),
	transition("UserGroupInterestsPage => UserGroupSettingsPage", slideOutAnimation),

	transition("AccountPage => UserGroupLocationPage", slideInAnimation),
	transition("UserGroupLocationPage => AccountPage", slideOutAnimation),
	transition("AccountPage => UserGroupInterestsPage", slideInAnimation),
	transition("UserGroupInterestsPage => AccountPage", slideOutAnimation),

	transition("* => UserGroupAddPage", slideUpAnimation),
	transition("UserGroupAddPage => UserGroupLocationPage", slideInAnimation),
	transition("UserGroupAddPage => *", slideDownAnimation),
	transition("UserGroupLocationPage => UserGroupInterestsPage", slideInAnimation),
	transition("UserGroupLocationPage => *", slideDownAnimation),
	transition("UserGroupInterestsPage => *", slideDownAnimation),

	transition("* => UserGroupSettingsPage", slideUpAnimation),
	transition("UserGroupSettingsPage => *", slideDownAnimation),

	transition("* => LoginPage", slideDownAnimation),
	transition("LoginPage => *", slideUpAnimation),
	transition("* => RegisterPage", slideDownAnimation),
	transition("RegisterPage => *", slideUpAnimation),
	transition("* => *", fadeAnimation),
]);
