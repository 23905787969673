import { Component, HostListener, Inject, Injector, Type } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { Logger, LoggerLocator } from "@tsng/logging";
import { RENDERED_BY, RenderEnvironment } from "@twly/core";
import { BehaviorSubject } from "rxjs";
import { CapacitorCheckForUpdateService } from "../../../twly/core/src/lib/capacitor/checkForUpdate/service";
import { DeepLinkService } from "../../../twly/core/src/lib/capacitor/deep-link/service";
import { loginRouteAnimation } from "./animations/login.animation";
import { SplashScreenService } from "./core/splash-screen/service";

@Component({
	selector: "app-root",
	template: `
		<div class="install-app" *ngIf="$showAppInstallBanner | async">
			<button type="button" class="close-button icon-button" (click)="$showAppInstallBanner.next(false)"><mat-icon>close</mat-icon></button>
			<img src="assets/icons/icon-192x192.png">
			<div class="text">
				<h1 i18n="Install unbored message|The install unbored message@@AppComponentInstallUnboredMessage">Install Unbored</h1>
			</div>
			<div class="actions">
				<button class="text-button install-button" (click)="appInstallEvent.prompt()"
						i18n="Install unbored button|The install unbored button@@AppComponentInstallUnboredButton">Install</button>
			</div>
		</div>
		<router-outlet name="modal"></router-outlet>
		<div class="content">
			<router-outlet #outlet="outlet"></router-outlet>
		</div>`,
	styleUrls: ["./component.scss"],
	animations: [loginRouteAnimation]
})
export class AppComponent {
	title = "ROOT";
	private logger: Logger = LoggerLocator.getLogger("AppComponent")();
	$showAppInstallBanner = new BehaviorSubject<boolean>(false);
	appInstallEvent: any;
	@HostListener("window:beforeinstallprompt", ["$event"])
	onBeforeInstallPrompt(event: Event) {
		if(this.renderedBy !== RenderEnvironment.BROWSER) return;
		this.logger.debug("onBeforeInstallPrompt");
		event.preventDefault();
		this.appInstallEvent = event;
		this.$showAppInstallBanner.next(true);
	}

	servicesToBootstrap: Type<unknown>[] = [
		SplashScreenService,
		CapacitorCheckForUpdateService,
		DeepLinkService
	];

	constructor(private titleService: Title, private injector: Injector, @Inject(RENDERED_BY) private renderedBy: RenderEnvironment) {
		this.titleService.setTitle($localize`:Application title|The title of application@@AppComponentApplicationTitle:Unbored - Never a dull moment`);
		this.servicesToBootstrap.forEach(service => this.injector.get(service));
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["label"];
	}
}
