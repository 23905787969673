import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	$not: {...}
 * }
 */
export class NotOperator implements Operator {
	constructor(private child: Operator) {
	}

	build(): object {
		return {
			"$not": this.child.build()
		};
	}

	clone(): NotOperator {
		return new NotOperator(this.child.clone());
	}
}