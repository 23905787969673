import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { TsNgStoreModule } from "@tsng/store";
import { TsNgUploadComponent } from "./component/upload/component";
import { TsNgUploadLabelDirective } from "./directive/label/directive";
import { TsNgUploadPlaceholderDirective } from "./directive/placeholder/directive";
import { TsNgQueuedFileDirective } from "./directive/queued-file/directive";
import { TsNgServerFileDirective } from "./directive/server-file/directive";
import { FILE_REDUCER_TOKEN, fileReducerProvider } from "./server-file/reducer";

@NgModule({
	declarations: [
		TsNgUploadComponent,
		TsNgServerFileDirective,
		TsNgQueuedFileDirective,
		TsNgUploadPlaceholderDirective,
		TsNgUploadLabelDirective
	],
	imports: [
		CommonModule,
		StoreModule.forFeature("file", FILE_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["file/create", "file/deleted", "file/updated", "file/created", "file/uploaded"])
	],
	providers: [
		{
			provide: FILE_REDUCER_TOKEN,
			useFactory: fileReducerProvider
		}
	],
	exports: [
		TsNgUploadComponent,
		TsNgServerFileDirective,
		TsNgQueuedFileDirective,
		TsNgUploadPlaceholderDirective,
		TsNgUploadLabelDirective
	]
})
export class TsNgFileModule {
}
