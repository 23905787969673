import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialSiteActivityStore, SiteActivity, SiteActivityConversion } from "./model";

export const ACTIVITY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("SiteActivityReducer");

export function siteActivityReducerProvider() {
	return reducerFactory("siteActivity", initialSiteActivityStore, SiteActivity, SiteActivityConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("siteActivity", ACTIVITY_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["siteActivity/deleted", "siteActivity/updated", "siteActivity/created"])
	],
	providers: [
		{
			provide: ACTIVITY_REDUCER_TOKEN,
			useFactory: siteActivityReducerProvider
		}
	]
})
export class SiteActivityCoreModule {

}
