import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { Action, EventBus, Message, Util } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { take } from "rxjs/operators";
import { SnackbarCreateAction, SnackbarCreatedAction, SnackbarDeletedAction, SnackbarLevel } from "../action";
import { SnackbarComponent } from "../inner/component";

@Injectable({
	providedIn: "root"
})
export class SnackbarService {
	private logger: Logger = LoggerLocator.getLogger("SnackbarService")();

	constructor(private eventBus: EventBus, private snackbar: MatSnackBar) {
		this.eventBus.localConsumer<SnackbarCreateAction>("snackbar/create")
			.subscribe(this.createSnackbar.bind(this));
	}

	private createSnackbar(message: Message<SnackbarCreateAction>) {
		const action = message.body;
		const uuid = Util.generateUUID();
		const snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, {
			data: action.data,
			duration: action?.data?.duration ?? 5000,
			politeness: (action?.data?.level === SnackbarLevel.ERROR) ? "assertive" : "polite",
			announcementMessage: action.data.message,
			panelClass: `level-${action?.data?.level ?? SnackbarLevel.DEFAULT}`,
			horizontalPosition: "center",
			verticalPosition: "bottom",
			direction: "ltr"
		});

		this.logger.debug("Creating snackbar from action", {
			action,
			uuid,
			snackbarRef
		});

		// Snackbar is created
		snackbarRef.afterOpened().pipe(take(1)).subscribe(() => {
			this.logger.debug("Created snackbar", {
				action,
				uuid,
				snackbarRef
			});

			message.reply<SnackbarCreatedAction>(this.createSnackbarCreatedAction(snackbarRef, uuid));
		});

		// Snackbar is deleted
		snackbarRef.afterDismissed().pipe(take(1)).subscribe(() => {
			this.logger.debug("Deleted (hidden) snackbar", {
				action,
				uuid,
				snackbarRef
			});

			const deletedAction = this.createSnackbarDeletedAction(uuid);
			this.eventBus.send<SnackbarDeletedAction>(uuid, deletedAction);
		});
	}

	private createSnackbarCreatedAction(snackbarRef: MatSnackBarRef<SnackbarComponent>,
		uuid: string
	): SnackbarCreatedAction {
		return new Action("snackbar/created", {
			close: () => snackbarRef.dismiss(),
			uuid
		});
	}

	// noinspection JSMethodCanBeStatic
	private createSnackbarDeletedAction(uuid: string): SnackbarDeletedAction {
		return new Action("snackbar/deleted", {
			uuid
		});
	}
}
