import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type PropertyStore = ModelMap<Property>;

export const initialPropertyStore: PropertyStore = <PropertyStore>Map();

export const PropertyConversion: ActionConverter.Config = {};

export interface PropertyModel extends StoreModel {
	name: string;
	icon: string;
}

export class Property extends Record({
	id: 0,
	rev: 0,
	name: "",
	icon: ""

}) implements PropertyModel {

	id: number;
	rev: number;
	name: string;
	icon: string;

	get displayName(): string {
		return this.name;
	};
}
