<header>
	<h1
		i18n="Splash screen title|The title of the splash screen@@SplashScreenTitle">Join the fun!</h1>
	<button class="icon-button" (click)="close.emit(true)" *ngIf="closableTexts.indexOf(text) > -1"
			aria-label="Close splashscreen"
			i18n-aria-label="Close button|The splashscreen close button@@SplashScreenComponentCloseMenu"
	>
		<mat-icon>close</mat-icon>
	</button>
</header>
<img
	src="assets/images/splash-screen.png"
	alt="splash screen image"
	i18n-alt="Alt tag|The alt tag on the splash screen image@@SplashScreenComponentImageAlt"
>

<ng-container [ngSwitch]="text">
	<p
		*ngSwitchCase="'DEFAULT-LOGIN'"
		i18n="Login splash screen standard message|The standard message on the login splash screen@@SplashScreenComponentDefaultLoginMessage"
	>
		Login for personal recommendations and more options to find the best activities in your area!
	</p>
	<p
		*ngSwitchCase="'CHAT-LOGIN'"
		i18n="Login splash screen group message|The group message on the login splash screen@@SplashScreenComponentChatLoginMessage"
	>
		Login to create groups with friends and share activities with friends and family!
	</p>
	<p
		*ngSwitchCase="'CHAT-NO-GROUPS'"
		i18n="chat splash screen no groups message|The no groups message on the chat splash screen@@SplashScreenComponentNoChatsMessage"
	>
		You don't have any groups!
		<br>
		Create a group to chat and share activities with friends and family!
	</p>
	<p
		*ngSwitchCase="'NO-ACTIVITIES-HOME'"
		i18n="No activities spash screen|The no activities splash screen@@SplashScreenComponentNoActivitiesHome"
	>
		There are no activities on the current selected location. Please select another location or increase
		the search radius.
	</p>

	<p
		*ngSwitchCase="'NO-ACTIVITIES-DISCOVER'"
		i18n="No discover activities spash screen|The no discover activities splash screen@@SplashScreenComponentNoActivitiesDiscover"
	>
		There are no activities found with the current filters. Please select another location, increase
		the search radius or change the filters.
	</p>
</ng-container>

<ng-container [ngSwitch]="text">
	<button
		*ngSwitchCase="'CHAT-NO-GROUPS'"
		class="text-button primary-button"
		[routerLink]="['/user-group/add']"
		(click)="close.emit(true)"
		i18n="Create group button|Text inside the create group button@@SplashScreenComponent.createGroupButton"
	>
		Create group
	</button>
	<ng-template *ngSwitchCase="'NO-ACTIVITIES-HOME'">
	</ng-template>
	<ng-template *ngSwitchCase="'NO-ACTIVITIES-DISCOVER'">
	</ng-template>
	<button
		*ngSwitchDefault
		class="text-button primary-button"
		[routerLink]="['/login']"
		(click)="close.emit(true)"
		i18n="Login button|Text inside the login button@@CommonLoginButton"
	>
		Login
	</button>
</ng-container>


<ng-template #elseTemplate>

</ng-template>
