import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Meta, MetaDefinition } from "@angular/platform-browser";

@Injectable({
	providedIn: "root"
})
export class MetaService {
	private renderer: Renderer2;

	constructor(
		private meta: Meta,
		@Inject(DOCUMENT) private document: Document,
		private renderFactory: RendererFactory2
	) {
		this.renderer = this.renderFactory.createRenderer(null, null);
	}

	public resetTags() {
		const title = "Unbored - Altijd iets te doen!";
		const description = "Unbored, ontdek alle activiteiten bij jou in de buurt.";

		this.deleteEventData();
		this.deleteCanonicalUrl();
		this.setTitle(title);
		this.setTags([
			{
				name: "description",
				content: description
			},  {
				property: "og:title",
				content: title
			}, {
				property: "og:type",
				content: "website"
			}, {
				property: "og:description",
				content: description
			}, {
				property: "og:image",
				id: "og-main-image",
				content: "https://unbored.io/assets/images/social-tile.png"
			}, {
				property: "og:image:width",
				id: "og-main-image-width",
				content: "700"
			}, {
				property: "og:image:height",
				id: "og-main-image-height",
				content: "700"
			}, {
				property: "og:url",
				content: "https://www.unbored.io/"
			}
		]);
	}

	public setTags(tags: MetaDefinition[]) {
		this.deleteTags(tags);
		this.meta.addTags(tags);
	}

	public setCanonicalUrl(canonicalUrl: string) {
		this.deleteCanonicalUrl();
		let link = this.renderer.createElement("link");
		link.id = "canonical-url";
		link.rel = "canonical";
		link.href = canonicalUrl;
		this.renderer.appendChild(this.document.head, link);
	}

	public setEventData(list: any[]) {
		this.deleteEventData();
		let script = this.renderer.createElement("script");
		script.id = "event-data";
		script.type = `application/ld+json`;
		script.text = JSON.stringify(list, null, 4);
		this.renderer.appendChild(this.document.head, script);
	}

	private deleteTags(tags: MetaDefinition[]) {
		tags.forEach(tag => {
			let domTags = [];
			if(tag.hasOwnProperty("name")) {
				domTags = this.meta.getTags(`name='${tag.name}'`);
			} else {
				domTags = this.meta.getTags(`property='${tag.property}'`);
			}
			if (domTags.length > 0) {
				this.meta.removeTagElement(domTags[0]);
			}
		});
	}

	private deleteEventData() {
		const element = this.document.getElementById("event-data");
		if (element != null) {
			this.renderer.removeChild(this.document.head, this.document.getElementById("event-data"));
		}
	}

	private deleteCanonicalUrl() {
		const element = this.document.getElementById("canonical-url");
		if (element != null) {
			this.renderer.removeChild(this.document.head, this.document.getElementById("canonical-url"));
		}
	}

	setTitle(title: string) {
		this.deleteByTagName("title");
		let titleElement = this.renderer.createElement("title");
		titleElement.innerHTML = title;
		this.renderer.appendChild(this.document.head, titleElement);
	}

	private deleteByTagName(name: string) {
		const element = this.document.head.getElementsByTagName(name);
		let i = element.length;
		while(i-- > 0) {
			this.renderer.removeChild(this.document.head, element[i]);
		}
	}
}
