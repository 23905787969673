import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerDomain, CrawlerDomainConversion, initialCrawlerDomainStore } from "./model";

export const CRAWLERDOMAINLOOKUP_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CrawlerDomainReducer");

export function crawlerDomainReducerProvider() {
	return reducerFactory("crawlerDomain", initialCrawlerDomainStore, CrawlerDomain, CrawlerDomainConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerDomain", CRAWLERDOMAINLOOKUP_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["crawlerDomain/deleted", "crawlerDomain/updated", "crawlerDomain/created"])
	],
	providers: [
		{
			provide: CRAWLERDOMAINLOOKUP_REDUCER_TOKEN,
			useFactory: crawlerDomainReducerProvider
		}
	]
})
export class CrawlerDomainCoreModule {

}
