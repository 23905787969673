export interface FailureObject {
	address: string,
	failureType: string,
	failureCode: number,
	message: string | object,
	type?: string
}

export class ErrorMessage {
	readonly address: string;
	readonly type: string;
	readonly failureType: string;
	readonly failureCode: number;
	readonly message: string | object;

	constructor(errorMessage: FailureObject) {
		this.address = errorMessage.address;
		this.type = errorMessage.type || "err";
		this.failureCode = errorMessage.failureCode;
		this.failureType = errorMessage.failureType;
		this.message = errorMessage.message;
	}

	public toJSON(): FailureObject {
		return {
			address: this.address,
			failureType: this.failureType,
			failureCode: this.failureCode,
			message: this.message,
			type: this.type
		};
	}
}