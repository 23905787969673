import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	ComponentRef,
	Inject,
	OnDestroy,
	OnInit,
	ViewContainerRef
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Logger, LoggerLocator } from "@tsng/logging";
import { from, ReplaySubject } from "rxjs";
import { first, map, switchMap, takeUntil } from "rxjs/operators";
import {
	ACTION_TOKEN_COMPONENT_CONFIGS, ActionTokenComponentConfig, TokenRequiresMoreInformation
} from "../model";

@Component({
	selector: "tsng-action-token-page",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: ""
})
export class ActionTokenPage implements OnInit, OnDestroy {
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	private readonly logger: Logger = LoggerLocator.getLogger("ActionTokenPage")();

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef,
		private changeDetectorRef: ChangeDetectorRef,
		private activatedRoute: ActivatedRoute,
		@Inject(ACTION_TOKEN_COMPONENT_CONFIGS) private availableComponentConfigs: ActionTokenComponentConfig<unknown>[]
	) {
	}

	ngOnInit() {
		this.activatedRoute.data.pipe(
			takeUntil(this.destroyed),
			first(data => data.hasOwnProperty("tokenConfig")),
			map(data => data.tokenConfig),
			switchMap(componentConfig => from(this.renderComponent(componentConfig)))
		).subscribe((componentRef) => {
			this.logger.debug("Component has been initialized", {
				componentRef
			});
		}, error => {
			this.logger.fatal("Component could not be initialized", {
				error
			});
		});
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	private async renderComponent<T>(config: ActionTokenComponentConfig<T>): Promise<ComponentRef<T>> {
		const {loader} = config;
		const loadedComponentClass = await loader();
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loadedComponentClass);

		this.viewContainerRef.clear();
		const componentRef = this.viewContainerRef.createComponent(componentFactory);
		componentRef.changeDetectorRef.markForCheck();
		componentRef.changeDetectorRef.detectChanges();
		return componentRef;
	}
}
