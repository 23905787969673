import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	$or: [ ... ]
 * }
 */
export class OrOperator implements Operator {
	constructor(private children: Operator[] = []) {
	}

	add(operator: Operator): OrOperator {
		this.children.push(operator);
		return this;
	}

	remove(operator: Operator): OrOperator {
		this.children = this.children.reduce((reduction, value) => {
			if (value === operator) return reduction;
			reduction.push(value);
			return reduction;
		}, []);
		return this;
	}

	getChildren(): Operator[] {
		return this.children;
	}

	build(): object {
		const or = this.children.map(value => value.build())
			.filter(value => value != null);

		if (or.length <= 0) return null;
		return {
			"$or": or
		};
	}

	clone(): OrOperator {
		return new OrOperator(this.children.length > 0 ? [...this.children.map(child => child.clone())] : []);
	}
}
