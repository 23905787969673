import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AbstractFieldComponent } from "../abstract-field/component";

@Component({
	selector: "tsng-textarea",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TsNgTextAreaFieldComponent),
			multi: true

		}
	]
})
export class TsNgTextAreaFieldComponent extends AbstractFieldComponent {
	@Input() name: string = "";
	@Input() rows: number;

	constructor(injector: Injector) {
		super(injector);
	}
}
