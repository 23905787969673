import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { FilterAction, FilterResult } from "@tsng/store";
import { tap } from "rxjs/operators";
import { EsFilteredAction } from "../../handlers/es-filter/es-filtered-action";

@Injectable({
	providedIn: "root"
})
export class TileFilterHandler {
	address = "tile-front/filter";
	private logger = LoggerLocator.getLogger("TileFilterHandler")();
	private cachedFilters = new Map();
	private myTileSectionId = 1;

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
		this.listenToCacheClearMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilterAction>(this.address)
			.subscribe(message => {
				this.handleLocalFilter(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private listenToCacheClearMessages() {
		this.eventBus.localConsumer("tile-front/cache-clear").subscribe(message => {
			this.cachedFilters.clear();

			if(message.getReplyAddress() != null) {
				message.reply(new Action("tile-front/cache-cleared", {}));
			}
		});
	}

	private handleLocalFilter(message: Message<FilterAction>) {
		const data = message.body.data;
		const clone = Object.assign<unknown, any>({}, data);
		const entityPrefix = message.body.type.split("/")[0];
		clone.filter = data.filter.build();
		const filterAction = new Action(`${entityPrefix}/filter`, clone);
		const filterHash = JSON.stringify(clone.params) + clone.search;
		if(this.cachedFilters.has(filterHash)) {
			message.reply<FilterResult>(new Action("entity/filtered", this.cachedFilters.get(filterHash)));
			return;
		}

		this.eventBus.request<FilterAction, EsFilteredAction>(filterAction.type, filterAction)
			.pipe(tap((filterResult) => {
				const body = Object.assign({}, filterResult.body.data);
				delete body.count;
				this.store.dispatch(new Action("tile/received", {
					items: body.items
				}));
				delete body.items;

				Object.entries(body).forEach(([key, value]) => {
					this.store.dispatch(new Action(key + "/received", {
						items: value
					}));
				});
			}))
			.subscribe(filterResult => {
				const ids = filterResult.body.data.items.map(item => item.id) as string[] | number[];
				const totalCount = filterResult.body.data.count;
				// only cache tile sections that are not my list
				if(clone.params.tileSectionIds.indexOf(this.myTileSectionId) === -1) {
					this.cachedFilters.set(filterHash, {ids, totalCount});
				}
				message.reply<FilterResult>(new Action("entity/filtered", {
					ids,
					totalCount
				}));
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}

}
