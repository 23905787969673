import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { ImageSet, ImageSetConversion, initialImageSetStore } from "./model";

export const IMAGE_SET_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("ImageSetReducer");

export function imageSetReducerProvider() {
	return reducerFactory("imageSet", initialImageSetStore, ImageSet, ImageSetConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("imageSet", IMAGE_SET_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["imageSet/deleted", "imageSet/updated", "imageSet/created"])
	],
	providers: [
		{
			provide: IMAGE_SET_REDUCER_TOKEN,
			useFactory: imageSetReducerProvider
		}
	]
})
export class ImageSetCoreModule {

}
