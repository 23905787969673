import { Routes } from "@angular/router";
import { confirmEmailModalRoute } from "./component/confirm-email-modal/route";
import { BasicLayoutComponent } from "./component/layout/basic/component";
import { loginModalRoute } from "./component/login-modal/route";
import { TwlyAuthorizationGuard } from "./core/authorization-guard/authorization-guard";
import { IncompleteAccountGuard } from "./core/incomplete-account-guard/guard";
import { LoginAuthorizationGuard } from "./core/login-authorization-guard/guard";
import { PendingTokenGuard } from "./core/pending-token-guard/guard";
import { RouteLoggingGuard } from "./core/route-loggin-guard/guard";
import { changeEmailFormModalRoute } from "./route/user-group/change-email.route";
import { changePasswordFormModalRoute } from "./route/user-group/change-password.route";

export const routes: Routes = [
	loginModalRoute,
	confirmEmailModalRoute,
	changePasswordFormModalRoute,
	changeEmailFormModalRoute,
	{
		path: "",
		component: BasicLayoutComponent,
		children: [
			{
				path: "unsubscribe",
				canActivate: [],
				loadChildren: () => import("./route/unsubscribe/module").then(mod => mod.UnsubscribePageModule)
			},{
				path: "token",
				canActivate: [LoginAuthorizationGuard],
				data: {
					label: "TokenPage"
				},
				loadChildren: () => import("@tsng/common/token").then(mod => mod.TsNgCommonActionTokenModule)
			},
			{
				path: "invite",
				canActivate: [LoginAuthorizationGuard],
				data: {
					label: "TokenPage"
				},
				loadChildren: () => import("@tsng/common/token").then(mod => mod.TsNgCommonActionTokenModule)
			},
			{
				path: "discover",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/discover/module").then(mod => mod.DiscoverPageModule)

			},{
				path: "search",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/search/module").then(mod => mod.SearchPageModule)

			},{
				path: "activity",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/activity/module").then(mod => mod.ActivityPageModule)

			}, {
				path: "login",
				data: {permission: "login/view"},
				canActivate: [LoginAuthorizationGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/login/module").then(mod => mod.LoginPageModule)
			}, {
				path: "reset-password",
				data: {permission: "login/view"},
				canActivate: [LoginAuthorizationGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/request-password-reset/module").then(mod => mod.RequestPasswordResetModule)
			}, {
				path: "register",
				data: {permission: "registration/view"},
				canActivate: [LoginAuthorizationGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/register/module").then(mod => mod.RegisterPageModule)
			}, {
				path: "chats",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/chat/module").then(mod => mod.ChatOverviewPageModule)
			},{
				path: "interests",
				canActivate: [TwlyAuthorizationGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/interest/module").then(mod => mod.InterestsPageModule)
			},{
				path: "finish-registration",
				canActivate: [TwlyAuthorizationGuard],
				loadChildren: () => import("./route/finish-registration/module").then(mod => mod.FinishRegistrationPageModule)
			}, {
				path: "user-group",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				data: {permission: "userGroup/view"},
				loadChildren: () => import("./route/user-group/module").then(mod => mod.UserGroupPageModule)
			}, {
				path: "account",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				data: {permission: "user/update"},
				loadChildren: () => import("./route/account/module").then(mod => mod.AccountPageModule)
			}, {
				path: "location",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/location/module").then(mod => mod.LocationPageModule)
			}, {
				path: "tile-section",
				canActivate: [TwlyAuthorizationGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/tile-section/module").then(mod => mod.TileSectionRouteModule)
			}, {
				path: "about-unbored",
				canActivate: [RouteLoggingGuard],
				loadChildren: () => import("./route/static-content/module").then(mod => mod.StaticContentPageModule)
			},{
				path: "privacy-statement",
				canActivate: [RouteLoggingGuard],
				loadChildren: () => import("./route/static-content/module").then(mod => mod.StaticContentPageModule)
			}, {
				path: "terms-and-conditions",
				canActivate: [RouteLoggingGuard],
				loadChildren: () => import("./route/static-content/module").then(mod => mod.StaticContentPageModule)
			}, {
				path: "confirm-account-delete",
				canActivate: [RouteLoggingGuard],
				loadChildren: () => import("./route/confirm-account-delete/module").then(mod => mod.RequestPasswordResetModule)
			}, {
				path: "",
				pathMatch: "full",
				canActivate: [TwlyAuthorizationGuard, IncompleteAccountGuard, PendingTokenGuard, RouteLoggingGuard],
				loadChildren: () => import("./route/home/module").then(mod => mod.HomePageModule)

			}, {
				path: "**",
				redirectTo: ""
			}
		]
	}
];
