import { Injectable } from "@angular/core";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";
import { CountedAction } from "../action/counted";
import { FilterAction } from "../action/filter";
import { FilteredAction } from "../action/filtered";
import { FilterResult } from "../filter/filter-result";

@Injectable({
	providedIn: "root"
})
export class FilterHandler {
	address = "entity/filter";
	private logger = LoggerLocator.getLogger("FilterHandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilterAction>(this.address)
			.subscribe(message => {
				this.handleLocalFilter(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalFilter(message: Message<FilterAction>) {
		const data: any = message.body.data;

		const entityPrefix = message.body.type.split("/")[0];
		data.filter = data.filter.build();
		const countAction = new Action(`${entityPrefix}/count`, data);
		const filterAction = new Action(`${entityPrefix}/filter`, data);

		combineLatest([
			this.eventBus.request<FilterAction, CountedAction>(countAction.type, countAction),
			this.eventBus.request<FilterAction, FilteredAction>(filterAction.type, filterAction)
		]).pipe(tap(([countResult, filterResult]) => {
			this.eventBus.send<FilteredAction>("store/sync", filterResult.body);
		}))
			.subscribe(([countResult, filterResult]) => {
				const ids = filterResult.body.data.items.map(item => item.id) as string[] | number[];
				const totalCount = countResult.body.data.count;
				message.reply<FilterResult>(new Action("entity/filtered", {
					ids,
					totalCount
				}));
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
