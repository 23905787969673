import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { Company, CompanyConversion, initialCompanyStore } from "./model";

export const COMPANY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CompanyReducer");

export function companyReducerProvider() {
	return reducerFactory("company", initialCompanyStore, Company, CompanyConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("company", COMPANY_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["company/deleted", "company/updated", "company/created"])
	],
	providers: [
		{
			provide: COMPANY_REDUCER_TOKEN,
			useFactory: companyReducerProvider
		}
	]
})
export class CompanyCoreModule {

}
