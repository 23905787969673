import { Inject, Injectable } from "@angular/core";
import { SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import {
	Action, EventBus, Message, MESSAGE_CODEC, MessageCodec, MessageType, Socket, SocketStates, Util
} from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { TwentelyAccount } from "@twly/core";
import { merge, Observable, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { ConnectionStatus, ConnectionTarget } from "../target";

@Injectable({
	providedIn: "root"
})
export class SocketConnector implements ConnectionTarget {
	private logger = LoggerLocator.getLogger("SocketConnector")();

	constructor(private socket: Socket, private eventBus: EventBus, @Inject(MESSAGE_CODEC) private codec: MessageCodec<any, any>) {
	}

	pushEnabled(): boolean {
		return true;
	}

	canConnect(principal: TwentelyAccount): boolean {
		return principal.authRoleId !== 10;
	}

	connect(principal: TwentelyAccount, accessToken: string): Observable<SocketStates | null> {
		if (this.socket.status === SocketStates.OPEN) {
			return of(null);
		}

		return this.socket.open().pipe(
			switchMap(state => {
				if(state !== SocketStates.OPEN) {
					return of(state);
				}
				const message = (new Message("socket/set-user", MessageType.SEND, new Action("socket/set-user", {accessToken: accessToken}), null, this.codec));
				message.setReplyAddress(this.generateReplyAddress());
				this.socket.send(message.toJSON());


				return this.socket.messages.pipe(
					filter(message => (message?.body as any)?.type === "socket/user-set"),
					switchMap(message => of(state))
				)
			}),
			switchMap(state => {
			return this.connectedToSocket(principal).pipe(map(() => state));
		}));
	}

	// copy of eventbus#generateReplyAddress
	private generateReplyAddress(): string {
		// todo how to resolve this, vertx reply + uuid is 50 chars and backend has max on 36
		return ("__vertx.reply." + Util.generateUUID()).slice(0, 36);
		// return "__vertx.reply." + Util.generateUUID();
	}

	connectionStatus(): Observable<ConnectionStatus> {
		return this.socket.statusObservable.pipe(map(status => {
			switch (status) {
				case SocketStates.CLOSED:
					return ConnectionStatus.DISCONNECTED;
				case SocketStates.OPENING:
					return ConnectionStatus.CONNECTING;
				case SocketStates.OPEN:
					return ConnectionStatus.CONNECTED;
				case SocketStates.CLOSING:
					return ConnectionStatus.DISCONNECTING;
				default:
					return ConnectionStatus.DEFAULT;
			}
		}));
	}

	private connectedToSocket(principal: TwentelyAccount): Observable<unknown> {
		return new Observable<unknown>(observer => {
			const userChannels = [
				this.eventBus.consumer(`user/${principal.id}`)
			];
			if ([100, 150, 1000].includes(principal.authRoleId)) {
				userChannels.push(this.eventBus.consumer("user/0"));
			}

			let successfulRegistrations = 0;
			let internalUserChannelSubscriptions = merge(...userChannels)
				.pipe(tap(event => {
					if (event.hasOwnProperty("success") && event["success"] === true) {
						successfulRegistrations++;
						if (successfulRegistrations === userChannels.length) {
							observer.next(new Action("internal/socket-connected",
								principal.id,
								principal.rev,
								principal
							));
						}
					}
				}), filter(message => message.hasOwnProperty("success") === false))
				.subscribe({
					next: event => {
						// todo this should not be handled within the authentication handler nor in the
						//  socket connection class..
						// Filter out snackbar messages
						const action = event.body as Action;
						if (action.type.startsWith("user/") && (action.data["title"] != null || action.data["text"] != null)) {
							// Display snackbar
							this.eventBus.send("snackbar/create",
								new Action<SnackbarCreateData>("snackbar/create", {
									message: action.data["text"] || "Unknown message text :(",
									level: SnackbarLevel.SUCCESS
								})
							);
							return;
						}

						this.eventBus.send(action.type, event.body);
					},
					error: error => {
						this.logger.fatal("Unable to subscribe to user channels", {error});
						observer.error(error);
					},
					complete: () => {
						this.logger.error("User channel observables closed");
					}
				});

			// Clear all existing inner subscriptions.
			return () => {
				internalUserChannelSubscriptions.unsubscribe();
				internalUserChannelSubscriptions = null;
			};
		});
	}
}
