import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { LoggerLocator } from "@tsng/logging";
import { AndOperator, EqualOperator, SourceProvider } from "@tsng/store";
import { TwentelyAccount, UserGroup } from "@twly/core";
import { OrderedMap } from "immutable";
import { Observable, of, OperatorFunction } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AccountProvider } from "../../../../../twly/core/src/lib/account-provider/account-provider";

@Injectable({
	providedIn: "root"
})
export class IncompleteAccountGuard implements CanActivate {
	private logger = LoggerLocator.getLogger("IncompleteAccountGuard")();

	constructor(private accountProvider: AccountProvider,
		private router: Router,
		private sourceBuilder: SourceProvider
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.accountProvider.getAccountObservable()
			.pipe(switchMap(account => {
				if (account.hasPermission("userGroup/view") !== true) {
					// we don't check guest users for the progress.
					return of(true);
				}

				return of(account).pipe(this.checkIfAccountRegistrationCompleted());
			}));
	}

	checkIfAccountRegistrationCompleted(): OperatorFunction<TwentelyAccount, true | UrlTree> {
		return (source: Observable<TwentelyAccount>) => source.pipe(switchMap(account => {
			const userGroupSource = this.sourceBuilder.getBuilder()
				.main("userGroup")
				.build();
			userGroupSource.addOperator("currentAccountPrivateGroup", new AndOperator([
				new EqualOperator("groupType", "private"),
				new EqualOperator("userGroupMember.userId", account.id)
			]));

			return userGroupSource.dataChanged()
				.pipe(map((userGroup: OrderedMap<number, { userGroup: UserGroup }>) => {
					if(userGroup.isEmpty()) {
						this.logger.fatal("User groups where empty", userGroup)
						return true;
					}

					const group = userGroup.first().userGroup;
					if (group.id != 10) {
						// Only check for non-guest accounts
						if (group.location == null || group.location.longitude == null || group.location.latitude == null) {
							return this.router.createUrlTree(["/finish-registration"]);
						}
					}
					return true;
				}));
		}));
	}
}
