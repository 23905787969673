import { Action } from "@tsng/core";
import { Map } from "immutable";

export function state(state: Map<string, any> = Map([]), action: Action) {
	switch (action.type) {
		case "state/set":
			return state.set(action.id.toString(), action.data);

		case "state/clear":
			return state.filterNot((value, id) => {
				return id.includes(action.id.toString());
			});

		case "store/logout":
			// case "principal/received": todo find a proper solution upon switching accounts.
			return Map([]);

		default:
			return state;
	}
}
