import {
	AfterViewInit, ChangeDetectorRef, Component, Directive, HostBinding, Injector, Input, OnDestroy, OnInit
} from "@angular/core";
import { ControlValueAccessor, NgControl, FormControl } from "@angular/forms";
import { Logger, LoggerLocator } from "@tsng/logging";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TsFormControl } from "../../control/default/control";
import { HintResults } from "../../control/hint";
import { TsNgRootFormGroupDirective } from "../../directive/root-form-group/directive";

@Directive()
export abstract class AbstractFieldComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
	id: string = "";
	@Input() placeholder = "";
	@Input() readonly: boolean = false;
	@Input() hidden: boolean = false;
	/** @private internal field which shouldn't be accessed outside the template or internally */
	_formControl = new FormControl(null);
	protected control: TsFormControl;
	protected root: TsNgRootFormGroupDirective | undefined;
	protected logger: Logger = LoggerLocator.getLogger("AbstractFieldComponent")();
	protected destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	protected changeDetectorRef: ChangeDetectorRef;

	constructor(protected injector: Injector) {
		this.changeDetectorRef = this.injector.get(ChangeDetectorRef);
		this.id = `internal-input-${Math.random().toString(36).substr(2, 9)}`;
	}

	@HostBinding("style.display") get isVisible(): string {
		return ((this.hidden === true) ? "none" : "block");
	}

	@HostBinding("class.highlight-as-invalid") get showInvalidHighlightedState(): boolean {
		return (this.showHighlightedState() && this.control.invalid);
	}

	ngAfterViewInit(): void {
		const ngControl: NgControl = this.injector.get(NgControl, null);
		if (ngControl == null) {
			return;
		}

		const root: TsNgRootFormGroupDirective = this.injector.get(TsNgRootFormGroupDirective, null);
		if (root != null) {
			this.root = root;

			this.root.onSubmit.pipe(takeUntil(this.destroyed)).subscribe(() => {
				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			});
		}

		// @ts-ignore ignore type hinting here.
		this.control = ngControl.control as TsFormControl;
		this.changeDetectorRef.markForCheck();
		this.changeDetectorRef.detectChanges();
	}

	getHintResults(): HintResults {
		if (this.showHighlightedState() === false) {
			return null;
		}

		return this.control.hints;
	}

	showHighlightedState(): boolean {
		if (this.control == null || this.control.hints == null) {
			return false;
		}

		if (this.root != null && this.control.updateOn === "submit" && this.root.isSubmitted) {
			return true;
		}

		if (this.control.updateOn === "blur" && (this.control.touched || (this.root != null && this.root.isSubmitted))) {
			return true;
		}

		return this.control.updateOn === "change" && this.control.dirty;
	}

	isRequired(): boolean {
		if (this.control == null || this.control.configuredHints == null) {
			return false;
		}

		return this.control.configuredHints.findIndex(hint => hint.name === "required") > -1;
	}

	ngOnInit(): void {
		this._formControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe(value => {
			this.onChange(value);
		});
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onFocus = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled === true) {
			this._formControl.disable({
				onlySelf: true,
				emitEvent: false
			});

			return;
		}

		this._formControl.enable({
			onlySelf: true,
			emitEvent: false
		});
	}

	writeValue(value: any): void {
		this._formControl.setValue(value, {
			emitModelToViewChange: true,
			emitViewToModelChange: false,
			onlySelf: true,
			emitEvent: false
		});
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	onFocus: () => void = () => {
		this.logger.fatal(
			"#onTouchedListener registerOnTouched was not called, this can happen if there is no [formControl] or formControlName given");
	};

	onChange: (value: any) => void = (value: any) => {
		this.logger.fatal(
			"#onChangeListener registerOnChange was not called, this can happen if there is no [formControl] or formControlName given");
	};
}
