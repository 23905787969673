import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type SiteStore = ModelMap<Site>;

export const initialSiteStore: SiteStore = <SiteStore>Map();

export const SiteConversion: ActionConverter.Config = {};

export interface Location {
	latitude: number,
	longitude: number
}

export interface SiteModel extends StoreModel {
	url: string;
	venueId: string;
	name: string;
	narrative: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	cityId: number | null;
	phone: string;
	email: string;
	location: Location;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	propertyIds: string[];
	openingHours: [];
	closed: [];
	errorLevel: number;
	errors: object[];
	publishedRev: number;
	relevanceStatus: number;
	venueType: string;
	remark: string;
	entryStatus: number;
}

export class Site extends Record({
	id: 0,
	rev: 0,
	url: "",
	venueId: "",
	name: "",
	narrative: "",
	street: "",
	houseNumber: "",
	zipCode: "",
	city: "",
	cityId: null,
	phone: "",
	email: "",
	location: {
		latitude: 0,
		longitude: 0
	},
	audienceIds: [],
	tagIds: [],
	tags: [],
	propertyIds: [],
	openingHours: [],
	closed: [],
	errorLevel: 0,
	errors: [],
	publishedRev: 0,
	relevanceStatus: 0,
	venueType: "",
	remark: "",
	entryStatus: 0


}) implements SiteModel {

	id: number;
	rev: number;
	url: string;
	venueId: string;
	name: string;
	narrative: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	cityId: number | null;
	phone: string;
	email: string;
	location: Location;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	propertyIds: string[];
	openingHours: [];
	closed: [];
	errorLevel: number;
	errors: object[];
	publishedRev: number;
	relevanceStatus: number;
	venueType: string;
	remark: string;
	entryStatus: number;

	get displayName(): string {
		return this.name;
	};

	get address(): string {
		return this.street + " " + this.houseNumber;
	};

	get streetDisplayName(): string {
		return this.street + " " + this.houseNumber;
	};

	get hasLocation(): boolean {
		return this.location != null && this.location.latitude != null && this.location.longitude != null;
	};

	get hasRevisionChanges(): boolean {
		return this.rev > this.publishedRev;
	};

	get isPublished(): boolean {
		return this.venueId != null;
	};


}
