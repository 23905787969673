import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ModalRouteData } from "@twly/common";

@Component({
	selector: "app-dialog-component",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class AppDialogComponent<T> {
	constructor(@Inject(MAT_DIALOG_DATA) public data: ModalRouteData<T>, public dialog: MatDialog) {
	}

}
