import { ACTION_TOKEN_COMPONENT_CONFIGS } from "@tsng/common/token";

// https://stackoverflow.com/questions/60773079/angular-9-localize-and-xi18n-tagged-template-expressions-are-not-supported-in
// This way the i18n extract process doesn't complain about 'Tagged template expressions are not supported in metadata.'
const getTranslatedToastMessageForEmailChanged = () => $localize`:Your email was successfully changed toast@@EmailSuccessfullyChangedToast:Your email has been changed`;
export const CONFIRM_EMAIL_CHANGE_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/email-updated",
		onSuccess: {
			navigateTo: ["/"],
			toastMessage: "Your email has been changed"
		}
	}
};


// const getTranslatedToastMessageForPasswordChanged = () => $localize`:Your password was successfully changed toast@@PasswordSuccessfullyChangedToast:Your password has been changed`;
// export const CONFIRM_PASSWORD_CHANGE_PROVIDER = {
// 	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
// 	multi: true,
// 	useValue: {
// 		tokenType: "user/update-email",
// 		onSuccess: {
// 			navigateTo: ["/"],
// 			toastMessage: getTranslatedToastMessageForPasswordChanged
// 		}
// 	}
// };
