import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { Location } from "../site/model";

export type CrawlerAddressStore = ModelMap<CrawlerAddress>;

export const initialCrawlerAddressStore: CrawlerAddressStore = <CrawlerAddressStore>Map();

export const CrawlerAddressConversion: ActionConverter.Config = {};

export interface CrawlerAddressModel extends StoreModel {
	crawlerDomainId: number | null;
	sourceCrawlerUrlId: number | null;
	esSourceId: string;
	dateFound: string;
	sourceUrl: string;
	name: string;
	description: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	phone: string;
	email: string;
	url: string;
	relevanceStatus: number;
	location: Location;
	rawSource: object;
}

export class CrawlerAddress extends Record({
	id: 0,
	rev: 0,

	crawlerDomainId: null,
	sourceCrawlerUrlId: null,
	esSourceId: "",
	dateFound: "",
	sourceUrl: "",
	name: "",
	description: "",
	street: "",
	houseNumber: "",
	zipCode: "",
	city: "",
	phone: "",
	email: "",
	url: "",
	relevanceStatus: 0,
	location: {
		latitude: 0.0,
		longitude: 0.0
	},
	rawSource: {}

}) implements CrawlerAddressModel {
	id: number;
	rev: number;

	crawlerDomainId: number | null;
	sourceCrawlerUrlId: number | null;
	esSourceId: string;
	dateFound: string;
	name: string;
	description: string;
	sourceUrl: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	phone: string;
	email: string;
	url: string;
	relevanceStatus: number;
	location: Location;
	rawSource: object;

	get displayName(): string {
		return this.crawlerDomainId == null ? "" : this.crawlerDomainId.toString();
	};

	get relevanceStatusIconName(): string {
		switch (this.relevanceStatus) {
			case 1:
				return "done";
			case 2:
				return "done_all";
			case 99:
				return "remove_circle";
			default:
				return "help_outline";
		}
	};
}
