import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-splash-screen",
	templateUrl: "component.html",
	styleUrls: ["component.scss"]
})
export class SplashScreenComponent {
	@Input() text = "DEFAULT-LOGIN";
	@Output() close = new EventEmitter<boolean>();
	closableTexts = ["DEFAULT-LOGIN"];
}
