import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { Action, EventBus } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { Observable, of, throwError } from "rxjs";

import { switchMap } from "rxjs/operators";
import {
	ACTION_TOKEN_COMPONENT_CONFIGS,
	ActionTokenComponentConfig,
	ActionTokenRequiresMoreInformation,
	ActionTokenSuccess,
	isActionTokenRequiresMoreInformation,
	isActionTokenSuccess
} from "../model";

@Injectable({
	providedIn: "root"
})
export class ActionTokenPageResolver implements Resolve<ActionTokenComponentConfig<unknown> | null> {
	private readonly logger: Logger = LoggerLocator.getLogger("ActionTokenPageResolver")();

	constructor(private router: Router,
		private eventBus: EventBus,
		@Inject(ACTION_TOKEN_COMPONENT_CONFIGS) private availableComponentConfigs: ActionTokenComponentConfig<unknown>[]
	) {
	}

	resolve(route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<ActionTokenComponentConfig<unknown> | null> {
		let token: string = route.paramMap.get("token");
		if (token == null) {
			this.logger.error("Token param not set", {
				params: route.params
			});
			return throwError("Token param not set");
		}
		return this.processToken(token);
	}

	private processToken(token: string): Observable<ActionTokenComponentConfig<unknown> | null> {
		const action = new Action("actionToken/handle", {token});
		return this.eventBus.request<unknown, ActionTokenRequiresMoreInformation | ActionTokenSuccess>(action.type,
			action
		).pipe(switchMap((message) => {
			const action = message.body;
			if (isActionTokenRequiresMoreInformation(action)) {
				return this.handleConflictStatus(action);
			}

			if (isActionTokenSuccess(action)) {
				return this.handleOkStatus(action);
			}

			return this.handleError(action);
		}));
	}


	private handleConflictStatus(action: ActionTokenRequiresMoreInformation): Observable<ActionTokenComponentConfig<unknown> | null> {
		const config = this.determineConfigFromAction(action);
		if (config == null) {
			return of(null);
		}
		config.initialReply = action;
		return of(config);
	}

	private handleOkStatus(action: ActionTokenSuccess): Observable<ActionTokenComponentConfig<unknown> | null> {
		const config = this.determineConfigFromAction(action);
		if (config == null) {
			return of(null);
		}

		let toastMessage;

		if(config?.onSuccess?.toastMessage != null || config?.onSuccess?.toastMessage != "") {
			toastMessage = config.onSuccess.toastMessage;
		} else {
			toastMessage = $localize`:Success toast|token handled successfully@@ActionTokenPageResolver:Success`
		}

		const snackbarAction = new Action("snackbar/create", {
			message: toastMessage,
			level: "success"
		});
		this.eventBus.send(snackbarAction.type, snackbarAction);
		this.router.navigate(config.onSuccess.navigateTo, {replaceUrl: true});
		return of(null);
	}

	private handleError(action: Action): Observable<null> {
		this.logger.error("Unable to handle action token", {
			action: action
		});

		const snackbarAction = new Action("snackbar/create", {
			message: $localize`:Error toast|token invalid@@ActionTokenPageResolver:This link is no longer valid`,
			level: "error"
		});
		this.eventBus.send(snackbarAction.type, snackbarAction);
		this.router.navigate(["/"], {replaceUrl: true});
		return of(null);
	}

	private determineConfigFromAction(action: any): ActionTokenComponentConfig<unknown> {
		if (action?.data?.form == null) {
			this.logger.error("Form is missing in payload", {
				action
			});
			const snackbarAction = new Action("snackbar/create", {
				message: $localize`:Error toast|Malformed action@@ActionTokenPageResolver:Something went wrong, please contact support`,
				level: "error"
			});
			this.eventBus.send(snackbarAction.type, snackbarAction);
			this.router.navigate(["/"], {replaceUrl: true});
			return null;
		}
		const tokenType = action.data.form
		const config = this.availableComponentConfigs.find(config => config.tokenType === tokenType);
		if (config == null) {
			this.logger.fatal("Unknown token action type", {
				type: tokenType
			});
			const snackbarAction = new Action("snackbar/create", {
				message: $localize`:Error toast|Malformed action@@ActionTokenPageResolver:Something went wrong, please contact support`,
				level: "error"
			});
			this.eventBus.send(snackbarAction.type, snackbarAction);
			this.router.navigate(["/"], {replaceUrl: true});
			return null;
		}
		return config;
	}
}
