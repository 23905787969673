import { Inject, Injectable, InjectionToken } from "@angular/core";
import { SwPush } from "@angular/service-worker";

export const VAPID_PUBLIC_KEY = new InjectionToken<number>("VapidPublicKey");


@Injectable({
  providedIn: "root"
})
export class ServiceWorkerPushNotificationService {
  constructor(@Inject(VAPID_PUBLIC_KEY) private vapidPublicKey: string, private swPush: SwPush) {

  }

  /**
   * This function can be used to subscribe to push notifications.
   */
  public async subscribe() {
    return this.swPush.requestSubscription({
      serverPublicKey: this.vapidPublicKey
    });
  }

  /**
   * This function can be used to unsubscribe to push notifications.
   */
  public async unsubscribe() {
    return this.swPush.unsubscribe()
  }
}
