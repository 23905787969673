<form (ngSubmit)="changePassword()" [formGroup]="form" tsNgRootFormGroup>
	<tsng-input
		formControlName="newPassword"
		i18n="Label|The label for the password input@@TsNgPasswordResetConfirmationNewPasswordInputLabel"
		i18n-placeholder="Placeholder|The placeholder of the password input@@TsNgPasswordResetConfirmationNewPasswordInputPlaceHolder"
		placeholder="Please specify a new password"
		type="password"
	>
		Password
	</tsng-input>

	<tsng-input
		formControlName="newPasswordConfirmation"
		i18n="Label|The label for the confirm password input@@TsNgPasswordResetConfirmationConfirmPasswordInputLabel"
		i18n-placeholder="Placeholder|The placeholder for the confirm password input@@TsNgPasswordResetConfirmationConfirmPasswordInputPlaceHolder"
		placeholder="Please confirm your new password"
		type="password"
	>
		Confirm the password
	</tsng-input>
	<button i18n="Button|The change password button@@TsNgPasswordResetConfirmationChangeButton" type="submit">
		Change
	</button>
</form>
