import { animate, state, style, transition, trigger } from "@angular/animations";

export const buttonAnimation = trigger("buttonState", [
	state("inactive", style({
		transform: "scale(1)"
	})),
	state("active", style({
		transform: "scale(0.9)"
	})),
	transition("inactive => active", animate("50ms ease-in")),
	transition("active => inactive", animate("50ms ease-out"))
]);