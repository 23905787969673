import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { RouterModule, Routes } from "@angular/router";
import { TsNgFormModule } from "@tsng/form";
import { NavigateBackModule } from "@twly/common";
import { LocationInputModule } from "../../component/location-input/module";
import { AutomaticLoginPage } from "./component";


@NgModule({
	declarations: [AutomaticLoginPage],
	imports: [
		CommonModule,
		TsNgFormModule,
		MatIconModule,
		MatSliderModule,
		RouterModule,
		ReactiveFormsModule,
		MatSelectModule,
		LocationInputModule,
		NavigateBackModule,
		MatProgressSpinnerModule
	]
})

export class AutomaticLoginPageModule {
}
