import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$e: <<value>>}
 * }
 */
export class EqualOperator implements Operator {
	constructor(private field: string, private value: any) {
	}

	build(): object {
		const object = {};
		object[this.field] = {"$e": this.value};
		return object;
	}

	clone(): EqualOperator {
		return new EqualOperator(this.field, this.value);
	}
}