import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import {
	initialUserNotificationSettingStore, UserNotificationSetting, UserNotificationSettingConversion
} from "./model";

export const USERNOTIFICATIONSETTING_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
	"UserNotificationSettingReducer");

export function userNotificationSettingReducerProvider() {
	return reducerFactory(
		"notification_setting",
		initialUserNotificationSettingStore,
		UserNotificationSetting,
		UserNotificationSettingConversion
	);
}

@NgModule({
	imports: [
		StoreModule.forFeature("notification_setting", USERNOTIFICATIONSETTING_REDUCER_TOKEN),
		TsNgStoreModule.forFeature([
			"notification_setting/deleted",
			"notification_setting/updated",
			"notification_setting/created"
		])
	],
	providers: [
		{
			provide: USERNOTIFICATIONSETTING_REDUCER_TOKEN,
			useFactory: userNotificationSettingReducerProvider
		}
	]
})
export class UserNotificationSettingCoreModule {

}
