import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule, Type } from "@angular/core";
import { ServiceWorkerModule, SwRegistrationOptions } from "@angular/service-worker";
import { ACCOUNT_MODEL } from "@tsng/account";
import { SnackbarService } from "@tsng/common/snackbar";
import {
	HTTP_EVENT_BUS_BRIDGE_OPTIONS, SOCKET_EVENT_BUS_BRIDGE_OPTIONS, SOCKET_OPTIONS
} from "@tsng/core";
import { SocketOptions } from "@tsng/core/lib/socket/options";
import { FILE_UPLOAD_ENDPOINT, FileFilterHandler, FileSyncHandler } from "@tsng/file";
import { CreateHandler, DeleteHandler, FilterHandler, Schema, SyncHandler, UpdateHandler } from "@tsng/store";
import {
	ChatMessageOverviewhandler,
	CHECK_FOR_UPDATE_INTERVAL,
	CheckForUpdateService,
	CONFIG_URL,
	EsFilterHandler,
	InterestFilterHandler,
	PRODUCTION_MODE,
	RENDERED_BY, RenderEnvironment,
	TileFilterHandler,
	TwentelyAccount,
	TwlyAuthenticationHandler,
	VERSION
} from "@twly/core";
import { TARGET } from "./config/config";
import { ConfigService } from "./config/service";
import { SocketConnector } from "./connector/implementation/socket";
import { CONNECTION_TARGETS } from "./connector/target";
import { TwlyHttpEventBusBridge } from "./http/http-event-bus-bridge";
import { TileSectionFilterHandler } from "./models/tileSection/tile-section-filter-handler";
import { PushNotificationService } from "./push-notification/service";
import { TwlySocketEventBusBridge } from "./socket/socket-event-bus-bridge";

export function printStartupMessage(version: string) {
	const imageStyling = `font-size:200px; background: url(https://propeld.nl/images/propeldlogo.png), linear-gradient(rgba(18,16,100,.9), rgba(18,16,100,.9)); background-size: auto; background-repeat: no-repeat; background-position: center center;`;
	const xssTextStyling = "font-size: 2em; letter-spacing: 1em; font-family: arial; color: #fff; background-color: #FFB817; font-weight: bold; padding: 8px;";
	const versionTextStyling = "font-size: 2em; letter-spacing: 1em; font-family: arial; color: #fff; background-color: #005C47; font-weight: bold; padding: 8px;";
	console.info("%c            ", imageStyling);
	console.info(`%c Application version: ${version}`, versionTextStyling);
	console.info(
		"%c Stop! This is a browser feature intended for developers. If someone told you to copy-paste something here to enable a feature, it is a scam and it could give them access to your account.",
		xssTextStyling
	);
}

export function initializeApplication(configService: ConfigService, version: string, rootInjector: Injector) {
	printStartupMessage(version);

	const servicesToBootstrap: Type<unknown>[] = [
		TwlyHttpEventBusBridge,
		TwlySocketEventBusBridge,
		FilterHandler,
		DeleteHandler,
		EsFilterHandler,
		FileFilterHandler,
		FileSyncHandler,
		SyncHandler,
		UpdateHandler,
		CreateHandler,
		TwlyAuthenticationHandler,
		SnackbarService,
		CheckForUpdateService,
		TileFilterHandler,
		InterestFilterHandler,
		ChatMessageOverviewhandler,
		TileSectionFilterHandler,
		PushNotificationService
	];

	return (): Promise<boolean> => {
		return configService.loadConfig().toPromise().then((config) => {
			console.debug("#initializeApplication - configuration has been loaded", {config});
			servicesToBootstrap.forEach(service => rootInjector.get(service));
			return Promise.resolve(true);
		});
	};
}

export function initializeCheckForUpdates(configService: ConfigService): number {
	return configService.get("checkUpdatesInterval");
}

export function initializeServiceWorkerRegistrationOptions(configService: ConfigService,
	isProduction: boolean
): SwRegistrationOptions {
	return {enabled: isProduction};
}

export function initializeFileUploadEndpoint(configService: ConfigService): string {
	return `${configService.get("apiEndpoint")}file`;
}

export function initializeSocketOptions(configService: ConfigService): SocketOptions {
	return configService.get("socketOptions");
}

export function socketEventBusBridgeOptionsFactory(schema: Schema, configService: ConfigService) {
	const crudMethods: string[] = [];
	for (let entity of schema.getEntities()) {
		const name = entity.getName();
		crudMethods.push(`${name}/create`);
		crudMethods.push(`${name}/update`);
		crudMethods.push(`${name}/delete`);
		crudMethods.push(`${name}/filter`);
		crudMethods.push(`${name}/count`);
		crudMethods.push(`${name}/get`);
	}

	return {
		allowedInbound: configService.get(["socketEventBusBridgeOptions", "allowedInbound"]),
		allowedOutbound: crudMethods.concat(configService.get([
			"socketEventBusBridgeOptions", "allowedOutbound"
		])),
		enableRegister: configService.get(["socketEventBusBridgeOptions", "enableRegister"]),
		httpEndpoint: configService.get("apiEndpoint")
	};
}

export function httpEventBusBridgeOptionsFactory(configService: ConfigService) {
	return {
		allowedInbound: configService.get(["httpEventBusBridgeOptions", "allowedInbound"]),
		allowedOutbound: configService.get(["httpEventBusBridgeOptions", "allowedOutbound"]),
		enableRegister: configService.get(["httpEventBusBridgeOptions", "enableRegister"]),
		httpEndpoint: configService.get("apiEndpoint")
	};
}

@NgModule({
	imports: [
		HttpClientModule, ServiceWorkerModule.register("ngsw-worker.js")
	]
})
export class SharedBaseModule {
	static forRoot(environment: any): ModuleWithProviders<SharedBaseModule> {
		return {
			ngModule: SharedBaseModule,
			providers: [
				{
					provide: CONFIG_URL,
					useValue: "./assets/config.json"
				},
				{
					provide: VERSION,
					useValue: environment.versionNumber
				}, {
					provide: APP_INITIALIZER,
					useFactory: initializeApplication,
					multi: true,
					deps: [ConfigService, VERSION, Injector]
				}, {
					provide: TARGET,
					useValue: environment.target
				}, {
					provide: PRODUCTION_MODE,
					useValue: environment.production
				}, {
					provide: CHECK_FOR_UPDATE_INTERVAL,
					useFactory: initializeCheckForUpdates,
					deps: [ConfigService]
				}, {
					provide: SwRegistrationOptions,
					useFactory: initializeServiceWorkerRegistrationOptions,
					deps: [ConfigService, PRODUCTION_MODE]
				}, {
					provide: FILE_UPLOAD_ENDPOINT,
					useFactory: initializeFileUploadEndpoint,
					deps: [ConfigService]
				}, {
					provide: SOCKET_OPTIONS,
					useFactory: initializeSocketOptions,
					deps: [ConfigService]
				}, {
					provide: ACCOUNT_MODEL,
					useValue: TwentelyAccount
				}, {
					provide: SOCKET_EVENT_BUS_BRIDGE_OPTIONS,
					useFactory: socketEventBusBridgeOptionsFactory,
					deps: [Schema, ConfigService]
				}, {
					provide: HTTP_EVENT_BUS_BRIDGE_OPTIONS,
					useFactory: httpEventBusBridgeOptionsFactory,
					deps: [ConfigService]
				}, {
					provide: CONNECTION_TARGETS,
					multi: true,
					useClass: SocketConnector
				}, {
					provide: RENDERED_BY,
					useValue: RenderEnvironment.BROWSER
				}
			]
		};
	}
}
