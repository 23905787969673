import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CrawlerPathStatusStore = ModelMap<CrawlerPathStatus>;

export const initialCrawlerPathStatusStore: CrawlerPathStatusStore = <CrawlerPathStatusStore>Map();

export const CrawlerPathStatusConversion: ActionConverter.Config = {};

export interface CrawlerPathStatusModel extends StoreModel {
	crawlerDomainId: number;
	path: string;
	fetchStatus: number;
	orderNo: number | null;
}

export class CrawlerPathStatus extends Record({
	id: 0,
	rev: 0,
	crawlerDomainId: null,
	path: "",
	params: "",
	fetchStatus: 0,
	orderNo: 0

}) implements CrawlerPathStatusModel {
	id: number;
	rev: number;

	crawlerDomainId: number;
	path: string;
	params: string;
	fetchStatus: number;
	orderNo: number;

	get displayName(): string {
		return this.path;
	};
}
