import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NavigateBackDirective } from "./directive";

@NgModule({
	declarations: [
		NavigateBackDirective
	],

	exports: [
		NavigateBackDirective
	],

	imports: [
		CommonModule
	]
})
export class NavigateBackModule {
}
