import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TsNgFileModule } from "@tsng/file";
import { ProfilePictureUpload } from "./profile-picture/component";
import { SingleFileUpload } from "./single-file/component";

@NgModule({
	declarations: [ProfilePictureUpload, SingleFileUpload],
	imports: [TsNgFileModule, MatIconModule, CommonModule, MatProgressSpinnerModule, MatProgressBarModule],
	exports: [ProfilePictureUpload, SingleFileUpload]

})
export class FileModule {

}