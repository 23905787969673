import { Injectable } from "@angular/core";
import { Action, EventBus } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { FilteredAction } from "@tsng/store";
import { EsFilteredAction } from "@twly/core";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class ChatMessageOverviewhandler {
	address = "chatmsg/get-overview";
	private logger = LoggerLocator.getLogger("ChatMessageOverviewhandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer(this.address)
			.subscribe(message => {
				this.getOverview();
			}, error => {
				this.logger.fatal(error);
			});
	}

	private getOverview() {
		this.eventBus.request<any, EsFilteredAction>("chatmsg/filter-overview", new Action("chatmsg/filter-overview", {
			sort: [
				{
					"field": "timestamp",
					"direction": "DESC"
				}
			]
		}))
			.pipe(tap((filterResult) => {
				const body = Object.assign({}, filterResult.body.data);
				delete body.count;
				delete body.replyTo;
				body.user = body.user.map((idObj: {id: string, rev: number}) => ({id: parseInt(idObj.id, 10), rev: idObj.rev}));
				this.eventBus.send<FilteredAction>("store/sync", new Action(filterResult.body.type, body));
			}))
			.subscribe(filterResult => {

			}, error => {
				this.logger.error("Overview from chat messages could not be retrieved", error);
			});
	}
}
