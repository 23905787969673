import { NgZone } from "@angular/core";

export class Util {
	static generateUUID(): string {
		const stringArr = [];
		for (let i = 0; i < 8; i++) {
			// tslint:disable-next-line:no-bitwise
			const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
			stringArr.push(S4);
		}

		return stringArr.join("-");

	}

	static setIntervalOutsideNgZone(
		ngZone: NgZone,
		handler: TimerHandler,
		timeout?: number,
		...args: any[]
	): number {
		return ngZone.runOutsideAngular(() => {
			return window.setInterval(handler, timeout, ...args);
		});
	}

	static clearIntervalOutsideNgZone(ngZone: NgZone, handle?: number): void {
		return ngZone.runOutsideAngular(() => {
			return window.clearInterval(handle);
		});
	}

	static setTimeoutOutsideNgZone(
		ngZone: NgZone,
		handler: TimerHandler,
		timeout?: number,
		...args: any[]
	): number {
		return ngZone.runOutsideAngular(() => {
			return window.setTimeout(handler, timeout, ...args);
		});
	}

	static clearTimeoutOutsideNgZone(ngZone: NgZone, handle?: number): void {
		return ngZone.runOutsideAngular(() => {
			return window.clearTimeout(handle);
		});
	}
}
