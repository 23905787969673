<form (ngSubmit)="changeEmail()" [formGroup]="form" tsNgRootFormGroup>
	<tsng-input
		formControlName="email"
		i18n="Label|The label for the change email input@@TsNgChangeEmailFormEmailInputLabel"
		i18n-placeholder="Placeholder|The placeholder for the change email input@@TsNgChangeEmailFormEmailInputPlaceholder"
		placeholder="Please provide your desired email address"
		type="email"
	>
		Desired email address
	</tsng-input>

	<button i18n="Button|The change email button@@TsNgChangeEmailFormEmailChangeButton" type="submit">
		Change
	</button>
</form>
