import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$gt: <<value>>}
 * }
 */
export class GreaterThanOperator implements Operator {
	constructor(private field: string, private value: any) {
	}

	build(): object {
		const object = {};
		object[this.field] = {"$gt": this.value};
		return object;
	}

	clone(): GreaterThanOperator {
		return new GreaterThanOperator(this.field, this.value);
	}
}