import { StoreModel } from "@tsng/store";
import { List, Record } from "immutable";

export const loggedIn = {
	DEFAULT: "DEFAULT",
	FALSE: "FALSE",
	TRUE: "TRUE"
};

export enum Role {
	GUEST = "GUEST", USER = "USER", CADMIN = "CADMIN", ADMIN = "ADMIN"
}

export interface AccountModel extends StoreModel {
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string | null;
	phone: string;
	role: Role;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	permissions: List<String>;
}

export class Account extends Record({
	id: 0,
	rev: 0,
	initials: "",
	firstName: "",
	infix: "",
	surname: "",
	gender: "",
	email: "",
	phone: "",
	role: Role.GUEST,
	active: true,
	ppId: null,
	parentId: null,
	companyName: "",
	nodeType: "",
	locale: "nl_NL",
	loggedIn: loggedIn.DEFAULT,
	wasAdmin: false,
	companyIds: "",
	permissions: List()
}) implements AccountModel {
	id: number;
	rev: number;
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string;
	phone: string;
	role: Role;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	permissions: List<String>;

	get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

	// todo how can this be done in a module???
	// get profilePictureSrc(): string {
	// 	if (this.ppId == null || this.ppId === 0) {
	// 		return `/assets/images/profile.jpg`;
	// 	} else {
	// 		return `${environment.apiEndpoint}/user-file/${this.ppId}/100x100`;
	// 	}
	// }

	get isLoggedIn(): boolean {
		return (this.id > 0 && this.loggedIn === loggedIn.TRUE);
	}

	hasPermission(name: string): boolean {
		return this.permissions != null && this.permissions.indexOf(name) !== -1;
	}

	hasAllPermissions(permissions: string[]): boolean {
		return this.permissions != null && permissions.every(permission => (this.permissions.includes(permission)));
	}

	hasAnyPermissions(permissions: string[]): boolean {
		return this.permissions != null && permissions.some(permission => (this.permissions.includes(permission)));
	}
}