import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PictureNotFoundDirective } from "./directive";
import { ImageDirective } from "./image/directive";
import { SourceDirective } from "./source/directive";

@NgModule({
	declarations: [ImageDirective, SourceDirective, PictureNotFoundDirective],
	exports: [PictureNotFoundDirective, ImageDirective, SourceDirective],
	imports: [CommonModule]
})
export class PictureNotFoundModule {
}
