import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialInterestStore, Interest, InterestConversion } from "./model";

export const INTEREST_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("InterestReducer");

export function interestReducerProvider() {
	return reducerFactory("interest", initialInterestStore, Interest, InterestConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("interest", INTEREST_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["interest/deleted", "interest/updated", "interest/created"])
	],
	providers: [
		{
			provide: INTEREST_REDUCER_TOKEN,
			useFactory: interestReducerProvider
		}
	]
})
export class InterestCoreModule {

}
