import { AsyncPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { DummyFooterComponent } from "./dummy-footer.component";

@NgModule({
	declarations: [DummyFooterComponent],
	exports: [
		DummyFooterComponent
	],
	imports: [
		AsyncPipe
	]
})

export class DummyFooterComponentModule {
}

