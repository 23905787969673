import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type UserGroupMemberStore = ModelMap<UserGroupMember>;

export const initialUserGroupMemberStore: UserGroupMemberStore = <UserGroupMemberStore>Map();

export const UserGroupMemberConversion: ActionConverter.Config = {};

export enum UserGroupMemberRole {
	OWNER = 40,
	ADMIN = 35,
	MEMBER = 30
}

export interface UserGroupMemberModel extends StoreModel {
	userRole: string;
	userStatus: string;
	userId: number;
	userGroupId: number;
	authRoleId: number;
	timestampSelected: Date;
}

export class UserGroupMember extends Record({
	id: 0,
	rev: 0,
	userRole: "",
	userStatus: "",
	userId: 0,
	userGroupId: 0,
	authRoleId: 0,
	timestampSelected: new Date()
}) implements UserGroupMemberModel {
	id: number;
	rev: number;
	userRole: string;
	userStatus: string;
	userId: number;
	userGroupId: number;
	authRoleId: number;
	timestampSelected: Date;

	get displayName() {
		return "";
	}

	get role() {
		switch (this.userRole) {
			case "POWNER":
				return $localize`:Group member owner|The owner role of the group member@@UserGroupMemberOwner:Owner`;
			case "PMEMBER":
				return $localize`:Group member member|The member role of the group member@@UserGroupMemberMember:Member`;
			default:
				return $localize`:Group member unknown|The group member role is unknown@@UserGroupMemberUnknown:Unknown`;
		}
	}
}
