import { SCHEMA_DEFINITION, SchemaDefinition } from "./model";

export const SCHEMA_DEFINITION_PROVIDER = {
	provide: SCHEMA_DEFINITION,
	useValue: {
		tables: {
			"user": {
				"columns": {
					"id": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": "nextval('user_id_seq'::regclass)",
						"cast": null
					},
					"rev": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": null,
						"cast": null
					},
					"parentId": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": null,
						"cast": null
					},
					"email": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 60,
						"default": null,
						"cast": null
					},
					"gender": {
						"type": "CHAR",
						"nullable": false,
						"length": 1,
						"default": "'U'::bpchar",
						"cast": null
					},
					"firstName": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 20,
						"default": null,
						"cast": null
					},
					"infix": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 10,
						"default": null,
						"cast": null
					},
					"surname": {
						"type": "VARCHAR",
						"nullable": false,
						"length": 45,
						"default": null,
						"cast": null
					},
					"phone": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 15,
						"default": null,
						"cast": null
					},
					"role": {
						"type": "VARCHAR",
						"nullable": false,
						"length": 12,
						"default": "'USER'::bpchar",
						"cast": null
					},
					"locale": {
						"type": "VARCHAR",
						"nullable": false,
						"length": 5,
						"default": "'nl_NL'::character varying",
						"cast": null
					},
					"active": {
						"type": "BOOLEAN",
						"nullable": false,
						"length": 0,
						"default": "false",
						"cast": null
					},
					"initials": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 10,
						"default": "''::character varying",
						"cast": null
					},
					"ppId": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": "0",
						"cast": null
					},
					"lastLogin": {
						"type": "TIMESTAMPZ",
						"nullable": true,
						"length": 0,
						"default": null,
						"cast": null
					}
				},
				"relations": {
					"fk_user_company": {
						"column": "parentId",
						"references": "company.id"
					}
				},
				"indexes": {
					"user_pkey": "id"
				}
			},
			"company": {
				"columns": {
					"id": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": "nextval('company_id_seq'::regclass)",
						"cast": null
					},
					"rev": {
						"type": "INTEGER",
						"nullable": false,
						"length": 0,
						"default": null,
						"cast": null
					},
					"parentId": {
						"type": "INTEGER",
						"nullable": true,
						"length": 0,
						"default": null,
						"cast": null
					},
					"name": {
						"type": "VARCHAR",
						"nullable": false,
						"length": 45,
						"default": null,
						"cast": null
					},
					"v_street": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 45,
						"default": null,
						"cast": null
					},
					"v_houseNumber": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 10,
						"default": null,
						"cast": null
					},
					"v_zipCode": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 7,
						"default": null,
						"cast": null
					},
					"v_city": {
						"type": "VARCHAR",
						"nullable": false,
						"length": 45,
						"default": null,
						"cast": null
					},
					"p_street": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 45,
						"default": null,
						"cast": null
					},
					"p_houseNumber": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 10,
						"default": null,
						"cast": null
					},
					"p_zipCode": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 7,
						"default": null,
						"cast": null
					},
					"p_city": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 45,
						"default": null,
						"cast": null
					},
					"phone": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 15,
						"default": null,
						"cast": null
					},
					"email": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 45,
						"default": null,
						"cast": null
					},
					"site": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 60,
						"default": null,
						"cast": null
					},
					"cocNo": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 20,
						"default": null,
						"cast": null
					},
					"taxNo": {
						"type": "VARCHAR",
						"nullable": true,
						"length": 20,
						"default": null,
						"cast": null
					}
				},
				"relations": {
					"fk_company_company": {
						"column": "parentId",
						"references": "company.id"
					},
					"_fk_company_company": {
						"column": "id",
						"referencedBy": "company.parentId"
					},
					"_fk_user_company": {
						"column": "id",
						"referencedBy": "user.parentId"
					}
				},
				"indexes": {
					"company_pkey": "id",
					"unq_company_vcity_name": "v_city, name"
				}
			}
		}
	} as SchemaDefinition
};
