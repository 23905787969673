import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { AppModal } from "../modal/component";
import { ConfirmEmailModalComponent } from "./component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<ConfirmEmailModalComponent>>,
	componentRef: ComponentRef<ConfirmEmailModalComponent>,
	eventBus: EventBus
) {
	componentRef.instance.closed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		eventBus.send("ui/login-modal/closed", new Action("ui/confirm-email-modal/closed", {}));
		dialogRef.close();
	});
}

export function translatedTitle() {
	return $localize`:The title of the confirm email modal@@ConfirmEmailModalComponent.title:Confirm email`;
}

export function componentLoader() {
	return import("./component").then(m => m.ConfirmEmailModalComponent);
}


export const confirmEmailModalRoute: ModalRoute<ConfirmEmailModalComponent> = {
	path: "confirm-email",
	outlet: "modal",
	component: AppModal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		additionalDialogConfig: {
			disableClose: true
		},
		setupComponentHooks
	}
};
