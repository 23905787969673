import { Hint, HintLevel } from "@tsng/form";

export function passwordMinLength(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "passwordMinLength",
		message: $localize`:Validator|The password min length validator@@tsngAccountMinLengthValidator:The minimum length for a password is 8 characters, it can be any combination of letters, numbers and symbols.`,
		trigger: control => {
			return (control.value != null && control.value.length < 8);
		}
	};
}
