import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { Map } from "immutable";
import { Observable, of, throwError } from "rxjs";
import { catchError, filter, first, map } from "rxjs/operators";
import { Account, loggedIn } from "../../store/model";

export type RequirePermission = string | {
	[key: string]: any, hasPermission: ((route: ActivatedRouteSnapshot, account: Account) => boolean)
};

@Injectable({
	providedIn: "root"
})
export class AuthorizationGuard implements CanActivate {
	private currentUrl = "";
	private logger = LoggerLocator.getLogger("AuthorizationGuard")();
	private accountSelector;

	constructor(private selectorProvider: SelectorProvider, private router: Router) {
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
		this.navigateOnLogout();
	}

	/**
	 * Function that redirects an user to the login page if the user is not logged in.
	 */
	navigateOnLogout(): void {
		of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => accountMap.first().account),
			filter(account => account.loggedIn === loggedIn.FALSE)
		)
			.subscribe(account => {
				this.router.navigate(["/login"], {
					queryParams: {
						returnUrl: this.currentUrl
					}
				}).catch(error => {
					this.logger.error("Error thrown when navigating", {
						error: error
					});
				});
			}, error => {
				this.logger.error("Error within Observable chain", {
					error: error
				});
			});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		this.currentUrl = (state != null && state.url != null) ? state.url : "";
		return of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => accountMap.first().account),
			filter(account => account != null && account.loggedIn !== loggedIn.DEFAULT),
			first(),
			map(account => {
				if (account.loggedIn !== loggedIn.TRUE) {
					return false;
				}

				const permission: RequirePermission = route && route.data && route.data.permission;
				if (permission == null) {
					return true;
				}

				if (typeof permission === "string") {
					const result = account.hasPermission(permission);
					this.logger.debug("User canActivate information", {
						result: result
					});
					return result;
				}
				const result = permission.hasPermission.call(permission, route, account);
				this.logger.debug("User canActivate information", {
					result: result
				});
				return result;
			}),
			catchError(error => {
				this.logger.error("Error within Observable chain", {
					error: error
				});
				return throwError(error);
			})
		);
	}
}
