import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { Action, EventBus } from "@tsng/core";
import { email, required, TsFormControl, TsFormGroup } from "@tsng/form";
import { LoggerLocator } from "@tsng/logging";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "tsng-change-email-form",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class TsNgChangeEmailForm {
	@Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	form = new TsFormGroup({
		email: new TsFormControl("", [required(), email()])
	}, {updateOn: "submit"});
	private readonly logger = LoggerLocator.getLogger("TsNgChangeEmailForm")();

	constructor(private eventBus: EventBus) {
	}

	changeEmail() {
		if (this.form.invalid) {
			this.logger.warning("Form wasn't valid");
			return;
		}

		let action = new Action("user/request-update-email", this.form.value);
		this.eventBus.request("user/request-update-email", action)
			.subscribe(response => {
				this.logger.info("Email change has been requested successfully", response);
				this.changed.emit(true);
			}, error => {
				this.logger.error("Email change request failed", error);
				const action = new Action("snackbar/create", {
					message: $localize`:Error toast|Change email failed@@tsNgChangeEmailFormChangeFailed:We were unable to change your email, please try again.`,
					level: "error"
				});
				this.eventBus.send(action.type, action);
			});
	}
}
