/* tslint:disable:no-console */
import { LogHandler } from "../handler";
import { LogTransformer } from "../transformer";
import { LogType } from "../type";

export class ConsoleHandler implements LogHandler {
	private transformer: LogTransformer;
	private logLevel: LogType = LogType.INFO;

	setTransformer(transformer: LogTransformer): void {
		this.transformer = transformer;
	}

	handle(type: LogType, message: string, context?: object): void {
		if (this.transformer != null) {
			const result = this.transformer.transform(type, message, context);
			message = result.message;
			context = result.context;
		}

		switch (type) {
			case LogType.DEBUG:
				if(this.logLevel == LogType.TRACE || this.logLevel == LogType.DEBUG) {
					console.debug(`${message}`, context);
				}
				break;
			case LogType.TRACE:
				console.trace(`${message}`, context);
				break;
			case LogType.INFO:
				if(this.logLevel == LogType.INFO) {
					console.info(`${message}`, context);
				}
				break;
			case LogType.WARNING:
				console.warn(`${message}`, context);
				break;
			case LogType.ERROR:
				console.error(`${message}`, context);
				break;
			case LogType.FATAL:
				console.error(`${message}`, context);
				break;
			default:
				console.error(`LogType: ${type} isn't a known type`);
				break;
		}
	}
}
