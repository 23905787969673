export type OpeningHoursEntry = {
	date: string;
	dayOfWeek: string,
	opened: boolean,
	openingHours: any[]
}

export type ReadableOpeningHoursEntry = {
	date: Date,
	opened: boolean,
	timeDisplay: string;
	timeDisplayList: string[];
}

export class OpeningHours {

	public readableList: ReadableOpeningHoursEntry[];
	private readonly _firstIsToday: boolean;


	constructor(public list: OpeningHoursEntry[], private isEventBased: boolean = false, public isHistoric: Boolean = false) {
		this.readableList = this.toReadable();
		if (list != null && list.length != 0) {
			this._firstIsToday = list[0].opened && list[0].date === this.formatDate(new Date());

			if (!this.firstIsToday) {
				this.readableList = this.trimClosed(this.readableList)
			} else if (isEventBased) {
				this.readableList = this.trimClosed(this.readableList)
			}
		}
	}

	toJson() {
		const result = [];
		this.list.forEach(item => result.push({
			date: item.date,
			dayOfWeek: item.dayOfWeek,
			opened: item.opened,
			openingHours: item.openingHours
		}));
		return result;
	}

	get firstIsToday(): boolean {
		return this._firstIsToday;
	}

	toReadable(): ReadableOpeningHoursEntry[] {
		return this.list.reduce((acc, current) => {
			acc.push({
				date: new Date(current.date), //dayOfWeek: current.dayOfWeek,
				opened: current.opened,
				timeDisplay: this.openingHourTimes(current.openingHours),
				timeDisplayList: this.openingHourTimesList(current.openingHours)
			});
			return acc;
		}, [] as ReadableOpeningHoursEntry[]);
	}

	trimClosed(readableOpeningHoursList: ReadableOpeningHoursEntry[]): ReadableOpeningHoursEntry[] {
		let list = readableOpeningHoursList;
		// remove first closed days
		let i = 0;
		while(i < list.length) {
			if(list[i].opened) break;
			i += 1;
		}
		if(i>0) {
			list = list.slice(i, list.length);
		}

		// remove last closed days
		i = list.length-1;
		while(i > 0) {
			if(list[i].opened) break;
			i -= 1;
		}
		return list.slice(0,i+1);
	}

	openingHourTimes(times: any[]) {
		return this.openingHourTimesList(times).join(", ")
	}

	openingHourTimesList(times: any[]) {
		return times.reduce((a, c) => {
			a.push(this.timeDisplay(c.opens) + " - " + this.timeDisplay(c.closes));
			return a;
		}, [] as ReadableOpeningHoursEntry[]);
	}

	timeDisplay(s: string) {
		return s.substr(0, 2) + ":" + s.substr(2, 2);
	}

	formatDateDisplay(date: String): string {
		const d = new Date(parseInt(date.substr(0,4)),
				parseInt(date.substr(5,2)),
				parseInt(date.substr(8,2)),
			);

		let r = "";

		if(date.substr(0, 4) !== new Date().getFullYear().toString()) {
			r += date.substr(0, 4)+"-";
		}
		r += + d.getDate() + '-' +(d.getMonth()+1);
		return r;
	}

	/**
	 * Format a Date as YYYY-MM-DD
	 * @param date A Javascript date
	 */
	formatDate(date: Date) {
		return [
			date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())
		].join("-");
	}

	padTo2Digits(num) {
		return num.toString().padStart(2, "0");
	}
}
