import { InjectionToken } from "@angular/core";

export enum SchemaColumnType {
	INTEGER = "INTEGER", VARCHAR = "VARCHAR", TEXT = "TEXT", BOOLEAN = "BOOLEAN", JSONB = "JSONB", CHAR = "CHAR", TIMESTAMPZ = "TIMESTAMPZ"
}

export interface SchemaColumn {
	type: SchemaColumnType,
	nullable: boolean,
	length: number,
	default: string,
	cast: SchemaColumnType | null
	jsonStrategy?: "MERGE" | "REPLACE_ALL"
}

export type SchemaColumns = { [columnName: string]: SchemaColumn }

export interface SchemaRelationReferences {
	column: string;
	references: string;
	type?: SchemaRelationType;
}

export interface SchemaRelationReferencedBy {
	column: string;
	referencedBy: string;
	type?: SchemaRelationType;
}

export function isSchemaRelationReferences(relation: SchemaRelationReferences | SchemaRelationReferencedBy): relation is SchemaRelationReferences {
	return (relation as SchemaRelationReferences).references !== undefined;
}

export function isSchemaRelationReferencedBy(relation: SchemaRelationReferences | SchemaRelationReferencedBy): relation is SchemaRelationReferencedBy {
	return (relation as SchemaRelationReferencedBy).referencedBy !== undefined;
}

export enum SchemaRelationType {
	ONE_TO_MANY = "ONE_TO_MANY", MANY_TO_ONE = "MANY_TO_ONE", ONE_TO_ONE = "ONE_TO_ONE"
}

export type SchemaRelations = { [relationName: string]: SchemaRelationReferencedBy | SchemaRelationReferences }
export type SchemaIndex = string;
export type SchemaIndexes = { [indexName: string]: SchemaIndex }

export interface SchemaTable {
	columns: SchemaColumns;
	relations: SchemaRelations;
	indexes: SchemaIndexes
}

export type SchemaTables = { [tableName: string]: SchemaTable }
export type SchemaDefinition = { tables: SchemaTables }
export const SCHEMA_DEFINITION = new InjectionToken<SchemaDefinition>("SchemaDefinition");
