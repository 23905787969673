import { Injectable } from "@angular/core";
import { EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { tap } from "rxjs/operators";
import { DeleteAction } from "../action/delete";
import { DeletedAction } from "../action/deleted";

@Injectable({
	providedIn: "root"
})
export class DeleteHandler {
	address = "entity/delete";
	private logger = LoggerLocator.getLogger("DeleteHandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<DeleteAction>(this.address)
			.subscribe(message => {
				this.handleLocalDelete(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalDelete(message: Message<DeleteAction>) {
		this.eventBus.request<DeleteAction, DeletedAction>(message.body.type, message.body)
			.pipe(tap(result => {
				this.eventBus.send<DeletedAction>(result.body.type, result.body);
			}))
			.subscribe(result => {
				message.reply(result.body);
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
