import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CrawlerUrlStore = ModelMap<CrawlerUrl>;

export const initialCrawlerUrlStore: CrawlerUrlStore = <CrawlerUrlStore>Map();

export const CrawlerUrlConversion: ActionConverter.Config = {
	dateCreated: ActionConverter.stringToDate(new Date()),
	dateFetched: ActionConverter.stringToDate(new Date())
};

export interface CrawlerUrlModel extends StoreModel {
	crawlerDomainId: number;
	path: string;
	params: string;
	fetchStatus: number | null;
	httpStatus: number | null;
	dateCreated: string;
	dateFetched: string;
}

export class CrawlerUrl extends Record({
	id: 0,
	rev: 0,

	crawlerDomainId: null,
	path: "",
	params: "",
	fetchStatus: null,
	httpStatus: null,
	dateCreated: "",
	dateFetched: ""

}) implements CrawlerUrlModel {
	id: number;
	rev: number;

	crawlerDomainId: number;
	path: string;
	params: string;
	fetchStatus: number | null;
	httpStatus: number | null;
	dateCreated: string;
	dateFetched: string;

	get displayName(): string {
		return this.path;
	};
}
