/**
 * Various utility methods to calculate opening hour related information based on the server model.
 *
 * The servermodel is stored with the following data structure.
 *
 *	"openingHours": [{
 *		"name": "Openingstijden",
 *		"validFrom": "2022-01-01",
 *		"validThrough": "2022-12-31",
 *		"validFromWeek": 52,
 *		"validThroughWeek": 52,
 *		"days": [{
 *			"opens": "120000Z",
 *			"closes": "190000Z",
 *			"dayOfWeek": [ "th", "fr" ]
 *		}, {
 *			"opens": "120000Z",
 *			"closes": "200000Z",
 *			"dayOfWeek": [ "sa" ]
 *		}, {
 *			"opens": "120000Z",
 *			"closes": "190000Z",
 *			"remark": null,
 *			"dayOfWeek": [ "su" ]
 *		}]
 *	}]
 *
 * 	closed: [
 *
 * 	]
 *
 * Some methods return a list of OpeningHoursEntry items that can more easily be processed in the frontend.
 * This structure is derived from the server model and given as follows:
 *
 *	[{
 *		date: "2022-03-30",
 *		dayOfWeek: "wo",
 *		opened: false,
 *		openingHours: []
 *	}, {
 *		date: "2022-03-31",
 *		dayOfWeek: "th",
 *		opened: true,
 *		openingHours: [{
 *			"opens": "120000Z",
 *			"closes": "190000Z"
 *		}]
 *	}, {
 *		date: "2022-04-01",
 *		dayOfWeek: "fr",
 *		opened: true,
 *		openingHours: [{
 *			"opens": "120000Z",
 *			"closes": "190000Z"
 *		}]
 *	}, {
 *		date: "2022-04-02",
 *		dayOfWeek: "sa",
 *		opened: true,
 *		openingHours: [{
 *			"opens": "120000Z",
 *			"closes": "200000Z"
 *		}]
 *	}]
 */
import { OpeningHours, OpeningHoursEntry } from "./openingHours";

export class OpeningHoursCalculator {

	public isHistoric: boolean = false;

	constructor(
		private openingHours: any[],
		private closed: any[],
		private presentAtStartTime: Boolean = false,
		private currentDateTime: Date = null
	) {
		if (this.openingHours == null) this.openingHours = [];
		if (this.closed == null) this.closed = [];
		if(this.currentDateTime == null) this.currentDateTime = new Date();
	}

	/**
	 * Returns a list of opening hours starting from startDate, for the next numberOfDays days after that
	 *
	 * @param startDate YYYY-MM-DD
	 * @param numberOfDays
	 */
	withStartDate(startDate: string, numberOfDays: number): OpeningHours {
		let date = new Date(startDate);
		return new OpeningHours(this.collectDays(date, numberOfDays));
	}

	/**
	 * Returns a list of opening hours starting from today, for the next numberOfDays days after that.
	 * If none of these days contains an opened day, this method searches for the next day the venue is
	 * opened. If empty, none is given.
	 *
	 * @param startDate YYYY-MM-DD
	 * @param numberOfDays
	 */
	firstFromToday(numberOfDays: number): OpeningHours {
		const openingHours = this.firstOnOrAfterStartDate(this.formatDate(this.currentDateTime), numberOfDays)
		return openingHours;
	}

	/**
	 * Returns a list of opening hours starting from startDate, for the next numberOfDays days after that.
	 * If none of these days contains an opened day, this method searches for the next day the venue is
	 * opened. If empty, none is given.
	 *
	 * @param startDate YYYY-MM-DD
	 * @param numberOfDays
	 */
	firstOnOrAfterStartDate(startDate: string, numberOfDays: number): OpeningHours {
		let date = new Date(startDate);
		let list = this.collectDays(date, numberOfDays);
		list = this.removePastOpeningHours(list, this.currentDateTime, this.presentAtStartTime);

		// If all days are closed, find first next opening day
		const firstOpeningIndex = list.findIndex((h: OpeningHoursEntry, index: Number) => h.opened === true);
		this.isHistoric = false;
		if (firstOpeningIndex == -1) {
			let firstEntry = this.findFirstAfterDate(date);
			if (firstEntry != null) {
				list = this.collectDays(new Date(firstEntry.date), numberOfDays);
			} else {
				// No openinghours entry in the future is found.
				this.isHistoric = true;
			}
		}

		// If event based, trim all trailing openinghours
		// otherwise, show all week days
		let isEvent = this.openingHours.findIndex(v => v.type == "event") != -1;
		return new OpeningHours(list, isEvent, this.isHistoric);
	}

	private collectDays(date: Date, numberOfDays: number): OpeningHoursEntry[] {
		let d = new Date(date);
		let index = 0;
		let list = [] as OpeningHoursEntry[];
		while (index < numberOfDays) {
			list.push(this.forDate(d));
			d = this.nextDay(d);
			index++;
		}
		return list;
	}

	/**
	 * Return a OpeningHoursEntry for the given date based on the settings.
	 *
	 * @param date
	 */
	private forDate(date: Date): OpeningHoursEntry {
		let d = this.formatDate(date);
		let dayName = date.toString().substr(0, 2).toLowerCase();

		let result = null;
		this.openingHours
			.filter(oh => oh.validFrom <= d && oh.validThrough >= d)
			.map(oh => this.createOpeningHoursEntry(oh, date))
			.forEach(entry => {
				if (result == null) {
					result = entry;
					return;
				}
				if (entry != null) {
					result["openingHours"].push(entry["openingHours"][0]);
				}
			});

		if (result == null || this.isClosed(d)) {
			return {
				date: d,
				dayOfWeek: dayName,
				opened: false,
				openingHours: []
			};
		}

		return result;
	}

	/**
	 * Finds the first opening starting from the given date
	 * @param date
	 */
	private findFirstAfterDate(date: Date): OpeningHoursEntry {
		let d = this.formatDate(date);

		let result = null;

		this.openingHours
			// Find first entry that opens before or on the given day
			.filter(oh => oh.validFrom >= d)
			.map(oh => {
				let d = oh.validFrom;
				return this.createOpeningHoursEntry(oh, new Date(d));
			})
			.forEach(entry => {
				if (result == null) {
					result = entry;
					return;
				}
				if (entry != null) {
					result["openingHours"].push(entry["openingHours"][0]);
				}
			});

		return result;
	}

	/**
	 * Return OpeningHours for events to generate structured data ld+json
	 */
	eventData(): OpeningHours {
		// find first in list
		let d = "99";
		this.openingHours
			.filter(o => o.type === "event")
			.forEach(o => {if(o.validFrom < d) d = o.validFrom});
		if(d == "99") {
			// No event data found
			return new OpeningHours([] as OpeningHoursEntry[]);
		}

		// Loop through all dates, return an item for every day
		let result = [] as OpeningHoursEntry[];

		const formatTime = (s) => s.substr(0, 2) + ":" + s.substr(2, 2);

		this.openingHours.forEach(oh => {
			const startDate = new Date(oh.validFrom);
			const endDate = new Date(oh.validThrough);
			let date = startDate;
			while(date <= endDate) {

				let dayName = date.toString().substr(0, 2).toLowerCase();
				const days = oh.days.filter(day => day.dayOfWeek.indexOf(dayName) >= 0);
				if (days.length > 0) {
					for (let i = 0; i < days.length; i++) {
						result.push({
							date: this.formatDate(date),
							dayOfWeek: dayName,
							opened: true,
							openingHours: [{
								"opens": formatTime(days[i].opens),
								"closes": formatTime(days[i].closes)
							}]
						});
					}
				}
				date = this.addDays(date, 1);
			}
		});
		return new OpeningHours(result);
	}

	private createOpeningHoursEntry(oh, date: Date) {
		let dayName = date.toString().substr(0, 2).toLowerCase();
		const days = oh.days.filter(day => day.dayOfWeek.indexOf(dayName) >= 0);
		if (days.length === 0) {
			return null;
		}
		const o = {
			date: this.formatDate(date),
			dayOfWeek: dayName,
			opened: true,
			openingHours: []
		};
		for (let i = 0; i < days.length; i++) {
			o.openingHours.push({
				"opens": days[i].opens,
				"closes": days[i].closes
			});
		}
		return o;
	}

	/**
	 * Remove all entries that have ended (presentAtStartTime = false), and
	 * all entries that have ended or started (presentAtStartTime = true)
	 */
	removePastOpeningHours(list: OpeningHoursEntry[], now: Date, presentAtStartTime: Boolean): OpeningHoursEntry[] {
		const dateString = this.formatDate(now);
		const timeString = this.padTo2Digits(now.getHours())+this.padTo2Digits(now.getMinutes())+"00Z";

		const result = list.filter(oh => {
			// Anything for tomorrow is ok
			if(oh.date > dateString) return true;
			// Anything for yesterday is filtered
			if(oh.date < dateString && timeString > "050000Z") return false;
			// Filter based on time
			// Anything for today should have a time >= now
			oh.openingHours = oh.openingHours.filter(timeslot => {
				// Activity is yet to start
				if(timeString <= timeslot.opens) return true;

				// Activity has started and you must be present at start time
				if(timeString > timeslot.opens && presentAtStartTime === true) return false;

				// If we look after midnight, anything that closes before 05:00 is ok
				if(timeString <= "050000Z" && timeslot.closes <= "050000Z") return true;

				// If we look before midnight, anything that opens after midnight but closes before 05:00 is ok
				if(timeString <= "235959Z" && timeslot.opens >= "000000Z" && timeslot.closes <= "050000Z") return true;

				// Otherwise, allow anything that hasn't closed
				return timeString < timeslot.closes;
			});
			return oh.openingHours.length > 0;
		});
		return result;
	}


	isClosed(stringDate: string): boolean {
		return this.closed.find(entry => entry.date === stringDate);
	}

	/**
	 * Format a Date as YYYY-MM-DD
	 * @param date A Javascript date
	 */
	formatDate(date: Date) {
		return [
			date.getFullYear(), this.padTo2Digits(date.getMonth() + 1), this.padTo2Digits(date.getDate())
		].join("-");
	}

	nextDay(date: Date): Date {
		return this.addDays(date, 1);
	}

	addDays(date: Date, days: number) {
		const newDate = new Date(date.valueOf());
		newDate.setDate(newDate.getDate() + days);
		return newDate;
	}

	subtractDays(date: Date, days: number) {
		const newDate = new Date(date.valueOf());
		newDate.setDate(newDate.getDate() - days);
		return newDate;
	}

	padTo2Digits(num) {
		return num.toString().padStart(2, "0");
	}
}
