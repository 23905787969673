import { ObserversModule } from "@angular/cdk/observers";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { TsNgCheckboxFieldComponent } from "./component/checkbox-field/component";
import { TsNgComboBoxComponent } from "./component/combo-box-field/component";
import { TsNgComboBoxInput } from "./component/combo-box-field/input/component";
import { TsNgComboBoxInputDirective } from "./component/combo-box-field/input/directive";
import { TsNgComboBoxOption } from "./component/combo-box-field/option/component";
import { TsNgComboBoxOptionDirective } from "./component/combo-box-field/option/directive";
import { TsNgInputFieldComponent } from "./component/input-field/component";
import { TsNgRadioFieldComponent } from "./component/radio-field/component";
import { TsNgRadioFieldOptionComponent } from "./component/radio-field/option/component";
import { TsNgSelectFieldComponent } from "./component/select-field/component";
import { TsNgSelectFieldOptionComponent } from "./component/select-field/option/component";
import { TsNgTextAreaFieldComponent } from "./component/textarea-field/component";
import { TsNgRootFormGroupDirective } from "./directive/root-form-group/directive";

@NgModule({
	declarations: [
		TsNgInputFieldComponent,
		TsNgTextAreaFieldComponent,
		TsNgCheckboxFieldComponent,
		TsNgRootFormGroupDirective,
		TsNgRadioFieldComponent,
		TsNgRadioFieldOptionComponent,
		TsNgSelectFieldComponent,
		TsNgSelectFieldOptionComponent,
		TsNgComboBoxComponent,
		TsNgComboBoxInputDirective,
		TsNgComboBoxOptionDirective,
		TsNgComboBoxOption,
		TsNgComboBoxInput

	],
	imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatAutocompleteModule, ObserversModule],
	exports: [
		TsNgInputFieldComponent,
		TsNgTextAreaFieldComponent,
		TsNgCheckboxFieldComponent,
		TsNgRootFormGroupDirective,
		TsNgRadioFieldComponent,
		TsNgRadioFieldOptionComponent,
		TsNgSelectFieldComponent,
		TsNgComboBoxComponent,
		TsNgSelectFieldOptionComponent,
		TsNgComboBoxOptionDirective,
		TsNgComboBoxInputDirective,
		TsNgComboBoxOption,
		TsNgComboBoxInput
	]
})
export class TsNgFormModule {
}
