import { Logger, LoggerLocator } from "@tsng/logging";
import { fromJS, is, Map, OrderedMap } from "immutable";
import { Observable, OperatorFunction } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { isStoreModel, ModelMap, StoreModel } from "../model/model";
import { EntityLink } from "../schema/abstract-entity-link-builder";
import { SchemaRelationType } from "../schema/model";
import { MergeOperator, SelectorStoreData } from "./selector";

export class SelectorMerger {
	/**
	 * It converts a set of Map structures provided by the Selector into a flattened format of the data.
	 * This means that if a child child relation returns multiple models yet is a ONE to ONE relation with
	 * its's parent it will still be returned as a Map instead of a single StoreModel due to the relation it
	 * has with the parent.
	 *
	 * INPUT:
	 * <object>{
	 * 		main: OrderedMap<string | number, StoreModel>,
	 * 		link-one: Map<string | number, StoreModel>,
	 * 		link-two: Map<string | number, StoreModel>,
	 * 		link-three: Map<string | number, StoreModel>
	 * }
	 *
	 *
	 * OUTPUT:
	 * OrderedMap<string | number, {
	 * 		main: StoreModel,
	 * 		link-one: Map<string | number, StoreModel> | StoreModel,
	 * 		link-two: Map<string | number, StoreModel> | StoreModel,
	 * 		link-three: Map<string | number, StoreModel> | StoreModel
	 * }>
	 *
	 * EXAMPLE (User with company):
	 *
	 * INPUT:
	 * <object>{
	 * 		user: OrderedMap<string | number, User>,
	 * 		company: Map<string | number, Company>,
	 * }
	 *
	 * OUTPUT:
	 * OrderedMap<string | number, {
	 * 		user: User,
	 * 		company: Company,
	 * }>
	 */
	static createFlatMerger(links: EntityLink[]): MergeOperator<OrderedMap<string | number, { [sourceAlias: string]: StoreModel | ModelMap<StoreModel> }>> {
		function createLinkedState(links: EntityLink[],
			storeData: SelectorStoreData,
			relatedTo: EntityLink,
			relatedModels: ModelMap<StoreModel> | StoreModel
		): { [sourceAlias: string]: StoreModel | ModelMap<StoreModel> } {
			const childLinks = links.filter(link => link.targetAlias === relatedTo.sourceAlias);
			return childLinks.reduce((result, link) => {
				const associatedModels = SelectorMerger.filterModelsForRelation(link,
					storeData,
					relatedModels
				);

				let associatedData;
				const relationType = link.relation.getType();
				if ((relationType === SchemaRelationType.ONE_TO_ONE || relationType === SchemaRelationType.MANY_TO_ONE) && isStoreModel(
					relatedModels)) {
					associatedData = associatedModels.first();
				} else {
					associatedData = associatedModels;
				}

				if (associatedData == null) {
					return result;
				}

				result[link.sourceAlias] = associatedData;
				return Object.assign({}, result, createLinkedState(links, storeData, link, associatedData));
			}, {});
		}

		let previousState: OrderedMap<string | number, { [sourceAlias: string]: StoreModel | ModelMap<StoreModel> }> = OrderedMap();
		return SelectorMerger.constructMergerOperator(previousState, (storeData: SelectorStoreData) => {
			const mainLink = SelectorMerger.findMainLink(links);
			const mainModels: ModelMap<StoreModel> = storeData[mainLink.sourceAlias];
			previousState = mainModels.map((model, id) => {
				const merged = Object.assign({},
					{[mainLink.sourceAlias]: model},
					createLinkedState(links, storeData, mainLink, model)
				);

				if (previousState == null) {
					return merged;
				}

				if (is(fromJS(previousState.get(id)), fromJS(merged))) {
					return previousState.get(id);
				}

				return merged;
			}) as OrderedMap<string | number, { [sourceAlias: string]: StoreModel | ModelMap<StoreModel> }>;

			return previousState;
		});
	}

	/**
	 * It converts a set of Map structures provided by the Selector into a deeply merged format of the data.
	 *
	 * INPUT:
	 * <object>{
	 * 		main: OrderedMap<string | number, StoreModel>,
	 * 		link-one: Map<string | number, StoreModel>,
	 * 		link-two: Map<string | number, StoreModel>,
	 * 		link-three: Map<string | number, StoreModel>
	 * }
	 *
	 *
	 * OUTPUT:
	 * OrderedMap<string | number, {
	 * 		id: string | number,
	 * 		rev: number,
	 * 		...
	 * 		link-one: object[] | object,
	 * 		link-two: object[] | object,  (depending on if this is a property of the main model)
	 * 		link-three: object[] | object (depending on if this is a property of the main model)
	 * }>
	 *
	 *
	 * EXAMPLE (User with company):
	 *
	 * INPUT:
	 * <object>{
	 * 		user: OrderedMap<string | number, User>,
	 * 		company: Map<string | number, Company>,
	 * }
	 *
	 * OUTPUT:
	 * OrderedMap<string | number, {
	 * 		id: 1,
	 * 		rev: 1,
	 * 		email: info@twensoc.nl,
	 * 		... (other user properties)
	 * 		company: Company
	 * }>
	 *
	 * EXAMPLE (Company with users):
	 *
	 * INPUT:
	 * <object>{
	 * 		company: Map<string | number, Company>,
	 * 		user: OrderedMap<string | number, User>,
	 * }
	 *
	 * OUTPUT:
	 * OrderedMap<string | number, {
	 * 		id: 1,
	 * 		rev: 1,
	 * 		v_city: Enschede,
	 * 		... (other company properties)
	 * 		user: User[]
	 * }>
	 */
	static createdNestedMerger(links: EntityLink[]): MergeOperator<OrderedMap<string | number, unknown>> {
		function createLinkedState(links: EntityLink[],
			storeData: SelectorStoreData,
			relatedTo: EntityLink,
			relatedModel: StoreModel
		) {
			const childLinks = links.filter(link => link.targetAlias === relatedTo.sourceAlias);
			return childLinks.reduce((result, link) => {
				const associatedModels = SelectorMerger.filterModelsForRelation(link,
					storeData,
					relatedModel
				);

				const transformedModels = associatedModels.map(record => {
					return Object.assign({},
						record.toObject(),
						createLinkedState(links, storeData, link, record)
					);
				});

				const relationType = link.relation.getType();
				if (relationType === SchemaRelationType.ONE_TO_ONE || relationType === SchemaRelationType.MANY_TO_ONE) {
					result[link.sourceAlias] = transformedModels.first();
				} else {
					result[link.sourceAlias] = transformedModels.toArray();
				}
				return result;
			}, {});
		}

		let previousState: OrderedMap<string | number, unknown> = OrderedMap();
		return SelectorMerger.constructMergerOperator(previousState, (storeData: SelectorStoreData) => {
			const mainLink = SelectorMerger.findMainLink(links);
			const mainModels: ModelMap<StoreModel> = storeData[mainLink.sourceAlias];
			previousState = mainModels.map((model, id) => {
				const merged = Object.assign({},
					model.toObject(),
					createLinkedState(links, storeData, mainLink, model)
				);

				if (previousState == null) {
					return merged;
				}

				if (is(fromJS(previousState.get(id)), fromJS(merged))) {
					return previousState.get(id);
				}

				return merged;
			}) as OrderedMap<string | number, unknown>;

			return previousState;
		});
	}

	private static filterModelsForRelation(link: EntityLink,
		storeData: SelectorStoreData,
		relatedModels: ModelMap<StoreModel> | StoreModel
	): ModelMap<StoreModel> {
		if (isStoreModel(relatedModels)) {
			relatedModels = Map([[relatedModels.id, relatedModels]]);
		}

		const ids = relatedModels.map(self => self[link.relation.getSourceField()]);
		const comparisonFunction = (model: StoreModel) => ids.includes(model[link.relation.getTargetField()]);
		return storeData[link.sourceAlias].filter(comparisonFunction) as ModelMap<StoreModel>;
	}

	private static constructMergerOperator<T>(previousState: OrderedMap<string | number, T>,
		innerLogic: (storeData: SelectorStoreData) => OrderedMap<string | number, T>
	): () => OperatorFunction<SelectorStoreData, OrderedMap<string | number, T>> {
		const mergeOperator = () => (source: Observable<SelectorStoreData>) => source.pipe(map((storeData: SelectorStoreData) => innerLogic(
			storeData)), finalize(() => {
			previousState = null;
		}));
		return mergeOperator;
	}

	private static findMainLink(links: EntityLink[]): EntityLink {
		const logger: Logger = LoggerLocator.getLogger("SelectorMerger")();
		const mainLink = links.find(link => link.relation === null);
		if (mainLink == null) {
			logger.fatal("Main model couldn't be determined", {links});
			throw new Error(
				"Main model couldn't be determined, this should never occur please contact a sain developer");
		}
		return mainLink;
	}
}
