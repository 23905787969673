import { Observable, throwError } from "rxjs";
import { DeliveryOptions } from "../delivery-options";
import { EventBus } from "../event-bus";
import { MessageCodec } from "../message-codec";
import { ErrorMessage } from "./error-message";

export const DEFAULT_MESSAGE_TIMEOUT = 30 * 1000;

export enum MessageType {
	SEND = "send", PUBLISH = "publish", REGISTER = "register", UNREGISTER = "unregister", REGISTERED = "registered"
}

export interface MessageObject<T> {
	address: string,
	body: T,
	type: MessageType,
	headers?: { [key: string]: string }
	replyAddress?: string
}

export class Message<T> {
	readonly address: string;
	readonly type: MessageType;
	readonly body: T;
	readonly headers: { [key: string]: string };
	readonly replyTimeout = DEFAULT_MESSAGE_TIMEOUT;
	private replyAddress: string;
	private readonly eventBus: EventBus;

	constructor(
		address: string,
		type: MessageType,
		body: T,
		eventBus: EventBus,
		codec: MessageCodec<unknown, T>,
		options?: DeliveryOptions
	) {
		this.address = address;
		this.type = type;
		this.body = codec.transform(body);
		this.eventBus = eventBus;

		if (options == null) {
			return;
		}

		if (options.hasOwnProperty("headers")) {
			this.headers = options.headers;
		}

		if (options.hasOwnProperty("replyTimeout")) {
			this.replyTimeout = options.replyTimeout;
		}
	}

	public setReplyAddress(replyAddress: string) {
		this.replyAddress = replyAddress;
	}

	public getReplyAddress(): string {
		return this.replyAddress;
	}

	public reply<A>(body: A, options?: DeliveryOptions): void {
		if (this.replyAddress == null) return; // todo should this return an error?
		const reply = this.eventBus.createMessage(this.replyAddress, MessageType.SEND, body, options);
		this.eventBus.sendReply(reply, options);
	}

	public replyAndRequest(body: T, options?: DeliveryOptions): Observable<Message<T>> {
		if (this.replyAddress == null) return throwError("Message cannot be replied upon!");
		const reply = this.eventBus.createMessage(this.replyAddress, MessageType.SEND, body, options);
		return this.eventBus.sendAndRequestReply(reply, options);
	}

	public fail(failureCode: number, message: string): void {
		const error = new ErrorMessage({
			address: this.replyAddress,
			failureType: "RECIPIENT_FAILURE",
			failureCode: failureCode,
			message: message
		});
		this.eventBus.sendReply(error as any, {});
	}

	public toJSON(): MessageObject<T> {
		return Object.assign({
			address: this.address,
			body: this.body,
			type: this.type
		}, this.replyAddress == null ? {} : {
			replyAddress: this.replyAddress
		}, this.headers == null ? {headers: {}} : {
			headers: this.headers
		});
	}
}
