import { Logger, LoggerLocator } from "@tsng/logging";
import { Action } from "../action";
import { MESSAGE_CODEC, MessageCodec } from "../message-codec";

export class ActionCodec
	implements MessageCodec<{ type: string, id: number | string, rev: number, data: any }, Action> {
	private logger: Logger = LoggerLocator.getLogger("ActionCodec")();
	private actionProperties = ["type", "id", "rev", "data"];

	transform(body?: { type, id, rev, data }): Action {
		if (body == null) {
			this.logger.warning("Message body was empty, as such an empty action was created");
			return new Action("unknown", {});
		}

		if (Object.keys(body).every(key => this.actionProperties.includes(key)) === false) {
			this.logger.error(
				"Message body didn't have the required properties / had unknown properties as such an empty action was created",
				{
					body
				}
			);
			return new Action("unknown", {});
		}

		const {type, id, rev, data} = body;
		this.logger.debug("Transforming action", {
			type,
			id,
			rev,
			data
		});
		return new Action(type, id, rev, data);
	}
}

export const ACTION_MESSAGE_CODEC_PROVIDER = {
	provide: MESSAGE_CODEC,
	useClass: ActionCodec
};

