import { CommonModule } from "@angular/common";
import {
	ChangeDetectionStrategy, Component, Inject, LOCALE_ID, NgModule, OnDestroy, OnInit
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { TsNgFormModule } from "@tsng/form";
import { LoggerLocator } from "@tsng/logging";
import { ConfigService, RENDERED_BY, RenderEnvironment } from "@twly/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LoginModalModule } from "../../../component/login-modal/module";
import { MetaService } from "../../../core/meta-service/service";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "accept-user-group-invite-page",
	templateUrl: "component.html",
	styleUrls: ["component.scss", "../../../component/layout/basic/shared.scss"]
})
export class AcceptUserGroupInvitePage implements OnInit, OnDestroy {
	showAccountNeeded = new BehaviorSubject(false);
	initialReply;
	initialReplyObservable = new ReplaySubject<{ message: string, userGroupName: string, userGroupImage: number, userName: string, token: string }>(
		1);
	private readonly logger = LoggerLocator.getLogger("AcceptUserGroupInvitePage")();
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(private activatedRoute: ActivatedRoute,
		private eventBus: EventBus,
		private router: Router,
		public configService: ConfigService,
		private metaService: MetaService,
		@Inject(RENDERED_BY) private renderedBy: RenderEnvironment,
		@Inject(LOCALE_ID) public locale: string
	) {
	}

	ngOnInit(): void {
		this.initialReply = this.activatedRoute.snapshot?.data?.tokenConfig?.initialReply;
		if (this.initialReply == null) {
			this.logger.error("No initial reply found");
			return;
		}

		if (this.initialReply?.data?.errorMessage === "Account required" && this.renderedBy === RenderEnvironment.BROWSER) {
			this.setPendingToken();
			this.showAccountNeeded.next(true);
		} else {
			this.setMetaTags(this.initialReply.data);
			this.initialReplyObservable.next(this.initialReply?.data);
		}
	}

	setMetaTags({
		message,
		userGroupName,
		userGroupImage,
		userName,
		token
	}: { message: string, userGroupName: string, userGroupImage: number, userName: string, token: string }) {
		this.metaService.setTags([
			{
				property: "og:title",
				content: $localize`:og title meta tag for usergroup invite@@AcceptUserGroupInvitePageMetaOgTitle:Invite for ${userGroupName}:INTERPOLATION:`
			},
			{
				property: "og:description",
				content: $localize`:og title meta tag for usergroup invite@@AcceptUserGroupInvitePageMetaOgDescription:${userName}:INTERPOLATION: invites you to join ${userGroupName}:INTERPOLATION:`
			},
			{
				property: "og:url",
				content: this.configService.get("websiteUrl") + this.router.url.substr(1)
			},
			{
				property: "og:image",
				content: `${this.configService.get("apiEndpoint")}invite/${token}.png`
			}
		]);
	}

	setPendingToken() {
		if (this.renderedBy === RenderEnvironment.SERVER) return;
		localStorage.setItem("pendingToken",
			this.activatedRoute.snapshot?.data?.tokenConfig?.initialReply?.data.token
		);
	}

	removePendingToken() {
		if (this.renderedBy === RenderEnvironment.SERVER) return;
		localStorage.removeItem("pendingToken");
	}

	ngOnDestroy() {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
		this.metaService.resetTags();
	}

	accept() {
		const data = this.initialReply.data;
		data.accept = true;
		const action = new Action("actionToken/handle", data);
		this.removePendingToken();
		this.eventBus.request(action.type, action).pipe(takeUntil(this.destroyed)).subscribe(() => {
			this.logger.debug("Group invite accepted");
			const action = new Action("snackbar/create", {
				message: $localize`:Success toast|Accept group invite succeeded@@AcceptUserGroupInvitePageSuccessToast:Successfully joined group ${data.userGroupName}`,
				level: SnackbarLevel.SUCCESS
			});
			this.eventBus.send(action.type, action);
			this.router.navigate(["/user-group/", data.userGroupId, "settings"], {replaceUrl: true}).catch(
				error => this.logger.error(error));
		}, error => {
			this.logger.error("Unable to accept group invite", {
				value: data,
				error
			});
			const action = new Action("snackbar/create", {
				message: $localize`:Error toast|Accept group invite failed@@AcceptUserGroupInvitePage.errorToast:Could not join group ${data.userGroupName}, please try again later or ask for a new invite.`,
				level: SnackbarLevel.ERROR
			});
			this.eventBus.send(action.type, action);
		});
	}

	decline() {
		this.removePendingToken();
		this.router.navigate(["/"], {replaceUrl: true}).catch(error => this.logger.error(error));
	}
}

@NgModule({
	declarations: [AcceptUserGroupInvitePage],
	exports: [
		AcceptUserGroupInvitePage
	],
	imports: [
		CommonModule, TsNgFormModule, MatIconModule, RouterModule, ReactiveFormsModule, LoginModalModule
	]
})
export class ConfirmFinishRegistrationModule {
}
