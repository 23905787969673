import { Directive, Injector, Input, TemplateRef } from "@angular/core";
import { TsNgAbstractPermissionDirective } from "../abstract-permission/directive";

@Directive({
	selector: "[ifAnyPermissions]"
})
export class TsNgIfAnyPermissionsDirective extends TsNgAbstractPermissionDirective {
	mustAllMatch = false;

	constructor(injector: Injector, templateRef: TemplateRef<any>) {
		super(injector);
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifAnyPermissions(permission: string[]) {
		this.setPermissions(permission);
	}

	@Input() set ifAnyPermissionsThen(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifAnyPermissionsElse(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionElse(templateRef);
	}
}