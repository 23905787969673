<tsng-upload-component
	#upload
	[acceptedExtensions]="acceptedExtensions"
	[autoUpload]="autoUpload"
	[entity]="entity"
	[group]="group"
	[maxFiles]="maxFiles"
	[referenceId]="referenceId"
>
	<div *tsngUploadPlaceholder>
		<mat-icon class="inner-icon icon-img">person</mat-icon>
	</div>
	<img *tsngServerFileDef="let file" [src]="fileEndpoint + '/' + file.getThumbnail(150, 150)">

	<ng-container *tsngQueuedFileDef="let queuedFile">
		<div class="queued-image-container">
			<img
				*ngIf="queuedFile.getThumbnail() | async as thumbnail else iconTemplate" [ngStyle]="{'filter': 'grayscale(' + (autoUpload === false ? 0
				: calcGrayScaleValue(queuedFile.getProgress() | async)) +
				')'}" [src]="thumbnail"
			>

			<ng-template #iconTemplate>
				<mat-icon class="icon-img">insert_drive_file</mat-icon>
			</ng-template>
			<mat-progress-spinner
				[mode]="'determinate'"
				[value]="queuedFile.getProgress() | async"
				class="example-margin"
				diameter="134"
				strokeWidth="3"
			></mat-progress-spinner>
		</div>

	</ng-container>
</tsng-upload-component>
<button (click)="upload.openNativeFileMenu()" class="icon-button change-image-button" type="button">
	<mat-icon>edit</mat-icon>
</button>
