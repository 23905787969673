import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	AbstractEventBusBridge,
	EventBus,
	HttpEventBusBridgeOptions,
	Message,
	Socket,
	SOCKET_EVENT_BUS_BRIDGE_OPTIONS,
	SocketStates
} from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { AccountProvider } from "@twly/core";
import { Observable, Subscription } from "rxjs";
import { first, map, take } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class TwlySocketEventBusBridge extends AbstractEventBusBridge {
	protected logger: Logger = LoggerLocator.getLogger("TwlySocketEventBusBridge")();
	private endpoint: string;
	private listener: Subscription;

	constructor(
		protected eventBus: EventBus,
		private socket: Socket,
		private httpClient: HttpClient,
		@Inject(SOCKET_EVENT_BUS_BRIDGE_OPTIONS) options: HttpEventBusBridgeOptions,
		private accountProvider: AccountProvider
	) {
		super(eventBus, options);
		if (options.hasOwnProperty("httpEndpoint") === false) {
			this.logger.fatal(
				"No httpEndpoint specified, please add it to the 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
			throw new Error(
				"No httpEndpoint specified, please add it to the 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
		}
		this.endpoint = options.httpEndpoint;

		// check if user is a guest user...
		this.isGuestUser().subscribe(isGuestUser => {
			if (isGuestUser === false) {
				this.listenToSocket();
			}
		});
	}

	sendMessage<T = unknown>(message: Message<T>) {
		this.isGuestUser().subscribe(isGuestUser => {
			// check if user is a guest user...
			if (isGuestUser) {
				this.sendMessageForGuest(message);
				return;
			}

			if (this.listener == null) {
				this.listenToSocket();
			}
			this.sendMessageForLoggedInUser(message);
		});
	}

	private isGuestUser(): Observable<boolean> {
		return this.accountProvider.getAccountObservable().pipe(map(account => {
			return account.authRoleId === 10;
		}), take(1));
	}

	private sendMessageForGuest(message: Message<unknown>) {
		const headers = message.headers || {};
		headers["X-type"] = message.type;
		headers["X-replyAddress"] = message.getReplyAddress() || "";

		this.httpClient.post<{}>(this.endpoint + message.address, message.body, {
			headers,
			withCredentials: true
		}).pipe(first()).subscribe(result => {
			message.reply(result);
		}, error => {
			this.logger.error("Unable to send message for guest user", message);
			message.fail(error.status, error.statusText);
		});
	}

	private sendMessageForLoggedInUser(message: Message<unknown>) {
		if (this.socket.status !== SocketStates.OPEN) {
			this.logger.fatal("Cannot send message because socket is not open!", {
				message
			});
			return;
		}
		this.socket.send(message.toJSON());
	}

	private listenToSocket() {
		this.listener = this.socket.messages.subscribe(message => {
			this.sendOverEventBus(message);
		});
	}
}
