import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { Action, EventBus } from "@tsng/core";
import { email, required, TsFormControl, TsFormGroup } from "@tsng/form";
import { LoggerLocator } from "@tsng/logging";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "tsng-login-form",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class TsNgLoginForm {
	@Output() loggedIn: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	form = new TsFormGroup({
		email: new TsFormControl("", [required(), email()]),
		password: new TsFormControl("", [required()]),
		rememberMe: new TsFormControl(false)
	}, {updateOn: "submit"});
	private readonly logger = LoggerLocator.getLogger("TsNgLoginForm")();

	constructor(private eventBus: EventBus) {
	}

	login() {
		if (this.form.invalid) {
			this.logger.warning("Form wasn't valid");
			return;
		}

		const action = new Action("user/login", this.form.value);
		this.eventBus.request("user/authenticate", action).subscribe(response => {
			this.logger.info("User was logged in successfully", response);
			this.eventBus.send(action.type, action);
			this.loggedIn.emit(true);
		}, error => {
			this.logger.error("Login failed", error);
			const action = new Action("snackbar/create", {
				message: $localize`:Error toast|Login failed toast@@tsNgLoginFormLoginFailed:The provided email and/or password is invalid.`,
				level: "error"
			});
			this.eventBus.send(action.type, action);
		});
	}
}
