import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { TsNgAccountModule } from "@tsng/account";
import { TsNgCommonSnackbarModule } from "@tsng/common/snackbar";
import { TsNgStateModule } from "@tsng/common/state";
import { TsNgCoreModule } from "@tsng/core";
import { TsNgStoreModule } from "@tsng/store";
import {
	ActivityCoreModule,
	ChatMessageCoreModule,
	CityCoreModule,
	CompanyCoreModule,
	ImageCoreModule,
	ImageSetCoreModule,
	InterestCoreModule,
	KeywordCoreModule,
	PropertyCoreModule,
	RENDERED_BY,
	RenderEnvironment,
	SharedBaseModule,
	SiteActivityCoreModule,
	SiteCoreModule,
	TicketCoreModule,
	TileCoreModule,
	TileSectionCoreModule,
	UserCoreModule,
	UserGroupCoreModule,
	UserGroupMemberCoreModule,
	UserNotificationSettingCoreModule,
	VenueCoreModule
} from "@twly/core";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { MyListItemCoreModule } from "../../../twly/core/src/lib/models/myListItem/module";
import { ScrollPositionCoreModule } from "../../../twly/core/src/lib/models/scrollPosition/module";
import { environment } from "../environments/environment";
import { AppComponent } from "./component";
import { ClickAnimationModule } from "./component/click-animation/module";
import { DummyFooterComponent } from "./component/dummy-footer/dummy-footer.component";
import { DummyFooterComponentModule } from "./component/dummy-footer/module";
import { BasicLayoutComponent } from "./component/layout/basic/component";
import { AppModalComponentModule } from "./component/modal/module";
import { SplashScreenModule } from "./component/splash-screen/module";
import { ErrorHandlerService } from "./core/error-handler/service";
import { SCHEMA_DEFINITION_PROVIDER } from "./core/schema/schema";
import { routes } from "./route";
import { AutomaticLoginPageModule } from "./route/automatic-login/module";
import { CONFIRM_FINISH_REGISTRATION_PROVIDER, MAGIC_LOGIN_PROVIDER } from "./route/automatic-login/provider";
import { CONFIRM_PASSWORD_RESET_PROVIDER } from "./route/confirm-password-reset/provider";
import { ConfirmFinishRegistrationModule } from "./route/user-group/accept-invite/component";
import { CONFIRM_INVITATION_USER_GROUP_PROVIDER } from "./route/user-group/accept-invite/provider";
import { CONFIRM_EMAIL_CHANGE_PROVIDER } from "./route/user-group/provider";

@NgModule({
	declarations: [
		AppComponent, BasicLayoutComponent
	],
	imports: [
		BrowserModule.withServerTransition({appId: "serverApp"}),
		TransferHttpCacheModule,
		NgxGoogleAnalyticsModule.forRoot("G-CTTW982M6J"),
		NgxGoogleAnalyticsRouterModule,
		HttpClientModule,
		TsNgCoreModule,
		TsNgStateModule,
		TsNgStoreModule,
		TsNgCommonSnackbarModule,
		ActivityCoreModule,
		CityCoreModule,
		CompanyCoreModule,
		KeywordCoreModule,
		ImageSetCoreModule,
		ImageCoreModule,
		SiteCoreModule,
		SiteActivityCoreModule,
		TicketCoreModule,
		TileCoreModule,
		MyListItemCoreModule,
		TileSectionCoreModule,
		InterestCoreModule,
		PropertyCoreModule,
		UserCoreModule,
		VenueCoreModule,
		UserGroupCoreModule,
		UserGroupMemberCoreModule,
		UserNotificationSettingCoreModule,
		ChatMessageCoreModule,
		AutomaticLoginPageModule,
		TsNgAccountModule.forRoot(),
		BrowserAnimationsModule,
		//todo find way to enable this only for dev mode
		// StoreDevtoolsModule.instrument({
		// 	maxAge: 25,
		// 	logOnly: environment.production
		// }),
		RouterModule.forRoot(routes, {
			relativeLinkResolution: "corrected",
			initialNavigation: "enabledBlocking"
		}),
		AppModalComponentModule,
		ConfirmFinishRegistrationModule,
		SplashScreenModule,
		SharedBaseModule.forRoot(environment),
		ScrollPositionCoreModule,
		MatIconModule,
		ClickAnimationModule,
		DummyFooterComponentModule
	],
	providers: [
		CONFIRM_EMAIL_CHANGE_PROVIDER,
		CONFIRM_PASSWORD_RESET_PROVIDER,
		CONFIRM_FINISH_REGISTRATION_PROVIDER,
		CONFIRM_INVITATION_USER_GROUP_PROVIDER,
		SCHEMA_DEFINITION_PROVIDER,
		MAGIC_LOGIN_PROVIDER,
		{
			provide: RENDERED_BY,
			useValue: RenderEnvironment.BROWSER
		}, {
			provide: ErrorHandler,
			useClass: ErrorHandlerService
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
