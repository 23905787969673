<fieldset [ngClass]="{'disabled': _formControl.disabled === true, 'read-only': readonly}">
	<label [for]="id">
		<ng-content></ng-content>
		<ng-container *ngIf="isRequired()">*</ng-container>
	</label>
	<div id="wrapper">
		<textarea
			(blur)="onFocus()"
			[formControl]="_formControl"
			[hidden]="hidden"
			[id]="id"
			[placeholder]="placeholder"
			[rows]="rows"
			[readOnly]="readonly"
			[attr.name]="name"
		></textarea>
	</div>
	<div *ngIf="getHintResults() != null" id="hints">
		<p *ngFor="let item of getHintResults() | keyvalue" [ngClass]="item.value.level" class="hint">
			{{item.value.message}}
		</p>
	</div>
</fieldset>
