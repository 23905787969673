export const additionalSchemaDefinitions = {
	account: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "nextval('user_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"parentId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"email": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 60,
				"default": null,
				"cast": null
			},
			"gender": {
				"type": "CHAR",
				"nullable": false,
				"length": 1,
				"default": "'U'::bpchar",
				"cast": null
			},
			"firstName": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 20,
				"default": null,
				"cast": null
			},
			"infix": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": null,
				"cast": null
			},
			"surname": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 45,
				"default": null,
				"cast": null
			},
			"phone": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 15,
				"default": null,
				"cast": null
			},
			"role": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 12,
				"default": "'USER'::bpchar",
				"cast": null
			},
			"locale": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 5,
				"default": "'nl_NL'::character varying",
				"cast": null
			},
			"active": {
				"type": "BOOLEAN",
				"nullable": false,
				"length": 0,
				"default": "false",
				"cast": null
			},
			"initials": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": "''::character varying",
				"cast": null
			},
			"ppId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "0",
				"cast": null
			},
			"lastLogin": {
				"type": "TIMESTAMPZ",
				"nullable": true,
				"length": 0,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_account_company": {
				"column": "parentId",
				"references": "company.id"
			}
		}
	},
	tile: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "nextval('tile_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"name": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 64,
				"default": null,
				"cast": null
			},
			"tileSectionId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"keywordId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"imageId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_tile_tileSectionId": {
				"column": "tileSectionId",
				"references": "tileSection.id"
			},
			"fk_tile_imageId": {
				"column": "imageId",
				"references": "image.id"
			},
			"fk_tile_keywordId": {
				"column": "keywordId",
				"references": "keyword.id"
			},
			"_fk_myListItemKeyword_tile": {
				"column": "keywordId",
				"references": "myListItem.keywordId"
			},
			"_fk_myListItemActivity_tile": {
				"column": "activityId",
				"references": "myListItem.activityId"
			},
		}
	},
	myListItem: {
		columns: {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "nextval('myListItemTile_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"keywordId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},"activityId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"timestamp": {
				"type": "TIMESTAMPZ",
				"nullable": true,
				"length": 0,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_myListItemKeyword_tile": {
				"column": "keywordId",
				"references": "tile.keywordId"
			},
			"fk_myListItemActivity_tile": {
				"column": "activityId",
				"references": "tile.activityId"
			},
		}
	},
	myListItemTile: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "nextval('myListItemTile_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"name": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 64,
				"default": null,
				"cast": null
			},
			"tileSectionId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"keywordId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"imageId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_myListItemTile_tileSectionId": {
				"column": "tileSectionId",
				"references": "tileSection.id"
			},
			"fk_myListItemTile_imageId": {
				"column": "imageId",
				"references": "image.id"
			},
			"fk_myListItemTile_keywordId": {
				"column": "keywordId",
				"references": "keyword.id"
			}
		}
	},
	chatmsg: {
		"columns": {
			"id": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 0,
				"default": "nextval('tile_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"content": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 4096,
				"default": null,
				"cast": null
			},
			"replyToId": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 64,
				"default": null,
				"cast": null
			},
			"userId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"mentionIds": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"activityIds": {
				"type": "VARCHAR",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"link": {
				"type": "JSONB",
				"nullable": false,
				"length": 0,
				"default": "'{}'::jsonb",
				"cast": "JSONB"
			}
		},
		"relations": {
			"fk_chatmsg_groupId": {
				"column": "groupId",
				"references": "group.id"
			},
			"fk_chatmsg_userId": {
				"column": "userId",
				"references": "user.id"
			}
		}
	},
	venue: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "0",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"name": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"street": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"houseNumber": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": null,
				"cast": null
			},
			"zipCode": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": null,
				"cast": null
			},
			"city": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 60,
				"default": null,
				"cast": null
			},
			"email": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"website": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 100,
				"default": null,
				"cast": null
			},
			"tagIds": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			},
			"location": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"_fk_venue_activity": {
				"column": "id",
				"referencedBy": "activity.venueId"
			}
		}
	},
	activity: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "0",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"siteId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "0",
				"cast": null
			},
			"name": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"street": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"houseNumber": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": null,
				"cast": null
			},
			"zipCode": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 10,
				"default": null,
				"cast": null
			},
			"city": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 60,
				"default": null,
				"cast": null
			},
			"email": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 80,
				"default": null,
				"cast": null
			},
			"url": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			},
			"tagIds": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			},
			"location": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			},
			"presentAtStartTime": {
				"type": "BOOLEAN",
				"nullable": false,
				"length": 0,
				"default": "true",
				"cast": null
			},
			"reservationRequired": {
				"type": "BOOLEAN",
				"nullable": false,
				"length": 0,
				"default": "true",
				"cast": null
			},
			"useVenueAddress": {
				"type": "BOOLEAN",
				"nullable": true,
				"length": 0,
				"default": "true",
				"cast": null
			},
			"useVenueContact" : {
				"type": "BOOLEAN",
				"nullable": true,
				"length": 0,
				"default": "true",
				"cast": null
			},
			"useVenueOpeningHours" : {
				"type": "BOOLEAN",
				"nullable": true,
				"length": 0,
				"default": "true",
				"cast": null
			},
			"images": {
				"type": "VARCHAR",
				"nullable": true,
				"length": 255,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_activity_venue": {
				"column": "venueId",
				"references": "venue.id"
			}
		}
	},
	interest: {
		"columns": {
			"id": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": "nextval('tile_id_seq'::regclass)",
				"cast": null
			},
			"rev": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"keywordId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			},
			"imageId": {
				"type": "INTEGER",
				"nullable": false,
				"length": 0,
				"default": null,
				"cast": null
			}
		},
		"relations": {
			"fk_tile_imageId": {
				"column": "imageId",
				"references": "image.id"
			},
			"fk_tile_keywordId": {
				"column": "keywordId",
				"references": "keyword.id"
			}
		}
	}}
