import { Inject, Injectable } from "@angular/core";
import { Entity } from "./entity";
import { Field, FieldTypes } from "./field";
import {
	isSchemaRelationReferences,
	SCHEMA_DEFINITION,
	SchemaColumn,
	SchemaColumns,
	SchemaColumnType,
	SchemaDefinition,
	SchemaRelationReferencedBy,
	SchemaRelationReferences,
	SchemaRelations,
	SchemaRelationType,
	SchemaTable,
	SchemaTables
} from "./model";
import { Relation } from "./relation";

// TODO Figure out how this will ever be possible to map JSON Arrays
const SCHEMA_COLUMN_TO_FIELD_TYPE_MAP = {
	[SchemaColumnType.VARCHAR]: FieldTypes.STRING,
	[SchemaColumnType.CHAR]: FieldTypes.STRING,
	[SchemaColumnType.TEXT]: FieldTypes.STRING,
	[SchemaColumnType.INTEGER]: FieldTypes.NUMBER,
	[SchemaColumnType.BOOLEAN]: FieldTypes.BOOLEAN,
	[SchemaColumnType.JSONB]: FieldTypes.OBJECT
};

@Injectable({
	providedIn: "root"
})
export class Schema {
	private readonly entities: Entity[];

	constructor(@Inject(SCHEMA_DEFINITION) private readonly schemaDefinition: SchemaDefinition) {
		this.entities = this.createEntities(schemaDefinition.tables);
	}

	entityExists(entityName: string): boolean {
		return this.entities.find(entity => (entity.getName() === entityName)) != null;
	}

	entity(entityName: string): Entity {
		return this.entities.find(entity => (entity.getName() === entityName));
	}

	getEntities(): Readonly<Entity>[] {
		return this.entities;
	}

	private createEntities(tables: SchemaTables): Entity[] {
		return Object.keys(tables)
			//.reduce todo add one to one logic
			.map((tableName) => this.createEntity(tableName, tables[tableName]));
	}

	private createEntity(tableName: string, table: SchemaTable): Entity {
		const relations = this.createRelations(tableName, table.relations);
		const fields = this.createFields(table.columns);
		return new Entity(tableName, fields, relations);
	}

	private createRelations(tableName: string, relations: SchemaRelations): Relation[] {
		return Object.keys(relations)
			.map((relationId) => this.createRelation(tableName, relationId, relations[relationId]));
	}

	// noinspection JSMethodCanBeStatic
	private createRelation(
		tableName: string,
		relationId: string,
		relation: SchemaRelationReferences | SchemaRelationReferencedBy
	): Relation {
		let type = (relationId.startsWith("_") ? SchemaRelationType.ONE_TO_MANY
			: SchemaRelationType.MANY_TO_ONE);
		if (relation?.type != null) {
			type = relation.type;
		}
		const sourceName = tableName;
		const sourceField = relation.column;

		let targetName,
			targetField;
		if (isSchemaRelationReferences(relation)) {
			[targetName, targetField] = relation.references.split(".");
		} else {
			[targetName, targetField] = relation.referencedBy.split(".");
		}
		return new Relation(relationId, type, sourceName, sourceField, targetName, targetField);
	}

	private createFields(columns: SchemaColumns): Field[] {
		return Object.keys(columns)
			.map((columnName) => this.createField(columnName, columns[columnName]));
	}

	// noinspection JSMethodCanBeStatic
	private createField(columnName: string, column: SchemaColumn): Field {
		const fieldType = SCHEMA_COLUMN_TO_FIELD_TYPE_MAP[column.type];
		return new Field(columnName, fieldType);
	}
}
