import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { City, CityConversion, initialCityStore } from "./model";

export const CITY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CityReducer");

export function cityReducerProvider() {
	return reducerFactory("city", initialCityStore, City, CityConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("city", CITY_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["city/deleted", "city/updated", "city/created"])
	],
	providers: [
		{
			provide: CITY_REDUCER_TOKEN,
			useFactory: cityReducerProvider
		}
	]
})
export class CityCoreModule {

}
