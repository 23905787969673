export enum FieldTypes {
	NUMBER = "NUMBER", STRING = "STRING", BOOLEAN = "BOOLEAN", OBJECT = "OBJECT", DATE = "DATE", ARRAY = "ARRAY"
}

export class Field {
	constructor(private readonly name: string, private readonly type: FieldTypes) {
	}

	getName(): string {
		return this.name;
	}

	getType(): FieldTypes {
		return this.type;
	}
}
