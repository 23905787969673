import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CrawlerUrlLinkStore = ModelMap<CrawlerUrlLink>;

export const initialCrawlerUrlLinkStore: CrawlerUrlLinkStore = <CrawlerUrlLinkStore>Map();

export const CrawlerUrlLinkConversion: ActionConverter.Config = {};

export interface CrawlerUrlLinkModel extends StoreModel {
	urlId: number;
	refersToUrlId: number;
	description: string;
}

export class CrawlerUrlLink extends Record({
	id: 0,
	rev: 0,
	urlId: null,
	refersToUrlId: null,
	description: ""

}) implements CrawlerUrlLinkModel {
	id: number;
	rev: number;

	urlId: number;
	refersToUrlId: number;
	description: string;

	get displayName(): string {
		return this.urlId + ", " + this.refersToUrlId;
	};
}
