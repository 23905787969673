import { LoggerLocator } from "@tsng/logging";
import { ReplaySubject } from "rxjs";
import { Util } from "../../util/util";
import { Message } from "../message/message";

/**
 * Class that can be used to hold a collection of handlers.
 * It provides functionality to register a new handler, see if a handler exists and unregister a handler.
 * In the future a round robin strategy could be implemented in this class.
 */
export class HandlerHolder {
	private handlers: Map<string, ReplaySubject<Message<unknown>>> = new Map();
	private logger = LoggerLocator.getLogger("HandlerHolder")();

	get size() {
		return this.handlers.size;
	}

	get(uuid: string) {
		return this.handlers.get(uuid);
	}

	has(uuid: string): boolean {
		return this.handlers.has(uuid);
	}

	getNext(): ReplaySubject<Message<unknown>> {
		//todo implement round robin here!
		return this.handlers.values().next().value;
	}

	getAll(): ReplaySubject<Message<unknown>>[] {
		return Array.from(this.handlers.values());
	}

	register(handler: ReplaySubject<Message<unknown>>): string {
		const uuid = Util.generateUUID();
		this.handlers.set(uuid, handler);
		return uuid;
	}

	unregister(uuid: string) {
		if (this.handlers.has(uuid) === false) {
			this.logger.warning(`Handler for uuid: '${uuid}' is not registered and cannot be unregistered`);
			return;
		}
		this.handlers.delete(uuid);
	}

}
