import { Injectable } from "@angular/core";
import { Schema } from "../schema/schema";
import { FilterActionBuilderBuilder } from "./builder";

@Injectable({
	providedIn: "root"
})
export class FilterProvider {
	constructor(private schema: Schema) {
	}

	getBuilder(): FilterActionBuilderBuilder {
		return new FilterActionBuilderBuilder(this.schema);
	}
}
