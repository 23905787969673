import { Directive, HostBinding, Input, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ConfigService, Image, ImageGravityPresets, ImageResizingPresets } from "@twly/core";

@Directive({
	selector: "[imageProxy]"
})
export class ImageProxyDirective {
	private imageData: Image | null = null;
	private configData: { preset: ImageResizingPresets, gravity?: ImageGravityPresets, srcSetWidth: string }[] | null = null;

	@Input() set image(image: Image | null) {
		this.imageData = image;
		this.recalculateSrcSet();
	};

	@Input("imageProxy") set config(config: { preset: ImageResizingPresets, gravity?: ImageGravityPresets, srcSetWidth: string }[] | null) {
		this.configData = config;
		this.recalculateSrcSet();
	}

	@HostBinding("srcset") srcset: SafeResourceUrl;

	constructor(private configService: ConfigService, private domSanitizer: DomSanitizer) {
	}

	private recalculateSrcSet() {
		if (this.imageData == null || this.configData == null) {
			this.srcset = "";
			return;
		}

		const srcSet = this.imageData.createSrcSetLinks(this.configService.get("publicImageEndpoint"),
			this.configData
		);
		this.srcset = this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, this.domSanitizer.bypassSecurityTrustResourceUrl(srcSet));
	}
}
