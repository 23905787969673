import { NgModule } from "@angular/core";
import { ActionTokenPage } from "./page/component";
import { actionTokenRoutingModule } from "./route";

@NgModule({
	imports: [actionTokenRoutingModule],
	declarations: [ActionTokenPage],
	exports: [ActionTokenPage]
})
export class TsNgCommonActionTokenModule {
}
