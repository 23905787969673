import { AsyncValidatorFn, FormControl } from "@angular/forms";
import { Logger, LoggerLocator } from "@tsng/logging";
import { TsAbstractControl } from "../abstract";
import { getHintResult, getValidatorFunctionsFromHints, HintLevel, HintResults, Hints } from "../hint";

export class TsFormControl<TValue = any> extends FormControl<TValue> implements TsAbstractControl<TValue> {
	type = "control";
	hints: HintResults = null;
	configuredHints: Hints;
	protected logger: Logger = LoggerLocator.getLogger("TsFormControl")();

	constructor(formState: TValue, validators?: Hints);
	constructor(formState: TValue, options?: {
		validators?: Hints, hints?: Hints, asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null, updateOn?: "change" | "blur" | "submit"
	});
	// @ts-ignore we suppress type hinting here to add additional logic before the super call.
	constructor(formState: TValue, options?: any = {}) {
		if (Array.isArray(options)) {
			options = {validators: options};
		}

		if (Array.isArray(options.validators) === false) {
			options.validators = [];
		}

		if (Array.isArray(options.hints) === false) {
			options.hints = [];
		}

		super(formState, {
			validators: getValidatorFunctionsFromHints(options.validators),
			asyncValidators: options.asyncValidators,
			updateOn: options.updateOn
		});
		this.configuredHints = [...options.validators, ...options.hints];
	}

	getChanges(): any | undefined {
		if (this.pristine) {
			return undefined;
		}

		return this.value;
	}

	patch(value: TValue | null) {
		if (this.dirty === true) {
			this.logger.warning(`Control ${this} changed after it was changed by the user.`);
			return;
		}

		this.patchValue(value, {
			emitEvent: false,
			emitModelToViewChange: true,
			emitViewToModelChange: false
		});
	}

	updateValueAndValidity(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
		if (this.enabled) {
			this.hints = getHintResult(this as any, this.configuredHints);
		}
		super.updateValueAndValidity(opts);
	}

	// @ts-ignore we suppress type hinting here to overwrite a default method
	setValidators(validators: any): void {
		const hints = this.configuredHints.filter(hint => hint.level != HintLevel.ERROR);
		super.setValidators(getValidatorFunctionsFromHints(validators));
		this.configuredHints = [...hints, ...validators];
	}

	setHints(hints: Hints): void {
		this.configuredHints = hints;
		this.updateValueAndValidity({
			onlySelf: true,
			emitEvent: true
		});
	}

	clearHints(): void {
		this.configuredHints = null;
		this.updateValueAndValidity({
			onlySelf: true,
			emitEvent: true
		});
	};
}
