import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";
import { TsNgCoreModule } from "@tsng/core";
import { TsNgFormModule } from "@tsng/form";
import { TsNgStoreModule } from "@tsng/store";
import { TsNgChangeEmailForm } from "./component/change-email-form/component";
import { TsNgChangePasswordForm } from "./component/change-password-form/component";
import { TsNgLoginForm } from "./component/login-form/component";
import { TsNgPasswordResetConfirmation } from "./component/password-reset-confirmation-form/component";
import { TsNgRequestPasswordReset } from "./component/request-password-reset-form/component";
import { TsNgIfAllPermissionDirective } from "./directive/if-all-permission/directive";
import { TsNgIfAnyPermissionsDirective } from "./directive/if-any-permission/directive";
import { TsNgIfPermissionDirective } from "./directive/if-permission/directive";
import { Account } from "./store/model";
import { ACCOUNT_MODEL, ACCOUNT_REDUCER_TOKEN, accountReducerProvider } from "./store/reducer";

@NgModule({
	declarations: [
		TsNgLoginForm,
		TsNgRequestPasswordReset,
		TsNgChangePasswordForm,
		TsNgChangeEmailForm,
		TsNgPasswordResetConfirmation,
		TsNgIfPermissionDirective,
		TsNgIfAllPermissionDirective,
		TsNgIfAnyPermissionsDirective
	],
	exports: [
		TsNgLoginForm,
		TsNgRequestPasswordReset,
		TsNgChangePasswordForm,
		TsNgChangeEmailForm,
		TsNgPasswordResetConfirmation,
		TsNgIfPermissionDirective,
		TsNgIfAllPermissionDirective,
		TsNgIfAnyPermissionsDirective
	],
	imports: [
		CommonModule, ReactiveFormsModule, TsNgCoreModule, TsNgFormModule, TsNgStoreModule.forFeature([
			"store/login", "store/set-logged-in", "store/logout", "store/principal-changed"
		]), StoreModule.forFeature("account", ACCOUNT_REDUCER_TOKEN)
	]
})
export class TsNgAccountModule {
	static forRoot(): ModuleWithProviders<TsNgAccountModule> {
		return {
			ngModule: TsNgAccountModule,
			providers: [
				{
					provide: ACCOUNT_MODEL,
					useValue: Account
				}, {
					provide: ACCOUNT_REDUCER_TOKEN,
					useFactory: accountReducerProvider,
					deps: [ACCOUNT_MODEL]
				}
			]
		};
	}
}
