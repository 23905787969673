import { SchemaRelationType } from "./model";

export class Relation {
	constructor(
		private readonly id: string,
		private readonly type: SchemaRelationType,
		private readonly sourceName: string,
		private readonly sourceField: string,
		private readonly targetName: string,
		private readonly targetField: string
	) {
	}

	getIdentifier(): string {
		return this.id;
	}

	getType(): SchemaRelationType {
		return this.type;
	}

	getSourceName(): string {
		return this.sourceName;
	}

	getSourceField(): string {
		return this.sourceField;
	}

	getTargetName(): string {
		return this.targetName;
	}

	getTargetField(): string {
		return this.targetField;
	}
}




