import { Action } from "@tsng/core";
import { Operator } from "../filter/operator/operator";

export enum SortDirection {
	DEFAULT = "", ASC = "ASC", DESC = "DESC"
}

export interface Sort {
	field: string;
	direction: SortDirection
}

export type CompoundEntityName = string;

export interface FilterActionBody {
	search: string;
	limit: number;
	offset: number;
	sort: Sort[];
	filter: Operator;
	compound?: CompoundEntityName[]
	params?: unknown;
}

export type FilterAction = Action<FilterActionBody>;

export type FilterCleanableProperty = 'search' | 'limit' | 'offset' | 'sort' | 'operators' | 'params';

export type FilterCleanableProperties = FilterCleanableProperty[]
