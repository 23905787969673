import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { loggedIn } from "@tsng/account";
import { LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { RENDERED_BY, RenderEnvironment, TwentelyAccount } from "@twly/core";
import { Map } from "immutable";
import { Observable, of, throwError } from "rxjs";
import { catchError, filter, first, map } from "rxjs/operators";

export type RequirePermission = string | {
	[key: string]: any, hasPermission: ((route: ActivatedRouteSnapshot, account: TwentelyAccount) => boolean)
};

@Injectable({
	providedIn: "root"
})
export class LoginAuthorizationGuard implements CanActivate {
	private currentUrl = "";
	private logger = LoggerLocator.getLogger("LoginAuthorizationGuard")();
	private accountSelector;

	constructor(
		private selectorProvider: SelectorProvider,
		private router: Router,
		@Inject(RENDERED_BY) private renderedBy: RenderEnvironment
	) {
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		this.currentUrl = (state != null && state.url != null) ? state.url : "";

		if (this.renderedBy === RenderEnvironment.SERVER) {
			return of(true);
		}

		return of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: TwentelyAccount }>) => accountMap.first().account),
			filter(account => account != null && account.loggedIn !== loggedIn.DEFAULT),
			first(),
			map(account => {
				if (account.loggedIn === loggedIn.FALSE) {
					this.logger.debug("User is not logged in, granted access to login route");
					return true;
				}

				const permission: RequirePermission = route && route.data && route.data.permission;
				if (permission == null) {
					this.logger.debug("Route has no permission access granted");
					return true;
				}

				if (typeof permission === "string") {
					const result = account.hasPermission(permission);
					this.logger.debug("User permission granted result", {
						permission,
						result
					});
					if (result === true) {
						return true;
					}
					return this.router.createUrlTree(["/"]);
				}
				const result = permission.hasPermission.call(permission, route, account);
				this.logger.debug("User permission granted result for permission function", {
					result: result
				});
				if (result === true) {
					return true;
				}
				return this.router.createUrlTree(["/"]);
			}),
			catchError(error => {
				this.logger.error("Error within Observable chain", {
					error: error
				});
				return throwError(error);
			})
		);
	}
}
