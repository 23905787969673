import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerAddressLookup, CrawlerAddressLookupConversion, initialCrawlerAddressLookupStore } from "./model";

export const CRAWLERADDRESSLOOKUP_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>(
	"CrawlerAddressLookupReducer");

export function crawlerAddressLookupReducerProvider() {
	return reducerFactory(
		"crawlerAddressLookup",
		initialCrawlerAddressLookupStore,
		CrawlerAddressLookup,
		CrawlerAddressLookupConversion
	);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerAddressLookup", CRAWLERADDRESSLOOKUP_REDUCER_TOKEN),
		TsNgStoreModule.forFeature([
			"crawlerAddressLookup/deleted",
			"crawlerAddressLookup/updated",
			"crawlerAddressLookup/created"
		])
	],
	providers: [
		{
			provide: CRAWLERADDRESSLOOKUP_REDUCER_TOKEN,
			useFactory: crawlerAddressLookupReducerProvider
		}
	]
})
export class CrawlerAddressLookupCoreModule {

}
