import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AccountProvider } from "@twly/core";

@Injectable({
	providedIn: "root"
})
export class PendingTokenGuard implements CanActivate {

	constructor(private accountProvider: AccountProvider,
		private router: Router
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return this.accountProvider.getAccountObservable().pipe(
			map(account => {
				if(account.hasPermission("userGroup/view") === false) {
					return true;
				}
				const token = this.getToken();
				if (token != null) {
					return this.router.createUrlTree(["/token", token]);
				}
				return true;
			})
		)
	}

	getToken(): string | null {
		return localStorage.getItem("pendingToken");
	}
}
