/**
 * The account of the user logged in lives as a record in the global application state.
 */
import { InjectionToken, Type } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";
import { Action } from "@tsng/core";
import { Account, loggedIn } from "./model";

export const ACCOUNT_MODEL = new InjectionToken("AccountModel");
export const ACCOUNT_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("AccountReducer");

export function accountReducerProvider<T extends Account>(model: Type<T>) {
	return function reducer(state: T = new model({}), action: Action): T {
		switch (action.type) {
			case "user/updated":
				if (action.id === state.id) return <T>state.merge(action.data); else return state;
			case "principal/received":
				return <T>state.merge(action.data)
					.set("loggedIn", loggedIn.TRUE);
			case "store/set-logged-in":
				return <T>state.set("loggedIn", action.data);
			case "store/logout":
				return new model({});
			default:
				return state;
		}
	};
}
