import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type TileSectionStore = ModelMap<TileSection>;

export const initialTileSectionStore: TileSectionStore = <TileSectionStore>Map();

export const TileSectionConversion: ActionConverter.Config = {};

export type TileSectionOptions = {
	audienceIds?: number[],
	keywordIds?: number[],
	tileDisplay?: string,
	sectionDisplay?: string,
	today?: boolean,
	featured?: boolean,
	color?: string
}

export type TileSectionVisibilityOptions = {
	fromDate?: string | null,
	throughDate?: string | null,
	fromTime?: string | null,
	throughTime: string | null
}

export interface TileSectionModel extends StoreModel {
	name: string;
	category: string;
	orderNo: number;
	keywordId: number | null;
	options: TileSectionOptions;
	visibilityOptions: TileSectionVisibilityOptions;
}

export class TileSection extends Record({
	id: 0,
	rev: 0,
	name: "",
	category: "",
	orderNo: 1,
	keywordId: null,
	options: {},
	visibilityOptions: {}

}) implements TileSectionModel {
	id: number;
	rev: number;
	name: string;
	category: string;
	orderNo: number;
	keywordId: number | null;
	options: TileSectionOptions;
	visibilityOptions: TileSectionVisibilityOptions;

	get displayName(): string {
		return this.name;
	};
}
