import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { Location, Site, SiteModel } from "../site/model";
import { SiteActivity } from "../siteActivity/model";

export type ChatMessageStore = ModelMap<ChatMessage>;


export type ChatMessageLink = {
	url: null | string;
	title: null | string;
	imageUrl: null | string;
	siteActivityId: null | number;
	keywordId: null | number;
	description: null | string;
}

export const initialChatMessageStore: ChatMessageStore = <ChatMessageStore>Map();

function toNumber(defaultValue: number|string): ActionConverter.Function {
	return (value: number|string) => {
		if (typeof value !== "number" && isNaN(parseInt(value))) return defaultValue;
		if (typeof value === "string") return parseInt(value, 10);
		return value;
	};
}

export const ChatMessageConversion: ActionConverter.Config = {
	timestamp: ActionConverter.stringToDate(new Date()),
	groupId: toNumber(0)
};

export interface ChatMessageModel extends StoreModel {
	groupId: number | string;
	timestamp: Date;
	userId: number;
	replyToId: string;
	mentionIds : string[];
	activityIds : string[];
	readByUserIds : number[];
	content : string;
	link: ChatMessageLink
}

export class ChatMessage extends Record({
	id: 0,
	rev: 0,
	groupId: null,
	timestamp: new Date(),
	userId: null,
	replyToId: null,
	mentionIds : [],
	activityIds : [],
	readByUserIds : [],
	content : "",
	link: {
		linkUrl: null,
		linkTitle: null,
		linkImageUrl: null,
		linkDescription: null
	}

}) implements ChatMessageModel {
	id: string;
	rev: number;
	groupId: number | string;
	timestamp: Date;
	userId: number;
	replyToId: string;
	mentionIds : string[];
	activityIds : string[];
	readByUserIds : number[];
	content : string;
	link: ChatMessageLink

	get displayName(): string {
		return this.content;
	};
}
