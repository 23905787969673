import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";
import { Action } from "@tsng/core";
import { ActionConverter, ModelMap, ReducerHandlers } from "@tsng/store";
import { FileConversion, initialFilesStore, ServerFile } from "./server-file";

export const FILE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("FileReducer");

export function fileReducerProvider() {
	return (state: ModelMap<ServerFile> = initialFilesStore, action: Action) => {
		switch (action.type) {
			case "file/received":
				return ReducerHandlers.handleReceivedAction(state,
					ActionConverter.adapt(action, FileConversion) as any,
					new ServerFile()
				);

			case "file/create":
				return ReducerHandlers.handleCreateAction(state,
					ActionConverter.adapt(action, FileConversion) as any,
					new ServerFile()
				);

			case "file/created":
			case "file/uploaded":
				return ReducerHandlers.handleCreatedAction(state,
					ActionConverter.adapt(action, FileConversion) as any,
					new ServerFile()
				);

			case "file/updated":
				return ReducerHandlers.handleUpdatedAction(state,
					ActionConverter.adapt(action, FileConversion) as any,
					new ServerFile()
				);

			case "file/deleted":
				return ReducerHandlers.handleDeletedAction(state,
					ActionConverter.adapt(action, FileConversion) as any
				);

			case "store/logout":
				// case "principal/received": todo find a proper solution upon switching accounts.
				return initialFilesStore;

			default:
				return state;
		}
	};
}
