import { createSelector, select, Selector, State, Store } from "@ngrx/store";
import { Logger, LoggerLocator } from "@tsng/logging";
import { Map, OrderedMap } from "immutable";
import { combineLatest, Observable, OperatorFunction } from "rxjs";
import { debounceTime, distinctUntilChanged, map, switchMap } from "rxjs/operators";
import { isStoreModel, StoreModel } from "../model/model";
import {
	AbstractEntityLinkBuilder, EntityLink, EntityLinkConfiguration
} from "../schema/abstract-entity-link-builder";
import { Relation } from "../schema/relation";
import { Schema } from "../schema/schema";
import { SelectorMerger } from "./merger";

export type MergeOperator<T> = () => OperatorFunction<SelectorStoreData, T>;

export interface SelectorLink extends EntityLink {
	selector: (props) => Selector<any, any>
}

export type SelectorStoreData = { [sourceAlias: string]: Map<number | string, StoreModel> | OrderedMap<number | string, StoreModel> }

export type SelectorMergeFactory = (links: SelectorLink[]) => MergeOperator<any>;

/**
 * See the {@see ./README.md} file for documentation
 */
export class SelectorBuilder
	extends AbstractEntityLinkBuilder<SelectorLink, () => OperatorFunction<any, any>> {
	protected logger: Logger = LoggerLocator.getLogger("SelectorBuilder")();
	private mergeOperator: MergeOperator<any>;
	private mergeFactory: SelectorMergeFactory;

	constructor(protected schema: Schema, private store: Store<any>) {
		super();
	}

	setMergeFactory(factory: SelectorMergeFactory): SelectorBuilder {
		const self = this.clone();
		self.mergeFactory = factory;
		return self;
	}

	setMergeOperator(mergeOperator: MergeOperator<any>) {
		const self = this.clone();
		self.mergeOperator = mergeOperator;
		return self;
	}

	build<T, D>(): () => OperatorFunction<T, D> {
		const links = this.buildLinks();
		if (this.mergeOperator != null) {
			return this.createSelectorOperatorFunction(links);
		}

		if (this.mergeFactory == null) {
			this.mergeFactory = SelectorMerger.createFlatMerger;
		}

		this.mergeOperator = this.mergeFactory(links);
		return this.createSelectorOperatorFunction(links);
	}

	protected createEntityLink(sourceConfiguration: EntityLinkConfiguration,
		targetLink?: SelectorLink
	): SelectorLink | null {
		const link = super.createEntityLink(sourceConfiguration, targetLink);
		if (link == null) {
			return null;
		}

		let selector: (props) => Selector<any, any>;
		if (targetLink != null) {
			selector = this.buildLinkedSelector(targetLink.selector, link.relation);
		} else {
			selector = this.buildMainSelector();
		}
		link.selector = selector;
		return link;
	};

	private createSelectorOperatorFunction<T, D>(links: SelectorLink[]): () => OperatorFunction<T, D> {
		return () => (source: Observable<{ ids?: number[] | string[] }>) => source.pipe(
			// get all relevant models
			switchMap(props => {
				const selectors: Observable<any>[] = [];
				links.forEach(link => {
					selectors.push(this.createObservableFromSelector(this.store,
						link.sourceAlias,
						link.selector,
						props
					));
				});

				// return all relevant models in
				return combineLatest(selectors)
					.pipe<SelectorStoreData>(map(value => Object.assign({}, ...value)));
			}), // wait till there are no events for 100 milliseconds
			debounceTime(100), // merge all relevant models
			this.mergeOperator()
		);
	}

	private createObservableFromSelector(input: Observable<any>,
		name: string,
		selector,
		props
	): Observable<any> {
		return input.pipe(select(selector(props)), distinctUntilChanged((prev, curr) => {
			return prev.equals(curr);
		}), map(value => ({[name]: value})));
	}

	private buildMainSelector() {
		return (props) => createSelector(this.getStoreSelector(this.mainEntityConfiguration.entity.getName()),
			(state) => {
				if (state == null) {
					this.logger.fatal(`No state found for entity: ${this.mainEntityConfiguration.entity.getName()}. Dit could mean that the ${this.mainEntityConfiguration.entity.getName()} CoreModule is missing`);
				}
				let internalState = state;
				if (isStoreModel(state)) {
					internalState = Map([[state.id, state]]);
				}
				if (props.hasOwnProperty("ids")) {
					let availableIds = props.ids.filter(id => (internalState.has(id) === true));
					return availableIds.reduce((previousValue, id) => previousValue.set(id,
						internalState.get(id)
					), OrderedMap());
				}
				return internalState;
			}
		);
	}

	private buildLinkedSelector(mainSelector: (props) => Selector<any, any>,
		relation: Relation
	) {
		return (props) => createSelector(this.getStoreSelector(relation.getTargetName()),
			mainSelector(props), (state, parentState) => {
				if (state == null) {
					this.logger.fatal(`No state found for entity: ${relation.getTargetName()}. this could mean that the ${relation.getTargetName()}CoreModule is missing`);
				}
				let internalState = state;
				if (isStoreModel(state)) {
					internalState = Map([[state.id, state]]);
				}

				const filterParam: any[] = parentState.map(self => self[relation.getSourceField()])
					.toArray();
				return internalState.filter(model => filterParam.includes(model[relation.getTargetField()]));
			});
	}

	private getStoreSelector(entityName: string) {
		return (state: State<unknown>) => state[entityName];
	}
}
