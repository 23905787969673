import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	$nor: [...]
 * }
 */
export class NorOperator implements Operator {
	constructor(private children: Operator[] = []) {
	}

	add(operator: Operator): NorOperator {
		this.children.push(operator);
		return this;
	}

	remove(operator: Operator): NorOperator {
		this.children = this.children.reduce((reduction, value) => {
			if (value === operator) return reduction;
			reduction.push(value);
			return reduction;
		}, []);
		return this;
	}

	getChildren(): Operator[] {
		return this.children;
	}

	build(): object | null {
		const nor = this.children.map(value => value.build())
			.filter(value => value != null);

		if (nor.length <= 0) return null;

		return {
			"$nor": nor
		};
	}

	clone(): NorOperator {
		return new NorOperator([...this.children.map(child => child.clone())]);
	}
}
