import { inject, Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Logger, LoggerLocator } from "@tsng/logging";
import { RENDERED_BY, RenderEnvironment } from "@twly/core";

@Injectable({
	  providedIn: "root"
})
export class DeepLinkService {
	private router = inject(Router);
	private zone = inject(NgZone);
	private renderedBy: RenderEnvironment = inject(RENDERED_BY);
	private logger: Logger = LoggerLocator.getLogger("ShareService")();

	constructor() {
		if(this.renderedBy !== RenderEnvironment.CAPACITOR) {
			return;
		}
		this.initializeApp();
	}

	initializeApp() {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.zone.run(() => {
				// Example url: https://unbored.io/nl/token/abc
				// slug = /token/abc
				// https://regex101.com/r/ZRFdol/1
				const regex = /https:\/\/unbored\.io(\/nl|\/en)?(.*)/g;
				const slug = regex.exec(event.url)[2];
				if (slug != null) {
					this.router.navigateByUrl(slug).catch(error => {
						this.logger.error("Failed to navigate to", {slug, error});
					});
				}
				// If no match, do nothing - let regular routing
				// logic take over
			});
		});
	}
}
