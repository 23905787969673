import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialScrollPositionStore, ScrollPosition, ScrollPositionConversion } from "./model";
import { scrollPositionReducer } from "./reducer";

export const SCROLL_POSITION_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("ScrollPositionReducer");

export function scrollPositionReducerProvider() {
	return reducerFactory("scrollPosition", initialScrollPositionStore, ScrollPosition, ScrollPositionConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("scrollPosition", scrollPositionReducer), TsNgStoreModule.forFeature([
			"scrollPosition/set",
			"scrollPosition/unset",
			"scrollPosition/clear"
		])
	],
	providers: [
		{
			provide: SCROLL_POSITION_REDUCER_TOKEN,
			useFactory: scrollPositionReducerProvider
		}
	]
})
export class ScrollPositionCoreModule {}
