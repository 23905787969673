import { ACTION_TOKEN_COMPONENT_CONFIGS } from "@tsng/common/token";

// https://stackoverflow.com/questions/60773079/angular-9-localize-and-xi18n-tagged-template-expressions-are-not-supported-in
// This way the i18n extract process doesn't complain about 'Tagged template expressions are not supported in metadata.'
const getTranslatedToastMessage = () => $localize`:Successfully confirmed the password reset change:Your password has been updated`; // todo this still doesn't work with i18n
export function componentLoader() {
	return import("./component").then(mod => mod.AutomaticLoginPage);
}
export const CONFIRM_FINISH_REGISTRATION_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/finish-registration",
		onSuccess: {
			navigateTo: ["/finish-registration"],
			toastMessage: ""
		},
		loader: componentLoader
	}
};

export const MAGIC_LOGIN_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/magic-login",
		onSuccess: {
			navigateTo: ["/"],
			toastMessage: ""
		},
		loader: componentLoader
	}
};
