import { ACTION_TOKEN_COMPONENT_CONFIGS } from "@tsng/common/token";

const getTranslatedToastMessage = () => $localize`:Successfully confirmed the password reset change:Your password has been updated`; // todo fix translation

export function componentLoader() {
	return import("./component").then(mod => mod.ConfirmPasswordResetPage);
}

export const CONFIRM_PASSWORD_RESET_PROVIDER = {
	provide: ACTION_TOKEN_COMPONENT_CONFIGS,
	multi: true,
	useValue: {
		tokenType: "user/reset-password",
		onSuccess: {
			navigateTo: ["/login"],
			toastMessage: "Your password has been updated"
		},
		loader: componentLoader
	}
};
