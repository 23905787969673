import { Inject, Injectable, NgZone } from "@angular/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { filter, first, timeoutWith } from "rxjs/operators";
import SockJS from "sockjs-client/dist/sockjs";
import { MessageObject } from "../event-bus/message/message";
import { Util } from "../util/util";
import { SOCKET_OPTIONS, SocketOptions } from "./options";

export enum SocketStates {
	OPENING, OPEN, CLOSING, CLOSED, NO_HEARTBEAT
}

export const DEFAULT_PING_INTERVAL = 5000;
export const DEFAULT_HEARTBEAT_INTERVAL = 50000; //2 * 25 seconden default VertX
export const DEFAULT_TIMEOUT_DURATION = 10000;

@Injectable({
	providedIn: "root"
})
export class Socket {
	private socketMessages: Subject<MessageObject<unknown>> = new Subject();
	private socket;
	private statusEvents: BehaviorSubject<SocketStates> = new BehaviorSubject(SocketStates.CLOSED);
	private readonly pingInterval: number;
	private readonly heartbeatInterval: number;
	private readonly timeoutDuration: number;
	private readonly url: string;
	private pingTimerHandle: number;
	private heartbeatTimerHandle: number;
	private logger: Logger = LoggerLocator.getLogger("Socket")();

	constructor(@Inject(SOCKET_OPTIONS) private options: SocketOptions, private ngZone: NgZone) {
		this.url = options.url;
		this.pingInterval = options.pingInterval ?? DEFAULT_PING_INTERVAL;
		this.heartbeatInterval = options.heartbeatInterval ?? DEFAULT_HEARTBEAT_INTERVAL;
		this.timeoutDuration = options.timeout ?? DEFAULT_TIMEOUT_DURATION;
		this.initPing();
	}

	get messages(): Observable<MessageObject<unknown>> {
		return this.socketMessages.asObservable();
	}

	get statusObservable(): Observable<SocketStates> {
		return this.statusEvents.asObservable();
	}

	get status(): SocketStates {
		return this.statusEvents.value;
	}

	private set internalStatus(status: SocketStates) {
		this.statusEvents.next(status);
	}

	send(message: MessageObject<unknown>) {
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			this.logger.fatal("Socket is closed, or is closing");
			throw new Error("Socket is closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) {
			this.logger.fatal("Socket has not finished opening yet");
			throw new Error("Socket has not finished opening yet");
		}

		this.logger.debug(`Message send:\n\n${JSON.stringify(message, null, 4)}`);
		this.socket.send(JSON.stringify(message));
	}

	open(): Observable<SocketStates> {
		if (this.status !== SocketStates.CLOSED) {
			this.logger.fatal("Socket is already open, first close it using this.close()");
			return throwError("Socket is already open, first close it using this.close()");
		}
		this.statusEvents.next(SocketStates.OPENING);
		let observable = this.statusEvents.pipe(
			filter((state: SocketStates) => state === SocketStates.OPEN),
			timeoutWith(this.timeoutDuration,
				throwError("[SOCKET-TIMEOUT-MESSAGE] socket was unable to open")
			),
			first()
		);
		this.socket = this.openSocket(this.url);
		this.bindCallbacks();
		return observable;
	}

	close() {
		if (this.status === SocketStates.CLOSING || this.status === SocketStates.CLOSED) {
			this.logger.fatal("Socket was already closed, or is closing");
			throw new Error("Socket was already closed, or is closing");
		}
		if (this.status === SocketStates.OPENING) {
			this.logger.fatal("Socket is not open yet");
			throw new Error("Socket is not open yet");
		}
		this.internalStatus = SocketStates.CLOSING;
		this.socket.close();
	}

	private initPing() {
		this.statusEvents.pipe(filter(event => event === SocketStates.OPEN))
			.subscribe(() => this.startPing());
		this.statusEvents.pipe(filter(event => event === SocketStates.CLOSED))
			.subscribe(() => {
				this.stopPing();
				this.cancelHeartbeatTimeout();
			});
	}

	private startPing() {
		if (this.pingTimerHandle != null) {
			Util.clearIntervalOutsideNgZone(this.ngZone, this.pingTimerHandle);
		}

		this.pingTimerHandle = Util.setIntervalOutsideNgZone(this.ngZone, () => {
			this.socket.send("{\"type\":\"ping\"}");
		}, this.pingInterval);
	}

	private stopPing() {
		if (this.pingTimerHandle == null) {
			return;
		}

		Util.clearIntervalOutsideNgZone(this.ngZone, this.pingTimerHandle);
		this.pingTimerHandle = null;
	}

	private heartbeatReceived() {
		this.cancelHeartbeatTimeout();

		this.heartbeatTimerHandle = Util.setTimeoutOutsideNgZone(this.ngZone, () => {
			this.noServerHeartbeat();
		}, this.heartbeatInterval);
	}

	private cancelHeartbeatTimeout() {
		if (this.heartbeatTimerHandle == null) {
			return;
		}

		Util.clearTimeoutOutsideNgZone(this.ngZone, this.heartbeatTimerHandle);
		this.heartbeatTimerHandle = null;
	}

	private noServerHeartbeat() {
		this.logger.warning("Server didn't send a heartbeat within the required timeframe, closing socket");
		this.internalStatus = SocketStates.NO_HEARTBEAT;
		this.socket.close();
	}

	private bindCallbacks() {
		this.socket.onopen = () => {
			this.internalStatus = SocketStates.OPEN;
		};

		this.socket.onclose = () => {
			this.internalStatus = SocketStates.CLOSED;
		};

		this.socket.onmessage = (event) => {
			const message: MessageObject<unknown> = JSON.parse(event.data);
			this.socketMessages.next(message);
			this.logger.debug(`Message received:\n\n${JSON.stringify(message, null, 4)}`);
		};

		this.socket.onheartbeat = () => {
			this.heartbeatReceived();
		};
	}

	private openSocket(url: string) {
		return new SockJS(this.url, {}, {timeout: this.timeoutDuration});
	}
}
