import { StateComponent } from "../model";
import { StateDecoratorService } from "../service/service";

export function State() {
	return function (target: any, propertyKey: string) {
		// overwrite ngOnDestroy so we can clean up any potential memory leaks within the StateDecoratorService
		if (target.hasOwnProperty("ngOnDestroy")) {
			const descriptor = Object.getOwnPropertyDescriptor(target, "ngOnDestroy");
			const method = descriptor.value;
			target.ngOnDestroy = function () {
				if (this["$tsng_common_state.decorator_service"] != null) {
					this["$tsng_common_state.decorator_service"].ngOnDestroy();
					this["$tsng_common_state.decorator_service"] = null;
				}
				method.apply(this, arguments);
			};
		}

		Object.defineProperty(target, propertyKey, {
			get: function () {
				const me: StateComponent = this;
				if (this["$tsng_common_state.decorator_service"] == null) {
					this["$tsng_common_state.decorator_service"] = StateDecoratorService.create(me.injector);
				}
				return this["$tsng_common_state.decorator_service"].getState();
			},
			set: function () {
				const me: StateComponent = this;
				if (this["$tsng_common_state.decorator_service"] == null) {
					this["$tsng_common_state.decorator_service"] = StateDecoratorService.create(me.injector);
				}
				return this["$tsng_common_state.decorator_service"].setState(arguments[0]);
			},
			enumerable: false,
			configurable: false
		});
	};
}
