import { Inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { CountedAction, FilterAction, FilteredAction, FilterResult } from "@tsng/store";
import { RENDERED_BY, RenderEnvironment } from "@twly/core";
import { combineLatest } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class TileSectionFilterHandler {
	address = "tile-section-front/filter";
	private logger = LoggerLocator.getLogger("TileSectionFilterHandler")();
	private cachedFilters = new Map();

	constructor(private eventBus: EventBus, private store: Store<unknown>, @Inject(RENDERED_BY) private renderedBy: RenderEnvironment) {
		this.listenToMessages();
		this.listenToCacheClearMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilterAction>(this.address)
			.subscribe(message => {
				this.handleLocalFilter(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private listenToCacheClearMessages() {
		this.eventBus.localConsumer("tile-front/cache-clear").subscribe(message => {
			this.cachedFilters.clear();
		});
	}

	private handleLocalFilter(message: Message<FilterAction>) {
		const data: any = message.body.data;

		const entityPrefix = message.body.type.split("/")[0];
		data.filter = data.filter.build();
		const countAction = new Action(`${entityPrefix}/count`, data);
		const filterAction = new Action(`${entityPrefix}/filter`, data);

		let filterHash;
		if (this.renderedBy === RenderEnvironment.BROWSER )  {
			filterHash = btoa(data.filter);
			if (this.cachedFilters.has(filterHash)) {
				message.reply<FilterResult>(new Action(
					"entity/filtered",
					this.cachedFilters.get(filterHash)
				));
				return;
			}
		}

		combineLatest([
			this.eventBus.request<FilterAction, CountedAction>(countAction.type, countAction),
			this.eventBus.request<FilterAction, FilteredAction>(filterAction.type, filterAction)
		]).pipe(tap(([countResult, filterResult]) => {
			this.eventBus.send<FilteredAction>("store/sync", filterResult.body);
		}))
			.subscribe(([countResult, filterResult]) => {
				const ids = filterResult.body.data.items.map(item => item.id) as string[] | number[];
				const totalCount = countResult.body.data.count;
				if(this.renderedBy === RenderEnvironment.BROWSER) {
					this.cachedFilters.set(filterHash, {ids, totalCount});
				}
				message.reply<FilterResult>(new Action("entity/filtered", {
					ids,
					totalCount
				}));
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
