import { Injectable } from "@angular/core";
import { createSelector, select, State, Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { EntityRevisionState, FilteredAction, GetAction, ReceivedAction } from "@tsng/store";
import { NEVER } from "rxjs";
import { catchError, filter, first, map, switchMap, tap } from "rxjs/operators";

export type fileSyncAction = Action<{
	items: EntityRevisionState[], [entityName: string]: EntityRevisionState[]
}>

@Injectable({
	providedIn: "root"
})
export class FileSyncHandler {
	address = "file-store/sync";
	private logger = LoggerLocator.getLogger("FileSyncHandler")();

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<fileSyncAction>(this.address)
			.pipe(map((message: Message<any>) => ({
				entity: "file",
				ids: message.body.data.items,
				filter: message.body.data.filter
			})))
			.subscribe(idsToSync => {
				this.syncIds(idsToSync);
			}, error => {
				this.logger.error(error);
			});
	}

	private syncIds(ids: { entity: string, ids: { id: number, rev: number, fileSeq: number }[], filter: any}) {
		this.store.pipe(select(this.createToRetrieveSelector(ids.entity)(ids.ids)),
			first(),
			filter(idsToRetrieve => idsToRetrieve.length > 0),
			map(idsToRetrieve => this.createGetAction(ids.entity, idsToRetrieve, ids.filter)),
			switchMap(getAction => this.eventBus.request<unknown, ReceivedAction>(getAction.type, getAction)),
			catchError(error => {
				this.logger.error(error);
				return NEVER;
			})
		).subscribe(receivedMessage => {
			this.store.dispatch(receivedMessage.body);
		});
	}

	// noinspection JSMethodCanBeStatic
	private createGetAction(entity: string, ids: number[], filter): GetAction {
		return {
			type: entity + "/get",
			data: Object.assign({}, {
				ids: ids
			}, {
				entity: filter.entity,
				group: filter.group
			}, filter?.refId != null ? {refId: filter.refId}: {})
		} as any;
	}

	private parseFilterAction(action: FilteredAction): { entity: string, ids: EntityRevisionState[] }[] {
		return Object.keys(action["data"]).reduce((acc, key) => {
			acc.push({
				entity: key === "items" ? this.getEntityFromAction(action) : key,
				ids: action["data"][key]
			});
			return acc;
		}, []);
	}

	// noinspection JSMethodCanBeStatic
	private getEntityFromAction(action: Action): string {
		return action.type.split("/")[0];
	}

	private createToRetrieveSelector(entityName: string) {
		return (props: { id: number, rev: number, fileSeq: number }[]) => createSelector(this.getStoreSelector(entityName),
			(state) => {
				if (state == null) {
					this.logger.fatal(`No state found for entity: ${entityName}. this could mean that the ${entityName}CoreModule is missing`);
				}
				return props
					.filter(idObj => (state.has(idObj.id) === false || (state.has(idObj.id) && (state.get(
						idObj.id).rev < idObj.rev || state.get(idObj.id).fileSeq < idObj.fileSeq))))
					.map(idObj => idObj.id);
			});
	}

	private getStoreSelector(entityName: string) {
		return (state: State<unknown>) => state[entityName];
	}
}
