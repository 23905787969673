import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ClickAnimationComponent } from "./component";

@NgModule({
	declarations: [
		ClickAnimationComponent
	],

	exports: [
		ClickAnimationComponent
	],

	imports: [
		CommonModule
	]
})
export class ClickAnimationModule {
}