import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { LocationInputComponent } from "./component";

@NgModule({
	declarations: [LocationInputComponent],
	imports: [CommonModule, MatAutocompleteModule, ReactiveFormsModule, MatIconModule],
	exports: [LocationInputComponent]
})
export class LocationInputModule {}