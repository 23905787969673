import { Component, Inject, Input, ViewChild } from "@angular/core";
import { FILE_UPLOAD_ENDPOINT, TsNgUploadComponent } from "@tsng/file";
import { Observable } from "rxjs";

@Component({
	selector: "profile-picture-upload",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class ProfilePictureUpload {
	@Input("entity") entity: string = "";
	@Input("group") group: string = "pp";
	@Input("maxFiles") maxFiles: number = 1;
	@Input("referenceId") referenceId: number | string;
	@Input("autoUpload") autoUpload: boolean = true;
	@Input("acceptedExtensions") acceptedExtensions: string[] = [".jpg", ".png", ".jpeg"];
	@ViewChild(TsNgUploadComponent) upload;

	constructor(@Inject(FILE_UPLOAD_ENDPOINT) public fileEndpoint) {
	}

	calcGrayScaleValue(progress: number) {
		return Math.abs(progress - 100) / 100;
	}

	startUpload() {
		this.upload.startUpload();
	}

	uploadFinished(): Observable<boolean> {
		return this.upload.uploadFinished();
	}
}
