import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialSiteStore, Site, SiteConversion } from "./model";

export const SITE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("SiteReducer");

export function siteReducerProvider() {
	return reducerFactory("site", initialSiteStore, Site, SiteConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("site", SITE_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["site/deleted", "site/updated", "site/created"])
	],
	providers: [
		{
			provide: SITE_REDUCER_TOKEN,
			useFactory: siteReducerProvider
		}
	]
})
export class SiteCoreModule {

}
