import { Hint, HintLevel, TsFormGroup } from "@tsng/form";

export function passwordsMustBeEqual(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "passwordsMustBeEqual",
		message: $localize`:Validator|The new passwords should be equal validator@@validatorPasswordsMustBeEqual:The passwords should be equal.`,
		trigger: control => {
			const parentGroup = control.parent as TsFormGroup;
			if (parentGroup == null) {
				return false;
			}

			const newPasswordField = parentGroup.getControl("password");
			const newPasswordConfirmationField = parentGroup.getControl("passwordConfirmation");
			return (newPasswordField.value != null && newPasswordConfirmationField.value != null && newPasswordField.value !== newPasswordConfirmationField.value);
		}
	};
}
