import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { createSelector, select, Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { TwentelyAccount, ScrollPositionStore } from "@twly/core";
import { Map } from "immutable";
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction, ReplaySubject } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { routeAnimation } from "../../../animations/route.animation";
import { UserGroupAddPage } from "../../../route/user-group/add/component";
import { UserGroupInterestsPage } from "../../../route/user-group/interests/component";
import { UserGroupLocationPage } from "../../../route/user-group/location/component";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-basic-layout",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	providers: [],
	animations: [
		routeAnimation, trigger("slideInOutDown", [
			transition(":enter", [
				style({transform: "translateY(100%)"}),
				animate("300ms ease-in", style({transform: "translateY(0%)"}))
			]), transition(":leave", [
				animate("300ms ease-in", style({transform: "translateY(100%)"}))
			])
		]), trigger("slideInOutUp", [
			transition(":enter", [
				style({transform: "translateY(-100%)"}),
				animate("300ms ease-in", style({transform: "translateY(0%)"}))
			]), transition(":leave", [
				animate("300ms ease-in", style({transform: "translateY(-100%)"}))
			])
		])
	]
})
export class BasicLayoutComponent implements OnDestroy {
	pagesWithoutNavbar: string[] = [
		"LoginPage",
		"RegisterPage",
		"TokenPage",
		"UserGroupAddPage",
		"UserGroupLocationPage",
		"UserGroupInterestsPage",
		"ChatDetailPage",
		"TileSectionDetailPage"
	];
	currentPageObservable: Observable<string>;
	showSplashScreenSubject = new BehaviorSubject("CLOSED");
	currentAccount: TwentelyAccount;
	scrollTop: number;
	private logger: Logger = LoggerLocator.getLogger("BasicLayoutComponent")();
	private currentPageSubject = new BehaviorSubject("");
	private readonly accountSelector: () => OperatorFunction<any, any>;
	private canShowGroup = new ReplaySubject(1);
	private destroyed = new ReplaySubject<boolean>(1);
	@ViewChild("main") mainElement: ElementRef;

	constructor(
		private selectorProvider: SelectorProvider,
		private store: Store,
		private eventBus: EventBus
	) {
		this.currentPageObservable = this.currentPageSubject.asObservable().pipe(distinctUntilChanged());
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
		this.listenToAccount();
		this.listenToScrollSaveMessage();
		this.listenToScrollHydrateMessage();
		this.listenToResetScrollMessage();
	}

	prepareRoute(outlet: RouterOutlet) {
		this.currentPageSubject.next(outlet.activatedRouteData["label"]);
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["label"];
	}

	ngOnDestroy() {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	showSplashScreen(account: TwentelyAccount) {
		if (this.currentPageSubject.value === "TokenPage") return;

		const splashScreenSeen = this.getSplashScreenSeen();
		if (account.hasPermission("login/view") && (splashScreenSeen == null || new Date(splashScreenSeen).getTime() < (new Date().getTime() - (24 * 60 * 60 * 1000)))) {
			this.showSplashScreenSubject.next("DEFAULT-LOGIN");
			this.setSplashScreenSeen();
		}
	}

	setSplashScreenSeen() {
		localStorage.setItem("splashScreenSeen", new Date().toISOString());
	}

	getSplashScreenSeen() {
		return localStorage.getItem("splashScreenSeen");
	}

	closeSplashScreen() {
		this.showSplashScreenSubject.next("CLOSED");
	}

	private listenToAccount() {
		of({}).pipe(this.accountSelector(),
			map((accountMap: Map<number, { account: TwentelyAccount }>) => accountMap.first().account),
			tap(account => {
				this.currentAccount = account;
				// Disable login splashscreen for now
				// this.showSplashScreen(account);
			}),
			filter((account: TwentelyAccount) => account.hasPermission("userGroup/view")),
			takeUntil(this.destroyed)
		)
			.subscribe(account => {
				if (account.selectedUserGroup > 0) {
					this.canShowGroup.next(true);
				}
			});
	}

	clearTilesCache() {
		this.eventBus.publish("tile-front/cache-clear", {});
		this.eventBus.send("scrollPosition/clear", new Action("scrollPosition/clear", {}));
	}

	listenToResetScrollMessage() {
		this.eventBus.localConsumer("layout/reset-scroll-position").pipe(takeUntil(this.destroyed))
			.subscribe(message => {
				if (this.mainElement != null) {
					this.mainElement.nativeElement.scrollTo(0, 0);
				}
			});
	}

	listenToScrollSaveMessage() {
		this.eventBus.localConsumer("layout/save-scroll-position").pipe(takeUntil(this.destroyed))
			.subscribe(message => {
				this.eventBus.send("scrollPosition/set", new Action("scrollPosition/set", {
					id: message.body["data"]["id"],
					position: this.scrollTop
				}));
			});
	}

	listenToScrollHydrateMessage() {
		this.eventBus.localConsumer("layout/hydrate-scroll-position").pipe(switchMap(message => {
			return this.store.pipe(
				select(createSelector((state: {scrollPosition: ScrollPositionStore}) => state.scrollPosition, state => state)),
				take(1),
				map(state => [message, state])
			);
		})).subscribe(([message, scrollStore]: [Message<unknown>, ScrollPositionStore]) => {
			if (scrollStore.has(message.body["data"]["id"]) === false) {
				return;
			}
			this.mainElement.nativeElement.scrollTo(0, scrollStore.get(message.body["data"]["id"]));
		});
	}

	setScroll(event) {
		this.scrollTop = event.target.scrollTop;
	}

	resetPageState(selector: string) {
		const action = new Action("state/clear", selector as any, -1, {});
		this.eventBus.send(action.type, action);
	}
}
