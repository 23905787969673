<form (ngSubmit)="changePassword()" [formGroup]="form" tsNgRootFormGroup>
	<tsng-input
		formControlName="password"
		i18n="Label|The label for the current password input@@TsNgChangePasswordFormCurrentPasswordInputLabel"
		i18n-placeholder="Placeholder|The placeholder for the current password input@@TsNgChangePasswordFormCurrentPasswordInputPlaceholder"
		placeholder="Please provide your current password"
		type="password"
	>
		Current password
	</tsng-input>

	<tsng-input
		formControlName="newPassword"
		i18n="Label|The label for the new password input@@TsNgChangePasswordFormNewPasswordInputLabel"
		i18n-placeholder="Placeholder|The placeholder of the new password input@@TsNgChangePasswordFormNewPasswordInputPlaceholder"
		placeholder="Please specify a new password"
		type="password"
	>
		New password
	</tsng-input>

	<tsng-input
		formControlName="newPasswordConfirmation"
		i18n="Label|The label for the confirm new password input@@TsNgChangePasswordFormConfirmNewPasswordInputLabel"
		i18n-placeholder="Placeholder|The placeholder for the confirm new password input@@TsNgChangePasswordFormConfirmNewPasswordInputPlaceholder"
		placeholder="Please confirm your new password"
		type="password"
	>
		Confirm the password
	</tsng-input>

	<button i18n="Button|The change password button@@TsNgChangePasswordFormChangeButton" type="submit">
		Change
	</button>
</form>
