import { InjectionToken } from "@angular/core";

export const MESSAGE_CODEC = new InjectionToken<MessageCodec<unknown, unknown>>("MessageCodec");

export const MESSAGE_CODEC_PROVIDER = {
	provide: MESSAGE_CODEC,
	useValue: null
};

export interface MessageCodec<S, R> {
	transform(messageBody?: S): R;
}
