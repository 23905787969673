import { Logger } from "./interface";

export class LoggerLocator {
	private static loggers: { [className: string]: Logger } = {};
	private static logger: Logger = null;

	static getLogger<T extends Logger>(forClass?: string): () => T {
		const getter = () => {
			if (this.logger == null) {
				throw new Error(
					"LoggerLocator::logger has not been defined, please provide it before calling getLogger()() using LoggerLocator::provide(...)");
			}

			if (forClass == null) {
				return this.logger;
			}

			if (this.loggers[forClass] == null) {
				this.loggers[forClass] = this.logger.clone().setDefaultContext({
					className: forClass,
					hashCode: this.generateHashCode()
				});
			}

			return this.loggers[forClass] as T;
		};

		return getter as () => T;
	}

	static provide(logger: Logger): void {
		this.logger = logger;
	}

	private static generateHashCode(): string {
		const stringArr = [];
		for (let i = 0; i < 8; i++) {
			// tslint:disable-next-line:no-bitwise
			const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
			stringArr.push(S4);
		}
		return stringArr.join("-");
	}
}
