import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { Location } from "../site/model";

export type CityStore = ModelMap<City>;

export const initialCityStore: CityStore = <CityStore>Map();

export const CityConversion: ActionConverter.Config = {};

export interface CityModel extends StoreModel {
	name: string;
	province: string;
	location: Location;
}

export class City extends Record({
	id: 0,
	rev: 0,
	name: "",
	province: "",
	location: {
		latitude: 0.0,
		longitude: 0.0
	}

}) implements CityModel {
	id: number;
	rev: number;

	name: string;
	province: string;
	location: Location;

	get displayName(): string {
		return this.name;
	};
}
