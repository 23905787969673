import { NgModule } from "@angular/core";
import { HTTP_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER } from "./event-bus-bridge/http/http-event-bus-bridge";
import { SOCKET_EVENT_BUS_BRIDGE_OPTIONS_PROVIDERS } from "./event-bus-bridge/socket/socket-event-bus-bridge";
import { ACTION_MESSAGE_CODEC_PROVIDER } from "./event-bus/message-codec/action-codec";
import { SOCKET_OPTIONS_PROVIDER } from "./socket/options";

@NgModule({
	providers: [
		SOCKET_OPTIONS_PROVIDER,
		HTTP_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER,
		SOCKET_EVENT_BUS_BRIDGE_OPTIONS_PROVIDERS,
		ACTION_MESSAGE_CODEC_PROVIDER
	]
})
export class TsNgCoreModule {
}
