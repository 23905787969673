import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { AuthRole, AuthRoleConversion, initialAuthRoleStore } from "./model";

export const AUTHROLE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("AuthRoleReducer");

export function authRoleReducerProvider() {
	return reducerFactory("authRole", initialAuthRoleStore, AuthRole, AuthRoleConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("authRole", AUTHROLE_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["authRole/deleted", "authRole/updated", "authRole/created"])
	],
	providers: [
		{
			provide: AUTHROLE_REDUCER_TOKEN,
			useFactory: authRoleReducerProvider
		}
	]
})
export class AuthRoleCoreModule {

}
