import { Directive, Injector, Input, TemplateRef } from "@angular/core";
import { TsNgAbstractPermissionDirective } from "../abstract-permission/directive";

@Directive({
	selector: "[ifPermission]"
})
export class TsNgIfPermissionDirective extends TsNgAbstractPermissionDirective {
	mustAllMatch = true;

	constructor(injector: Injector, templateRef: TemplateRef<any>) {
		super(injector);
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifPermission(permission: string) {
		this.setPermissions([permission]);
	}

	@Input() set ifPermissionThen(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifPermissionElse(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionElse(templateRef);
	}
}