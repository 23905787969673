import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
	AbstractEventBusBridge,
	EventBus,
	HTTP_EVENT_BUS_BRIDGE_OPTIONS, HttpEventBusBridgeOptions,
	Message
} from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { first } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class TwlyHttpEventBusBridge extends AbstractEventBusBridge {
	protected logger: Logger = LoggerLocator.getLogger("SocketEventBusBridge")();
	private endpoint: string;

	constructor(
		protected eventBus: EventBus,
		private httpClient: HttpClient,
		@Inject(HTTP_EVENT_BUS_BRIDGE_OPTIONS) options: HttpEventBusBridgeOptions
	) {
		super(eventBus, options);
		if (options.hasOwnProperty("httpEndpoint") === false) {
			this.logger.fatal(
				"No httpEndpoint specified, please add it to the 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
			throw new Error("No httpEndpoint specified, please add it to the 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
		}
		this.endpoint = options.httpEndpoint;
	}

	sendMessage(message: Message<unknown>) {
		const headers = message.headers || {};
		headers["X-type"] = message.type;
		headers["X-replyAddress"] = message.getReplyAddress() || "";

		this.httpClient.post<{}>(this.endpoint + message.address, message.body, {
			headers,
			withCredentials: true
		}).pipe(first()).subscribe(result => {
			message.reply(result);
		}, error => {
			message.fail(error.status, error.statusText);
		});
	}
}
