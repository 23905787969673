import { Inject, Injectable, InjectionToken, NgZone } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { LoggerLocator } from "@tsng/logging";
import { timer } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { filter, switchMap, take, takeUntil } from "rxjs/operators";

export const CHECK_FOR_UPDATE_INTERVAL = new InjectionToken<number>("CheckForUpdateInterval");
export const PRODUCTION_MODE = new InjectionToken<boolean>("ProductionMode");

@Injectable({providedIn: "root"})
export class CheckForUpdateService {
	private logger = LoggerLocator.getLogger("CheckForUpdateService")();
	private updateInterval: number;

	constructor(@Inject(CHECK_FOR_UPDATE_INTERVAL) updateInterval,
		private ngZone: NgZone,
		private swUpdate: SwUpdate
	) {
		if (this.swUpdate.isEnabled === false) {
			return;
		}
		this.setUpdateInterval(updateInterval);
		this.listenToVersionUpdates();
		this.forceUpdateIfAvailable();
		this.showPromptOnUpdateAvailable();
	}

	setUpdateInterval(updateInterval: number) {
		if (updateInterval == null) {
			this.logger.warning("Update interval incorrectly configured, defaulting to 30 minutes");
			updateInterval = 1800000;
		}
		this.updateInterval = updateInterval;

	}

	forceUpdateIfAvailable() {
		this.ngZone.runOutsideAngular(() => {
			this.swUpdate.versionUpdates.pipe(filter(event => event.type === "VERSION_READY"),
				takeUntil(timer(40000)),
				take(1)
			).subscribe(() => {
				this.logger.debug("New version available, forcing update");
				this.reloadApplication();
			});
		});
	}

	listenToVersionUpdates(): void {
		this.ngZone.runOutsideAngular(() => {
			this.swUpdate.versionUpdates.subscribe(event => {
				switch (event.type) {
					case "VERSION_DETECTED":
						this.logger.debug(`New app version detected: ${event.version.hash}`);
						break;
					case "VERSION_READY":
						this.logger.debug(`Current app version: ${event.currentVersion.hash}`);
						this.logger.info(`New app version ready for use 🚀: ${event.latestVersion.hash}`);
						break;
					case "VERSION_INSTALLATION_FAILED":
						this.logger.fatal(`Failed to install app version '${event.version.hash} 😭`,
							{error: event.error}
						);
						break;
				}
			});
		});
	}

	promptUser(message: string): boolean {
		return window.confirm(message);
	}

	reloadApplication() {
		window.location.reload();
	}

	showPromptOnUpdateAvailable() {
		this.ngZone.runOutsideAngular(() => {
			timer(40001, this.updateInterval)
				.pipe(switchMap(() => fromPromise(this.swUpdate.checkForUpdate())),
					filter(hasUpdate => hasUpdate === true)
				)
				.subscribe(() => {
					this.logger.debug("New version available, prompting user to update");
					if (this.promptUser($localize`:Update prompt|The prompt message the users sees when there is a new version available@@CheckForUpdateServiceUpdatePrompt:There is a new version of this application available. Please update.`)) {
						this.reloadApplication();
					}
				});
		});
	}
}
