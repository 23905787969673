import { ChangeDetectorRef, ElementRef, Injectable, Injector, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action, EventBus } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { ReplaySubject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

@Injectable()
export class StateDecoratorService implements OnDestroy {
	private readonly key: string;
	private state: any = null;
	private destroyed = new ReplaySubject(1);
	private readonly logger: Logger = LoggerLocator.getLogger("StateDecoratorService")();

	private constructor(
		parentElement: ElementRef,
		private store: Store<any>,
		private eventBus: EventBus,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.key = this.calculateElementPath(parentElement.nativeElement);
		this.store.select("$tsng_common_state").pipe(map(map => {
			if (this.key == null) return null;
			return map.get(this.key);
		}), distinctUntilChanged(), takeUntil(this.destroyed))
			.subscribe(value => {
				this.state = value;
				this.changeDetectorRef.markForCheck();
				this.changeDetectorRef.detectChanges();
			});

		this.logger.debug(`#constructor - Has been created for: ${this.key}`);
	}

	static create(parentInjector: Injector): StateDecoratorService {
		const elementRef = parentInjector.get(ElementRef);
		const store = parentInjector.get<Store<any>>(Store);
		const eventBus = parentInjector.get<EventBus>(EventBus);
		const changeDetectorRef = parentInjector.get(ChangeDetectorRef, null);
		return new StateDecoratorService(elementRef, store, eventBus, changeDetectorRef);
	}

	getState(): any {
		return this.state;
	}

	setState(data: any): void {
		const action = new Action("state/set", this.key, -1, data);
		this.eventBus.send(action.type, action);
	}

	ngOnDestroy(): void {
		this.logger.debug("#ngOnDestroy - Has been destroyed");
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	// noinspection JSMethodCanBeStatic
	private calculateElementPath(element: Element) {
		var stack = [];
		while (element.parentNode != null) {
			var sibCount = 0;
			var sibIndex = 0;
			for (let i = 0; i < element.parentNode.childNodes.length; i++) {
				var sib = element.parentNode.childNodes[i];
				if (sib.nodeName == element.nodeName) {
					if (sib === element) {
						sibIndex = sibCount;
					}
					sibCount++;
				}
			}
			if (element.hasAttribute("id") && element.id != "") {
				stack.unshift(element.nodeName.toLowerCase() + "#" + element.id);
			} else if (sibCount > 1) {
				stack.unshift(element.nodeName + ":nth-of-type(" + (sibIndex + 1) + ")");
			} else {
				stack.unshift(element.nodeName);
			}
			element = element.parentNode as Element;
		}
		stack.splice(0, 1); // removes the html element
		return stack.join(" > ");
	}
}

