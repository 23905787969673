import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Schema } from "../schema/schema";
import { SelectorBuilder } from "./selector";

@Injectable({
	providedIn: "root"
})
export class SelectorProvider {
	constructor(private schema: Schema, private store: Store<any>) {
	}

	getBuilder() {
		return new SelectorBuilder(this.schema, this.store);
	}
}
