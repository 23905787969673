import { TemplateRef } from "@angular/core";
import { Account } from "../../store/model";

export class TsNgIfPermissionContext {
	constructor(private permissions: string[],
		private mustAllMatch: boolean,
		private account?: Account,
		private thenTemplateRef?: TemplateRef<any>,
		private elseTemplateRef?: TemplateRef<any>,
	) {
	}

	hasPermission(): boolean {
		if (this.mustAllMatch === true) {
			return (this.account && this.account.hasAllPermissions(this.permissions));
		} else {
			return (this.account && this.account.hasAnyPermissions(this.permissions));
		}
	}

	getThenTemplate(): TemplateRef<any> {
		return this.thenTemplateRef;
	}

	hasThenTemplate(): boolean {
		return (this.thenTemplateRef != null);
	}

	getElseTemplate(): TemplateRef<any> | null {
		return this.elseTemplateRef;
	}

	hasElseTemplate(): boolean {
		return (this.elseTemplateRef != null);
	}
}