import { Injectable } from "@angular/core";
import {
	ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from "@angular/router";
import { LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { Map } from "immutable";
import { Observable, of, throwError } from "rxjs";
import { catchError, distinctUntilChanged, filter, first, map, switchMap, tap } from "rxjs/operators";
import { Account, loggedIn } from "../../store/model";

/**
 * Service that can be used to verify if an user is not logged in and can access the login page.
 *    For permissions the following data is needed in the route configuration:
 *    {
		path: "login",
		component: loginPageComponent,
		canActivate: [LoginGuard],
	}
 */
@Injectable({
	providedIn: "root"
})
export class LoginGuard implements CanActivate {
	private logger = LoggerLocator.getLogger("LoginGuard")();
	private accountSelector;

	constructor(
		private selectorProvider: SelectorProvider,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
		this.navigateOnLogin();
	}

	navigateOnLogin(): void {
		of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => accountMap.first().account),
			map(account => account.isLoggedIn),
			distinctUntilChanged(),
			filter(loggedIn => loggedIn === true),
			switchMap(loggedIn => this.activatedRoute.queryParamMap.pipe(first()))
		)
			.subscribe(params => {
				const url = params.get("returnUrl") == null ? "" : params.get("returnUrl");
				this.logger.debug("User has logged in, redirecting him", {
					params: params,
					url: url
				});

				this.router.navigate([url])
					.catch(error => {
						this.logger.error("Unable to redirect the user", {
							error: error
						});
					});
			}, error => {
				this.logger.fatal("NavigateOnLogin observable crashed", {
					error: error
				});
			});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => accountMap.first().account),
			filter(account => account.loggedIn !== loggedIn.DEFAULT),
			first(),
			map(account => account.loggedIn !== loggedIn.TRUE),
			tap(canActivatePage => {
				if (canActivatePage === true) return;

				this.router.navigate([""]).catch(error => {
					this.logger.error("Unable to redirect the user", {
						error: error
					});
				});
			}),
			catchError(error => {
				this.logger.fatal("CanActivate observable crashed", {
					error: error
				});

				return throwError(error);
			})
		);
	}
}