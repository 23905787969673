import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type ImageSetStore = ModelMap<ImageSet>;

export const initialImageSetStore: ImageSetStore = <ImageSetStore>Map();

export const ImageSetConversion: ActionConverter.Config = {};

export enum ImageSetStatus {
	DRAFT = "draft", PENDING_REVIEW = "review", REJECTED = "rejected", PUBLISHED = "published", DEACTIVATED = "deactivated"
}

export interface ImageSetModel extends StoreModel {
	name: string;
	keywordId: number;
	siteActivityId: number;
	venueId: string;
	activityId: string;
	category: string;
	status: ImageSetStatus;
}

export class ImageSet extends Record({
	id: 0,
	rev: 0,
	name: "",
	category: "",
	keywordId: 0,
	siteActivityId: 0,
	siteId: "",
	activityId: "",
	venueId: "",
	status: ImageSetStatus.DRAFT
}) implements ImageSetModel {
	id: number;
	rev: number;
	name: string;
	category: string;
	keywordId: number;
	siteActivityId: number;
	venueId: string;
	activityId: string;
	status: ImageSetStatus;

	get displayName(): string {
		return this.name;
	};

	get refId(): number | string {
		switch (this.entity) {
			case "keyword":
				return this.keywordId;
			case "siteActivity":
				return this.siteActivityId;
			case "venue":
				return this.venueId;
			case "activity":
				return this.siteActivityId;
		}
	}

	get entity(): string {
		if (this.siteActivityId != null && this.siteActivityId != 0) {
			return "siteActivity";
		} else if (this.keywordId != null && this.keywordId != 0) {
			return "keyword";
		}  else if (this.venueId != null && this.venueId != "") {
			return "venue";
		} else if (this.activityId != null && this.activityId != "") {
			return "activity";
		} else {
			throw Error("Unknown entity for imageSet " + this);
		}
	}

	get imageSetUri(): string {
		return this.getIdUri();
	}

	private getIdUri(): string {
		// add leading zeros to the id and after every 3 characters add a '/'.
		return this.entity + "/" + this.refId.toString().padStart(9, "0").replace(/(.{3})/g, "$1/");
	}
}
