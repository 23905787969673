import {
	ChangeDetectionStrategy,
	Component,
	ContentChild,
	ElementRef,
	HostBinding,
	Injector,
	Input,
	OnDestroy,
	ViewChild
} from "@angular/core";
import { LoggerLocator } from "@tsng/logging";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { TsNgUploadLabelDirective } from "../../directive/label/directive";
import { TsNgUploadPlaceholderDirective } from "../../directive/placeholder/directive";
import { TsNgQueuedFileDirective } from "../../directive/queued-file/directive";
import { TsNgServerFileDirective } from "../../directive/server-file/directive";
import { ServerFile } from "../../server-file/server-file";
import { DefaultFileSource } from "../../source/default/source";
import { FileSource } from "../../source/source";

@Component({
	selector: "tsng-upload-component",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	host: {
		"(dragover)": "dragOver($event)",
		"(dragenter)": "dragEnter($event)",
		"(dragleave)": "dragLeave($event)",
		"(drop)": "onDrop($event)",
		"(click)": "openNativeFileMenu()"
	}
})
export class TsNgUploadComponent implements OnDestroy {
	@Input() disabled = false;
	@ContentChild(TsNgUploadLabelDirective,
		{static: false}
	) public fileLabelDirective: TsNgUploadLabelDirective;
	@ContentChild(TsNgServerFileDirective,
		{static: false}
	) public serverFileDirective: TsNgServerFileDirective;
	@ContentChild(TsNgQueuedFileDirective,
		{static: false}
	) public queuedFileDirective: TsNgQueuedFileDirective;
	@ContentChild(TsNgUploadPlaceholderDirective,
		{static: false}
	) public placeholderDirective: TsNgUploadPlaceholderDirective;
	public source: FileSource;
	@ViewChild("fileInput", {static: false}) private fileInput: ElementRef;
	private logger = LoggerLocator.getLogger("TsNgUploadComponent")();
	private dragFocus = false;

	constructor(private injector: Injector) {
		this.source = new DefaultFileSource(injector);
	}

	@Input("entity") set entity(entity: string) {
		this.source.setEntity(entity);
	}

	@Input("group") set group(group: string) {
		this.source.setGroup(group);
	}

	@Input("maxFiles") set MaxFiles(limit: number) {
		this.source.setMaxFiles(limit);
	}

	@Input("referenceId") set referenceId(referenceId: number | string) {
		this.source.setReferenceId(referenceId);
	}

	@Input("fileSequence") set fileSequence(fileSequence: number | null) {
		this.source.setFileSequence(fileSequence);
	}

	@Input("autoUpload") set autoUpload(autoUpload: boolean) {
		this.source.setAutoUpload(autoUpload);
	}

	@Input("removeWhenFailed") set removeWhenFailed(remove: boolean) {
		this.source.setRemoveWhenFailed(remove);
	}

	@Input("acceptedExtensions") set acceptedExtensions(acceptedExtensions: string[]) {
		this.source.setAcceptedExtensions(acceptedExtensions);
	}

	@HostBinding("class.drag-focus") get hasDragFocus() {
		return (this.dragFocus && this.disabled === false);
	}

	@HostBinding("class.disabled") get isDisabled() {
		return (this.disabled === true);
	}

	openNativeFileMenu(): void {
		if (this.disabled === true) return;
		this.fileInput.nativeElement.click();
	}

	//
	// /**
	//  * Function to add files using the {@link FileResource#uploadFiles}
	//  * It checks if the user did not drop too many files and if the component is not disabled.
	//  */
	addFiles(files: FileList): void {
		// un-select everything after dropping or clicking some weird browser quirk
		window.getSelection().removeAllRanges();

		if (this.disabled) {
			this.logger.debug("Tried to upload files on a disabled upload-component", {
				disabled: this.disabled
			});
		}

		this.source.addToQueue(files);
	}

	/**
	 * Function to handle a file drop event. It prevents the event to propagate and uses the {@link addFiles} to upload the files.
	 * @param {DragEvent} event
	 */
	onDrop(event: DragEvent): void {
		event.preventDefault();
		this.dragFocus = false;
		if (event.dataTransfer == null || event.dataTransfer.files == null) return;
		this.addFiles(event.dataTransfer.files);
	}

	/**
	 * Function that handles the dragenter event.
	 * @param {DragEvent} event
	 */
	dragEnter(event: DragEvent): void {
		this.dragFocus = true;
	}

	/**
	 * Function that handles the dragleave event.
	 * @param {DragEvent} event
	 */
	dragLeave(event: DragEvent): void {
		this.dragFocus = false;
	}

	/**
	 * Function that handles the dragover event.
	 * @param {DragEvent} event
	 */
	dragOver(event: DragEvent): void {
		event.preventDefault();
	}

	startUpload() {
		this.source.startUpload();
	}

	uploadFinished(): Observable<boolean> {
		return this.source.queueChanged().pipe(filter(value => value.length === 0), map(value => true));
	}

	fileUploaded(): Observable<ServerFile> {
		return this.source.getFileUploadedObservable();
	}

	ngOnDestroy(): void {
		this.source.destroy();
	}
}
