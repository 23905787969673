import { Type } from "@angular/core";
import { Action } from "@tsng/core";
import { ModelMap, StoreModel } from "../model/model";
import { ActionConverter } from "./action-converter";
import { ReducerHandlers } from "./reducer-handler";

export function reducerFactory<T extends StoreModel>(entity: string,
	initialValue: ModelMap<T>,
	model: Type<StoreModel>,
	conversion: ActionConverter.Config
) {
	return (state: ModelMap<T> = initialValue, action: Action) => {
		switch (action.type) {
			case entity + "/received":
				return ReducerHandlers.handleReceivedAction(state,
					ActionConverter.adapt(action, conversion) as any,
					new model()
				);

			case entity + "/create":
				return ReducerHandlers.handleCreateAction(state,
					ActionConverter.adapt(action, conversion) as any,
					new model()
				);

			case entity + "/created":
				return ReducerHandlers.handleCreatedAction(state,
					ActionConverter.adapt(action, conversion) as any,
					new model()
				);

			case entity + "/updated":
				return ReducerHandlers.handleUpdatedAction(state,
					ActionConverter.adapt(action, conversion) as any,
					new model()
				);

			case entity + "/deleted":
				return ReducerHandlers.handleDeletedAction(state,
					ActionConverter.adapt(action, conversion) as any
				);

			case "store/logout":
				// case "principal/received": todo find a proper solution upon switching accounts.
				return initialValue;

			default:
				return state;
		}
	};
}
