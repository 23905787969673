import { Injectable } from "@angular/core";
import { Action } from "@ngrx/store";
import { EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { tap } from "rxjs/operators";
import { CreateAction } from "../action/create";
import { CreatedAction } from "../action/created";

export const getClientId = (function () {
	let id = 0;
	return (): number => {
		return --id;
	};
})();

@Injectable({
	providedIn: "root"
})
export class CreateHandler {
	address = "entity/create";
	nextIdFunc = getClientId;
	private logger = LoggerLocator.getLogger("CreateHandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<CreateAction>(this.address)
			.subscribe(message => {
				this.handleLocalCreate(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalCreate(message: Message<CreateAction>) {
		const createAction = this.setClientId(message.body);
		this.eventBus.send("store/create", createAction);
		this.eventBus.request<CreateAction, CreatedAction>(message.body.type, createAction)
			.pipe(tap((result: any) => {
				this.eventBus.send<CreatedAction>(result.body.type, result.body);
			}))
			.subscribe(result => {
				message.reply(result.body);
			}, error => {
				this.eventBus.send(this.getEntityFromAction(createAction) + "/deleted", {
					type: this.getEntityFromAction(createAction) + "/deleted",
					id: createAction.id,
					data: {}
				});
				message.fail(error.failureCode, error.message);
			});
	}

	private setClientId(action: any) {
		const id = this.nextIdFunc();
		action.id = id;
		action.data.id = id;

		return action;
	}

	private getEntityFromAction(action: Action): string {
		return action.type.split("/")[0];
	}
}
