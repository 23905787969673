import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialUserStore, User, UserConversion } from "./model";

export const USER_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("UserReducer");

export function userReducerProvider() {
	return reducerFactory("user", initialUserStore, User, UserConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("user", USER_REDUCER_TOKEN), TsNgStoreModule.forFeature([
			"store/create",
			"user/deleted",
			"user/updated",
			"user/created",
			"state/set",
			"state/updated",
			"state/clear",
			"state/cleared"
		])
	],
	providers: [
		{
			provide: USER_REDUCER_TOKEN,
			useFactory: userReducerProvider
		}
	]
})
export class UserCoreModule {

}
