import { Hint, HintLevel, TsFormGroup } from "@tsng/form";

export function newPasswordsMustBeEqual(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "newPasswordsMustBeEqual",
		message: $localize`:Validator|The new passwords should be equal validator@@tsngAccountNewPasswordsMustBeEqual:The new passwords should be equal.`,
		trigger: control => {
			const parentGroup = control.parent as TsFormGroup;
			if (parentGroup == null) {
				return false;
			}

			const newPasswordField = parentGroup.getControl("newPassword");
			const newPasswordConfirmationField = parentGroup.getControl("newPasswordConfirmation");
			return (newPasswordField.value != null && newPasswordConfirmationField.value != null && newPasswordField.value !== newPasswordConfirmationField.value);
		}
	};
}

export function passwordCannotBeEqualToOldPassword(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "passwordCannotBeEqualToOldPassword",
		message: $localize`:Validator|The new passwords cannot be equal to the old password@@tsngAccountPasswordCannotBeEqualToOldPassword:The new password cannot be equal to the old password.`,
		trigger: control => {
			const parentGroup = control.parent as TsFormGroup;
			if (parentGroup == null) {
				return false;
			}

			const passwordField = parentGroup.getControl("password");
			const newPasswordField = parentGroup.getControl("newPassword");
			return (newPasswordField.value != null && passwordField.value != null && passwordField.value === newPasswordField.value);
		}
	};
}
