import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener
} from "@angular/core";
import { buttonAnimation } from "../../animations/button.animation";

@Component({
	selector: "[clickAnimation]",
	animations: [
		buttonAnimation
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-content></ng-content>`
})
export class ClickAnimationComponent {
	@HostBinding("@buttonState") trigger = "inactive";

	constructor(private changeDetectorRef: ChangeDetectorRef) {
	}

	@HostListener("click") onClick() {
		this.trigger = "active";
		setTimeout(() => {
			this.trigger = "inactive";
			this.changeDetectorRef.markForCheck();
		}, 50);
	}
}
