import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialUserGroupStore, UserGroup, UserGroupConversion } from "./model";

export const USER_GROUP_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("UserReducer");

export function userGroupReducerProvider() {
	return reducerFactory("userGroup", initialUserGroupStore, UserGroup, UserGroupConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("userGroup", USER_GROUP_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["userGroup/deleted", "userGroup/updated", "userGroup/created"])
	],
	providers: [
		{
			provide: USER_GROUP_REDUCER_TOKEN,
			useFactory: userGroupReducerProvider
		}
	]
})
export class UserGroupCoreModule {

}
