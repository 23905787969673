import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { AppModal } from "../modal/component";
import { LoginModalComponent } from "./component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<LoginModalComponent>>,
	componentRef: ComponentRef<LoginModalComponent>,
	eventBus: EventBus
) {
	componentRef.instance.closed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		eventBus.send("ui/login-modal/closed", new Action("ui/login-modal/closed", {}));
		dialogRef.close();
	});
}

export function translatedTitle() {
	return $localize`:The title of the login modal@@LoginModalComponent.title:Login`;
}

export function componentLoader() {
	return import("./component").then(m => m.LoginModalComponent);
}

export const loginModalRoute: ModalRoute<LoginModalComponent> = {
	path: "login",
	outlet: "modal",
	component: AppModal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		setupComponentHooks,
		additionalDialogConfig: {
		}
	}
};
