import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type TileStore = ModelMap<Tile>;

export const initialTileStore: TileStore = <TileStore>Map();
export const myListItemInitialTileStore: TileStore = <TileStore>Map();

export const TileConversion: ActionConverter.Config = {};

export interface TileModel extends StoreModel {
	tileSectionId: number;
	keywordId: number;
	imageId: number;
	activityId: string;
	userGroupId: number;
	name: string;
	city: string;
	slug: string;
	dateLine1: string;
	dateLine2: string;
}

export class Tile extends Record({
	id: 0,
	rev: 0,
	keywordId: 0,
	tileSectionId: 0,
	imageId: 0,
	activityId: 0,
	userGroupId: 0,
	name: '',
	slug: '',
	city: '',
	dateLine1: '',
	dateLine2: ''

}) implements TileModel {

	id: number;
	rev: number;
	keywordId: number;
	tileSectionId: number;
	imageId: number;
	activityId: string;
	userGroupId: number;
	name: string;
	city: string;
	slug: string;
	dateLine1: string;
	dateLine2: string;

	get displayName(): string {
		return this.id?.toString();
	};


	get tileSlug(): string {
		return encodeURIComponent(this.city.toLowerCase()) + `-${this.slug}-${this.activityId}`;
	}
}
