import { Injectable } from "@angular/core";
import { Action, EventBus, Message, Socket, SocketStates } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { combineLatest, fromEvent, merge, Observable, Subscription } from "rxjs";
import { filter, map, skipWhile, startWith, switchMap, tap } from "rxjs/operators";
import { loggedIn, Role } from "../store/model";

@Injectable({
	providedIn: "root"
})
export class AuthenticationHandler {
	private logger = LoggerLocator.getLogger("AuthenticationHandler")();
	private reconnectionRetries = 0;
	private userChannelSubscription: Subscription;
	private inFlightLoginRequest: Message<Action>;

	constructor(private eventBus: EventBus, private socket: Socket) {
		eventBus.localConsumer("user/authenticate").subscribe(this.requestRefreshToken.bind(this));
		eventBus.localConsumer("principal/received").subscribe(this.connectedToSocket.bind(this));
		eventBus.localConsumer("internal/logout").subscribe(this.doLogout.bind(this));

		combineLatest([
			socket.statusObservable.pipe(skipWhile(status => status !== SocketStates.OPEN)),
			this.listenToPageVisibilityChanges()
		])
			.pipe(filter(([status, visibilityChange]) => (status === SocketStates.CLOSED) && (visibilityChange.documentIsHidden === false)))
			.subscribe(this.reconnectToSocket.bind(this));

		// Right after initialization we try to open a websocket connection.
		// But as this is generally going to fail we instantly reset the reconnection attempts as well
		// (only in this instance though).
		this.reconnectToSocket();
		this.reconnectionRetries = 0;
	}

	private listenToPageVisibilityChanges(): Observable<{ documentIsHidden: boolean }> {
		// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example
		let hidden: string;
		let visibilityChange: string;
		const proxyDocument: any = document;

		// Opera 12.10 and Firefox 18 and later support
		if (typeof proxyDocument?.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof proxyDocument?.msHidden !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof proxyDocument?.webkitHidden !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}

		return fromEvent(proxyDocument, visibilityChange).pipe(startWith({documentIsHidden: false}),
			map(() => ({documentIsHidden: document[hidden] == true}))
		);
	}

	private connectedToSocket(message: Message<Action>) {
		this.reconnectionRetries = 0;
		this.eventBus.send("store/principal-changed", message.body);

		if (this.userChannelSubscription != null) {
			this.userChannelSubscription.unsubscribe();
			this.userChannelSubscription = null;
		}

		const userChannels = [
			this.eventBus.consumer(`user/${(message.body as any).data.id}`)
		];
		if ((message.body as any).data.role === Role.ADMIN) {
			userChannels.push(this.eventBus.consumer("user/0"));
		}

		let successfulRegistrations = 0;
		this.userChannelSubscription = merge(...userChannels)
			.pipe(tap(event => {
				if (event.hasOwnProperty("success") && event["success"] === true) {
					successfulRegistrations++;
					if (successfulRegistrations === userChannels.length) {
						this.eventBus.send("store/login", message.body);
						this.inFlightLoginRequest?.reply(message.body);
					}
				}
			}), filter(message => message.hasOwnProperty("success") === false))
			.subscribe({
				next: event => {
					this.eventBus.publish((event.body as Action).type, event.body);
				},
				error: error => {
					this.notifyNotLoggedIn();
					this.inFlightLoginRequest?.fail(-1, error.message);
					this.logger.fatal("Unable to subscribe to user channels", {error});
				},
				complete: () => {
					this.logger.error("complete");
				}
			});
	}

	private requestRefreshToken(message: Message<Action>) {
		// We store the in flight login request here as we can only answer it once the principal/received
		// is send along by the server back to the client over an opened websocket.
		this.inFlightLoginRequest = message;
		this.eventBus.request("user/login", message.body).subscribe(response => {
			this.reconnectToSocket();
		}, error => {
			this.notifyNotLoggedIn();
			this.inFlightLoginRequest?.fail(error.failureCode, error.message);
			this.logger.error("User login failed", {error});
		});
	}

	private reconnectToSocket() {
		if (this.reconnectionRetries > 10) {
			this.logger.fatal("Unable to re-establish connection with server through the socket");
		}

		this.reconnectionRetries++;
		this.requestAccessToken().pipe(switchMap(() => this.socket.open())).subscribe({
			error: error => {
				this.notifyNotLoggedIn();
				this.inFlightLoginRequest?.fail(-1, error.message);
				this.logger.error("Unable to request new access token, user didn't have a valid refresh token",
					{error}
				);
			}
		});
	}

	private requestAccessToken(): Observable<Message<Action>> {
		return this.eventBus.request("user/login", {} as any);
	}

	private notifyNotLoggedIn() {
		this.eventBus.send("store/set-logged-in", {
			type: "store/set-logged-in",
			data: loggedIn.FALSE
		});
	}

	private doLogout(message: Message<Action>) {
		this.eventBus.request("user/logout", {}).subscribe(() => {
			const action = new Action("store/logout", -1, -1, {});
			this.eventBus.send(action.type, action);
			message.reply(new Action("internal/loggedOut", -1, -1, {success: true}));
		});
	}
}
