import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Logger, LoggerLocator } from "@tsng/logging";
import { AbstractFieldComponent } from "../abstract-field/component";

@Component({
	selector: "tsng-checkbox",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TsNgCheckboxFieldComponent),
			multi: true

		}
	]
})
export class TsNgCheckboxFieldComponent extends AbstractFieldComponent {
	protected logger: Logger = LoggerLocator.getLogger("TsNgCheckboxFieldComponent")();
	private _readonly: boolean = false;
	private _isDisabled: boolean = false;
	private manageInternalDisabledState = (() => {
		let previousDisabledState = false;
		let previousReadonlyState = false;

		return () => {
			if (this.control == null) {
				return;
			}

			if (previousReadonlyState === this._readonly && previousDisabledState === this.control.disabled) {
				return;
			}

			if (previousDisabledState !== this.control.disabled) {
				previousDisabledState = this.control.disabled;
			}

			if (previousReadonlyState !== this._readonly) {
				previousReadonlyState = this._readonly;
			}

			if (this.control.disabled || this._readonly) {
				this._formControl.disable({emitEvent: false});
			} else {
				this._formControl.enable({emitEvent: false});
			}

			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		};
	})();

	constructor(injector: Injector) {
		super(injector);
	}

	get readOnly(): boolean {
		return this._readonly;
	}

	@Input("readonly") set readOnly(readonly: boolean) {
		if (typeof readonly !== "boolean") {
			console.warn("Type of readonly is not typeof boolean");
			return;
		}
		this._readonly = readonly;
		this.manageInternalDisabledState();
	}

	get disabled(): boolean {
		return this._isDisabled;
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
		this.manageInternalDisabledState();
	}

	setDisabledState(isDisabled: boolean): void {
		super.setDisabledState(isDisabled);

		this._isDisabled = isDisabled;
		this.manageInternalDisabledState();
	}
}
