import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$nin: <<value[]>>}
 * }
 */
export class NotInOperator implements Operator {
	constructor(private field: string, private value: any[]) {
	}

	build(): object {
		const object = {};
		object[this.field] = {"$nin": this.value};
		return object;
	}

	clone(): NotInOperator {
		return new NotInOperator(this.field, this.value.length > 0 ? [...this.value]: []);
	}
}