import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialPropertyStore, Property, PropertyConversion } from "./model";

export const PROPERY_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("PropertyReducer");

export function propertyReducerProvider() {
	return reducerFactory("property", initialPropertyStore, Property, PropertyConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("property", PROPERY_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["property/deleted", "property/updated", "property/created"])
	],
	providers: [
		{
			provide: PROPERY_REDUCER_TOKEN,
			useFactory: propertyReducerProvider
		}
	]
})
export class PropertyCoreModule {

}
