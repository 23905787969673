import { animate, animateChild, group, query, style } from "@angular/animations";

export const slideOutAnimation = [
	// set new page X location to be -100%
	style({
		position: "relative",
		overflow: "hidden"
	}),
	query(":enter, :leave", [
		style({
			position: "absolute",
			overflow: "hidden",
			top: 0,
			transform: "translateX(0%)",
			width: "100%"
		})
	], {optional: true}),
	query(":enter",
		[
			style({
				transform: "translateX(-100%)",
				opacity: 0
			})
		],
		{optional: true}
	),
	query(":leave", animateChild(), {optional: true}),
	group([
		query(
			":leave",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateX(100%)",
						opacity: 0
					})
				)
			],
			{optional: true}
		),
		query(":enter",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateX(0%)",
						opacity: 1
					})
				)
			],
			{optional: true}
		)
	]),
	query(":enter", animateChild(), {optional: true})
];

export const slideInAnimation = [
	// set new page X location to be 100%
	style({
		position: "relative",
		overflow: "hidden"
	}),
	query(":enter, :leave", [
		style({
			position: "absolute",
			top: 0,
			transform: "translateX(0%)",
			height: "100%",
			width: "100%"
		})
	], {optional: true}),
	query(":enter",
		[
			style({
				transform: "translateX(200%)",
				opacity: 0
			})
		],
		{optional: true}
	),
	query(":leave", animateChild(), {optional: true}),
	group([
		query(
			":leave",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateX(-100%)",
						opacity: 0
					})
				)
			],
			{optional: true}
		),
		query(":enter",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateX(0%)",
						opacity: 1
					})
				)
			],
			{optional: true}
		)
	]),
	query(":enter", animateChild(), {optional: true})
];

export const slideUpAnimation = [
	// set new page X location to be 100%
	style({
		position: "relative",
		overflow: "hidden"
	}),
	query(":enter, :leave", [
		style({
			position: "absolute",
			top: 0,
			right: 0,
			width: "100%",
			height: "100vh"
		})
	], {optional: true}),
	query(":enter",
		[
			style({
				transform: "translateY(100%)",
				opacity: 0
			})
		],
		{optional: true}
	),
	query(":leave", animateChild(), {optional: true}),
	group([
		query(
			":leave",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateY(-100%)",
						opacity: 0
					})
				)
			],
			{optional: true}
		),
		query(":enter",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateY(0%)",
						opacity: 1
					})
				)
			],
			{optional: true}
		)
	]),
	query(":enter", animateChild(), {optional: true})
];

export const slideDownAnimation = [
	// set new page X location to be 100%
	style({
		position: "relative",
		overflow: "hidden"
	}),
	query(":enter, :leave", [
		style({
			position: "absolute",
			top: 0,
			right: 0,
			width: "100%",
			height: "100vh"
		})
	], {optional: true}),
	query(":enter",
		[
			style({
				transform: "translateY(-100%)",
				opacity: 0
			})
		],
		{optional: true}
	),
	query(":leave", animateChild(), {optional: true}),
	group([
		query(
			":leave",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateY(100%)",
						opacity: 0
					})
				)
			],
			{optional: true}
		),
		query(":enter",
			[
				animate(
					"0.3s ease-out",
					style({
						transform: "translateY(0%)",
						opacity: 1
					})
				)
			],
			{optional: true}
		)
	]),
	query(":enter", animateChild(), {optional: true})
];

export const fadeAnimation = [
	style({position: "relative"}), query(":enter, :leave", [
		style({
			position: "absolute",
			width: "100%"
		})
	]), query(":enter", [
		style({opacity: 0})
	], {optional: true}),

	query(":leave", [
		style({opacity: 1}), animate("0.05s", style({opacity: 0}))
	], {optional: true}),

	query(":enter", [
		style({opacity: 0}), animate("0.05s", style({opacity: 1}))
	], {optional: true})

];
