import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { AppModal } from "./component";
import { AppDialogContentComponent } from "./content/component";
import { AppDialogComponent } from "./dialog/component";

@NgModule({
	declarations: [AppModal, AppDialogComponent, AppDialogContentComponent],
	exports: [AppModal],
	imports: [MatDialogModule, MatIconModule, CommonModule]
})
export class AppModalComponentModule {

}
