import { HttpClient, HttpEventType } from "@angular/common/http";
import { Injector } from "@angular/core";
import { Action, EventBus, Message, MessageObject, MessageType } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import {
	AndOperator, CompoundEntityName, FilterAction, FilterResult, Operator, SelectorProvider, Sort
} from "@tsng/store";
import { OrderedMap } from "immutable";
import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject, Subject, zip } from "rxjs";
import {
	debounceTime, filter, finalize, flatMap, map, publishReplay, refCount, switchMap, takeUntil, tap
} from "rxjs/operators";
import { QueuedFile, QueuedFileStatus } from "../../queued-file/queued-file";
import { FileModel, ServerFile } from "../../server-file/server-file";
import { FILE_UPLOAD_ENDPOINT, FileSource } from "../source";

export interface UploadParams {
	entity: string,
	group: string,
	refId: string | number,
	fileSeq?: number
}

export interface FileFilterActionBody {
	search: string;
	limit: number;
	offset: number;
	sort: Sort[];
	filter: Operator;
	entity: string;
	group: string;
	refId?: number | string;
	compound?: CompoundEntityName[];
	params?: unknown;
}

export class DefaultFileSource implements FileSource {
	private httpClient: HttpClient;
	private eventBus: EventBus;
	private data: Observable<OrderedMap<number, ServerFile>>;
	private count: Observable<number>;
	private readonly doRefresh: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
	private acceptedExtensions: string[];
	private queuedFiles: QueuedFile[] = [];
	private queuedFilesSubject: BehaviorSubject<QueuedFile[]> = new BehaviorSubject<QueuedFile[]>([]);
	private autoUpload = true;
	private readonly sourceUpdates: ReplaySubject<void> = new ReplaySubject<void>(1);
	private readonly selectorOperator: () => OperatorFunction<number[] | string[], OrderedMap<number, { file: ServerFile }>>;
	private filterActionType = "file/filter";
	private maxFiles = -1;
	private maxSimultaneousUploads = 3;
	private uploadQueue: Subject<QueuedFile> = new Subject<QueuedFile>();
	private canUploadSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
	private entity: string;
	private referenceId: string | number;
	private fileSequence: number | null;
	private group: string;
	private removeWhenFailed: boolean = true;
	private logger = LoggerLocator.getLogger("DefaultFileSource")();
	private latestCount: number = 0;
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	private uploadEndpoint: string;
	private fileUploadedSubject: Subject<ServerFile> = new Subject<ServerFile>();

	constructor(injector: Injector) {
		this.httpClient = injector.get(HttpClient);
		this.eventBus = injector.get(EventBus);
		this.uploadEndpoint = injector.get(FILE_UPLOAD_ENDPOINT);
		this.selectorOperator = injector.get(SelectorProvider).getBuilder().main("file").build();
		this.manageQueue();
		this.manageUploads();
		this.listenToSourceUpdates();
	}

	getAutoUpload(): boolean {
		return this.autoUpload;
	}

	setAutoUpload(value: boolean) {
		this.autoUpload = value;
	}

	getRemoveWhenFailed(): boolean {
		return this.removeWhenFailed;
	}

	setRemoveWhenFailed(remove: boolean) {
		this.removeWhenFailed = remove;
	}

	addToQueue(files: FileList) {

		if ((files.length + this.latestCount) > this.maxFiles && this.maxFiles !== 1) {
			this.logger.error("Tried to upload more files than were allowed by the configuration", {
				files: files,
				currentCount: this.latestCount,
				maximumFileCount: this.maxFiles
			});
			const action = new Action("snackbar/create", {
				message: $localize`:Error toast|Too many files@@DefaultFileSource:The provided files list is to big.`,
				level: "error"
			});
			this.eventBus.send(action.type, action);
			return;
		}

		for (let i = 0; i < files.length; i++) {
			const file = files[i];
			if (this.isFileAccepted(file) === false) {
				this.logger.error("file is not accepted!", file);
				return;
			}

			const queuedFile = new QueuedFile(file);
			queuedFile.setState(this.autoUpload === true ? QueuedFileStatus.READY_TO_UPLOAD
				: QueuedFileStatus.IDLE);
			if(this.maxFiles === 1 && this.autoUpload === false) {
				this.queuedFiles.pop();
			}
			this.queuedFiles.push(queuedFile);
		}
		this.queuedFilesSubject.next(this.queuedFiles);
	}

	isFileAccepted(file: File) {
		if (this.acceptedExtensions == null || this.acceptedExtensions.length === 0) return true;
		const fileNameSplit = file.name.split(".");
		return this.acceptedExtensions.includes("." + fileNameSplit[fileNameSplit.length - 1]);
	}

	countChanged(): Observable<number> {
		if (this.count == null) {
			this.createSourceStream();
		}
		return this.count;
	}

	dataChanged(): Observable<OrderedMap<number, ServerFile>> {
		if (this.data == null) {
			this.createSourceStream();
		}
		return this.data;
	}

	createFilterAction(): Action<FileFilterActionBody> {
		return new Action<FileFilterActionBody>(this.filterActionType, Object.assign({}, {
			search: "",
			limit: this.maxFiles,
			offset: 0,
			sort: [],
			filter: new AndOperator(),
			entity: this.entity,
			group: this.group
		}, this.referenceId != null ? {refId: this.referenceId} : {}));
	}

	createSourceStream() {
		const baseStream: Observable<Message<FilterResult>> = this.doRefresh.pipe(tap(() => this.logger.debug(
			"Source was refreshed")),
			map(() => this.createFilterAction()),
			tap(action => this.logger.debug("Action was build", {action})),
			switchMap(action => this.eventBus.request<FilterAction, FilterResult>("internal-file/filter",
				action
			)),
			finalize(() => this.logger.debug("Datastream was closed")),
			publishReplay(1),
			refCount()
		);

		this.count = baseStream.pipe(map(message => message.body.data.totalCount), tap(totalCount => {
			this.latestCount = totalCount;
			this.logger.debug("Total count changed", {totalCount});
		}), publishReplay(1), refCount());

		this.data = baseStream.pipe(map(message => message.body.data),
			tap((data) => this.logger.debug("Ids changed", {data})),
			this.selectorOperator(),
			map(data => data.map(file => file.file)),
			tap((data) => this.logger.debug("Data changed", {data})),
			publishReplay(1),
			refCount()
		) as Observable<OrderedMap<number, ServerFile>>;
	}

	getAcceptedExtensions(): string[] {
		return this.acceptedExtensions;
	}

	getEntity(): string {
		return this.entity;
	}

	getGroup(): string {
		return this.group;
	}

	getMaxFiles(): number {
		return this.maxFiles;
	}

	getReferenceId(): number | string {
		return this.referenceId;
	}

	getFileSequence(): number | null {
		return this.fileSequence;
	}

	queueChanged(): Observable<QueuedFile[]> {
		return this.queuedFilesSubject;
	}

	refresh(): void {
		this.doRefresh.next();
	}

	refreshQueue(): void {
		this.queuedFilesSubject.next(this.queuedFiles);
	}

	setAcceptedExtensions(extensions: string[]): void {
		if (extensions.every(extension => extension.startsWith(".")) === false) {
			this.logger.error("extensions have to start with '.'", extensions);
			return;
		}
		this.acceptedExtensions = extensions;
		this.sourceUpdates.next();
	}

	setEntity(entity: string): void {
		this.entity = entity;
		this.sourceUpdates.next();
	}

	setGroup(group: string): void {
		this.group = group;
		this.sourceUpdates.next();
	}

	setMaxFiles(limit: number) {
		if(this.fileSequence != null && limit !== 1) {
			this.logger.error("Cannot set max files to another value then 1 if file sequence is set");
			return;
		}
		this.maxFiles = limit;
		this.sourceUpdates.next();
	}

	setReferenceId(referenceId: number | string): void {
		this.referenceId = referenceId;
		this.sourceUpdates.next();
	}

	setFileSequence(fileSequence: number | null): void {
		if(this.maxFiles !== 1 && fileSequence != null) {
			this.logger.error("fileSequence can only be set if maxFiles is set to 1");
			return;
		}
		this.fileSequence = fileSequence;
		this.sourceUpdates.next();
	}

	getFileUploadedObservable(): Observable<ServerFile> {
		return this.fileUploadedSubject.asObservable();
	}

	sourceUpdated(): Observable<void> {
		return this.sourceUpdates;
	}

	startUpload(): void {
		this.queuedFiles.filter(file => file.getState() === QueuedFileStatus.IDLE).map(file => file.setState(
			QueuedFileStatus.READY_TO_UPLOAD));
		this.queuedFilesSubject.next(this.queuedFiles);
	}


	manageQueue() {
		this.queuedFilesSubject.pipe(
			filter(files => files.find(file => file.getState() === QueuedFileStatus.READY_TO_UPLOAD) != null),
			flatMap(files => {
				const toQueue = files.filter(file => file.getState() === QueuedFileStatus.READY_TO_UPLOAD);
				toQueue.map(file => file.setState(QueuedFileStatus.QUEUED));
				this.queuedFilesSubject.next(this.queuedFiles);
				return toQueue;
			}),
			takeUntil(this.destroyed)
		).subscribe(file => {
			this.logger.debug("adding file to queue", {file: file});
			this.uploadQueue.next(file);
		});
	}

	manageUploads() {
		zip(this.uploadQueue, this.canUploadSubject).pipe(map(([file, test]) => file)).subscribe(file => {
			file.setState(QueuedFileStatus.UPLOADING);
			this.queuedFilesSubject.next(this.queuedFiles);
			const uploadParams = {
				entity: this.entity,
				group: this.group,
				refId: this.referenceId
			};
			if (this.fileSequence != null) {
				uploadParams["fileSequence"] = this.fileSequence;
			}
			this.uploadFile(file, uploadParams);
		});

		for (let i = 0; i < this.maxSimultaneousUploads; i++) {
			this.canUploadSubject.next();
		}
	}

	uploadFile(queuedFile: QueuedFile, uploadParams: UploadParams) {
		const requestData = new FormData();
		requestData.append("file", queuedFile.getFile());
		for (const key in uploadParams) {
			if (uploadParams.hasOwnProperty(key) === false) continue;
			requestData.append(key, uploadParams[key]);
		}
		this.logger.debug(
			"Uploading file",
			{
				file: queuedFile,
				params: uploadParams
			}
		);
		this.httpClient.post(this.uploadEndpoint, requestData, {
			reportProgress: true,
			observe: "events",
			withCredentials: true
		}).subscribe(event => {
			if (event.type === HttpEventType.UploadProgress) {
				queuedFile.setProgress(Math.round(event.loaded / event.total * 100));
			} else if (event.type === HttpEventType.Response) {
				queuedFile.setState(QueuedFileStatus.DONE);
				this.logger.debug(
					"File finished uploading",
					{
						file: queuedFile,
						params: uploadParams
					}
				);

				this.queuedFiles.splice(this.queuedFiles.indexOf(queuedFile), 1);
				this.queuedFilesSubject.next(this.queuedFiles);
				const receivedBody: {type: string, data: FileModel} = event.body as  {type: string, data: FileModel};
				const receivedMessage: MessageObject<Action<FileModel>> = {address: "file/uploaded", body: new Action<FileModel>(receivedBody.type, receivedBody.data), type: MessageType.SEND};
				this.eventBus.sendMessageObject(receivedMessage);
				this.fileUploadedSubject.next(new ServerFile(receivedBody.data));
				this.canUploadSubject.next();
				this.doRefresh.next();
			}
		}, error => {
			queuedFile.setState(QueuedFileStatus.ERROR);
			if (this.removeWhenFailed === true) {
				this.queuedFiles = this.queuedFiles.filter(file => file.getState() !== QueuedFileStatus.ERROR)
			}
			this.queuedFilesSubject.next(this.queuedFiles);


			const action = new Action("snackbar/create", {
				message: $localize`:Error toast|Upload failed@@DefaultFileSource:The upload has failed`,
				level: "error"
			});
			this.eventBus.send(action.type, action);
			this.canUploadSubject.next();
		});
	}

	listenToSourceUpdates() {
		this.sourceUpdates.pipe(
			debounceTime(50),
			takeUntil(this.destroyed)
		).subscribe(() => {
			this.doRefresh.next();
		})
	}

	destroy() {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

}
