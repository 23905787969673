import { Directive, Self } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { Observable } from "rxjs";

@Directive({
	selector: "form[tsNgRootFormGroup][formGroup]"
})
export class TsNgRootFormGroupDirective {
	constructor(@Self() private formGroupDirective: FormGroupDirective) {
	}

	get isSubmitted(): boolean {
		return this.formGroupDirective.submitted;
	}

	get onSubmit(): Observable<unknown> {
		return this.formGroupDirective.ngSubmit;
	}
}
