import { Collection, Map } from "immutable";

export interface Model extends Collection.Keyed<string, any> {
}

export interface StoreModel extends Model {
	id: number | string;
	rev: number;
	displayName: string;
}

export function isStoreModel(model?: Collection.Keyed<any, any>): model is StoreModel {
	return model && model.has("id") && model.has("rev");
}

export interface ModelMap<T extends StoreModel> extends Map<number | string, T> {
}
