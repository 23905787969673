import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { Action, EventBus } from "@tsng/core";
import { required, TsFormControl, TsFormGroup } from "@tsng/form";
import { LoggerLocator } from "@tsng/logging";
import { passwordMinLength } from "../../validator";
import { newPasswordsMustBeEqual, passwordCannotBeEqualToOldPassword } from "./validator";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "tsng-change-password-form",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class TsNgChangePasswordForm {
	@Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	form = new TsFormGroup({
		password: new TsFormControl("", [required()]),
		newPassword: new TsFormControl("",
			[required(), passwordMinLength(), passwordCannotBeEqualToOldPassword()]
		),
		newPasswordConfirmation: new TsFormControl("", [required(), newPasswordsMustBeEqual()])
	}, {updateOn: "submit"});
	private readonly logger = LoggerLocator.getLogger("TsNgChangePasswordForm")();

	constructor(private eventBus: EventBus) {
	}

	changePassword() {
		if (this.form.invalid) {
			this.logger.warning("Form wasn't valid");
			return;
		}

		let action = new Action("user/change-password", this.form.value);
		this.eventBus.request("user/change-password", action)
			.subscribe(response => {
				this.logger.info("Password has been changed successfully", response);
				this.changed.emit(true);
			}, error => {
				this.logger.error("Password change request failed", error);
				const action = new Action("snackbar/create", {
					message: $localize`:Error toast|Change password failed@@tsNgChangePasswordFormChangeFailed:We were unable to change your password, please try again.`,
					level: "error"
				});
				this.eventBus.send(action.type, action);
			});
	}
}
