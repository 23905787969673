import { Type } from "@angular/core";
import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { Location } from "../site/model";

export type UserGroupStore = ModelMap<UserGroup>;

export const initialUserGroupStore: UserGroupStore = <UserGroupStore>Map();

export const UserGroupConversion: ActionConverter.Config = {};

export enum UserGroupType {
	PRIVATE = "private",
	SHARED = "shared"
}

export type userGroupSettings = {
	useMyLocation: boolean;
	[key: string]: any;
}

export interface UserGroupModel extends StoreModel {
	name: string;
	groupType: UserGroupType;
	status: string;
	imageId: number;
	interest: number[];
	searchRadius: number;
	location: Location;
	newsletter: boolean;
	settings: userGroupSettings
}

export class UserGroup extends Record({
	id: 0,
	rev: 0,
	name: "",
	groupType: UserGroupType.PRIVATE,
	status: "",
	imageId: 0,
	interest: [],
	searchRadius: 10,
	location: {
		latitude: 0,
		longitude: 0
	},
	newsletter: false,
	settings: {
		useMyLocation: false
	}
}) implements UserGroupModel {
	id: number;
	rev: number;
	name: string;
	groupType: UserGroupType;
	status: string;
	imageId: number;
	interest: number[];
	searchRadius: number;
	location: Location;
	settings: userGroupSettings;

	newsletter: boolean;
	get displayName(): string {
		return this.name;
	};

	get hasProfilePicture(): boolean {
		return (this.imageId == null || this.imageId === 0) === false;
	}

	get profilePictureSrc(): string {
		if (this.imageId == null || this.imageId === 0) {
			return `NO-IMAGE`;
		} else {
			return `${this.imageId}/100x100`;
		}
	}
}
