import { InjectionToken } from "@angular/core";

export type TargetType = "consumer" | "management";

export type ConfigJson = {
	"$base": unknown, "$targetOverrides": {
		[key in TargetType]?: unknown
	}
};

export const TARGET = new InjectionToken<TargetType>("target");

export const CONFIG_URL = new InjectionToken<string>("configUrl");

export const VERSION = new InjectionToken<string>("Version");
