export class Action<T extends object = object> {
	public readonly type: string;
	public readonly id: number|string;
	public readonly rev: number;
	public readonly data: T;

	constructor(type: string, id: number|string, rev: number, data: T);
	constructor(type: string, data: T);
	constructor(...args: any[]) {
		const type = args[0];
		let id = -1;
		let rev = -1;
		let data;

		if (args.length === 2) {
			data = args[1];
		} else {
			id = args[1];
			rev = args[2];
			data = args[3];
		}

		this.type = type;
		this.id = id;
		this.rev = rev;
		this.data = data;
	}

	toString(): string {
		return JSON.stringify({
			type: this.type,
			id: this.id,
			rev: this.rev,
			data: this.data
		});
	}

	toJSON(): any {
		return {
			type: this.type,
			id: this.id,
			rev: this.rev,
			data: this.data
		};
	}
}
