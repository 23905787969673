import {
	ChangeDetectorRef,
	Directive,
	EmbeddedViewRef,
	Injector,
	OnDestroy,
	OnInit,
	TemplateRef,
	ViewContainerRef
} from "@angular/core";
import { LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { BehaviorSubject, combineLatest, Observable, of, OperatorFunction, ReplaySubject } from "rxjs";
import { combineAll, map, startWith, takeUntil } from "rxjs/operators";
import { Account } from "../../store/model";
import { TsNgIfPermissionContext } from "./context";
import { Map } from "immutable";


/**
 * See the documentation from {@see NgIf} as this Directive is a copy of the NgIf Directive:
 * {@link https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts}
 *
 * The differences are purely superficial when it comes to naming and the way the condition is passed
 * along to this class.
 *
 * Unlike the ngIf directive we don't pass a boolean condition to this class instead we pass a string
 * which specifies the required permission for this element to be displayed. If the user has this
 * permission the element shows it else it shows the elseViewRef if provided.
 *
 * Examples:
 *
 * Simple form:
 * <div *ifPermission="company/list">
 *     <a href="company list url">Companies</a>
 * </div>
 *
 * Form with else block:
 *
 * <div *ifPermission="company/list; else elseBlock">
 *     <a href="company list url">Companies</a>
 * </div>
 * <ng-template #elseBlock>
 *     <span>You are not allowed to view the company list<span>
 * </ng-template>
 *
 * Full form:
 *
 * <div *ifPermission="company/list; then thenBlock else elseBlock"></div>
 * <ng-template #thenBlock>
 *     <a href="company list url">Companies</a>
 * </ng-template>
 * <ng-template #elseBlock>
 *     <span>You are not allowed to view the company list<span>
 * </ng-template>
 */
@Directive()
export abstract class TsNgAbstractPermissionDirective implements OnInit, OnDestroy {
	private destroyed = new ReplaySubject<boolean>(1);
	private permissionChanged = new ReplaySubject<string[]>(1);
	private thenTemplateChanged = new ReplaySubject<TemplateRef<any>>(1);
	private elseTemplateChanged = new BehaviorSubject<TemplateRef<any> | null>(null);
	private thenViewRef: EmbeddedViewRef<any> | null = null;
	private elseViewRef: EmbeddedViewRef<any> | null = null;
	private logger = LoggerLocator.getLogger("abstractPermissionDirective")();
	private readonly accountSelector: () => OperatorFunction<any, any>;
	abstract mustAllMatch: boolean;
	private accountObservable: Observable<Account>;
	private selectorProvider: SelectorProvider;
	private viewContainer: ViewContainerRef;
	private changeDetectorRef: ChangeDetectorRef;

	constructor(protected injector: Injector) {
		this.selectorProvider = this.injector.get(SelectorProvider);
		this.viewContainer = this.injector.get(ViewContainerRef);
		this.changeDetectorRef = this.injector.get(ChangeDetectorRef);
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();

	}

	setPermissions(permissions: string[]) {
		this.permissionChanged.next(permissions);
	}

	setIfPermissionThen(templateRef: TemplateRef<any> | null) {
		this.validateTemplateRef("ifPermissionThen", templateRef);
		this.thenViewRef = null; // clear previous view if any
		this.thenTemplateChanged.next(templateRef);
	}

	setIfPermissionElse(templateRef: TemplateRef<any> | null) {
		this.validateTemplateRef("ifPermissionElse", templateRef);
		this.elseViewRef = null; // clear previous view if any
		this.elseTemplateChanged.next(templateRef);
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	ngOnInit(): void {
		this.accountObservable = of({}).pipe(
			this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => (accountMap.first().account as any)),
		);
		combineLatest([
			this.permissionChanged,
			this.accountObservable,
			this.thenTemplateChanged,
			this.elseTemplateChanged
		]).pipe(map(([permissions, account, thenTemplate, elseTemplate]) =>
			this.permissionContextFactory(permissions, account, thenTemplate, elseTemplate)
		), takeUntil(this.destroyed))
			.subscribe(context => {
				this.updateView(context);
			}, error => {
				this.logger.error("Error within Observable chain");
			});
	}

	private permissionContextFactory(permissions: string[],
		account?: Account,
		thenTemplate?: TemplateRef<any>,
		elseTemplate?: TemplateRef<any>
	): TsNgIfPermissionContext {
		return new TsNgIfPermissionContext(permissions, this.mustAllMatch, account, thenTemplate, elseTemplate);
	}

	private updateView(context: TsNgIfPermissionContext) {
		if (context.hasPermission()) {
			this.viewContainer.clear();
			this.elseViewRef = null;

			if (context.hasThenTemplate()) {
				this.thenViewRef = this.viewContainer.createEmbeddedView(context.getThenTemplate(),
					{$implicit: context.hasPermission()}
				);
			}
			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
			return;
		}

		if (!this.elseViewRef) {
			this.viewContainer.clear();
			this.thenViewRef = null;

			if (context.hasElseTemplate()) {
				this.elseViewRef = this.viewContainer.createEmbeddedView(context.getElseTemplate(),
					{$implicit: context.hasPermission()}
				);
			}
			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		}
	}

	private validateTemplateRef(property: string, templateRef: TemplateRef<any> | null) {
		const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
		if (isTemplateRefOrNull === false) {
			this.logger.fatal(`${property} must be a TemplateRef, but received something else.`);
			throw new Error(`${property} must be a TemplateRef, but received something else.`);
		}
	}
}

