import { Observable } from "rxjs";
import { FilterCleanableProperties, Sort, SortDirection } from "../action/filter";
import { Operator } from "../filter/operator/operator";

export enum SourceStatus {
	INITIAL = "INITIAL",
	LOADING = "LOADING",
	STABLE = "STABLE"
}

/**
 * See the {@see ./README.md} file for documentation
 */
export interface Source<T extends any = unknown> {
	getSearch(): Readonly<string>;

	setSearch(query: string): this;

	getLimit(): Readonly<number>;

	setLimit(limit: number): this;

	getOffset(): Readonly<number>;

	setOffset(offset: number): this;

	getSort(): Readonly<Sort[]>;

	setSort(sort: Sort[]): this;

	addSort(sort: Sort): this;

	removeOperator(fieldName: string): this;

	adjustSort(fieldName: string, direction: SortDirection): this;

	getOperators(): Readonly<Map<string, Operator>>;

	setOperators(operators: Map<string, Operator>): this;

	addOperator(identifier: string, operator: Operator): this;

	removeOperator(identifier: string): this;

	setParams(params: unknown): this;

	getParams(): Readonly<unknown>;

	/**
	 * Method which relays the changes to the total count, this updates with every status update or refresh.
	 */
	countChanged(): Observable<number>;

	/**
	 * Method which relays the changes to the source itself, any adjustments made to it trigger this as a
	 * notifier method.
	 */
	sourceUpdated(): Observable<void>;

	/**
	 * Method which relays the changes to the status of the source, this is where the loading state comes
	 * from.
	 */
	statusChanged(): Observable<SourceStatus>;

	/**
	 * Method which relays the data changes, this is where the output comes from it contains the data in
	 * the configured format.
	 */
	dataChanged(): Observable<T>;

	refresh(): void;

	clone(filterHandlerAddress?: string): Source;

	clean(propertiesToClean?: FilterCleanableProperties): this;
}
