import { NgModule } from "@angular/core";
import { TwlyTimeSincePipe } from "./pipe";

@NgModule({
	exports: [
		TwlyTimeSincePipe
	],
	declarations: [
		TwlyTimeSincePipe
	]

})
export class TimeSinceModule {
}