import { Directive, Injector, Input, TemplateRef } from "@angular/core";
import { TsNgAbstractPermissionDirective } from "../abstract-permission/directive";

@Directive({
	selector: "[ifAllPermissions]"
})
export class TsNgIfAllPermissionDirective extends TsNgAbstractPermissionDirective {
	mustAllMatch = true;

	constructor(injector: Injector, templateRef: TemplateRef<any>) {
		super(injector);
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifAllPermissions(permission: string[]) {
		this.setPermissions(permission);
	}

	@Input() set ifAllPermissionsThen(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionThen(templateRef);
	}

	@Input() set ifAllPermissionsElse(templateRef: TemplateRef<any> | null) {
		this.setIfPermissionElse(templateRef);
	}
}