import { Inject, Injectable, Injector, NgZone } from "@angular/core";
import { App, AppState } from "@capacitor/app";
import { DialogPlugin } from "@capacitor/dialog";
import { SplashScreenPlugin } from "@capacitor/splash-screen";
import { BundleInfo, CapacitorUpdaterPlugin } from "@capgo/capacitor-updater";
import { LoggerLocator } from "@tsng/logging";
import { CHECK_FOR_UPDATE_INTERVAL, RENDERED_BY, RenderEnvironment } from "@twly/core";
import { Observable, ReplaySubject, timer } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { filter, map, pluck, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { SPLASH_SCREEN } from "../../../../../../consumer/src/app/core/splash-screen/interface";
import { CAPACITOR_UPDATER, DIALOG } from "./interface";

@Injectable({providedIn: "root"})
export class CapacitorCheckForUpdateService {
	private logger = LoggerLocator.getLogger("CheckForUpdateService")();
	private updateInterval: number;
	private appStateSubject = new ReplaySubject<AppState>();
	private capacitorUpdater: CapacitorUpdaterPlugin;
	private splashscreen: SplashScreenPlugin;
	private dialog: DialogPlugin;

	constructor(@Inject(CHECK_FOR_UPDATE_INTERVAL) updateInterval,
		@Inject(RENDERED_BY) renderedBy: RenderEnvironment,
		private injector: Injector,
		private ngZone: NgZone
	) {
		if (renderedBy !== RenderEnvironment.CAPACITOR) {
			return;
		}
		this.capacitorUpdater = this.injector.get(CAPACITOR_UPDATER);
		this.splashscreen = this.injector.get(SPLASH_SCREEN);
		this.dialog = this.injector.get(DIALOG);

		this.notifiedAppReady();
		this.setUpdateInterval(updateInterval);
		this.forceUpdateIfAvailable();
		this.showPromptOnUpdateAvailable();
		this.initializeAppStateSubject();
	}

	notifiedAppReady() {
		this.capacitorUpdater.notifyAppReady().catch(error => this.logger.error("Failed to notify app ready",
			error
		));
	}

	initializeAppStateSubject() {
		App.getState().then(state => {
			this.appStateSubject.next(state);
		});
		App.addListener("appStateChange", (state) => {
			this.logger.debug("App state changed", state);
			this.appStateSubject.next(state);
		});
	}

	setUpdateInterval(updateInterval: number) {
		if (updateInterval == null) {
			this.logger.warning("Update interval incorrectly configured, defaulting to 30 minutes");
			updateInterval = 1800000;
		}
		this.updateInterval = updateInterval;

	}

	forceUpdateIfAvailable() {
		this.logger.debug("Checking for update");
		this.ngZone.runOutsideAngular(() => {
			this.appStateSubject.pipe(filter(state => state.isActive),
				tap(() => this.logger.debug("Checking for update")),
				switchMap(() => fromPromise(this.capacitorUpdater.getLatest())),
				filter(latest => latest.message === "Update available"),
				tap(() => {this.logger.debug("Update available")}),
				switchMap((latest) => fromPromise(this.capacitorUpdater.download({
					url: latest.url,
					version: latest.version
				}))),
				takeUntil(timer(40000)),
				take(1),
				switchMap(bundleInfo => this.updateApplication(bundleInfo))
			).subscribe({
				error: error => {
					this.logger.error("Failed to update application", error);
					this.splashscreen.hide();
				}
			});
		});
	}

	promptUser(): Observable<boolean> {
		return fromPromise(this.dialog.confirm({
			title: "Update",
			message: $localize`:Update prompt|The prompt message the users sees when there is a new version available@@CheckForUpdateServiceUpdatePrompt:There is a new version of this application available. Please update.`
		}).then(result => this.logger.info("Result", result))).pipe(pluck("value"));
	}

	updateApplication(bundleInfo: BundleInfo) {
		this.splashscreen.show();
		this.logger.debug("Updating application", bundleInfo);
		return this.capacitorUpdater.set({id: bundleInfo.id});
	}

	showPromptOnUpdateAvailable() {
		this.ngZone.runOutsideAngular(() => {
			this.appStateSubject.pipe(
				switchMap(state => timer(40001, this.updateInterval).pipe(filter(() => state.isActive))),
				switchMap(() => fromPromise(this.capacitorUpdater.getLatest())),
				filter(latest => latest.message === "Update available"),
				switchMap((latest) => fromPromise(this.capacitorUpdater.download({
					url: latest.url,
					version: latest.version
				}))),
				switchMap(bundleInfo => this.promptUser().pipe(
					filter(shouldUpdate => shouldUpdate === true),
					map(() => bundleInfo)
				)),
				switchMap(bundleInfo => this.updateApplication(bundleInfo))
			).subscribe({
				error: error => {
					this.logger.error("Failed to update application", error);
					this.splashscreen.hide();
				}
			});
		});
	}
}
