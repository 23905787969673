import { EventBus } from "@tsng/core";
import { FilterActionBuilderBuilder } from "../filter/builder";
import { AbstractEntityLinkBuilder, EntityLink } from "../schema/abstract-entity-link-builder";
import { Schema } from "../schema/schema";
import { MergeOperator, SelectorBuilder, SelectorMergeFactory } from "../selector/selector";
import { DefaultSource } from "./default/source";
import { Source } from "./source";

export class SourceBuilder extends AbstractEntityLinkBuilder<EntityLink, Source> {
	private mergeOperator: MergeOperator<any>;
	private mergeFactory: SelectorMergeFactory;
	private filterBuilder: FilterActionBuilderBuilder;
	private selectorBuilder: SelectorBuilder;
	private filterHandlerAddress: string = "entity/filter";

	constructor(protected eventBus: EventBus, protected schema: Schema) {
		super();
	}

	setMergeFactory(factory: SelectorMergeFactory): this {
		const self = this.clone();
		self.mergeFactory = factory;
		return self;
	}

	setMergeOperator<T>(mergeOperator: MergeOperator<T>): this {
		const self = this.clone();
		self.mergeOperator = mergeOperator;
		return self;
	}

	setFilterHandlerAddress(address: string): this {
		const self = this.clone();
		self.filterHandlerAddress = address;
		return self;
	}

	setFilterBuilder(filterBuilder: FilterActionBuilderBuilder): this {
		const self = this.clone();
		self.filterBuilder = filterBuilder;
		return self;
	}

	setSelectorBuilder(selectorBuilder: SelectorBuilder): this {
		const self = this.clone();
		self.selectorBuilder = selectorBuilder;
		return self;
	}

	build<T>(): Source<T> {
		let filterBuilder = this.filterBuilder
			.main(this.mainEntityConfiguration.entity.getName(), this.mainEntityConfiguration);

		let selectorBuilder = this.selectorBuilder
			.main(this.mainEntityConfiguration.entity.getName(), this.mainEntityConfiguration);

		this.linkedEntityConfigurations.forEach(config => {
			filterBuilder = filterBuilder.link(config.entity.getName(), config);
			selectorBuilder = selectorBuilder.link(config.entity.getName(), config);
		});

		if (this.mergeFactory != null) {
			selectorBuilder = selectorBuilder.setMergeFactory(this.mergeFactory);
		}

		if (this.mergeOperator != null) {
			selectorBuilder = selectorBuilder.setMergeOperator(this.mergeOperator);
		}

		return new DefaultSource<unknown, T>(this.eventBus,
			this.filterHandlerAddress,
			filterBuilder.build(),
			selectorBuilder.build()
		);
	}
}
