import { Inject, Injectable, Injector, NgZone } from "@angular/core";
import { SplashScreenPlugin } from "@capacitor/splash-screen";
import { Logger, LoggerLocator } from "@tsng/logging";
import { RENDERED_BY, RenderEnvironment } from "@twly/core";
import { take } from "rxjs/operators";
import { SPLASH_SCREEN } from "./interface";

@Injectable({
	providedIn: "root"
})
export class SplashScreenService {

	private logger: Logger = LoggerLocator.getLogger("SplashScreenService")();
	private splashScreen: SplashScreenPlugin | null = null;

	constructor(private injector: Injector,
		@Inject(RENDERED_BY) private renderedBy: string,
		private ngZone: NgZone
	) {
		if (this.renderedBy !== RenderEnvironment.CAPACITOR) {
			return;
		}

		this.splashScreen = this.injector.get(SPLASH_SCREEN);
		this.listenForStable();
	}

	private listenForStable(): void {
		this.logger.debug("Waiting for application to become stable");
		this.ngZone.onStable.pipe(take(1)).subscribe(() => {
			this.logger.info("Application is stable, hiding splash screen");
			this.splashScreen.hide({fadeOutDuration: 3000})
				.catch(error => this.logger.error("Failed to hide splash screen", error));
		});
	}

}
