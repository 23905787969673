import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type ScrollPositionStore = ModelMap<ScrollPosition>;

export const initialScrollPositionStore: ScrollPositionStore = <ScrollPositionStore>Map();

export const ScrollPositionConversion: ActionConverter.Config = {
};

export interface ScrollPositionModel extends StoreModel {
	id: string;
	rev: number;
	scrollLeft: number;
	scrollTop: number;
	displayName: string;
}

export class ScrollPosition extends Record({
	id: "",
	rev: 0,
	scrollLeft: 0,
	scrollTop: 0,
	displayName: ""
}) implements ScrollPositionModel {
	id: string;
	rev: number;
	scrollLeft: number;
	scrollTop: number;
	displayName: string;
}
