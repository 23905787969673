import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerAddress, CrawlerAddressConversion, initialCrawlerAddressStore } from "./model";

export const CRAWLERADDRESS_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CrawlerAddressReducer");

export function crawlerAddressReducerProvider() {
	return reducerFactory("crawlerAddress", initialCrawlerAddressStore, CrawlerAddress, CrawlerAddressConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerAddress", CRAWLERADDRESS_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["crawlerAddress/deleted", "crawlerAddress/updated", "crawlerAddress/created"])
	],
	providers: [
		{
			provide: CRAWLERADDRESS_REDUCER_TOKEN,
			useFactory: crawlerAddressReducerProvider
		}
	]
})
export class CrawlerAddressCoreModule {

}
