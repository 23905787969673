import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type UserNotificationSettingStore = ModelMap<UserNotificationSetting>;

export const initialUserNotificationSettingStore: UserNotificationSettingStore = <UserNotificationSettingStore>Map();

export const UserNotificationSettingConversion: ActionConverter.Config = {};

export interface UserNotificationSettingModel extends StoreModel {
	type: string;
	userId: number;
	medium: number;
	retention: number;
	showSummary: boolean;
}

export class UserNotificationSetting extends Record({
	id: 0,
	rev: 0,
	type: "",
	userId: 0,
	medium: 0,
	retention: 0,
	showSummary: true

}) implements UserNotificationSettingModel {
	id: number;
	rev: number;

	type: string;
	userId: number;
	medium: number;
	retention: number;
	showSummary: boolean;

	get displayName(): string {
		return this.type;
	};
}
