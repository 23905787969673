import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type VenueStore = ModelMap<Venue>;

export const initialVenueStore: VenueStore = <VenueStore>Map();

export const VenueConversion: ActionConverter.Config = {};

export interface esLocation {
	lon: number,
	lat: number
}

export interface VenueModel extends StoreModel {
	siteId: number;
	name: string;
	narrative: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	phone: string;
	email: string;
	url: string;
	location: esLocation;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	openingHours: [];
	closed: [];
	errorLevel: number;
	errors: object[];
	propertyIds: number[];
}

export class Venue extends Record({
	id: 0,
	rev: 0,
	siteId: null,
	name: "",
	narrative: "",
	street: "",
	houseNumber: "",
	zipCode: "",
	city: "",
	phone: "",
	email: "",
	url: "",
	location: {
		lon: 0,
		lat: 0
	},
	audienceIds: [],
	tagIds: [],
	tags: [],
	openingHours: [],
	closed: [],
	errorLevel: 0,
	errors: [],
	propertyIds: []

}) implements VenueModel {

	id: number;
	rev: number;
	siteId: number;
	name: string;
	narrative: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	phone: string;
	email: string;
	url: string;
	location: esLocation;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	openingHours: [];
	closed: [];
	errorLevel: number;
	errors: object[];
	propertyIds: number[];

	get displayName(): string {
		return this.name;
	};

	get streetDisplayName(): string {
		return this.street + " " + this.houseNumber;
	}
}
