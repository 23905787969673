import { Directive, Injector, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl } from "@angular/forms";
import { Logger, LoggerLocator } from "@tsng/logging";
import { EqualOperator, StoreModel } from "@tsng/store";
import { BehaviorSubject, of, ReplaySubject } from "rxjs";
import { debounceTime, filter, map, switchMap, takeUntil } from "rxjs/operators";
import { AbstractFieldComponent } from "../abstract-field/component";
import { SearchableSourceInterface } from "./source";

@Directive()
export abstract class AbstractComboBox extends AbstractFieldComponent implements OnDestroy, OnInit {
	displaySource: SearchableSourceInterface;
	searchSource: SearchableSourceInterface;
	search = new FormControl<string |  { [entityName: string]: StoreModel }>("");
	model: BehaviorSubject<StoreModel> = new BehaviorSubject<StoreModel>(null);
	@Input() placeholder = "";
	@Input() readonly: boolean = false;
	@Input() hidden: boolean = false;
	protected sourceSubject = new ReplaySubject<SearchableSourceInterface>(1);
	protected logger: Logger = LoggerLocator.getLogger("AbstractComboBox")();

	constructor(protected injector: Injector) {
		super(injector);
	}

	@Input("source") set autoCompleteSource(source: SearchableSourceInterface) {
		if (source == null) return;
		// create a clone of the source so the search and value observables don't have side effects that
		// change each other.
		this.displaySource = source.clone().clean(["search", "operators"]);
		this.searchSource = source;
		this.searchSource = source;
		this.sourceSubject.next(source);
	}

	/**
	 * See {@link ControlValueAccessor#registerOnTouched}
	 */
	registerOnTouched(callback: any): void {
		this.onFocus = () => {
			callback();
		};
	}

	/**
	 * See {@link ControlValueAccessor#setDisabledState}
	 */
	setDisabledState(isDisabled: boolean): void {
		this.search.reset({
			value: this.search.value,
			disabled: isDisabled
		}, {
			onlySelf: true,
			emitEvent: false
		});
		super.setDisabledState(isDisabled);
	}

	ngOnInit() {
		super.ngOnInit();

		this.listenToIdChanges();
		this.listenToSearchChanges();
	}

	protected listenToIdChanges() {
		this._formControl.valueChanges.pipe(switchMap(id => {
			return this.sourceSubject.pipe(map(source => ({
				source: this.displaySource,
				id: id
			})));
		}), switchMap(({source, id}) => {
			if (id == null) {
				return of(null);
			}
			return source.addOperator("equalIdOperator", new EqualOperator("id", id))
				.dataChanged();
		}), map(models => models?.first()), takeUntil(this.destroyed)).subscribe(model => {
			this.logger.debug("New model has been set", {
				model: model
			});
			this.model.next(model);
		});
	}

	protected listenToSearchChanges() {
		this.search.valueChanges.pipe(
			filter(value => typeof value === "string"),
			switchMap((value: string) => this.sourceSubject.pipe(map(source => ({
				source: this.searchSource,
				query: value
			})))),
			debounceTime(200),
			takeUntil(this.destroyed)
		).subscribe(({source, query}) => {
			this.searchSource.setSearch(query);

			if (query === "") {
				this.logger.debug("Control is empty setting value to null", {
					query
				});
				this.writeValue(null);
			}
		});
	}

	protected patchSearchInput(models: { [entityName: string]: StoreModel }) {
		this.search.patchValue(models, {emitEvent: false});
	}
}
