import { Action } from "@tsng/core";
import { Map } from "immutable";

export const scrollPositionReducer = (state: Map<string, number> = Map(), action: Action) => {
	switch (action.type) {
		case "scrollPosition/set":
			return state.withMutations(state => {
				state.set(action.data["id"], action.data["position"]);
			});

		case "scrollPosition/unset":
			return state.withMutations(state => {
				state.remove(action.data["id"]);
			});

		case "scrollPosition/clear":
		case "store/logout":
			return Map();

		default:
			return state;
	}
};
