import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerPathStatus, CrawlerPathStatusConversion, initialCrawlerPathStatusStore } from "./model";

export const CRAWLERPATHSTATUS_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CrawlerPathStatusReducer");

export function crawlerPathStatusReducerProvider() {
	return reducerFactory(
		"crawlerPathStatus",
		initialCrawlerPathStatusStore,
		CrawlerPathStatus,
		CrawlerPathStatusConversion
	);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerPathStatus", CRAWLERPATHSTATUS_REDUCER_TOKEN),
		TsNgStoreModule.forFeature([
			"crawlerPathStatus/deleted",
			"crawlerPathStatus/updated",
			"crawlerPathStatus/created"
		])
	],
	providers: [
		{
			provide: CRAWLERPATHSTATUS_REDUCER_TOKEN,
			useFactory: crawlerPathStatusReducerProvider
		}
	]
})
export class CrawlerPathStatusCoreModule {

}
