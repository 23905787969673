import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type InterestStore = ModelMap<Interest>;

export const initialInterestStore: InterestStore = <InterestStore>Map();

export const InterestConversion: ActionConverter.Config = {};

export interface InterestModel extends StoreModel {
	keywordId: number;
	imageId: number;
}

export class Interest extends Record({
	id: 0,
	rev: 0,
	keywordId: 0,
	imageId: 0

}) implements InterestModel {

	id: number;
	rev: number;
	keywordId: number;
	imageId: number;

	get displayName(): string {
		return this.id?.toString();
	};
}
