<form (ngSubmit)="login()" [formGroup]="form" tsNgRootFormGroup>
	<tsng-input
		formControlName="email"
		i18n="Label|The email address input label@@TsNgLoginFormEmailInputLabel"
		i18n-placeholder="Placeholder|The email address input placeholder@@TsNgLoginFormEmailInputPlaceholder"
		placeholder="Please provide your email address"
		type="email"
	>
		Email address
	</tsng-input>
	<tsng-input
		formControlName="password"
		i18n="Label|The password input label@@TsNgLoginFormPasswordInputLabel"
		i18n-placeholder="Placeholder|The password input placeholder@@TsNgLoginFormPasswordInputPlaceholder"
		placeholder="Please provide your password"
		type="password"
	>
		Password
	</tsng-input>
	<tsng-checkbox
		formControlName="rememberMe"
		i18n="Label|The remember me placeholder@@TsNgLoginFormRememberMeInputLabel"
	>
		Keep me signed in (within this browser)
	</tsng-checkbox>
	<button i18n="Button|The login button text@@TsNgLoginFormRememberLoginButton" type="submit">
		Login
	</button>
</form>
