import { Injectable } from "@angular/core";
import { createSelector, select, State, Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { NEVER } from "rxjs";
import { catchError, filter, first, map, switchMap } from "rxjs/operators";
import { EntityRevisionState, FilteredAction } from "../action/filtered";
import { GetAction } from "../action/get";
import { ReceivedAction } from "../action/received";

@Injectable({
	providedIn: "root"
})
export class SyncHandler {
	address = "store/sync";
	private logger = LoggerLocator.getLogger("SyncHandler")();

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilteredAction>(this.address)
			.pipe(map((message: Message<FilteredAction>) => this.parseFilterAction(message.body)))
			.subscribe(idsToSync => {
				idsToSync.forEach(ids => {
					this.syncIds(ids);
				});
			}, error => {
				this.logger.error(error);
			});
	}

	private syncIds(ids: { entity: string, ids: EntityRevisionState[] }) {
		this.store.pipe(select(this.createToRetrieveSelector(ids.entity)(ids.ids)),
			first(),
			filter(idsToRetrieve => idsToRetrieve.length > 0),
			map(idsToRetrieve => this.createGetAction(ids.entity, idsToRetrieve)),
			switchMap(getAction => this.eventBus.request<unknown, ReceivedAction>(getAction.type, getAction)),
			catchError(error => {
				this.logger.error(error);
				return NEVER;
			})
		).subscribe(receivedMessage => {
			this.store.dispatch(receivedMessage.body);
		});
	}

	// noinspection JSMethodCanBeStatic
	private createGetAction(entity: string, ids: (number|string)[]): GetAction {
		return {
			type: entity + "/get",
			data: {
				ids: ids
			}
		} as any;
	}

	private parseFilterAction(action: FilteredAction): { entity: string, ids: EntityRevisionState[] }[] {
		return Object.keys(action["data"]).reduce((acc, key) => {
			acc.push({
				entity: key === "items" ? this.getEntityFromAction(action) : key,
				ids: action["data"][key]
			});
			return acc;
		}, []);
	}

	// noinspection JSMethodCanBeStatic
	private getEntityFromAction(action: Action): string {
		return action.type.split("/")[0];
	}

	private createToRetrieveSelector(entityName: string) {
		return (props: EntityRevisionState[]) => createSelector(this.getStoreSelector(entityName),
			(state) => {
					if (state == null) {
						this.logger.fatal(`No state found for entity: ${entityName}. this could mean that the ${entityName}CoreModule is missing`);
					}
					return props
						.filter(idObj => (state.has(idObj.id) === false || (state.has(idObj.id) && state.get(
							idObj.id).rev < idObj.rev)))
						.map(idObj => idObj.id);
			});
	}

	private getStoreSelector(entityName: string) {
		return (state: State<unknown>) => state[entityName];
	}
}
