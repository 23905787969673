import { Hint, HintLevel } from "@tsng/form";

export function arrayMinLength(minLength: number): Hint {
	return {
		level: HintLevel.ERROR,
		name: "arrayMinLength",
		message: $localize`:Validator|The default array length validator@@validatorMinLength:${minLength}:INTERPOLATION: items must be selected`,
		trigger: control => {
			return control.value == null || control.value.length < minLength;
		}
	};
}
