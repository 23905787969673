import { TsAbstractControl } from "../abstract";
import { TsFormArray } from "./array";
import { TsFormControl } from "./control";
import { TsFormGroup } from "./group";

export function isTsFormArray<T extends TsAbstractControl>(control: any): control is TsFormArray<T> {
	return control.type === "array";
}

export function isTsFormGroup(control: any): control is TsFormGroup {
	return control.type === "group";
}

export function isTsFormControl(control: any): control is TsFormControl {
	return control.type === "control";
}
