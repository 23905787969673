import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { SplashScreenComponent } from "./component";

@NgModule({
	declarations: [SplashScreenComponent],
	exports: [
		SplashScreenComponent
	],
	imports: [
		CommonModule, MatIconModule, RouterModule
	]
})

export class SplashScreenModule {
}

