import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TsNgChangeEmailForm } from "@tsng/account";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { AppModal } from "../../component/modal/component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<TsNgChangeEmailForm>>,
	componentRef: ComponentRef<TsNgChangeEmailForm>,
	eventBus: EventBus
) {
	componentRef.instance.changed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		dialogRef.close();
		const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
			message: $localize`:Success toast|Email changed successfully@@EmailChangedSuccessfully:Your email was updated successfully.`,
			level: SnackbarLevel.SUCCESS
		});
		eventBus.send(action.type, action);
	});
}

export const changeEmailFormModalRoute: ModalRoute<TsNgChangeEmailForm> = {
	path: "account/change-email",
	outlet: "modal",
	component: AppModal,
	data: {
		title: $localize`:Modal title|The title of email changed form@@EmailChangedFormTitle:Your email has been changed`,
		componentLoader: () => import("@tsng/account").then(m => m.TsNgChangeEmailForm),
		setupComponentHooks
	}
};
