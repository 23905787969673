import { Hint, HintLevel } from "@tsng/form";

export function notEmpty(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "required",
		message: $localize`:Validator|The default not empty validator@@validatorNotEmpty:This field cannot be empty`,
		trigger: control => {
			return (control.value == null || control.value?.trim() === "");
		}
	};
}
