import { InjectionToken, NgModule, Type } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { Action } from "@tsng/core";
import {
	ActionConverter,
	ModelMap,
	reducerFactory, ReducerHandlers,
	StoreModel,
	TsNgStoreModule
} from "@tsng/store";
import { initialMyListItemStore, MyListItem, MyListItemConversion } from "./model";

export const MY_LIST_ITEM_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("MyListItemReducer");

export function myListItemReducerProvider() {
	return reducerFactory("myListItem", initialMyListItemStore, MyListItem, MyListItemConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("myListItem", MY_LIST_ITEM_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["myListItem/deleted", "myListItem/updated", "myListItem/created", "myListItem/clear"])
	],
	providers: [
		{
			provide: MY_LIST_ITEM_REDUCER_TOKEN,
			useFactory: myListItemReducerProvider
		}
	]
})
export class MyListItemCoreModule {

}

