import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"$nnull: <<field>>
 * }
 */
export class NotNullOperator implements Operator {
	constructor(private field: string) {
	}

	build(): object {
		return {
			"$nnull": this.field
		};
	}

	clone(): NotNullOperator {
		return new NotNullOperator(this.field);
	}
}