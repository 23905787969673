import { InjectionToken } from "@angular/core";
import { TwentelyAccount } from "@twly/core";
import { Observable } from "rxjs";

export const CONNECTION_TARGETS = new InjectionToken<ConnectionTarget[]>("ConnectionTargets");

export enum ConnectionStatus {
	DEFAULT = "default",
	CONNECTING = "connecting",
	CONNECTED = "connected",
	DISCONNECTING = "disconnecting",
	DISCONNECTED = "disconnected"
}

export interface ConnectionTarget {
	connect(principal: TwentelyAccount, accessToken: string): Observable<unknown>;

	canConnect(principal: TwentelyAccount): boolean;

	pushEnabled(): boolean;

	connectionStatus(): Observable<ConnectionStatus>
}
