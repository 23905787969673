import { Logger, LoggerLocator } from "@tsng/logging";
import { merge, NEVER, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { EventBus } from "../event-bus/event-bus";
import { ErrorMessage } from "../event-bus/message/error-message";
import { Message, MessageObject } from "../event-bus/message/message";

export interface EventBusBridgeOptions {
	allowedInbound: (string | RegExp)[],
	allowedOutbound: string[],
	enableRegister: boolean
}

export abstract class AbstractEventBusBridge {
	protected logger: Logger = LoggerLocator.getLogger("AbstractEventBusBridge")();
	private allowedInbound: (string | RegExp)[] = []; // can be regex
	private allowedOutbound: string[] = []; // must be addresses

	protected constructor(protected eventBus: EventBus, protected options: EventBusBridgeOptions) {
		this.allowedInbound = options.allowedInbound;
		this.allowedOutbound = options.allowedOutbound;
		merge(this.listenToEventBus(), options.enableRegister ? this.listenToRegister() : NEVER).subscribe();
	}

	public sendOverEventBus<T = unknown>(message: MessageObject<T>) {
		if (this.matchesInbound(message.address) === false) {
			this.logger.warning(`Message for address '${message.address}' rejected!`, {message});
			if (message.hasOwnProperty("replyAddress")) {
				const error = new ErrorMessage({
					address: message.replyAddress,
					failureType: "ACCESS_DENIED",
					failureCode: 403,
					message: "access denied"
				});
				this.sendMessage(error as any);
			}
			return;
		}

		this.eventBus.sendMessageObject(message);
	}

	abstract sendMessage<T = unknown>(message: Message<T>);

	private listenToEventBus(): Observable<Message<unknown>> {
		const observables: Observable<Message<unknown>>[] = this.allowedOutbound.map(address => this.eventBus.localConsumer(
			address).pipe(catchError(error => of(error))));
		return merge(...observables).pipe(tap(message => {
			this.sendMessage(message);
		}));
	}

	private listenToRegister(): Observable<Message<unknown>> {
		return this.eventBus.registrationConsumer().pipe(tap(message => {
			this.sendMessage(message);
		}));
	}

	private matchesInbound(address: string) {
		if (this.allowedInbound.includes(address)) {
			return true;
		}
		return this.allowedInbound.some(allowed => (new RegExp(allowed).test(address)));
	}

}
