import { Field } from "./field";
import { Relation } from "./relation";

export class Entity {
	constructor(
		private readonly name: string,
		private readonly fields: Field[],
		private readonly relations: Relation[]
	) {
	}

	getName(): string {
		return this.name;
	}

	getFields(): Field[] {
		return this.fields;
	}

	findFieldByName(fieldName: string): Field | undefined {
		return this.fields.find(field => field.getName() === fieldName);
	}

	getRelations(): Relation[] {
		return this.relations;
	}

	findRelationByIdentifier(relationIdentifier: string): Relation | undefined {
		return this.relations.find(relation => relation.getIdentifier() === relationIdentifier);
	}

	findRelationByName(entityName: string): Relation | undefined {
		return this.relations.find(relation => relation.getTargetName() === entityName);
	}
}
