import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialUserGroupMemberStore, UserGroupMember, UserGroupMemberConversion } from "./model";

export const USER_GROUP_MEMBER_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("UserReducer");

export function userGroupMemberReducerProvider() {
	return reducerFactory("userGroupMember", initialUserGroupMemberStore, UserGroupMember, UserGroupMemberConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("userGroupMember", USER_GROUP_MEMBER_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["userGroupMember/deleted", "userGroupMember/updated", "userGroupMember/created"])
	],
	providers: [
		{
			provide: USER_GROUP_MEMBER_REDUCER_TOKEN,
			useFactory: userGroupMemberReducerProvider
		}
	]
})
export class UserGroupMemberCoreModule {

}
