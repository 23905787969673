import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
// import { environment } from "../../../environments/environment";

export type UserStore = ModelMap<User>;

export const initialUserStore: UserStore = <UserStore>Map();

export const UserConversion: ActionConverter.Config = {
	lastLogin: ActionConverter.stringToDate(new Date())
};

export interface UserModel extends StoreModel {
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	city: string;
	gender: string;
	email: string;
	phone: string;
	authRoleId: number;
	active: true;
	ppId: number | null;
	parentId: number | null;
	nodeType: string;
	locale: string;
	lastLogin: Date;
	ageCategory: string;
	settings: object;
}

export class User extends Record({
	id: 0,
	rev: 0,
	initials: "",
	firstName: "",
	infix: "",
	surname: "",
	city: "",
	gender: "",
	email: "",
	phone: "",
	authRoleId: 10,
	active: true,
	ppId: null,
	parentId: null,
	nodeType: "",
	locale: "",
	lastLogin: Date,
	ageCategory: "",
	settings: {}
}) implements UserModel {
	id: number;
	rev: number;
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	city: string;
	gender: string;
	email: string;
	phone: string;
	authRoleId: number;
	active: true;
	ppId: number | null;
	parentId: number | null;
	nodeType: string;
	locale: string;
	lastLogin: Date;
	ageCategory: string;
	settings: object;

	get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

	get hasProfilePicture(): boolean {
		return (this.ppId == null || this.ppId === 0) === false;
	}

	get profilePictureSrc(): string {
		if (this.ppId == null || this.ppId === 0) {
			return `NO-PROFILE-PICTURE`;
		} else {
			return `${this.ppId}/100x100`;
		}
	}
}
