import { ValidatorFn } from "@angular/forms";
import { TsAbstractControl } from "./abstract";

export enum HintLevel {
	ERROR = "ERROR", WARNING = "WARNING", SUCCESS = "SUCCESS", INFO = "INFO"
}

export type HintResults = { [key: string]: { level: HintLevel, message: string } } | null
export type HintTrigger = (control: TsAbstractControl) => boolean;
export type Hint = { level: HintLevel, message: string, trigger: HintTrigger, name: string }
export type Hints = Hint[];

export function getHintResult(control: TsAbstractControl, hints: Hints) {
	if (hints == null) {
		return null;
	}

	return hints.reduce((aggregatedResult: HintResults, hint) => {
		if (hint.trigger(control) === false) {
			return aggregatedResult;
		}

		// when we find the first hint we turn this into an object.
		if (aggregatedResult == null) {
			aggregatedResult = {};
		}

		aggregatedResult[hint.name] = {
			level: hint.level,
			message: hint.message
		};
		return aggregatedResult;
	}, null);
}

export function getValidatorFunctionsFromHints(hints: Hints): ValidatorFn[] {
	if (hints == null) {
		return null;
	}

	return hints.filter(hint => hint.level === HintLevel.ERROR)
		.map(hint => (control: TsAbstractControl) => {
			if (hint.trigger(control) === false) {
				return null;
			}

			const errorObject = {};
			errorObject[hint.name] = true;
			return errorObject;
		});
}
