import { Injectable } from "@angular/core";
import { createSelector, select, State, Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { FilterAction, FilteredAction, FilterResult, ReceivedAction } from "@tsng/store";
import { EntityRevisionState } from "@tsng/store/lib/action/filtered";
import { catchError, filter, first, map, switchMap, tap } from "rxjs/operators";
import { EsFilteredAction } from "./es-filtered-action";

@Injectable({
	providedIn: "root"
})
export class EsFilterHandler {
	address = "es-entity/filter";
	private logger = LoggerLocator.getLogger("EsFilterHandler")();

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilterAction>(this.address)
			.subscribe(message => {
				this.handleLocalFilter(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalFilter(message: Message<FilterAction>) {
		const data = message.body.data;
		const clone = Object.assign<unknown, any>({}, data);
		const entityPrefix = message.body.type.split("/")[0];
		clone.filter = data.filter.build();
		const filterAction = new Action(`${entityPrefix}/filter`, clone);

		this.eventBus.request<FilterAction, EsFilteredAction>(filterAction.type, filterAction)
			.pipe(tap((filterResult) => {
				const body = Object.assign({}, filterResult.body.data);
				delete body.count;
				const syncBody = this.createReceivedActionsForCompleteEntities(entityPrefix, body);
				this.eventBus.send<FilteredAction>("store/sync", new Action(filterResult.body.type, syncBody));
			}))
			.subscribe(filterResult => {
				const ids = filterResult.body.data.items.map(item => item.id) as string[] | number[];
				const totalCount = filterResult.body.data.count;
				message.reply<FilterResult>(new Action("entity/filtered", {
					ids,
					totalCount
				}));
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}

	private createReceivedActionsForCompleteEntities(mainEntity, messageBody): {
		items: EntityRevisionState[];
		[entityName: string]: EntityRevisionState[];
	} {
		const newBody = {};
		Object.keys(messageBody).forEach(entity => {
			if(messageBody[entity].length > 0 && Object.keys(messageBody[entity][0]).filter(v => v !== "id" && v!=="rev").length > 0) {
				//todo create custom store address for this!!!!
				this.hydrateStore(entity === "items" ? mainEntity : entity, messageBody[entity]);
				newBody[entity] = [];
				return;
			}
			newBody[entity] = messageBody[entity];
		});
		return newBody as {
			items: EntityRevisionState[];
			[entityName: string]: EntityRevisionState[];
		};
	}

	private hydrateStore(entity: string, items) {
		this.store.pipe(select(this.createToRetrieveSelector(entity)(items)),
			first(),
		).subscribe(toRetrieveIds => {
			if(items.length === 0) return;
			this.eventBus.send<FilteredAction>("store/principal-changed", new Action(entity + "/received", {items: items.filter(item => toRetrieveIds.indexOf(item.id) >= 0)}));
		});
	}

	private createToRetrieveSelector(entityName: string) {
		return (props: EntityRevisionState[]) => createSelector(this.getStoreSelector(entityName),
			(state) => {
				if (state == null) {
					this.logger.fatal(`No state found for entity: ${entityName}. this could mean that the ${entityName}CoreModule is missing`);
				}
				return props
					.filter(idObj => (state.has(idObj.id) === false || (state.has(idObj.id) && state.get(
						idObj.id).rev < idObj.rev)))
					.map(idObj => idObj.id);
			});
	}

	private getStoreSelector(entityName: string) {
		return (state: State<unknown>) => state[entityName];
	}
}
