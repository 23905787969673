import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { state } from "./reducer/reducer";

export const STATE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CompanyReducer");

export function stateReducerProvider() {
	return state;
}

@NgModule({
	imports: [
		StoreModule.forFeature("$tsng_common_state", STATE_REDUCER_TOKEN)
	],
	providers: [
		{
			provide: STATE_REDUCER_TOKEN,
			useFactory: stateReducerProvider
		}
	]
})
export class TsNgStateModule {
}
