import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Action, EventBus } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { AccountProvider } from "@twly/core";

@Injectable({
	providedIn: "root"
})
export class RouteLoggingGuard implements CanActivate {

	private readonly logger = LoggerLocator.getLogger("RouteLoggingGuard")();

	constructor(
		private accountProvider: AccountProvider,
		private router: Router,
		private eventBus: EventBus
	) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const url = this.getResolvedUrl(route);
		// this.eventBus.send("page/change", new Action("page/change", {url: url}));
		return true;
	}

	getResolvedUrl(route: ActivatedRouteSnapshot): string {
		let url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join("/"))
			.join("/");
		const queryParam = route.queryParamMap;
		if (queryParam.keys.length > 0) {
			url += "?" + queryParam.keys.map(key => queryParam.getAll(key)
				.map(value => key + "=" + value)
				.join("&")).join("&");
		}
		return url;
	}
}
