import { Injectable } from "@angular/core";
import { EventBus } from "@tsng/core";
import { FilterProvider } from "../filter/provider";
import { Schema } from "../schema/schema";
import { SelectorProvider } from "../selector/selector-provider";
import { SourceBuilder } from "./builder";

@Injectable({
	providedIn: "root"
})
export class SourceProvider {
	constructor(
		private eventBus: EventBus,
		private schema: Schema,
		private filterProvider: FilterProvider,
		private selectorProvider: SelectorProvider
	) {
	}

	getBuilder(): SourceBuilder {
		return new SourceBuilder(this.eventBus, this.schema)
			.setFilterBuilder(this.filterProvider.getBuilder())
			.setSelectorBuilder(this.selectorProvider.getBuilder());
	}
}
