import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CrawlerAddressLookupStore = ModelMap<CrawlerAddressLookup>;

export const initialCrawlerAddressLookupStore: CrawlerAddressLookupStore = <CrawlerAddressLookupStore>Map();

export const CrawlerAddressLookupConversion: ActionConverter.Config = {};

export interface CrawlerAddressLookupModel extends StoreModel {
	crawlerDomainId: number | null;
	pathRegex: string;
	extractMechanism: string;
	orderNo: number;
	fieldLookup: string;
}

export class CrawlerAddressLookup extends Record({
	id: 0,
	rev: 0,
	crawlerDomainId: null,
	pathRegex: "",
	extractMechanism: "",
	orderNo: 0,
	fieldLookup: "{ }"
}) implements CrawlerAddressLookupModel {
	id: number;
	rev: number;
	crawlerDomainId: null;
	pathRegex: string;
	extractMechanism: string;
	orderNo: 0;
	fieldLookup: "{ }";

	get displayName(): string {
		return this.crawlerDomainId;
	};
}
