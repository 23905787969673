import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerUrlLink, CrawlerUrlLinkConversion, initialCrawlerUrlLinkStore } from "./model";

export const CRAWLERURLLINK_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CrawlerUrlLinkReducer");

export function crawlerUrlLinkReducerProvider() {
	return reducerFactory("crawlerUrlLink", initialCrawlerUrlLinkStore, CrawlerUrlLink, CrawlerUrlLinkConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerUrlLink", CRAWLERURLLINK_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["crawlerUrlLink/deleted", "crawlerUrlLink/updated", "crawlerUrlLink/created"])
	],
	providers: [
		{
			provide: CRAWLERURLLINK_REDUCER_TOKEN,
			useFactory: crawlerUrlLinkReducerProvider
		}
	]
})
export class CrawlerUrlLinkCoreModule {

}
