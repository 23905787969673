import { EventBus, Message } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { BehaviorSubject, Observable, OperatorFunction, ReplaySubject } from "rxjs";
import { debounceTime, finalize, map, publishReplay, refCount, switchMap, tap } from "rxjs/operators";
import { FilterAction, FilterCleanableProperties, Sort, SortDirection } from "../../action/filter";
import { FilterActionBuilder } from "../../filter/action-builder";
import { FilterResult } from "../../filter/filter-result";
import { Operator } from "../../filter/operator/operator";
import { Source, SourceStatus } from "../source";

export class DefaultSource<T, D> implements Source {
	protected filterActionBuilder: FilterActionBuilder;
	private logger: Logger = LoggerLocator.getLogger("DefaultSource")();
	private readonly selectorOperator: () => OperatorFunction<number[] | string[], D>;
	private readonly sourceUpdates: ReplaySubject<void> = new ReplaySubject<void>(1);
	private readonly doRefresh: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
	private data: Observable<D>;
	private count: Observable<number>;
	private statusSubject = new BehaviorSubject<SourceStatus>(SourceStatus.INITIAL);

	constructor(protected eventBus: EventBus,
		private filterHandlerAddress: string,
		filterActionBuilder: FilterActionBuilder,
		selectorOperator: () => OperatorFunction<any, any>
	) {
		this.filterActionBuilder = filterActionBuilder;
		this.selectorOperator = selectorOperator;
	}

	addOperator(identifier: string, operator: Operator): this {
		return this.updateFilterAction("addOperator", identifier, operator);
	}

	addSort(sort: Sort): this {
		return this.updateFilterAction("addSort", sort);
	}

	adjustSort(fieldName: string, direction: SortDirection): this {
		return this.updateFilterAction("adjustSort", fieldName, direction);
	}

	countChanged(): Observable<number> {
		if (this.count == null) {
			this.createSourceStream();
		}
		return this.count;
	}

	dataChanged(): Observable<D> {
		if (this.data == null) {
			this.createSourceStream();
		}
		return this.data;
	}

	statusChanged(): Observable<SourceStatus> {
		return this.statusSubject.asObservable();
	}

	getLimit(): Readonly<number> {
		return this.filterActionBuilder.getLimit();
	}

	getOffset(): Readonly<number> {
		return this.filterActionBuilder.getOffset();
	}

	getOperators(): Readonly<Map<string, Operator>> {
		return this.filterActionBuilder.getOperators();
	}

	getParams(): Readonly<unknown> {
		return this.filterActionBuilder.getParams();
	}

	getSearch(): Readonly<string> {
		return this.filterActionBuilder.getSearch();
	}

	getSort(): Readonly<Sort[]> {
		return this.filterActionBuilder.getSort();
	}

	refresh(): void {
		this.doRefresh.next();
	}

	removeOperator(fieldName: string): this {
		return this.updateFilterAction("removeOperator", fieldName);
	}

	setLimit(limit: number): this {
		return this.updateFilterAction("setLimit", limit);
	}

	setOffset(offset: number): this {
		return this.updateFilterAction("setOffset", offset);
	}

	setOperators(operators: Map<string, Operator>): this {
		return this.updateFilterAction("setOperators", operators);
	}

	setParams(params: unknown): this {
		return this.updateFilterAction("setParams", params);
	}

	setSearch(query: string): this {
		return this.updateFilterAction("setSearch", query);
	}

	setSort(sort: Sort[]): this {
		return this.updateFilterAction("setSort", sort);
	}

	sourceUpdated(): Observable<void> {
		return this.sourceUpdates;
	}

	clone(filterHandlerAddress?: string): Source {
		return new DefaultSource<unknown, T>(this.eventBus,
			filterHandlerAddress == null ? this.filterHandlerAddress : filterHandlerAddress,
			this.filterActionBuilder.clone(),
			this.selectorOperator
		);
	}

	clean(propertiesToClean: FilterCleanableProperties = ["search", "sort", "operators", "params"]): this {
		return this.updateFilterAction("clean", propertiesToClean);
	}

	private createSourceStream() {
		const baseStream: Observable<Message<FilterResult>> = this.doRefresh.pipe(debounceTime(10),
			tap(() => {
				this.statusSubject.next(SourceStatus.LOADING);
				this.logger.debug("Source was refreshed");
			}),
			map(() => this.filterActionBuilder.build()),
			tap(action => this.logger.debug("Action was build", {action})),
			switchMap(action => this.eventBus.request<FilterAction, FilterResult>(this.filterHandlerAddress,
				action
			)),
			finalize(() => this.logger.debug("Datastream was closed")),
			publishReplay(1),
			refCount()
		);

		this.count = baseStream.pipe(map(message => message.body.data.totalCount),
			tap(totalCount => this.logger.debug("Total count changed", {totalCount})),
			publishReplay(1),
			refCount()
		);
		this.data = baseStream.pipe(map(message => message.body.data),
			tap((data) => this.logger.debug("Ids changed", {data})),
			this.selectorOperator(),
			tap((data) => {
				this.statusSubject.next(SourceStatus.STABLE);
				this.logger.debug("Data changed", {data})
			}),
			publishReplay(1),
			refCount()
		);
	}

	private updateFilterAction(method: keyof FilterActionBuilder, ...args: any[]): this {
		this.filterActionBuilder = this.filterActionBuilder[method].call(this.filterActionBuilder, ...args);
		this.sourceUpdates.next();
		this.refresh();
		return this;
	}
}
