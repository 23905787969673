import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialKeywordStore, Keyword, KeywordConversion } from "./model";

export const KEYWORD_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("KeywordReducer");

export function keywordReducerProvider() {
	return reducerFactory("keyword", initialKeywordStore, Keyword, KeywordConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("keyword", KEYWORD_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["keyword/deleted", "keyword/updated", "keyword/created"])
	],
	providers: [
		{
			provide: KEYWORD_REDUCER_TOKEN,
			useFactory: keywordReducerProvider
		}
	]
})
export class KeywordCoreModule {

}
