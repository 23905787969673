import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { EventBus } from "../../event-bus/event-bus";
import { Message } from "../../event-bus/message/message";
import { Socket, SocketStates } from "../../socket/socket";
import { AbstractEventBusBridge, EventBusBridgeOptions } from "../abstract-event-bus-bridge";

export const SOCKET_EVENT_BUS_BRIDGE_OPTIONS = new InjectionToken<EventBusBridgeOptions>(
	"SocketEventBusBridgeOptions");

export const SOCKET_EVENT_BUS_BRIDGE_OPTIONS_PROVIDERS = {
	provide: SOCKET_EVENT_BUS_BRIDGE_OPTIONS,
	useValue: {
		allowedInbound: [],
		allowedOutbound: [],
		enableRegister: false
	}
};

@Injectable({
	providedIn: "root"
})
export class SocketEventBusBridge extends AbstractEventBusBridge {
	protected logger: Logger = LoggerLocator.getLogger("SocketEventBusBridge")();

	constructor(
		protected eventBus: EventBus,
		private socket: Socket,
		@Inject(SOCKET_EVENT_BUS_BRIDGE_OPTIONS) options: EventBusBridgeOptions
	) {
		super(eventBus, options);
		this.listenToSocket();
	}

	sendMessage<T = unknown>(message: Message<T>) {
		if (this.socket.status !== SocketStates.OPEN) {
			this.logger.fatal("Cannot send message because socket is not open!", {
				message
			});
			return;
		}
		this.socket.send(message.toJSON());
	}

	private listenToSocket() {
		this.socket.messages.subscribe(message => {
			this.sendOverEventBus(message);
		});
	}
}
