import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type TicketStore = ModelMap<Ticket>;

export const initialTicketStore: TicketStore = <TicketStore>Map();

export const TicketConversion: ActionConverter.Config = {};

export interface TicketModel extends StoreModel {
	type: string;
	parentId: number | null;
	threadSeq: number | null;
	userId: number;
	userGroupId: number;
	siteId: number;
	siteActivityId: number;
	subject: string;
	message: string;
	timestamp: string;
	status: string;
	data: object;
}

export class Ticket extends Record({
	id: 0,
	rev: 0,
	type: "",
	parentId: null,
	threadSeq: null,
	userId: null,
	userGroupId: null,
	siteId: null,
	siteActivityId: null,
	subject: "",
	message: "",
	timestamp: "",
	status: "",
	data: {}

}) implements TicketModel {

	id: number;
	rev: number;
	type: string;
	parentId: number | null;
	threadSeq: number | null;
	userId: number;
	userGroupId: number;
	siteId: number;
	siteActivityId: number;
	subject: string;
	message: string;
	timestamp: string;
	status: string;
	data: object;

	get displayName(): string {
		return this.subject;
	};
}
