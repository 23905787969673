import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CrawlerDomainStore = ModelMap<CrawlerDomain>;

export const initialCrawlerDomainStore: CrawlerDomainStore = <CrawlerDomainStore>Map();

export const CrawlerDomainConversion: ActionConverter.Config = {};

export enum CrawlerDomainStatus {
	UNDEFINED, ALLOWED, BLOCKED
}

export interface CrawlerDomainModel extends StoreModel {
	domain: string;
	protocol: string;
	status: number;
	pathPriority: string;
}

export class CrawlerDomain extends Record({
	id: 0,
	rev: 0,
	domain: "",
	protocol: "",
	status: CrawlerDomainStatus.UNDEFINED,
	pathPriority: ""

}) implements CrawlerDomainModel {
	id: number;
	rev: number;
	domain: string;
	protocol: string;
	status: CrawlerDomainStatus;
	pathPriority: string;

	get displayName(): string {
		return this.domain;
	};

	get domainStatus(): string {
		switch (this.status) {
			case CrawlerDomainStatus.UNDEFINED:
				return $localize`:Domain status undefined|The undefined status of crawler domain@@CrawlerDomainModel:UNDEFINED`;
			case CrawlerDomainStatus.ALLOWED:
				return $localize`:Domain status allowed|The allowed status of crawler domain@@CrawlerDomainModel:ALLOWED`;
			case CrawlerDomainStatus.BLOCKED:
				return $localize`:Domain status blocked|The blocked status of crawler domain@@CrawlerDomainModel:BLOCKED`;
		}
	}
}
