<fieldset [ngClass]="{'disabled': _formControl.disabled === true, 'read-only': readonly}">
	<label [for]="id">
		<input
			(blur)="onFocus()"
			[formControl]="_formControl"
			[hidden]="hidden"
			[id]="id"
			[readOnly]="readonly"
			type="checkbox"
		>
		<span id="checkmark"></span>

		<ng-content></ng-content>
		<ng-container *ngIf="isRequired()">*</ng-container>
	</label>
	<div *ngIf="getHintResults() != null" id="hints">
		<p *ngFor="let item of getHintResults() | keyvalue" [ngClass]="item.value.level" class="hint">
			{{item.value.message}}
		</p>
	</div>
</fieldset>