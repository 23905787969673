import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageProxyDirective } from "./directive";

@NgModule({
	imports: [CommonModule],
	declarations: [ImageProxyDirective],
	exports: [ImageProxyDirective]
})
export class ImageProxyModule {
}
