import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TsNgAccountModule } from "@tsng/account";
import { ClickAnimationModule } from "../click-animation/module";
import { LoginModalComponent } from "./component";

@NgModule({
	declarations: [
		LoginModalComponent
	],

	exports: [
		LoginModalComponent
	],

	imports: [
		CommonModule, ClickAnimationModule, MatIconModule, RouterModule, TsNgAccountModule
	]
})
export class LoginModalModule {
}
