import { Logger, LoggerLocator } from "@tsng/logging";
import { Entity } from "./entity";
import { Relation } from "./relation";
import { Schema } from "./schema";

export interface EntityLinkConfiguration {
	entity: Entity,
	on: string | null,
	as: string,
	relationIdentifier: string | null
}

export interface EntityLink {
	// The source entity
	entity: Entity,
	// The relation from the targets perspective
	relation: Relation | null,
	sourceAlias: string,
	targetAlias: string | null
}

export abstract class AbstractEntityLinkBuilder<E extends EntityLink, F> {
	protected logger: Logger = LoggerLocator.getLogger("AbstractEntityLinkBuilder")();
	protected mainEntityConfiguration: EntityLinkConfiguration;
	protected linkedEntityConfigurations: EntityLinkConfiguration[] = [];
	protected abstract schema: Schema;

	main(entityName: string, config?: { on?: string, as?: string, relationIdentifier?: string }): this {
		const clone = this.clone();
		clone.mainEntityConfiguration = this.createEntityLinkConfiguration(entityName, config);
		return clone;
	}

	link(entityName: string, config?: { on?: string, as?: string, relationIdentifier?: string }): this {
		const clone = this.clone();
		clone.linkedEntityConfigurations = [
			...clone.linkedEntityConfigurations, this.createEntityLinkConfiguration(entityName, config)
		];
		return clone;
	}

	clone(): this {
		return Object.create(this);
	}

	abstract build(): F;

	buildLinks(): Array<E> {
		// Creating the main link
		const mainLink = this.createEntityLink(this.mainEntityConfiguration);
		if (this.linkedEntityConfigurations.length <= 0) {
			return [mainLink];
		}

		return this.createLinkedLinks(mainLink);
	}

	protected createEntityLink(sourceConfiguration: EntityLinkConfiguration,
		targetLink?: EntityLink
	): E | null {
		// When there is no relation specified we return the source as only (used for main links).
		if (targetLink == null) {
			return {
				entity: sourceConfiguration.entity,
				relation: null,
				sourceAlias: sourceConfiguration.as,
				targetAlias: null
			} as E;
		}

		// When sourceConfiguration on is set and doesn't match target (source) alias return null (no match)
		if (sourceConfiguration.on != null && sourceConfiguration.on !== targetLink.sourceAlias) {
			return null;
		}

		let relation: Relation;
		// When the target link is set and has no relationIdentifier on the source
		if (sourceConfiguration.relationIdentifier == null) {
			relation = targetLink.entity.findRelationByName(sourceConfiguration.entity.getName());
		}

		// When the target link is set and has a relationIdentifier on the source
		if (sourceConfiguration.relationIdentifier != null) {
			relation = targetLink.entity.findRelationByIdentifier(sourceConfiguration.relationIdentifier);
		}

		// If no relation is found return null (no match)
		if (relation == null) {
			return null;
		}

		return {
			entity: sourceConfiguration.entity,
			relation: relation,
			sourceAlias: sourceConfiguration.as,
			targetAlias: targetLink.sourceAlias
		} as E;
	};

	private createLinkedLinks(mainLink: E): Array<E> {
		const links = [mainLink];
		const linkedEntityConfigurations = this.linkedEntityConfigurations.slice(0);

		// loop over every linked configuration
		while (linkedEntityConfigurations.length > 0) {
			const preCalculationLength = links.length;

			// iterate over the links
			links.forEach(link => {
				// for each link iterate over the linkedEntityConfigurations
				linkedEntityConfigurations.forEach(config => {
					const potentialLink = this.createEntityLink(config, link);
					if (potentialLink != null) {
						const linkedEntityConfigurationIndex = linkedEntityConfigurations.indexOf(config);
						links.push(potentialLink);
						linkedEntityConfigurations.splice(linkedEntityConfigurationIndex, 1);
					}
				});
			});

			const postCalculationLength = links.length;
			if (preCalculationLength === postCalculationLength) {
				// Ensure we log the appropriate information to the console for the developer.
				const errorString = linkedEntityConfigurations.map(config => {
					let errorString = config.as;
					if (config.on != null) {
						errorString += ` => ${config.on}`;
					}
					if (config.relationIdentifier != null) {
						errorString += ` (with relationIdentifier: ${config.relationIdentifier})`;
					}
					return errorString;
				}).join(", ");

				const logMessage = `No relation found for '${errorString}'. Make sure that the entity exists and that the relational models are provided`;
				this.logger.fatal(logMessage, {
					invalidConfigurations: linkedEntityConfigurations,
					configurations: this.linkedEntityConfigurations
				});
				throw new Error(logMessage);
			}
		}

		return links;
	}

	private createEntityLinkConfiguration(entityName: string,
		config?: { on?: string, as?: string, relation?: string }
	) {
		const entity = this.schema.entity(entityName);
		if (entity == null) {
			this.logger.fatal(`Entity with name: '${entityName}' doesn't exist within schema`);
			throw new Error(`Entity with name: '${entityName}' doesn't exist within schema`);
		}
		return Object.assign({
			entity: entity,
			as: entityName,
			on: null,
			relationIdentifier: null
		}, config);
	}

}
