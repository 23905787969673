import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { esLocation } from "../venue/model";

export type ActivityStore = ModelMap<Activity>;

export const initialActivityStore: ActivityStore = <ActivityStore>Map();

export const ActivityConversion: ActionConverter.Config = {};

export interface ActivityModel extends StoreModel {
	siteActivityId: number;
	siteId: number;
	venueId: string;
	name: string;
	slug: string;
	narrative: string;
	seoTitle: string;
	seoDescription: string;
	inVenue: boolean;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	url: string;
	phone: string;
	email: string;
	location: esLocation;
	audienceIds: string[];
	tagIds: (string | number)[];
	tags: string[];
	useVenueContact: boolean;
	useVenueAddress: boolean;
	useVenueOpeningHours: boolean;
	openingHours: [];
	closed: [];
	presentAtStartTime: boolean;
	reservationRequired: boolean;
	images: [];
	propertyIds: number[];
	errorLevel: number;
	errors: object[];
	datePublished: Date | null;
	dateModified: Date | null;
}

export class Activity extends Record({
	id: 0,
	rev: 0,
	siteActivityId: 0,
	siteId: 0,
	venueId: "",
	name: "",
	slug: "",
	narrative: "",
	seoTitle: "",
	seoDescription: "",
	inVenue: true,
	url: "",
	street: "",
	houseNumber: "",
	zipCode: "",
	city: "",
	phone: "",
	email: "",
	location: {
		lon: 0,
		lat: 0
	},
	audienceIds: [],
	tagIds: [],
	tags: [],
	useVenueContact: true,
	useVenueAddress: true,
	useVenueOpeningHours: false,
	openingHours: [],
	closed: [],
	presentAtStartTime: false,
	reservationRequired: false,
	images: [],
	propertyIds: [],
	errorLevel: 0,
	errors: [],
	datePublished: null,
	dateModified: null

}) implements ActivityModel {
	id: number;
	rev: number;
	siteActivityId: number;
	siteId: number;
	venueId: string;
	name: string;
	slug: string;
	narrative: string;
	seoTitle: string;
	seoDescription: string;
	inVenue: boolean;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	url: string;
	phone: string;
	email: string;
	location: esLocation;
	audienceIds: string[];
	tagIds: (string | number)[];
	tags: string[];
	useVenueContact: boolean;
	useVenueAddress: boolean;
	useVenueOpeningHours: boolean;
	openingHours: [];
	closed: [];
	presentAtStartTime: boolean;
	reservationRequired: boolean;
	images: [];
	propertyIds: number[];
	errorLevel: number;
	errors: object[];
	datePublished: Date | null;
	dateModified: Date | null;

	get displayName(): string {
		return this.name;
	};

	get streetDisplayName(): string {
		return this.street + " " + this.houseNumber;
	}

	get splitNarrative(): string[] {
		return this.narrative.split("\n");
	}

	/**
	 * Returns the full activity slug with city, name and id
	 *
	 * Example:
	 * enschede-some-hot-activity-HJhasdhj13Abdha0s24
	 */
	get fullActivitySlug(): string {
		let slug = this.slug;
		if(slug == null || slug === "") {
			slug = this.name.trim()
		}
		const slugPrefix = encodeURIComponent(`${this.city}-${slug}`.toLowerCase());
		return `${slugPrefix}-${this.id}`;
	}

	get seoTitleDisplay(): string {
		if(this.seoTitle != null && this.seoTitle.trim() !== "") {
			return this.seoTitle;
		}
		return this.name;
	}

	get seoDescriptionDisplay(): string {
		if(this.seoDescription != null && this.seoDescription.trim() !== "") {
			return this.seoDescription;
		}
		return this.narrative.substr(0,80);
	}
}
