import { Injectable } from "@angular/core";
import { EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { tap } from "rxjs/operators";
import { UpdateAction } from "../action/update";
import { UpdatedAction } from "../action/updated";

@Injectable({
	providedIn: "root"
})
export class UpdateHandler {
	address = "entity/update";
	private logger = LoggerLocator.getLogger("UpdateHandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<UpdateAction>(this.address)
			.subscribe(message => {
				this.handleLocalUpdate(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalUpdate(message: Message<UpdateAction>) {
		this.eventBus.request<UpdateAction, UpdatedAction>(message.body.type, message.body)
			.pipe(tap(result => {
				this.eventBus.send(result.body.type, result.body);
			}))
			.subscribe(result => {
				message.reply(result.body);
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
