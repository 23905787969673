import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { FilterAction, FilterResult } from "@tsng/store";
import { tap } from "rxjs/operators";
import { EsFilteredAction } from "../../handlers/es-filter/es-filtered-action";

@Injectable({
	providedIn: "root"
})
export class InterestFilterHandler {
	address = "interest-front/filter";
	private logger = LoggerLocator.getLogger("InterestFilterHandler")();

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<FilterAction>(this.address)
			.subscribe(message => {
				this.handleLocalFilter(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalFilter(message: Message<FilterAction>) {
		const data = message.body.data;
		const clone = Object.assign<unknown, any>({}, data);
		const entityPrefix = message.body.type.split("/")[0];
		clone.filter = data.filter.build();
		const filterAction = new Action(`${entityPrefix}/filter`, clone);

		this.eventBus.request<FilterAction, EsFilteredAction>(filterAction.type, filterAction)
			.pipe(tap((filterResult) => {
				const body = Object.assign({}, filterResult.body.data);
				delete body.count;

				this.store.dispatch(new Action("keyword/received", {
					items: body.keywords
				}));
				this.store.dispatch(new Action("image/received", {
					items: body.images
				}));
				this.store.dispatch(new Action("interest/received", {
					items: body.items
				}));
			}))
			.subscribe(filterResult => {
				const ids = filterResult.body.data.items.map(item => item.id) as string[] | number[];
				const totalCount = filterResult.body.data.count;
				message.reply<FilterResult>(new Action("entity/filtered", {
					ids,
					totalCount
				}));
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
