import { InjectionToken } from "@angular/core";
import { OrderedMap } from "immutable";
import { Observable } from "rxjs";
import { QueuedFile } from "../queued-file/queued-file";
import { ServerFile } from "../server-file/server-file";

export const FILE_UPLOAD_ENDPOINT = new InjectionToken<string>(
	"example.com");

export interface FileSource {

	setEntity(entity: string): void;

	getEntity(): string;

	setGroup(group: string): void;

	getGroup(): string;

	setReferenceId(referenceId: number | string): void;

	getReferenceId(): number | string;

	setFileSequence(fileSequence: number | null): void;

	getFileSequence(): number | string;

	setAcceptedExtensions(extensions: (string)[]): void;

	getAcceptedExtensions(): (string)[],

	setMaxFiles(limit: number);

	getMaxFiles(): number;

	/**
	 * Method which relays the changes to the total count, this updates with every status update or refresh.
	 */
	countChanged(): Observable<number>;

	/**
	 * Method which relays the changes to the source itself, any adjustments made to it trigger this as a
	 * notifier method.
	 */
	sourceUpdated(): Observable<void>;

	/**
	 * Method which relays the data changes, this is where the output comes from it contains the data in
	 * the configured format.
	 */
	dataChanged(): Observable<OrderedMap<number, ServerFile>>;

	refresh(): void;

	queueChanged(): Observable<QueuedFile[]>;

	addToQueue(files: FileList);

	/**
	 * When autoUpload is disabled this method need to be called to start the uploading process
	 */
	startUpload(): void;

	getAutoUpload(): boolean;

	setAutoUpload(value: boolean): void;

	getRemoveWhenFailed(): boolean;

	setRemoveWhenFailed(remove: boolean): void;

	getFileUploadedObservable(): Observable<ServerFile>;

	refreshQueue(): void;

	destroy(): void;
}
