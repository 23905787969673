import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialTileSectionStore, TileSection, TileSectionConversion } from "./model";

export const TILESECTION_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("TileSectionReducer");

export function tileSectionReducerProvider() {
	return reducerFactory("tileSection", initialTileSectionStore, TileSection, TileSectionConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("tileSection", TILESECTION_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["tileSection/deleted", "tileSection/updated", "tileSection/created"])
	],
	providers: [
		{
			provide: TILESECTION_REDUCER_TOKEN,
			useFactory: tileSectionReducerProvider
		}
	]
})
export class TileSectionCoreModule {

}
