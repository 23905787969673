import { Component, Directive, Injector, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Action, EventBus } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import {
	CreateAction,
	CreatedAction,
	EqualOperator,
	SelectorMerger,
	Source,
	SourceBuilder,
	SourceProvider,
	UpdateAction,
	UpdatedAction
} from "@tsng/store";
import { OrderedMap } from "immutable";
import { Observable, ReplaySubject } from "rxjs";
import { filter, map, switchMap, takeUntil } from "rxjs/operators";
import { TsAbstractControl } from "../../..";
import { defaultFormGroupToAction } from "../../form-group-to-action/default";

@Directive()
export abstract class DetailPage implements OnDestroy, OnInit {
	abstract form: TsAbstractControl;
	abstract entity: string;
	protected formToActionFactory: (AbstractControl, string) => object = defaultFormGroupToAction;
	protected logger: Logger = LoggerLocator.getLogger("DetailPage")();
	protected destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
	protected eventBus: EventBus;
	protected activatedRoute: ActivatedRoute;
	private sourceBuilder: SourceBuilder;

	protected constructor(protected injector: Injector) {
		this.activatedRoute = injector.get(ActivatedRoute);
		this.sourceBuilder = injector.get(SourceProvider).getBuilder();
		this.eventBus = injector.get(EventBus);
	}

	ngOnInit(): void {
		this.loadData();
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	onSaveFormIsInvalid() {
		this.logger.warning("Form is not valid and therefore cannot be saved");
		const action = new Action("snackbar/create", {
			message: $localize`:Warning toast|Form invalid@@tsNgDetailPageFormInvalid:Please ensure all fields on the form have been filled out correctly`,
			level: "warning"
		});
		this.eventBus.send(action.type, action);
	}

	onSaveNoChanges() {
		this.logger.warning("There are no changes therefore the form cannot be saved");
		const action = new Action("snackbar/create", {
			message: $localize`:Warning toast|Form was not changed@@tsNgDetailPageNoChanges:There are no changes to save`,
			level: "warning"
		});
		this.eventBus.send(action.type, action);
	}

	abstract onSaveSuccess(action: object);

	abstract onSaveError(error: Error)

	save() {
		if (this.canFormBeSaved() === false) {
			return;
		}
		const action = this.formToActionFactory(this.form, this.entity);
		this.sendAction(action);
	}

	protected setSourceEntities(sourceBuilder: SourceBuilder): SourceBuilder {
		return sourceBuilder.main(this.entity);
	}

	protected loadData() {
		this.activatedRoute.paramMap.pipe(
			takeUntil(this.destroyed),
			map(paramMap => this.getIdParam(paramMap)),
			filter(id => (id > 0)),
			map(id => {
				const initialBuilder = this.getInitialSourceBuilder(id);
				const source = this.setSourceEntities(initialBuilder).build();
				return this.setIdFilter(source, id);
			}),
			switchMap(source => source.dataChanged() as Observable<OrderedMap<unknown, unknown>>),
			map(dataMap => dataMap.first())
		).subscribe(value => {
			this.patchForm(value);
		});
	}

	protected patchForm(data: unknown) {
		this.form.patch(data);
	}

	protected getInitialSourceBuilder(id: number) {
		return this.sourceBuilder
			.setMergeFactory(SelectorMerger.createdNestedMerger);
	}

	protected getIdParam(params: ParamMap) {
		return parseInt(params.get("id"), 10) ? parseInt(params.get("id"), 10) : 0;
	}

	protected canFormBeSaved(): boolean {
		if (this.form.valid === false) {
			this.onSaveFormIsInvalid();
			return false;
		}
		if (this.form.getChanges() == null) {
			this.onSaveNoChanges();
			return false;
		}
		return true;
	}

	protected sendAction(action) {
		this.eventBus.request<CreateAction | UpdateAction, CreatedAction | UpdatedAction>(action.type.includes(
			"update") ? "entity/update" : "entity/create", action)
			.subscribe(value => {
				this.resetFormState();
				this.onSaveSuccess(value.body);
			}, error => {
				this.onSaveError(error);
			});
	}

	protected resetFormState() {
		// this.form.reset();
		this.form.markAsPristine();
		this.form.markAsUntouched();
	}

	protected setIdFilter(source: Source, id: string | number) {
		return source.addOperator("idFilter", new EqualOperator("id", id));
	}
}
