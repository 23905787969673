import { ChangeDetectionStrategy, Component, HostBinding, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { EventBus } from "@tsng/core";
import { SnackbarCreateData, SnackbarLevel } from "../action";

@Component({
	selector: "tsng-snackbar-inner-component",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="data.icon == null; else providedIcon">
			<mat-icon *ngIf="data.level === level.ERROR">report</mat-icon>
			<mat-icon *ngIf="data.level === level.WARNING">priority_high</mat-icon>
			<mat-icon *ngIf="data.level === level.SUCCESS">done</mat-icon>
			<mat-icon *ngIf="data.level === level.INFO">info</mat-icon>
			<mat-icon *ngIf="data.level === level.DEFAULT || data.level == null">emoji_objects</mat-icon>
		</ng-container>
		<ng-template #providedIcon>
			<mat-icon>{{data.icon}}</mat-icon>
		</ng-template>

		<span>{{data.message}}</span>

		<ng-container *ngIf="hasAction">
			<button mat-button (click)="action()">{{data.action.label}}</button>
		</ng-container>
	`,
	styleUrls: ["./component.scss"],
	encapsulation: ViewEncapsulation.None
})
export class SnackbarComponent {
	public level = SnackbarLevel;

	constructor(
		public snackBarRef: MatSnackBarRef<SnackbarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarCreateData,
		private eventBus: EventBus
	) {
	}

	@HostBinding("class") get className(): string {
		return `level-${this.data?.level ?? SnackbarLevel.DEFAULT}`;
	}

	get hasAction(): boolean {
		return !!this.data.action;
	}

	action(): void {
		this.eventBus.send<unknown>(this.data.action.replyAddress, {});
		this.snackBarRef.dismiss();
	}
}
