import { Injectable } from "@angular/core";
import { SelectorProvider } from "@tsng/store";
import { TwentelyAccount } from "@twly/core";
import { OrderedMap } from "immutable";
import { Observable, of, OperatorFunction } from "rxjs";
import { distinctUntilChanged, map, publishReplay, refCount } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class AccountProvider {
	private readonly accountSelector: () => OperatorFunction<{}, OrderedMap<number, { account: TwentelyAccount }>>;

	constructor(private selectorProvider: SelectorProvider) {
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
	}

	getAccountObservable(): Observable<TwentelyAccount> {
		return of({}).pipe(
			this.accountSelector(),
			map(accountMap => accountMap.first().account),
			distinctUntilChanged(),
			publishReplay(1),
			refCount()
		);
	}
}
