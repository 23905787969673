import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$in: <<value[]>>}
 * }
 */
export class InOperator implements Operator {
	constructor(private field: string, private value: any[]) {
	}

	build(): object {
		const object = {};
		object[this.field] = {"$in": this.value};
		return object;
	}

	clone(): InOperator {
		return new InOperator(this.field, this.value.length > 0 ? [...this.value] : []);
	}
}