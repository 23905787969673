import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { first } from "rxjs/operators";
import { EventBus } from "../../event-bus/event-bus";
import { Message } from "../../event-bus/message/message";
import { AbstractEventBusBridge, EventBusBridgeOptions } from "../abstract-event-bus-bridge";

export interface HttpEventBusBridgeOptions extends EventBusBridgeOptions {
	httpEndpoint: string;
}

export const HTTP_EVENT_BUS_BRIDGE_OPTIONS = new InjectionToken<HttpEventBusBridgeOptions>(
	"HttpEventBusBridgeOptions");

export const HTTP_EVENT_BUS_BRIDGE_OPTIONS_PROVIDER = {
	provide: HTTP_EVENT_BUS_BRIDGE_OPTIONS,
	useValue: {
		httpEndpoint: "unknown"
	}
};

@Injectable({
	providedIn: "root"
})
export class HttpEventBusBridge extends AbstractEventBusBridge {
	protected logger: Logger = LoggerLocator.getLogger("SocketEventBusBridge")();
	private endpoint: string;

	constructor(
		protected eventBus: EventBus,
		private httpClient: HttpClient,
		@Inject(HTTP_EVENT_BUS_BRIDGE_OPTIONS) options: HttpEventBusBridgeOptions
	) {
		super(eventBus, options);
		if (options.hasOwnProperty("httpEndpoint") === false) {
			this.logger.fatal(
				"No httpEndpoint specified, please add it to the 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
			throw new Error("No httpEndpoint specified, please add it to the" + " 'SOCKET_EVENT_BUS_BRIDGE_OPTIONS'");
		}
		this.endpoint = options.httpEndpoint;
	}

	sendMessage(message: Message<unknown>) {
		this.httpClient.post<{}>(this.endpoint + message.address, message.body, {
			headers: message.headers,
			withCredentials: true
		}).pipe(first()).subscribe(result => {
			message.reply(result);
		}, error => {
			message.fail(error.status, error.statusText);
		});
	}
}
