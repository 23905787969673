import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { AbstractFieldComponent } from "../abstract-field/component";

@Component({
	selector: "tsng-input",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TsNgInputFieldComponent),
			multi: true

		}
	]
})
export class TsNgInputFieldComponent extends AbstractFieldComponent {
	@Input() type: "email" | "hidden" | "number" | "password" | "text" | "tel" | "url" = "text";
	@Input() inputmode: "none" | "text" | "decimal" | "numeric" | "tel" | "search" | "email" | "url" = "text";
	@Input() name: string = "";
	@Input() iconAtStart: string;
	@Input() iconAtEnd: string;
	@Input() autocomplete: string;

	constructor(injector: Injector) {
		super(injector);
	}

	registerOnChange(fn: any): void {
		if(this.type === "number") {
			this.onChange = (value) => fn(Number(value));
			return;
		}

		this.onChange = fn;
	}
}
