import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalRouteData } from "@twly/common";
import { ReplaySubject } from "rxjs";
import { map, switchMap, take, takeUntil } from "rxjs/operators";
import { AppDialogComponent } from "./dialog/component";

@Component({
	selector: "app-modal",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: ``
})
export class AppModal<T> implements OnInit, OnDestroy {
	data: ModalRouteData<T> | undefined;
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(private activatedRoute: ActivatedRoute, private dialog: MatDialog, private router: Router) {
	}

	ngOnInit() {
		this.activatedRoute.data.pipe(
			takeUntil(this.destroyed),
			map((data: ModalRouteData<T>) => {
				const additionalConfiguration = data.additionalDialogConfig ?? {};
				const configuration = Object.assign({}, additionalConfiguration, {data});
				return this.dialog.open(AppDialogComponent, configuration);
			}),
			switchMap(dialogRef => dialogRef.afterClosed().pipe(take(1))),
			switchMap(() => this.router.navigate([".", {outlets: {modal: null}}], {
				relativeTo: this.activatedRoute.parent,
				queryParamsHandling: "",
				replaceUrl: true
			})),
			takeUntil(this.destroyed)
		).subscribe();
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}
}
