import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ActionTokenPage } from "./page/component";
import { ActionTokenPageResolver } from "./resolver/service";

export const actionTokenRoutes: Routes = [
	{
		path: ":token",
		component: ActionTokenPage,
		resolve: {
			tokenConfig: ActionTokenPageResolver
		}
	}
];
export const actionTokenRoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forChild(actionTokenRoutes);
