import { Validators } from "@angular/forms";
import { Hint, HintLevel } from "./hint";

export function required(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "required",
		message: $localize`:Validator|The default required validator@@tsngFormRequiredHint:This field is required`,
		trigger: control => {
			return (Validators.required(control) != null);
		}
	};
}

export function minLength(length: number): Hint {
	return {
		level: HintLevel.ERROR,
		name: "minLength",
		message: $localize`:Validator|The default minimal length validator@@tsngFormMinimalLengthHint:The length of this field is shorter than the required minimal length ${length}`,
		trigger: control => {
			return (Validators.minLength(length)(control) != null);
		}
	};
}

export function maxLength(length: number): Hint {
	return {
		level: HintLevel.ERROR,
		name: "maxLength",
		message: $localize`:Validator|The default maximum length validator@@tsngFormMaximumLengthHint:The length of this field is larger than the maximum allowed length ${length}`,
		trigger: control => {
			return (Validators.maxLength(length)(control) != null);
		}
	};
}

export function lessThan(number: number): Hint {
	return {
		level: HintLevel.ERROR,
		name: "lessThan",
		message: $localize`:Validator|The default less than validator@@tsngFormLessThanHint:The value should be less than ${number}`,
		trigger: control => {
			return (control.value != null && control.value > number);
		}
	};
}

export function greaterThan(number: number): Hint {
	return {
		level: HintLevel.ERROR,
		name: "greaterThan",
		message: $localize`:Validator|The default greater than validator@@tsngFormGreaterThanHint:The value should be greater than ${number}`,
		trigger: control => {
			return (control.value != null && control.value < number);
		}
	};
}

export function email(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "email",
		message: $localize`:Validator|The default email validator@@tsngFormEmailHint:The provided value isn't a valid email address`,
		trigger: control => {
			return (Validators.email(control) != null);
		}
	};
}
