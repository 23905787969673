import { Validators } from "@angular/forms";
import { Hint, HintLevel } from "@tsng/form";

export function requiredTrue(): Hint {
	return {
		level: HintLevel.ERROR,
		name: "required",
		message: $localize`:Validator|The default required true validator@@validatorRequiredTrue:This must be checked`,
		trigger: control => {
			return (Validators.requiredTrue(control) != null);
		}
	};
}
