import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { ChatMessage, ChatMessageConversion, initialChatMessageStore } from "./model";
import { chatMessageReducer } from "./reducer";

export const CHAT_MESSAGE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("ChatMessageReducer");

export function chatMessageReducerProvider() {
	return reducerFactory("chatmsg", initialChatMessageStore, ChatMessage, ChatMessageConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("chatmsg", chatMessageReducer),
		TsNgStoreModule.forFeature(["chatmsg/deleted", "chatmsg/updated", "chatmsg/created", "chatmsg/batch-updated"])
	],
	providers: [
		{
			provide: CHAT_MESSAGE_REDUCER_TOKEN,
			useFactory: chatMessageReducerProvider
		}
	]
})
export class ChatMessageCoreModule {

}
