import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Action, EventBus } from "@tsng/core";
import { required, TsFormControl, TsFormGroup } from "@tsng/form";
import { Logger, LoggerLocator } from "@tsng/logging";
import { ReplaySubject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { passwordMinLength } from "../../validator";
import { newPasswordsMustBeEqual } from "../change-password-form/validator";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "tsng-confirm-password-reset-form",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class TsNgPasswordResetConfirmation implements OnInit, OnDestroy {
	form = new TsFormGroup({
		token: new TsFormControl("", [required()]),
		newPassword: new TsFormControl("", [required(), passwordMinLength()]),
		newPasswordConfirmation: new TsFormControl("", [required(), newPasswordsMustBeEqual()])
	}, {updateOn: "submit"});
	@Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>(false);
	private readonly logger: Logger = LoggerLocator.getLogger("TsNgPasswordResetConfirmation")();
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(private activatedRoute: ActivatedRoute, private eventBus: EventBus) {
	}

	ngOnInit(): void {
		const snapshot = this.activatedRoute.snapshot;
		this.form.get("token").setValue(snapshot.paramMap.get("token"));
		this.form.get("token").markAsPristine();
	}

	ngOnDestroy() {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	changePassword(): void {
		if (this.form.invalid === true) {
			this.logger.warning("The form is invalid", {form: this.form});
			return;
		}

		const value = this.form.value;
		const action = new Action("actionToken/handle", {
			token: value.token,
			password: value.newPassword,
			passwordConfirmation: value.newPasswordConfirmation
		});
		this.eventBus.request(action.type, action).pipe(takeUntil(this.destroyed)).subscribe(() => {
			this.changed.emit(true);
			this.logger.info("Password was successfully changed");
		}, error => {
			this.logger.error("Unable to change password using token", {
				value,
				error
			});
			const action = new Action("snackbar/create", {
				message: $localize`:Error toast|Confirming password reset failed toast@@tsNgPasswordResetConfirmationChangeFailed:We were unable to save the provided password, please try again.`,
				level: "error"
			});
			this.eventBus.send(action.type, action);
		});
	}
}
