import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type ImageStore = ModelMap<Image>;

export const initialImageStore: ImageStore = <ImageStore>Map();

export const ImageConversion: ActionConverter.Config = {};

export enum ImageResizingPresets {
	SIXTEEN_NINE_THREE_HUNDRED = "16_9_300",
	SIXTEEN_NINE_SIX_HUNDRED = "16_9_600",
	SIXTEEN_NINE_NINE_HUNDRED = "16_9_900",
	SIXTEEN_NINE_TWELVE_HUNDRED = "16_9_1200",
	SIXTEEN_NINE_FIFTEEN_HUNDRED = "16_9_1500",
	SIXTEEN_NINE_EIGHTEEN_HUNDRED = "16_9_1800",
	ONE_ONE_HUNDRED_FORTY_FIVE = "1_1_145",
	ONE_ONE_TWO_HUNDRED = "1_1_200",
	ONE_ONE_THREE_HUNDRED = "1_1_300",
	ONE_ONE_FOUR_HUNDRED = "1_1_400",
	ONE_ONE_FIVE_HUNDRED = "1_1_500"
}

export enum ImageDimension {
	SIXTEEN_NINE = "16x9", ONE_ONE = "1x1"
}

export enum ImageGravityPresets {
	CENTER = "center", SMART = "smart"
}

export type CropPosition = {
	x1: number; y1: number; x2: number; y2: number;
};

export interface ImageModel extends StoreModel {
	imageSetId: number;
	name: string;
	category: string;
	extension: string;
	dimension: string;
	aspectRatio: string;
	keywordId: number | null;
	siteActivityId: number | null;
	cropPosition: CropPosition;
}

export class Image extends Record({
	id: 0,
	rev: 0,
	imageSetId: 0,
	name: "",
	category: "",
	extension: "",
	dimension: "",
	aspectRatio: "",
	keywordId: null,
	siteActivityId: null,
	cropPosition: {
		x1: -1,
		y1: -1,
		x2: -1,
		y2: -1
	}
}) implements ImageModel {
	id: number;
	rev: number;
	imageSetId: number;
	name: string;
	category: string;
	extension: string;
	dimension: string;
	aspectRatio: string;
	keywordId: number | null;
	siteActivityId: number | null;
	cropPosition: CropPosition;

	get displayName(): string {
		return this.name;
	};

	get fileName(): string {
		return this.name + "." + this.extension;
	};

	createImageLink(preset: ImageResizingPresets,
		gravity: ImageGravityPresets = ImageGravityPresets.CENTER
	): string {
		let prefix = "unknown";
		let idString = "000000000000";
		if (this.keywordId != null) {
			prefix = "keyword";
			idString = this.keywordId.toString()?.padStart(9, "0");
		} else if (this.siteActivityId != null) {
			prefix = "siteActivity";
			idString = this.siteActivityId.toString()?.padStart(9, "0");
		}

		const dimension = this.getDimensionForPreset(preset);
		const k1 = idString.substring(0, 3);
		const k2 = idString.substring(3, 6);
		const k3 = idString.substring(6, 9);
		return `${preset}:${gravity}/img/${prefix}/${k1}/${k2}/${k3}/${encodeURIComponent(this.name)}-${dimension}.${this.extension}`;
	}

	createSrcSetLinks(endpoint: string,
		config: { preset: ImageResizingPresets, gravity?: ImageGravityPresets, srcSetWidth: string }[]
	): string {
		return config.reduce((srcSetString, item) => {
			if (srcSetString !== "") srcSetString = srcSetString + ",";
			return srcSetString + `${endpoint}${this.createImageLink(item.preset,
				item.gravity ?? ImageGravityPresets.CENTER
			)} ${item.srcSetWidth}`.trim();
		}, "");
	}

	private getDimensionForPreset(preset: ImageResizingPresets): ImageDimension {
		if (preset.startsWith("1_1")) {
			return ImageDimension.ONE_ONE;
		}

		if (preset.startsWith("16_9")) {
			return ImageDimension.SIXTEEN_NINE;
		}

		return ImageDimension.SIXTEEN_NINE;
	}
}
