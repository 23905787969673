import { BehaviorSubject, Observable } from "rxjs";

export enum QueuedFileStatus {
	IDLE = "idle", READY_TO_UPLOAD = "readToUpload", QUEUED = "queued", UPLOADING = "uploading", DONE = "done", ERROR = "error"
}

export class QueuedFile {
	private progressObservable: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private file: File;
	private fileContent: string;
	private thumbnailSubject = new BehaviorSubject(null);
	private shouldUpload = true;
	private statusSubject = new BehaviorSubject(QueuedFileStatus.IDLE);

	constructor(file: File) {
		this.setFile(file);
	}

	get displayName(): string {
		return this.file.name;
	}

	setShouldUpload(shouldUpload: boolean) {
		this.shouldUpload = shouldUpload;
	}

	getShouldUpload() {
		return this.shouldUpload;
	}

	getFile() {
		return this.file;
	}

	setState(state: QueuedFileStatus) {
		this.statusSubject.next(state);
	}

	getState(): QueuedFileStatus {
		return this.statusSubject.value;
	}

	getStateSubject(): Observable<QueuedFileStatus> {
		return this.statusSubject;
	}

	setProgress(progress: number) {
		this.progressObservable.next(progress);
	}

	getProgress(): Observable<number> {
		return this.progressObservable;
	}

	hasThumbnail(): boolean {
		return (this.thumbnailSubject.value.length > 0);
	}

	getThumbnail(): Observable<string> {
		const imgRegex = /^image\/([a-zA-Z].*)/;
		return this.thumbnailSubject;

		// if(this.file.type.toLowerCase().endsWith("pdf")) return "/assets/images/icon-file-pdf.png";
		// if(this.file.type.toLowerCase().endsWith("doc")) return "/assets/images/icon-file-doc.png";
		// if(this.file.type.toLowerCase().endsWith("xls")) return "/assets/images/icon-file-xls.png";
		// return "/assets/images/icon-file-unknown.png";
	}

	private setFile(file: File): void {
		this.file = file;
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = (event: ProgressEvent) => {
			this.fileContent = <string>fileReader.result;
			const imgRegex = /^image\/([a-zA-Z].*)/;
			if (imgRegex.test(this.file.type)) {
				this.thumbnailSubject.next(this.fileContent);
			}
		};
	}
}