import { animate, state, style, transition, trigger } from "@angular/animations";

export const SIDENAV_ANIMATION = [
	trigger("sideNavOpen", [
		state("false", style([
			{
				transform: "scale(1)",
				width: "*",
				position: "inherit"
			}
		])), state("true", style([
			{
				transform: "scale(0)",
				width: 0,
				position: "absolute"
			}
		])), transition("true <=> false", [
			animate("0.4s ease-in")
		])
	]), trigger("sideNavOpenSpin", [
		state("false", style([
			{
				transform: "rotate(0)"
			}
		])), state("true", style([
			{
				transform: "rotate(-360deg)"
			}
		])), transition("true <=> false", [
			animate("0.4s ease-out")
		])
	])
];
