import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { CONFIG_URL, ConfigJson, TARGET, TargetType } from "./config";

@Injectable({
	providedIn: "root"
})
export class ConfigService {
	protected logger: Logger = LoggerLocator.getLogger("ConfigService")();
	protected config: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);

	constructor(
		protected httpClient: HttpClient,
		@Inject(CONFIG_URL) protected configUrl: string,
		@Inject(TARGET) protected target: TargetType
	) {
	}

	loadConfig(): Observable<unknown> {
		this.httpClient.get<ConfigJson>(this.configUrl, {
			headers: {
				"ngsw-bypass": "true"
			}
		})
			.pipe(map(configJson => this.mergeTargetConfigurationIntoBase(configJson)))
			.subscribe(this.config);

		return this.config.pipe(filter(value => value != null), take(1));
	}

	get<T extends unknown>(path?: string[] | string): T {
		const currentConfig = this.config.value;

		if (typeof path === "string") {
			path = path.split(".");
		}

		return path.reduce((objectSection, attribute) => {
			if (objectSection == null) {
				return undefined;
			}

			return objectSection[attribute] || undefined;
		}, currentConfig) as any;
	}

	protected mergeTargetConfigurationIntoBase<T>(config: ConfigJson): T {
		const baseConfiguration = config["$base"];
		const overrideConfiguration = config["$targetOverrides"][this.target] || {};
		return this.mergeDeep({}, baseConfiguration, overrideConfiguration) as T;
	}

	private mergeDeep(target, ...sources) {
		if (!sources.length) return target;
		const source = sources.shift();

		if (ConfigService.isObject(target) && ConfigService.isObject(source)) {
			for (const key in source) {
				if (ConfigService.isObject(source[key])) {
					if (!target[key]) Object.assign(target, {[key]: {}});
					this.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, {[key]: source[key]});
				}
			}
		}

		return this.mergeDeep(target, ...sources);
	}

	private static isObject(item) {
		return (item && typeof item === "object" && !Array.isArray(item));
	}
}
