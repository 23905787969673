<div class="toolbar">
	<span
		i18n="Accept group invite page header text|The text of the header of the accept group invite page@@AcceptUserGroupInvitePageHeaderTitle"
	>
		Group invite
	</span>
</div>
<div class="page-content">
	<app-login-modal
		*ngIf="(showAccountNeeded | async) === true else acceptTemplate"
		(closing)="setPendingToken()"
		i18n-message="Account needed text|The text indicating that an account is needed@@AcceptUserGroupInvitePageAccountNeeded"
		message="You need an account to accept the invite, please login or register."
	></app-login-modal>
	<ng-template #acceptTemplate>
		<ng-container *ngIf="initialReplyObservable | async as data">
			<img
				class="group-image"
				*ngIf="data.userGroupImage != null && data.userGroupImage > 0 ; else twentelyImage"
				[src]="configService.get('apiEndpoint') + 'invite/' + data.token + '.png'"
			>
			<ng-template #twentelyImage>
				<img class="group-image" src="assets/icons/icon-72x72.png" alt="profile-image">
			</ng-template>
			<p class="invite-message">
				<span
					*ngIf="data.message == null else messageTemplate"
					i18n="Default invited message|The default invited message when no message is provided@@AcceptUserGroupInvitePageDefaultInvitedMessage"
				>
					{{data.userName}} invited you to join the group {{data.userGroupName}}
				</span>
				<ng-template #messageTemplate>
					{{data.message}}
				</ng-template>
			</p>

			<button
				class="text-button dark-button accept-button primary-button"
				i18n="Accept button|The accept button text@@commonAcceptButton"
				(click)="accept()"
			>Accept
			</button>
			<button
				class="text-button dark-button decline-button caption"
				i18n="Decline button|The decline button text@@commonDeclineButton"
				(click)="decline()"
			>Decline
			</button>
		</ng-container>

	</ng-template>
	<p></p>
</div>
