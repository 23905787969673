import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"<<field>>: {$lk: <<value>>}
 * }
 */
export class LikeOperator implements Operator {
	constructor(private field: string, private value: any) {
	}

	build(): object {
		const object = {};
		object[this.field] = {"$lk": this.value};
		return object;
	}

	clone(): LikeOperator {
		return new LikeOperator(this.field, this.value);
	}
}