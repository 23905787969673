import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type KeywordStore = ModelMap<Keyword>;

export const initialKeywordStore: KeywordStore = <KeywordStore>Map();

export const KeywordConversion: ActionConverter.Config = {};

export interface KeywordModel extends StoreModel {
	name: string;
}

export class Keyword extends Record({
	id: 0,
	rev: 0,
	name: ""

}) implements KeywordModel {

	id: number;
	rev: number;
	name: string;

	get displayName(): string {
		return this.name;
	};
}
