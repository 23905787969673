import { Operator } from "./operator";

/**
 * This operator becomes:
 * {
 * 	"$and: [...]
 * }
 */
export class AndOperator implements Operator {
	constructor(private children: Operator[] = []) {
	}

	add(operator: Operator): AndOperator {
		this.children.push(operator);
		return this;
	}

	remove(operator: Operator): AndOperator {
		this.children = this.children.reduce((reduction, value) => {
			if (value === operator) return reduction;
			reduction.push(value);
			return reduction;
		}, []);
		return this;
	}

	getChildren(): Operator[] {
		return this.children;
	}

	build(): object | null {
		const and = this.children.map(value => value.build())
			.filter(value => value != null);

		if (and.length <= 0) return null;
		return {
			"$and": and
		};
	}

	clone(): AndOperator {
		return new AndOperator(this.children.length > 0 ? [...this.children.map(child => child.clone())]: []);
	}
}
