import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, NgZone } from "@angular/core";
import { App, AppState } from "@capacitor/app";
import { loggedIn } from "@tsng/account";
import { AccountModel } from "@tsng/account/lib/store/model";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { RENDERED_BY, RenderEnvironment, TwentelyAccount } from "@twly/core";
import { combineLatest, fromEvent, merge, Observable, Subject, Subscription } from "rxjs";
import { filter, map, skipWhile, startWith, tap } from "rxjs/operators";
import { CONNECTION_TARGETS, ConnectionStatus, ConnectionTarget } from "../../connector/target";

@Injectable({
	providedIn: "root"
})
export class TwlyAuthenticationHandler {
	private logger = LoggerLocator.getLogger("TwlyAuthenticationHandler")();
	private connectionTargetSubscription: Subscription | null;
	private inFlightLoginRequest: Message<Action>;
	private reconnectionRetries = 0;

	constructor(private eventBus: EventBus,
		@Inject(CONNECTION_TARGETS) private connectionTargets: ConnectionTarget[],
		@Inject(DOCUMENT) private document: Document,
		@Inject(RENDERED_BY) private renderedBy: RenderEnvironment,
		private zone: NgZone
	) {
		eventBus.localConsumer("principal/received").subscribe(this.onPrincipalReceived.bind(this));
		eventBus.localConsumer("user/authenticate").subscribe(this.requestRefreshToken.bind(this));
		eventBus.localConsumer("internal/logout").subscribe(this.doLogout.bind(this));

		this.listenToConnectionTargetChanges();
		this.refreshPrincipal();
	}

	private listenToConnectionTargetChanges() {
		if(this.renderedBy === RenderEnvironment.SERVER) {
			return;
		}

		combineLatest([
			merge(...this.connectionTargets.map(connectionTarget => connectionTarget.connectionStatus()))
				.pipe(skipWhile(status => status !== ConnectionStatus.CONNECTED)),
			this.listenToPageVisibilityChanges()
		])
			.pipe(filter(([status, visibilityChange]) => (status === ConnectionStatus.DISCONNECTED) && (visibilityChange.documentIsHidden === false)))
			.subscribe(this.refreshPrincipal.bind(this));
	}

	private listenToPageVisibilityChanges(): Observable<{ documentIsHidden: boolean }> {
		// https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API#example
		let hidden: string;
		let visibilityChange: string;
		const proxyDocument: any = this.document;

		// Capacitor doesn't support the Page Visibility API
		if( this.renderedBy === RenderEnvironment.CAPACITOR ) {
			const pageVisibilitySubject = new Subject<{ documentIsHidden: boolean }>();
			App.addListener("appStateChange", (state: AppState) => {
				this.zone.run(() => {
					pageVisibilitySubject.next({documentIsHidden: state.isActive === false});
				});
			});
			return pageVisibilitySubject.asObservable();
			// return fromEvent(proxyDocument, "appStateChange").pipe(startWith({documentIsHidden: false}),
			// 	tap((event: AppState) => this.logger.debug("App state changed", event)),
			// 	map((event: AppState) => ({documentIsHidden: event.isActive === false}))
			// );
		}

		// Opera 12.10 and Firefox 18 and later support
		if (typeof proxyDocument?.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof proxyDocument?.msHidden !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof proxyDocument?.webkitHidden !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}

		return fromEvent(proxyDocument, visibilityChange).pipe(startWith({documentIsHidden: false}),
			map(() => ({documentIsHidden: this.document[hidden] == true}))
		);
	}

	private refreshPrincipal() {
		return this.requestAccessToken()
			.pipe(map(message => (message.body as Action).data as AccountModel),
				tap(account => this.logger.info("Principal information received", account))
			)
			.subscribe(account => {
				const principalReceived = new Action("principal/received", account.id, account.rev, account);
				this.eventBus.publish(principalReceived.type, principalReceived);
			}, error => {
				this.logger.fatal("Unable to request access token and retrieve principal information", error);
			});
	}

	private onPrincipalReceived(message: Message<Action>) {
		const principal = message.body.data as TwentelyAccount;

		// clear any previous connection subscriptions.
		if (this.connectionTargetSubscription != null) {
			this.connectionTargetSubscription.unsubscribe();
			this.connectionTargetSubscription = null;
		}
		const accessToken = (message.body.data as any).accessToken;
		delete (message.body.data as any).accessToken
		delete (principal as any).accessToken;

		// store the principal in the store
		this.eventBus.send("store/principal-changed", message.body);
		this.eventBus.send("store/set-logged-in", {
			type: "store/set-logged-in",
			data: loggedIn.DEFAULT
		});

		if (this.isGuestUser(principal)) {
			// if the user is a guest user we simply put said user into the store and stop any further
			// connecting to channels or opening of connection targets. The guest user lacks all those
			// abilities.
			this.logger.debug("User is being treated as a guest user");
			this.notifyLoggedIn(message);
			return;
		}

		const connectionObservables = this.connectionTargets
			.filter(target => target.canConnect(principal))
			.map(target => target.connect(principal, accessToken));
		this.connectionTargetSubscription = combineLatest(connectionObservables).subscribe(() => {
			this.notifyLoggedIn(message);
			this.inFlightLoginRequest?.reply(message.body);
		}, error => {
			this.notifyNotLoggedIn();
			this.inFlightLoginRequest?.fail(-1, error.message);
			this.logger.error("Unable to request new access token, user didn't have a valid refresh token",
				{error}
			);
		});

	}

	private isGuestUser(principal: TwentelyAccount): boolean {
		return principal.authRoleId === 10;
	}

	private notifyNotLoggedIn() {
		this.eventBus.send("store/set-logged-in", {
			type: "store/set-logged-in",
			data: loggedIn.FALSE
		});
	}

	private notifyLoggedIn(message: Message<Action>) {
		this.eventBus.send("store/login", message.body);
	}

	private requestRefreshToken(message: Message<Action>) {
		// We store the in flight login request here as we can only answer it once the principal/received
		// is sent along by the server back to the client over an opened websocket.
		this.reconnectionRetries = 0;
		this.inFlightLoginRequest = message;
		this.eventBus.request("user/login", message.body).subscribe(response => {
			this.refreshPrincipal();
		}, error => {
			this.notifyNotLoggedIn();
			this.inFlightLoginRequest?.fail(error.failureCode, error.message);
			this.logger.error("User login failed", {error});
		});
	}

	private requestAccessToken(): Observable<Message<Action>> {
		return this.eventBus.request("user/login", {} as any);
	}

	private doLogout(message: Message<Action>) {
		this.eventBus.request("user/logout", {}).subscribe(() => {
			const action = new Action("store/logout", -1, -1, {});
			this.eventBus.send(action.type, action);
			if(this.connectionTargetSubscription != null) {
				this.connectionTargetSubscription.unsubscribe();
			}
			message.reply(new Action("internal/loggedOut", -1, -1, {success: true}));
		});
	}
}
