import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TsNgChangePasswordForm } from "@tsng/account";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { AppModal } from "../../component/modal/component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<TsNgChangePasswordForm>>,
	componentRef: ComponentRef<TsNgChangePasswordForm>,
	eventBus: EventBus
) {
	componentRef.instance.changed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		dialogRef.close();
		const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
			message: $localize`:Success toast|Password changed successfully@@PasswordChangedSuccessfully:The password has been changed.`,
			level: SnackbarLevel.SUCCESS
		});
		eventBus.send(action.type, action);
	});
}

export const changePasswordFormModalRoute: ModalRoute<TsNgChangePasswordForm> = {
	path: "account/change-password",
	outlet: "modal",
	component: AppModal,
	data: {
		title: $localize`:Modal title|The title of the change password form@@ChangePasswordFormTitle:Change your password`,
		componentLoader: () => import("@tsng/account").then(m => m.TsNgChangePasswordForm),
		setupComponentHooks
	}
};
