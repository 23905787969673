import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { Action, EventBus } from "@tsng/core";
import { email, required, TsFormControl, TsFormGroup } from "@tsng/form";
import { LoggerLocator } from "@tsng/logging";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "tsng-request-password-reset-form",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class TsNgRequestPasswordReset {
	@Output() requested: EventEmitter<boolean> = new EventEmitter<boolean>(false);

	form = new TsFormGroup({
		email: new TsFormControl("", [required(), email()])
	}, {updateOn: "submit"});
	private readonly logger = LoggerLocator.getLogger("TsNgRequestPasswordReset")();

	constructor(private eventBus: EventBus) {
	}

	requestReset() {
		if (this.form.invalid) {
			this.logger.warning("Form wasn't valid");
			return;
		}

		let action = new Action("user/request-password-reset", 0, 0, this.form.value);
		this.eventBus.request("user/request-password-reset", action)
			.subscribe(response => {
				this.logger.info("Password reset request successfully received", response);
				this.requested.emit(true);
			}, error => {
				this.logger.error("Password reset request failed", error);
				const action = new Action("snackbar/create", {
					message: $localize`:Error toast|Password reset failed toast@@tsNgRequestPasswordResetRequestFailed:We were unable to request a password reset, please try again.`,
					level: "error"
				});
				this.eventBus.send(action.type, action);
			});
	}
}
