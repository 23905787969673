import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";
import { Location } from "../site/model";

export type SiteActivityStore = ModelMap<SiteActivity>;

export const initialSiteActivityStore: SiteActivityStore = <SiteActivityStore>Map();

export const SiteActivityConversion: ActionConverter.Config = {};

export interface SiteActivityModel extends StoreModel {
	siteId: number;
	activityId: string;
	venueId: string;
	url: string;
	name: string;
	narrative: string;
	slug: string;
	seoTitle: string;
	seoDescription: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	cityId: number | null;
	phone: string;
	email: string;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	propertyIds: string[];
	openingHours: [];
	closed: [];
	presentAtStartTime: boolean;
	reservationRequired: boolean;
	useSiteContact: boolean;
	useSiteAddress: boolean;
	useSiteOpeningHours: boolean;
	errorLevel: number;
	errors: object[];
	publishedRev: number;
	location: Location;
	remark: string;
	entryStatus: number;
	dateModified: Date | null;
	datePublished: Date | null;
}

export class SiteActivity extends Record({
	id: 0,
	rev: 0,
	siteId: 0,
	activityId: "",
	venueId: "",
	url: "",
	name: "",
	narrative: "",
	slug: "",
	seoTitle: "",
	seoDescription: "",
	street: "",
	houseNumber: "",
	zipCode: "",
	city: "",
	cityId: null,
	phone: "",
	email: "",
	audienceIds: [],
	tagIds: [],
	tags: [],
	openingHours: [],
	propertyIds: [],
	closed: [],
	presentAtStartTime: false,
	reservationRequired: false,
	venueContact: true,
	useSiteContact: true,
	useSiteAddress: true,
	useSiteOpeningHours: false,
	errorLevel: 0,
	errors: [],
	publishedRev: 0,
	location: {
		lon: 0,
		lat: 0
	},
	remark: "",
	entryStatus: 0,
	dateModified: null,
	datePublished: null

}) implements SiteActivityModel {
	id: number;
	rev: number;
	siteId: number | null;
	activityId: string;
	venueId: string;
	url: string;
	name: string;
	narrative: string;
	slug: string;
	seoTitle: string;
	seoDescription: string;
	street: string;
	houseNumber: string;
	zipCode: string;
	city: string;
	cityId: number;
	phone: string;
	email: string;
	audienceIds: string[];
	tagIds: string[];
	tags: string[];
	propertyIds: string[];
	openingHours: [];
	closed: [];
	presentAtStartTime: boolean;
	reservationRequired: boolean;
	useSiteContact: boolean;
	useSiteAddress: boolean;
	useSiteOpeningHours: boolean;
	errorLevel: number;
	errors: object[];
	publishedRev: number;
	location: Location;
	remark: string;
	entryStatus: number;
	dateModified: Date | null;
	datePublished: Date | null;


	get displayName(): string {
		return this.name;
	};

	get address(): string {
		return this.street + " " + this.houseNumber;
	};

	get streetDisplayName(): string {
		return this.street + " " + this.houseNumber;
	};

	get hasLocation(): boolean {
		return this.location != null && this.location.latitude != null && this.location.longitude != null;
	};

	get hasRevisionChanges(): boolean {
		return this.rev > this.publishedRev;
	};

	get isPublished(): boolean {
		return this.activityId != null;
	};
}
