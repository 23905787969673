<form (ngSubmit)="requestReset()" [formGroup]="form" tsNgRootFormGroup>
	<tsng-input
		formControlName="email"
		i18n="Label|The label for the email input@@TsNgRequestPasswordResetEmailInputLabel"
		i18n-placeholder="Placeholder|The placeholder for the email input@@TsNgRequestPasswordResetEmailInputPlaceholder"
		placeholder="Please provide your email address"
		type="email"
	>
		Email address
	</tsng-input>
	<button i18n="Button|The reset password button@@TsNgRequestPasswordResetButton" type="submit">
		Reset
	</button>
</form>
