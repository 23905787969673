import { Inject, Injectable, Injector } from "@angular/core";
import { PreferencesPlugin } from "@capacitor/preferences/dist/esm/definitions";
import {
	ActionPerformed, PushNotificationSchema, PushNotificationsPlugin, RegistrationError, Token
} from "@capacitor/push-notifications";
import { SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { AccountProvider, RENDERED_BY, RenderEnvironment, TwentelyAccount } from "@twly/core";
import { combineLatest, Observable, ReplaySubject } from "rxjs";
import { debounceTime, distinctUntilChanged, take } from "rxjs/operators";
import { PREFERENCES, PUSH_NOTIFICATION } from "./interface";

@Injectable({
	providedIn: "root"
})
export class PushNotificationService {
	private static readonly PUSH_NOTIFICATION_PERMISSION_CHECKED = "push-notification-permission-checked";
	private static readonly FIREBASE_TOKEN = "firebase-token";
	private static readonly USER_ID = "user-id";

	private tokenChanged: ReplaySubject<Token | null> = new ReplaySubject<Token | null>(1);
	private accountChanged: Observable<TwentelyAccount>;

	private logger: Logger = LoggerLocator.getLogger("PushNotificationService")();
	private preferences: PreferencesPlugin;
	private pushNotifications: PushNotificationsPlugin;

	private initialized: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(private eventBus: EventBus,
		@Inject(RENDERED_BY) private renderedBy: RenderEnvironment,
		private injector: Injector,
		private accountProvider: AccountProvider
	) {
		if (renderedBy !== RenderEnvironment.CAPACITOR) {
			this.logger.debug("Push notification service is not supported in this environment");
			return;
		}

		this.preferences = injector.get(PREFERENCES);
		this.pushNotifications = injector.get(PUSH_NOTIFICATION);
		this.accountChanged = this.accountProvider.getAccountObservable();

		this.bindStateReEvaluationListener();
		this.bindListeners();
		this.checkPermission();
	}

	public isInitialized(): Observable<boolean> {
		return this.initialized.asObservable().pipe(take(1));
	}

	public checkPermission() {
		this.pushNotifications.checkPermissions().then(result => {
			if (result.receive === "prompt") {
				this.requestPermission();
				return;
			}
			if (result.receive === "granted") {
				return this.onPermissionGranted();
			}

			return this.onPermissionDenied();
		})
			.finally(() => this.initialized.next(true))
			.catch(error => this.logger.error("Unable to request push notification permission", error));
	}

	public requestPermission() {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		this.pushNotifications.requestPermissions().then(result => {
			if (result.receive === "granted") {
				return this.onPermissionGranted();
			}

			return this.onPermissionDenied();
		})
			.finally(() => this.initialized.next(true))
			.catch(error => this.logger.error("Unable to request push notification permission", error));
	}

	private bindListeners() {
		this.pushNotifications.addListener("registration", this.onRegistration.bind(this));
		this.pushNotifications.addListener("registrationError", this.onRegistrationError.bind(this));
		this.pushNotifications.addListener("pushNotificationReceived",
			this.onPushNotificationReceived.bind(this)
		);
		this.pushNotifications.addListener("pushNotificationActionPerformed",
			this.onPushNotificationActionPerformed.bind(this)
		);

		// To prevent issues whereby the below addresses are not available or consumed within the application
		// We add 2 local consumers to ensure that the addresses are always consumed.
		this.eventBus.localConsumer("push-notification/received");
		this.eventBus.localConsumer("push-notification/perform-action");
	}

	private async onRegistration(token: Token) {
		this.logger.debug("Push registration success, token: ", token);
		this.tokenChanged.next(token);
	}

	/**
	 * The following scenarios are possible:
	 *     1. New Guest user - no permission
	 *     2. New Guest user - permission granted
	 *     3. Existing user - no permission
	 *     4. Existing user - permission granted
	 *
	 * The following login/logout situations are possible:
	 *     1. Sign in with guest user with permission granted - register token for user (if not already registered)
	 *     2. Sign in with guest user with permission denied - do nothing
	 *     3. Sign in with existing user with permission granted - register token for user (if not already registered)
	 *     4. Sign in with existing user with permission denied - do nothing
	 *     5. On App start - if user is logged in and permission is granted - register token for user (if not already registered)
	 *     6. If user signs out - remove token from user (if registered)
	 **/
	private async reEvaluateNeedToSynchronize(newUserId: string | null, newToken: string | null) {
		const existingToken = await this.preferences.get({key: PushNotificationService.FIREBASE_TOKEN});
		const existingUserId = await this.preferences.get({key: PushNotificationService.USER_ID});
		await this.setPreferences(newUserId, newToken);

		if (newToken == null && existingToken.value == null) {
			return;
		}

		if (existingToken.value === newToken && existingUserId.value === newUserId) {
			return;
		}

		if ((newToken == null || newUserId == null) && existingToken.value != null) {
			//todo error handling
			this.eventBus.request("notificationToken/unregister", new Action("notificationToken/unregister", {
				token: existingToken.value
			})).subscribe({
				error: (error) => {
					this.logger.error("Unable to unregister push notification", error);
				}
			});
			return;
		}

		this.eventBus.request("notificationToken/register", new Action("notificationToken/register", {
			token: newToken
		})).subscribe({
			error: async (error) => {
				this.logger.error("Unable to register push notification", error);
				await this.setPreferences(null, null);
			}
		});
	}

	private async setPreferences(userId: string | null, token: string | null) {
		if (userId == null) {
			await this.preferences.remove({key: PushNotificationService.USER_ID});
		} else {
			await this.preferences.set({
				key: PushNotificationService.USER_ID,
				value: userId
			});
		}

		if (token == null) {
			await this.preferences.remove({key: PushNotificationService.FIREBASE_TOKEN});
		} else {
			await this.preferences.set({
				key: PushNotificationService.FIREBASE_TOKEN,
				value: token
			});
		}
	}

	private async onRegistrationError(error: RegistrationError) {
		this.logger.error("Unable to register push notification", error);
		const toastAction = new Action("snackbar/create", {
			message: $localize`:Push notification error|Push notification error@@PushNotificationServicePermissionError:Notifications are disabled as we weren't able to register the device`,
			level: SnackbarLevel.ERROR
		});
		this.eventBus.send(toastAction.type, toastAction);

	}

	private async onPushNotificationReceived(notification: PushNotificationSchema) {
		this.logger.debug("Push notification received", notification);
		this.eventBus.publish("push-notification/received",
			new Action("push-notification/received", notification)
		);
	}

	private async onPushNotificationActionPerformed(notification: ActionPerformed) {
		this.logger.debug("Push notification action performed", notification);
		this.eventBus.publish("push-notification/perform-action",
			new Action("push-notification/perform-action", notification)
		);
	}

	private async onPermissionGranted() {
		this.logger.debug("Push notification permission granted");
		this.pushNotifications.register()
			.catch(error => this.logger.error("Unable to register push notification", error));
	}

	private async onPermissionDenied() {
		this.logger.warning("Push notification permission denied");
		this.tokenChanged.next(null);

		// if the user has denied permission we don't want to show another toast message.
		const previouslyCheckedNotificationPermission = await this.preferences.get({key: PushNotificationService.PUSH_NOTIFICATION_PERMISSION_CHECKED});
		if (previouslyCheckedNotificationPermission.value == null) {
			this.showPermissionDeniedToast();
			await this.preferences.set({
				key: PushNotificationService.PUSH_NOTIFICATION_PERMISSION_CHECKED,
				value: "true"
			});
			return;
		}
		this.logger.debug("Not showing toast as permission was already denied previously");
	}

	private showPermissionDeniedToast() {
		const toastAction = new Action("snackbar/create", {
			message: $localize`:Push notification denied|Push notification denied@@PushNotificationServicePermissionDenied:Notifications are disabled as access was denied, in case you wish to change this decision go to the application settings and enable push notification permissions.`,
			level: SnackbarLevel.WARNING
		});
		this.eventBus.send(toastAction.type, toastAction);
	}

	private bindStateReEvaluationListener() {
		combineLatest([
			this.accountChanged.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
			this.tokenChanged.pipe(distinctUntilChanged((a, b) => a?.value === b?.value))
		]).pipe(debounceTime(3000)).subscribe(([account, token]) => {
			this.reEvaluateNeedToSynchronize(account?.id?.toString(), token?.value)
				.catch(error => this.logger.error("Error within reEvaluateNeedToSynchronize", error));
		});
	}
}
