import { Injectable } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { BehaviorSubject, EMPTY, merge, Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class StoreHandler {
	private logger = LoggerLocator.getLogger("StoreHandler")();
	private addresses = new BehaviorSubject<string[]>([]);

	constructor(private eventBus: EventBus, private store: Store<unknown>) {
		this.listenToMessages();
	}

	public addHandlers(addresses: string[]) {
		const potentiallyNewHandlers = addresses.filter(address => this.addresses.value.includes(address) === false);
		if (potentiallyNewHandlers.length !== addresses.length) {
			this.logger.warning("duplicate handlers not allowed!", {
				duplicates: addresses.filter(address => this.addresses.value.includes(address) === true)
			});
		}
		if (potentiallyNewHandlers.length > 0) {
			this.addresses.next([...this.addresses.value, ...potentiallyNewHandlers]);
		}
	}

	public removeHandlers(addresses: string[]) {
		if (addresses.filter(address => this.addresses.value.includes(address) === false).length > 0) {
			this.logger.warning("Cannot remove handlers that are not registered!", {
				handlers: addresses.filter(address => this.addresses.value.includes(address) === false)
			});
		}
		this.addresses.next(this.addresses.value.filter(address => addresses.includes(address) === false));
	}

	private listenToMessages() {
		this.addresses.pipe(switchMap(addresses => {
			const observables: Observable<Message<unknown>>[] = addresses
				.map(address => this.eventBus.localConsumer(address).pipe(catchError(error => {
					this.logger.fatal("stopped listing to consumer because of error", error);
					return EMPTY;
				})));
			return merge(...observables);
		})).subscribe(message => {
			this.store.dispatch(message.body as Action);
		});
	}
}
