import { loggedIn } from "@tsng/account";
import { StoreModel } from "@tsng/store";
import { List, Record } from "immutable";

export interface AccountModel extends StoreModel {
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string | null;
	phone: string;
	authRoleId: number;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	selectedUserGroup: number;
	ageCategory: string;
	siteIds: List<number>;
	permissions: List<String>;
	settings: object;
}

export class TwentelyAccount extends Record({
	id: 0,
	rev: 0,
	initials: "",
	firstName: "",
	infix: "",
	surname: "",
	gender: "",
	email: "",
	phone: "",
	authRoleId: 10,
	active: true,
	ppId: null,
	parentId: null,
	companyName: "",
	nodeType: "",
	locale: "nl_NL",
	loggedIn: loggedIn.DEFAULT,
	wasAdmin: false,
	selectedUserGroup: 0,
	companyIds: "",
	ageCategory: "",
	siteIds: List(),
	permissions: List(),
	settings: {}
}) implements AccountModel {
	id: number;
	rev: number;
	initials: string;
	firstName: string;
	infix: string;
	surname: string;
	gender: string;
	email: string;
	phone: string;
	authRoleId: number;
	active: true;
	ppId: number | null;
	parentId: number | null;
	companyName: string;
	nodeType: string;
	locale: string;
	loggedIn: string;
	wasAdmin: boolean;
	selectedUserGroup: number;
	ageCategory: string;
	siteIds: List<number>;
	permissions: List<String>;
	settings: object;

	get displayName(): string {
		const v = [];
		if (this.firstName != null && this.firstName !== "") v.push(this.firstName);
		if (this.infix != null && this.infix !== "") v.push(this.infix);
		if (this.surname != null && this.surname !== "") v.push(this.surname);
		return v.join(" ");
	};

	get isLoggedIn(): boolean {
		return (this.id > 0 && this.loggedIn === loggedIn.TRUE && this.authRoleId > 10);
	}

	hasPermission(name: string): boolean {
		let x = this.permissions != null && this.permissions.indexOf(name) !== -1;
		// console.log("permission: " + name + ":" + x);
		return x;
	}

	hasAllPermissions(permissions: string[]): boolean {
		let x = this.permissions != null && permissions.every(permission => (this.permissions.includes(permission)));
		// console.log("permissions: " + permissions + ":" + x);
		return x;
	}

	hasAnyPermissions(permissions: string[]): boolean {
		let x = this.permissions != null && permissions.some(permission => (this.permissions.includes(permission)));
		// console.log("hasAnyPermission: "+permissions+ ":" + x);
		return x;
	}
}
