import { AsyncValidatorFn, FormGroup } from "@angular/forms";
import { Logger, LoggerLocator } from "@tsng/logging";
import { TsAbstractControl } from "../abstract";
import { getHintResult, getValidatorFunctionsFromHints, HintLevel, HintResults, Hints } from "../hint";
import { TsFormArray } from "./array";
import { TsFormControl } from "./control";
import { isTsFormArray, isTsFormControl, isTsFormGroup } from "./typeguard";

export class TsFormGroup<TControl extends {
	[K in keyof TControl]: TsAbstractControl| TsFormControl | TsFormArray<any> | TsFormGroup;
} = any> extends FormGroup<TControl> implements TsAbstractControl {
	type = "group";
	hints: HintResults;
	configuredHints: Hints;
	protected logger: Logger = LoggerLocator.getLogger("TsFormGroup")();
	protected deleted = false;

	constructor(
		controls: TControl,
		validators?: Hints
	);
	constructor(
		controls: TControl,
		options?: {
			validators?: Hints, hints?: Hints, asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null, updateOn?: "change" | "blur" | "submit"
		}
	);
	// @ts-ignore we suppress type hinting here to add additional logic before the super call.
	constructor(
		controls: TControl,
		options: any = {}
	) {
		if (Array.isArray(options)) {
			options = {validators: options};
		}

		if (Array.isArray(options.validators) === false) {
			options.validators = [];
		}

		if (Array.isArray(options.hints) === false) {
			options.hints = [];
		}

		super(controls, {
			validators: getValidatorFunctionsFromHints(options.validators),
			asyncValidators: options.asyncValidators,
			updateOn: options.updateOn
		});
		this.configuredHints = [...options.validators, ...options.hints];
	}

	getChanges(): { [key: string]: any } | undefined {
		if (this.pristine) {
			return undefined;
		}

		const controls: { [key: string]: TsAbstractControl } = this.controls as { [key: string]: TsAbstractControl };
		const changes = Object.keys(controls).reduce((reduction, key) => {
			const change = controls[key].getChanges();
			if (change === undefined) {
				return reduction;
			}

			reduction[key] = change;
			return reduction;
		}, {});

		if (Object.keys(changes).length <= 0) {
			return undefined;
		}
		return changes;
	}

	getDeleted(): boolean {
		return this.deleted;
	}

	setDeleted(deleted: boolean) {
		this.deleted = deleted;
	}

	patch(value: { [key: string]: any }) {
		Object.keys(value).forEach(key => {
			if (this.controls.hasOwnProperty(key)) {
				(this.controls[key] as TsAbstractControl).patch(value[key]);
				return;
			}

			this.logger.warning(`There was no control found for key: ${key}`, {
				data: value
			});
		});
	}

	getArray<T extends TsAbstractControl>(path: Array<string | number> | string): TsFormArray<T> {
		const control = this.get(path);
		if (control && isTsFormArray<T>(control)) {
			return control;
		}
		throw new Error("No array found within path");
	}

	getGroup(path: Array<string | number> | string): TsFormGroup {
		const control = this.get(path);
		if (control && isTsFormGroup(control)) {
			return control;
		}
		throw new Error("No group found within path");
	}

	getControl(path: Array<string | number> | string): TsFormControl {
		const control = this.get(path);
		if (control && isTsFormControl(control)) {
			return control;
		}
		throw new Error("No control found within path");
	}

	updateValueAndValidity(opts?: { onlySelf?: boolean; emitEvent?: boolean }): void {
		if (this.enabled) {
			this.hints = getHintResult(this as any, this.configuredHints);
		}
		super.updateValueAndValidity(opts);
	}

	// @ts-ignore we suppress type hinting here to overwrite a default method
	setValidators(validators: any): void {
		const hints = this.configuredHints.filter(hint => hint.level != HintLevel.ERROR);
		super.setValidators(getValidatorFunctionsFromHints(validators));
		this.configuredHints = [...hints, ...validators];
	}

	setHints(hints: Hints): void {
		this.configuredHints = hints;
		this.updateValueAndValidity({
			onlySelf: true,
			emitEvent: true
		});
	}

	clearHints(): void {
		this.configuredHints = null;
		this.updateValueAndValidity({
			onlySelf: true,
			emitEvent: true
		});
	};
}
