import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type AuthRoleStore = ModelMap<AuthRole>;

export const initialAuthRoleStore: AuthRoleStore = <AuthRoleStore>Map();

export const AuthRoleConversion: ActionConverter.Config = {};


export interface AuthRoleModel extends StoreModel {
	parentId: number | null;
	name: string;
}

export class AuthRole extends Record({
	id: 0,
	rev: 0,
	parentId: null,
	name: ""
}) implements AuthRoleModel {
	id: number;
	rev: number;
	parentId: number | null;
	name: string;

	get displayName(): string {
		return this.name;
	};
}
