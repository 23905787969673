/**
 * Created by Nick Schipper - Twensoc:
 * @author : Twensoc
 * Date: 04/12/2017
 * Time: 12:14
 * For Project: angular-core
 */
import { Action } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { fromJS, List, Map } from "immutable";
import { ReceivedAction } from "../action/received";

/**
 * Function that safely parses JSON
 * Meaning that if the JSON isn't valid JSON it will return the specified default value.
 * Else the value is converted from JSON to the respective type and returned.
 */
function safelyParseJson(value: string, defaultValue: any): any {
	const logger = LoggerLocator.getLogger("safelyParseJson")();
	try {
		value = JSON.parse(value);
	} catch (error) {
		logger.warning(error);
		value = defaultValue;
	}
	return value;
}

export class ActionConverter {
}

/**
 * The action converter class
 * This class has the ability to quickly and easily convert deeply nested structures ensuring they are the correct type
 * while also having their required sub-properties etc.
 *
 * This method is generally called from within a reducer using the following syntax:

 ActionConverter.adapt(action, modelConversionConfig)
 *
 *
 * And the config for each model can be formed like so:

 var a: ActionConverter.Config = {
			b: ActionConverter.isString("default value"),
			c: ActionConverter.jsonToMap(Map([]), {
				d: ActionConverter.jsonToMap(Map([]), {
					e: ActionConverter.stringToDate(new Date(2000, 0, 1))
				})
			}),
			f: ActionConverter.jsonToList(List([]), {
				g: ActionConverter.isNumber(0)
			})
		};
 */
export namespace ActionConverter {
	export type Config = { [key: string]: ActionConverter.Function };

	export interface Function {
		(value: any): any;
	}

	/**
	 * Function tha adapts the Action that it is given coupled with the provided Config
	 * into a new action in which the data has been mutated in such a way that it adheres the current Model
	 */
	export function adapt(action: ReceivedAction | Action<{ [key: string]: any }>,
		config: ActionConverter.Config
	): ReceivedAction | Action<{ [key: string]: any }> {
		if (action.data.hasOwnProperty("items")) {
			let items = action.data.items.slice(0);
			return new Action(action.type, action.id, action.rev, {
				items: items.map(value => ActionConverter._adaptProperties(Object.assign({}, value), config))
			});
		}
		let data = Object.assign({}, action.data);

		return new Action(
			action.type,
			action.id,
			action.rev,
			ActionConverter._adaptFirstProperties(data, config)
		);
	}

	/**
	 * Function that adapts an object with a set of properties based on the provided config.
	 * This function is generally best avoided as it's meant to be called by the adapt function
	 * @private
	 */
	export function _adaptFirstProperties(value: any, config: ActionConverter.Config): any {
		for (let key in config) {
			if (key in value) value[key] = config[key].call(this, value[key]);
		}
		return value;
	}

	/**
	 * Function that adapts an object with a set of properties based on the provided config.
	 * This function is generally best avoided as it's meant to be called by the adapt function
	 * or recursively by the toMap / toList and the toObj methods respectively.
	 * @private
	 */
	export function _adaptProperties(value: any, config: ActionConverter.Config): any {
		for (let key in config) {
			value[key] = config[key].call(this, value[key]);
		}
		return value;
	}

	/**
	 * Function that converts an object to an immutable Map
	 * While also recursively converting the nested properties of the map in such a way that they adheres to the Model.
	 */
	export function jsonToMap(defaultValue: Map<any, any>,
		config?: ActionConverter.Config
	): ActionConverter.Function {
		return (value: any) => {
			if (value == null) value = defaultValue.toJS();
			if (typeof value === "string") value = safelyParseJson(value, defaultValue.toJS());
			if (typeof value !== "object") value = defaultValue.toJS();

			if (config == null) return fromJS(value);
			return fromJS(ActionConverter._adaptProperties(value, config));
		};
	}

	/**
	 * Function that converts an object to an Record
	 * While also recursively converting the nested properties of the Record in such a way that they adhere to the Model.
	 */
	export function jsonToRecord<T extends any>(defaultValue: { new(args?: any): T },
		config?: ActionConverter.Config
	): ActionConverter.Function {
		return (value: any) => {
			if (value == null) value = {};
			if (typeof value === "string") value = safelyParseJson(value, {});
			if (typeof value !== "object") value = {};

			if (config == null) return (new defaultValue(value));
			return (new defaultValue(ActionConverter._adaptProperties(value, config)));
		};
	}

	/**
	 * Function that converts an object to an immutable List
	 * While  also recursively converting the nested array items of the array in such a way that they adhere to the Model.
	 */
	export function jsonToList(defaultValue: List<any>,
		config?: ActionConverter.Config
	): ActionConverter.Function {
		return (values: any[]) => {
			if (values == null) values = defaultValue.toJS();
			if (typeof values === "string") values = safelyParseJson(values, defaultValue.toJS());
			if (Array.isArray(values) === false) values = defaultValue.toJS();

			if (config === null) return fromJS(values);
			return fromJS(values.map((value) => ActionConverter._adaptProperties(value, config)));
		};
	}

	/**
	 * Function that converts an object to an object.
	 * While also recursively converting the nested object properties in such a way that they adhere to the Model.
	 */
	export function jsonToObj(defaultValue: Object,
		config?: ActionConverter.Config
	): ActionConverter.Function {
		return (value: any) => {
			if (value == null) value = defaultValue;
			if (typeof value === "string") value = safelyParseJson(value, defaultValue);
			if (value == null || typeof value !== "object") value = defaultValue;

			if (config === null) return value;
			return ActionConverter._adaptProperties(value, config);
		};
	}

	/**
	 * Function that converts the string value into a Date object.
	 * It also ensures that the date string is correctly converted to a valid Date.
	 */
	export function stringToDate(defaultValue: Date): ActionConverter.Function {
		return (value: string) => {
			if (value == null) return defaultValue;
			let date = new Date(value);
			if (date.toString() === "Invalid Date") return defaultValue;
			return date;
		};
	}

	/**
	 * Function that converts the string, number, boolean value into a boolean value.
	 */
	export function toBoolean(value: string | number | boolean): boolean {
		if (typeof value === "string") value = value.toLowerCase();
		return (value === "true" || value === "yes" || value === "y" || value === "1" || value === 1 || value === true);
	}

	/**
	 * Function that determines if the value is a typeof string, else it returns the default value specified
	 */
	export function isString(defaultValue: string): ActionConverter.Function {
		return (value: string) => {
			if (typeof value !== "string") return defaultValue;
			return value;
		};
	}

	/**
	 * Function that determines if the value is a typeof number, else it returns the default value specified
	 */
	export function isNumber(defaultValue: number): ActionConverter.Function {
		return (value: number) => {
			if (typeof value !== "number" || isNaN(value)) return defaultValue;
			return value;
		};
	}

	/**
	 * Function that determines if the value is a typeof boolean, else it returns the default value specified
	 */
	export function isBoolean(defaultValue: boolean): ActionConverter.Function {
		return (value: boolean) => {
			if (typeof value !== "boolean") return defaultValue;
			return value;
		};
	}

	/**
	 * Function that determines if the value is an Array, else it returns the default value specified
	 */
	export function isArray(defaultValue: any[]): ActionConverter.Function {
		return (value: any[]) => {
			if (Array.isArray(value) === false) return defaultValue;
			return value;
		};
	}

	/**
	 * Function that determines if the value is a typeof object, else it returns the default value specified
	 */
	export function isObject(defaultValue: Object): ActionConverter.Function {
		return (value: Object) => {
			if (value == null || typeof value !== "object") return defaultValue;
			return value;
		};
	}
}
