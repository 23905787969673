import { Action } from "@tsng/core";
import { ActionConverter, ReducerHandlers } from "@tsng/store";
import { ChatMessage, ChatMessageConversion, ChatMessageStore, initialChatMessageStore } from "@twly/core";

export const chatMessageReducer = (state: ChatMessageStore = initialChatMessageStore, action: Action) => {
	switch (action.type) {
		case "chatmsg/received":
			return ReducerHandlers.handleReceivedAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any,
				new ChatMessage()
			);

		case "chatmsg/create":
			return ReducerHandlers.handleCreateAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any,
				new ChatMessage()
			);

		case "chatmsg/created":
			return ReducerHandlers.handleCreatedAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any,
				new ChatMessage()
			);

		case "chatmsg/updated":
			return ReducerHandlers.handleUpdatedAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any,
				new ChatMessage()
			);

		case "chatmsg/batch-updated":
			return ReducerHandlers.handleReceivedAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any,
				new ChatMessage()
			);

		case "chatmsg/deleted":
			return ReducerHandlers.handleDeletedAction(state,
				ActionConverter.adapt(action, ChatMessageConversion) as any
			);

		case "store/logout":
			// case "principal/received": todo find a proper solution upon switching accounts.
			return initialChatMessageStore;

		default:
			return state;
	}
};
