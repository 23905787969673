import {
	AfterViewInit,
	Directive,
	InjectionToken,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit, Output,
	QueryList,
	SimpleChanges,
	ViewChildren,
	EventEmitter
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { defaultFormGroupToAction, TsAbstractControl, TsFormControl, TsFormGroup } from "@tsng/form";
import { Logger, LoggerLocator } from "@tsng/logging";
import {
	CreateAction, CreatedAction, SourceBuilder, SourceProvider, UpdateAction, UpdatedAction
} from "@tsng/store";
import { Record } from "immutable";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, merge, Subject } from "rxjs";
import { switchMap, takeUntil, tap, first, skipUntil, last, map, filter } from "rxjs/operators";
import { UntypedFormGroup } from "@angular/forms";

export enum RouteParamType {
	INT, STRING
}

/**
 * The ModelState indicates where the model exists (or not). It doesn't say anything about
 * whether or not the fields are modified.
 */

export enum ModelState {
	INIT, NEW, 		// New model, data exists only in form
	DRAFT,		// Draft model, data stored in local storage, not yet in the database
	STORED,	// Model data stored in the database, may be changed or not in form
	DELETED	// Model doesn't exist in database any more
}

export interface Closable {
	close(force: boolean): boolean;
}

export interface FormSection {
	form: TsAbstractControl,
	name: string
}

export const TS_FORM_SECTION: InjectionToken<readonly FormSection[]> = new InjectionToken<readonly FormSection[]>(
	"TS_FORM_SECTION");

export function waitFor<T>(signal$: Observable<any>) {
	return (source$: Observable<T>) => new Observable<T>(observer => {
		// combineLatest emits the first value only when
		// both source and signal emitted at least once
		combineLatest([
			source$, signal$.pipe(first())
		])
			.subscribe(([v]) => observer.next(v));
	});
}

export class MyServiceEvent {
	message: string;
	eventId: number;
}

@Directive()
export abstract class BaseForm implements OnInit, OnChanges, OnDestroy, Closable, AfterViewInit {
	abstract form: TsFormGroup;
	protected logger: Logger = LoggerLocator.getLogger("DetailForm")();
	protected eventBus: EventBus;
	protected formToActionFactory: (AbstractControl, string) => object = defaultFormGroupToAction;
	protected destroyed: ReplaySubject<boolean> = new ReplaySubject(1);

	modelIdObservable = new ReplaySubject<string | number>(1);
	modelObservable = new ReplaySubject<object>(1);
	modelStateObservable = new BehaviorSubject<ModelState>(ModelState.INIT);
	protected saveObservable = new ReplaySubject<object>(1);
	dataLoadedObservable = new ReplaySubject<object>(1);

	protected sourceBuilder: SourceBuilder;
	protected modelIdFieldName: string = "id";

	protected activatedRoute: ActivatedRoute;
	protected router: Router;

	formInitialisedSubject = new ReplaySubject<boolean>(1);

	// @ts-ignore
	model: Record;

	@Input("entity") entity: string = "Unknown form entity";

	@Input("name") name: string;

	@Input("modelId") set modelId(id: string | number) {
		this.modelIdObservable.next(id);
	}

	@Input("modelIdField") set modelIdField(modelIdFieldName: string) {
		this.modelIdFieldName = modelIdFieldName;
	}

	@Input("jsonModel") set jsonModel(model: object) {
		this.modelObservable.next(model);
	}

	@Input("fieldCfg") fieldCfgParam: string;
	fieldCfg: object = {};

	@Output() modelChanged = new EventEmitter<any>();

	formState = new ReplaySubject<any>(1);

	@ViewChildren(TS_FORM_SECTION) formSections: QueryList<FormSection>;

	protected constructor(injector: Injector) {
		this.eventBus = injector.get(EventBus);
		this.activatedRoute = injector.get(ActivatedRoute);
		this.router = injector.get(Router);
		this.sourceBuilder = injector.get(SourceProvider).getBuilder();
		if (this.name == null) this.name = this.entity + "Form";
	}

	set modelState(modelState: ModelState) {
		this.modelStateObservable.next(modelState);
	}

	ngOnInit() {
		if (this.fieldCfgParam != null) {
			this.fieldCfg = JSON.parse(this.fieldCfgParam);
		}
		this.init();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.modelId) {

			if (changes.modelId.firstChange) {
				// First load of model after instantiating this form
			} else {
				// TODO: RvR find conflicts

				// Possible conflict
				// 1. If another id is pushed, ask to save changes
				// 2. If same id but newer rev is pushed, conflict (changed by another user)
				// if (changes.modelId.currentValue !== changes.jsonModel.previousValue) {
				// 	alert("Save changes?");
				// } else {
				//
				// 	// Find conflicting fields if there are any
				// 	// A field may have been touched/changed but may still contain the 'old' value
				// 	// Eg: name is changed from "123" to "1234" and back to "123"
				// 	const formChanges = this.form.getChanges();
				// 	if (formChanges != null) {
				// 		const equals = this.deepIsEqual(this.form.value, changes.jsonModel.currentValue);
				// 		if (equals !== true) {
				// 			alert("Conflict. A new version is available.");
				// 		}
				// 	}
				// 	this.resetForm();
				// 	this.modelObservable.next(changes.jsonModel.currentValue);
				// }
			}

		} else if (changes.jsonModel) {

			if (changes.jsonModel.firstChange) {
				// First load of model after instantiating this form
			} else {
				// Possible conflict
				// 1. If another id is pushed, ask to save changes
				// 2. If same id but newer rev is pushed, conflict (changed by another user)
				if (changes.jsonModel.currentValue.id !== changes.jsonModel.previousValue.id) {
					alert("Save changes?");
				} else {

					// Find conflicting fields if there are any
					// A field may have been touched/changed but may still contain the 'old' value
					// Eg: name is changed from "123" to "1234" and back to "123"
					const formChanges = this.form.getChanges();
					if (formChanges != null) {
						const equals = this.deepIsEqual(this.form.value, changes.jsonModel.currentValue);
						if (equals !== true) {
							alert("Conflict. A new version is available.");
						}
					}
					this.resetForm();
					this.modelObservable.next(changes.jsonModel.currentValue);
				}
			}
		}
	}

	ngAfterViewInit() {
		this.registerFormSections();
		this.formInitialisedSubject.next(true);
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	protected init() {
		merge( //
			this.modelIdObservable.pipe(
				takeUntil(this.destroyed), //
				switchMap(id => {
					if (this.isEmpty(id)) {
						return this.newModel();
					} else {
						return this.loadModel(id);
					}
				})
			), //
			this.modelObservable
		).pipe(
			takeUntil(this.destroyed), //
			map(model => this.patchConfig(model)),
			switchMap(model => this.loadSecondaryModels(model)),
			tap(model => { this.dataLoadedObservable.next(model)}),
			switchMap(model => {
				this.modelChanged.emit(model);
				return this.toFormModel(model);
			}),
		)
			.subscribe(model => {
				combineLatest([
					of(model),
					this.formInitialisedSubject
				]).subscribe( ([model, _]) => {
					this.patchForm(model);
				})
			}, error => {
				this.logger.error("Error ",error);
			});

		// Create an async pipeline for saving the model, allowing complex forms to
		// create async adapters from model to action by overriding the modelToAction method
		this.saveObservable.pipe(
			takeUntil(this.destroyed), //
			switchMap(model => this.createSaveAction(model))
		).subscribe(action => {
			this.sendAction(action);
		}, error => {
			this.logger.error(error);
		});
	}

	public close(force: boolean): boolean {
		if (this.form.dirty === false) {
			return true;
		}

		if (force === true) {
			this.form.reset();
			return true;
		}

		if (window.confirm($localize`:Unsaved changes warning|The warning that there are unsaved changesd@@BaseForm:You have unsaved changes, are you sure you want to leave?`)) {
			this.form.reset();
			return true;
		}
		return false;
	}

	/**
	 * Init a new instance of a new model to be inserted
	 * @protected
	 */
	protected newModel(): Observable<any> {
		return of({
			id: 0,
			rev: 0
		});
	}

	/**
	 * Load the model by id.
	 *
	 * The inherited form component must override this method and must define how to load the model by id.
	 *
	 * @param id
	 * @protected
	 */
	protected abstract loadModel(id?: number | string): Observable<any>;

	/**
	 * Load secondaryModels
	 *
	 * @param model
	 * @protected
	 */
	protected loadSecondaryModels(model: any): Observable<any> {
		return of(model);
	}

	/**
	 * Patch config for any new model
	 *
	 * @param model
	 * @protected
	 */
	protected patchConfig(model): any {
		for (const [k, v] of Object.entries(this.fieldCfg)) {
			model[k] = v;
		}
		return model;
	}

	/**
	 * toFormModel
	 *
	 * Override this method to asynchronously adapt the primary model or load additional data.
	 *
	 * @param model The primary model passed to the form or loaded somehow
	 * @protected
	 */
	protected toFormModel(model: any): Observable<any> {
		return of(model);
	}

	/**
	 * Saves the form if the form has changes and is valid
	 */
	protected save() {
		if (this.canFormBeSaved() === false) return;
		this.saveObservable.next(this.form.getChanges());
	}

	/**
	 * Transforms the changes in the form to an observable action.
	 *
	 * @param changes
	 * @protected
	 */
	protected createSaveAction(changes: object): Observable<Action> {
		const model = this.form.value;
		const type = model.id <= 0 || model.id === "" ? this.entity + "/create" : this.entity + "/update";
		return of(new Action(type, model.id, model.rev, changes));
	}

	ctrlValue(name: string): any {
		return this.form.getControl(name)?.value;
	}

	/******************************************************************************************
	 * Form state methods
	 ******************************************************************************************/

	protected resetForm() {
		this.form.reset();
	}

	protected patchForm(data: any) {
		this.model = data;
		this.form.patch(data);

		if (this.isEmpty(this.model.id)) {
			// Mark fieldcontrols as dirty
			for (const [k, v] of Object.entries(this.fieldCfg)) {
				this.form.get(k).markAsDirty();
			}
		}
	}

	protected resetFormState() {
		this.form.markAsPristine();
		this.form.markAsUntouched();
	}

	/**
	 * Utility method to determine wheher or not the form can be saved
	 *
	 * @protected
	 */
	protected canFormBeSaved(): boolean {
		if (this.form.valid === false) {
			this.onSaveFormIsInvalid();
			return false;
		}
		if (this.form.getChanges() == null) {
			this.onSaveNoChanges();
			return false;
		}
		return true;
	}

	/**
	 * Sends the create or update action to the server
	 *
	 * @param action
	 * @protected
	 */
	protected sendAction(action): Observable<any> {
		const subject = new Subject<any>();
		this.eventBus.request<CreateAction | UpdateAction, CreatedAction | UpdatedAction>(action.type.includes(
			"update") ? "entity/update" : "entity/create", action)
			.subscribe(value => {
				this.resetFormState();
				this.onSaveSuccess(value.body);
				subject.next(value.body);
			}, error => {
				this.onError(error, $localize`:Save failed@@DetailCardComponent.saveErrorMessage:We were unable to save the information, please try again`);
				throw error;
			});
		return subject;
	}

	protected sendDeleteAction(action) {
		this.eventBus.request(action.type, action)
			.subscribe(value => {
				this.onDeleteSuccess(value.body as Action);
			}, error => {
				this.onError(error, $localize`:Delete failed@@DetailCardComponent.deleteErrorMessage:We were unable to delete the item, please try again`);
			});
	}

	/******************************************************************************************
	 * Snackbar methods
	 *
	 * We should find a better solution for this
	 ******************************************************************************************/
	onSaveSuccess(action: Action) {
		if ((action.id <= 0 || action.id === "") && action.data["id"] != null) {
			this.router.navigate(["../", action.data["id"]], {
				relativeTo: this.activatedRoute,
				replaceUrl: true
			}).catch(error => {
				this.logger.error(error.message, error);
			});
		}

		this.showSnack({
			message: $localize`:Saved@@DetailCardComponent.saveSuccessMessage:The information has been saved`,
			level: SnackbarLevel.SUCCESS
		});
	}

	onError(error, defaultMsg: String) {
		let message = defaultMsg;
		if(error.message?.startsWith("ILLEGAL_VALUE")) {
			message = "Ongeldige waarde voor het veld "+ error.message.substring(23);
		} else if(error.message?.startsWith("BAD_REQUEST")) {
			message = "Ongeldige request. Neem contact op met support (BAD_REQUEST).";
		} else if(error.message?.startsWith("NO_HANDLERS")) {
			message = "Ongeldige request. Neem contact op met support (NO_HANDLERS).";
		} else if(error.message?.startsWith("{")) {
			try {
				message = JSON.parse(error.message)?.data?.failure?.text;
			} catch (e) {
				this.logger.error("Could not interpret failure for: ",error.message)
				message = e.message
			}
		} else if(error.message != null) {
			message = error.message;
		}

		this.showSnack({
			message: message,
			level: SnackbarLevel.ERROR
		});
	}

	onDeleteSuccess(action: Action) {
		this.router.navigate(["../"], {
			relativeTo: this.activatedRoute,
			replaceUrl: true
		}).catch(error => {
			this.logger.error(error);
		});

		this.showSnack({
			message: $localize`:Deleted@@DetailCardComponent.deleteSuccessMessage:The item has been deleted`,
			level: SnackbarLevel.SUCCESS
		});
	}

	onDeleteError(error) {
		this.showSnack({
			message: $localize`:Delete failed@@DetailCardComponent.saveErrorMessage:We were unable to delete this item, please try again`,
			level: SnackbarLevel.ERROR
		});
	}

	onSaveFormIsInvalid() {
		const invalidFields = this.getInvalidFields(this.form);
		this.logger.error("Invalid fields: "+JSON.stringify(invalidFields, null, 4))

		this.logger.warning("Form is not valid and therefore cannot be saved");
		this.showSnack({
			message: $localize`:Form invalid@@DetailCardComponent.formInvalidMessage:Please ensure all fields on the form have been filled out correctly`,
			level: "warning"
		});
	}

	getInvalidFields(formGroup: UntypedFormGroup): object {
		const flds = {};
		for (let controlsKey in formGroup.controls) {
			const control = formGroup.controls[controlsKey];
			if (control instanceof UntypedFormGroup) {
				flds[controlsKey] = this.getInvalidFields(control);
			} else {
				if (!control.valid) {
					flds[controlsKey] = control.status;
				}
			}
		}
		return flds;
	}

	onSaveNoChanges() {
		this.logger.warning("There are no changes therefore the form cannot be saved");
		this.showSnack({
			message: $localize`:Form was not changed@@DetailCardComponent.noChangesMessage:There are no changes to save`,
			level: "warning"
		});
	}

	protected showSnack(snack: any) {
		const action = new Action("snackbar/create", {
			message: snack.message,
			level: snack.level
		});
		this.eventBus.send(action.type, action);
	}

	protected isEmpty(id: any): boolean {
		return id == null || id === 0 || id === "0" || id === "";
	}

	/**
	 * Deep compare the values of the left and right objects, and return only the differences
	 * in the same structure.
	 *
	 * @param first
	 * @param second
	 */
	deepIsEqual(first, second) {
		// If first and second are the same type and have the same value
		// Useful if strings or other primitive types are compared
		if (first === second) return true;

		// Try a quick compare by seeing if the length of properties are the same
		let firstProps = Object.getOwnPropertyNames(first);
		let secondProps = Object.getOwnPropertyNames(second);

		// Check different amount of properties
		if (firstProps.length != secondProps.length) return false;

		// Go through properties of first object
		for (let i = 0; i < firstProps.length; i++) {
			let prop = firstProps[i];
			// Check the type of property to perform different comparisons
			switch (typeof (first[prop])) {
				// If it is an object, decend for deep compare
				case "object":
					if (!this.deepIsEqual(first[prop], second[prop])) return false;
					break;
				case "number":
					// with JavaScript NaN != NaN so we need a special check
					if (isNaN(first[prop]) && isNaN(second[prop])) break;
				default:
					if (first[prop] != second[prop]) return false;
			}
		}
		return true;
	};

	registerFormSections() {
		this.formSections.forEach(section => {
			if (this.form.contains(section.name)) {
				this.logger.fatal(`A control with name '${section.name}' already exists`);
				return;
			}
			this.form.setControl(section.name, section.form);
		});
	}
}
