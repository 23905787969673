import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type FileStore = ModelMap<ServerFile>;

export const initialFilesStore: FileStore = <FileStore>Map();


export const FileConversion: ActionConverter.Config = {
};


export interface FileModel extends StoreModel {
	entityPrefix: string;
	refId: number;
	fileSeq: number;
	fileSeqRev: number;
	group: string;
	name: string;
	ext: string;
	derived: string;
	userId: number;
	oName: string;
	contentType: string;
	charSet: string;
	fileSize: number;
	timestamp: string;
	physicalFileDeleted: boolean;
}

export const FileRecord = Record({
	id: 0,
	rev: 0,
	entityPrefix: "",
	refId: 0,
	fileSeq: 0,
	fileSeqRev: 0,
	group: "",
	name: "",
	ext: "",
	derived: "",
	userId: 0,
	oName: "",
	contentType: "",
	charSet: "",
	fileSize: 0,
	timestamp: "",
	physicalFileDeleted: false
});

export class ServerFile extends FileRecord implements FileModel {
	id: number;
	rev: number;
	entityPrefix: string;
	refId: number;
	fileSeq: number;
	fileSeqRev: number;
	group: string;
	name: string;
	ext: string;
	derived: string;
	userId: number;
	oName: string;
	contentType: string;
	charSet: string;
	fileSize: number;
	timestamp: string;
	physicalFileDeleted: boolean;

	get displayName(): string {
		return `${this.oName} - V${this.fileSeqRev}`;
	}


	isImage(): boolean {
		const imgRegex = /^image\/([a-zA-Z].*)/;
		return imgRegex.test(this.contentType);
	}

	/**
	 * Used image regex: {@link https://regex101.com/r/LJjMx1/1}
	 */
	getThumbnail(width: number, height: number): string {
		const imgRegex = /^image\/([a-zA-Z].*)/;
		if (imgRegex.test(this.contentType)) {
			return `${this.id}/${width}x${height}`;
		}
	}
	//
	// 	const ext = this.ext.toLowerCase();
	// 	switch (ext) {
	// 		case "doc":
	// 		case "docx":
	// 			return `/assets/images/icon-file-doc.png`;
	//
	// 		case "xls":
	// 		case "xlsx":
	// 			return `/assets/images/icon-file-xls.png`;
	// 		case "pdf":
	// 			return `/assets/images/icon-file-pdf.png`;
	// 	}
	// 	return `/assets/images/icon-file-unknown.png`;
	//
	// }
}