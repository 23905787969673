import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { Image, ImageConversion, initialImageStore } from "./model";

export const IMAGE_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("ImageReducer");

export function imageReducerProvider() {
	return reducerFactory("image", initialImageStore, Image, ImageConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("image", IMAGE_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["image/deleted", "image/updated", "image/created"])
	],
	providers: [
		{
			provide: IMAGE_REDUCER_TOKEN,
			useFactory: imageReducerProvider
		}
	]
})
export class ImageCoreModule {

}
