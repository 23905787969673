import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { Map, Record } from "immutable";

export type CompanyStore = ModelMap<Company>;

export const initialCompanyStore: CompanyStore = <CompanyStore>Map();

export const CompanyConversion: ActionConverter.Config = {};

export interface CompanyModel extends StoreModel {
	parentId: number | null;
	name: string;
	venueId: string;
	v_street: string;
	v_houseNumber: string;
	v_zipCode: string;
	v_city: string;
	p_street: string;
	p_houseNumber: string;
	p_zipCode: string;
	p_city: string;
	phone: string;
	email: string;
	site: string;
	cocNo: string;
	taxNo: string;
}

export class Company extends Record({
	id: 0,
	rev: 0,
	parentId: null,
	siteId: null,
	name: "",
	v_street: "",
	v_houseNumber: "",
	v_zipCode: "",
	v_city: "",
	p_street: "",
	p_houseNumber: "",
	p_zipCode: "",
	p_city: "",
	phone: "",
	email: "",
	site: "",
	cocNo: "",
	taxNo: ""
}) implements CompanyModel {

	id: number;
	rev: number;
	parentId: null;
	venueId: string;
	name: string;
	v_street: string;
	v_houseNumber: string;
	v_zipCode: string;
	v_city: string;
	p_street: string;
	p_houseNumber: string;
	p_zipCode: string;
	p_city: string;
	phone: string;
	email: string;
	site: string;
	cocNo: string;
	taxNo: string;

	get displayName(): string {
		return this.name;
	};
}
