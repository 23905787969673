import { NgModule } from "@angular/core";
import { CapitalizePipe } from "./pipe";

@NgModule({
	exports: [
		CapitalizePipe
	],
	declarations: [
		CapitalizePipe
	]

})
export class CapitalizeModule {
}
