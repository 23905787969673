import { Action } from "@tsng/core";

export enum SnackbarLevel {
	DEFAULT = "default", INFO = "info", WARNING = "warning", ERROR = "error", SUCCESS = "success"
}

export interface SnackbarCreateData {
	message: string;
	level?: SnackbarLevel;
	duration?: number;
	icon?: string;
	action?: {
		label: string; replyAddress: string; // eventbus address to call upon clicking the action
	}
}

export type SnackbarCreateAction = Action<SnackbarCreateData>;

export interface SnackbarCreatedData {
	close: () => void,
	uuid: string
}

export type SnackbarCreatedAction = Action<SnackbarCreatedData>;

export interface SnackbarDeletedData {
	uuid: string
}

export type SnackbarDeletedAction = Action<SnackbarDeletedData>;
