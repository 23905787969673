import { AbstractControl } from "@angular/forms";

export function defaultFormGroupToAction(form: AbstractControl, entity: string) {
	const data = this.form.getChanges();
	const formValue = this.form.value;
	if (formValue.id <= 0) {
		// create
		return {
			type: entity + "/create",
			id: formValue.id,
			rev: formValue.rev,
			data: data
		};
	}
	// update
	return {
		type: entity + "/update",
		id: formValue.id,
		rev: formValue.rev,
		data: data
	};
}