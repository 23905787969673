import { AbstractEntityLinkBuilder, EntityLink } from "../schema/abstract-entity-link-builder";
import { Schema } from "../schema/schema";
import { FilterActionBuilder } from "./action-builder";
import { DefaultFilterActionBuilder } from "./default/action-builder";

export class FilterActionBuilderBuilder extends AbstractEntityLinkBuilder<EntityLink, FilterActionBuilder> {
	constructor(protected schema: Schema) {
		super();
	}

	build(): FilterActionBuilder {
		const links = this.buildLinks();
		const compounds = this.createCompoundEntityNames(links);
		const actionBuilder = new DefaultFilterActionBuilder();
		actionBuilder.setActionType(this.mainEntityConfiguration.entity.getName() + "/filter");
		actionBuilder.setCompounds(compounds);
		return actionBuilder;
	}

	// noinspection JSMethodCanBeStatic
	private createCompoundEntityNames(links: EntityLink[]) {
		return links.filter(link => link.relation != null).map(linked => linked.entity.getName());
	}
}
