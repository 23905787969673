import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { CrawlerUrl, CrawlerUrlConversion, initialCrawlerUrlStore } from "./model";

export const CRAWLERURL_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("CrawlerUrlReducer");

export function crawlerUrlReducerProvider() {
	return reducerFactory("crawlerUrl", initialCrawlerUrlStore, CrawlerUrl, CrawlerUrlConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("crawlerUrl", CRAWLERURL_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["crawlerUrl/deleted", "crawlerUrl/updated", "crawlerUrl/created"])
	],
	providers: [
		{
			provide: CRAWLERURL_REDUCER_TOKEN,
			useFactory: crawlerUrlReducerProvider
		}
	]
})
export class CrawlerUrlCoreModule {

}
