import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Logger, LoggerLocator } from "@tsng/logging";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: "app-login-modal",
	templateUrl: "component.html",
	styleUrls: ["component.scss"]
})
export class LoginModalComponent {
	@Output() closing = new EventEmitter<boolean>();
	closed = new EventEmitter<boolean>();
	@Input() message = null;
	private logger: Logger = LoggerLocator.getLogger("LoginModalComponent")();

	constructor(private router: Router, public activatedRoute: ActivatedRoute) {
		if(this.activatedRoute.snapshot.queryParams.hasOwnProperty("type") && this.activatedRoute.snapshot.queryParams.type === "location") {
			this.message = $localize`:Login for location change message@@LoginModalComponentLocationChangeMessage:Create an account or login to change your location.`;
		}
	}

	goTo(navigateCommands: string[]) {
		this.closing.next(true);
		this.router.navigate(navigateCommands)
			.then(v => {
				this.closed.emit(true);
			})
			.catch(error => {
				this.logger.fatal(error);
			});
	}
}
