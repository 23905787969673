import { ErrorHandler, Inject, Injectable } from "@angular/core";
import { Logger, LoggerLocator } from "@tsng/logging";

@Injectable({
	providedIn: "root"
})
export class ErrorHandlerService implements ErrorHandler{
	private logger: Logger = LoggerLocator.getLogger("ErrorHandlerService")();
	handleError(error: Error): void {
		this.logger.fatal(`Unhandled error ${error.message}`, {error});
	}
}
