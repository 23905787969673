import { Inject, InjectionToken, ModuleWithProviders, NgModule, OnDestroy } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { SCHEMA_DEFINITION_PROVIDER } from "./schema/provider";
import { StoreHandler } from "./store-handler/store-handler";

const STORE_HANDLER_ADDRESSES = new InjectionToken("StoreHandlerAddresses");

export const STORE_HANDLER_ADDRESSES_PROVIDER = {
	provide: STORE_HANDLER_ADDRESSES,
	multi: true,
	useValue: []
};

@NgModule({})
export class TsNgStoreFeatureModule implements OnDestroy {
	private addresses: string[];

	constructor(@Inject(STORE_HANDLER_ADDRESSES) addresses: string[][], private storeHandler: StoreHandler) {
		this.addresses = addresses.reduce((acc, val) => acc.concat(val), []);
		storeHandler.addHandlers(this.addresses);
	}

	ngOnDestroy() {
		this.storeHandler.removeHandlers(this.addresses);
	}
}

@NgModule({
	imports: [
		StoreModule.forRoot([])
	],
	providers: [STORE_HANDLER_ADDRESSES_PROVIDER, SCHEMA_DEFINITION_PROVIDER]
})
export class TsNgStoreModule {
	static forFeature(addresses: string[]): ModuleWithProviders<TsNgStoreFeatureModule> {
		return {
			ngModule: TsNgStoreFeatureModule,
			providers: [
				{
					provide: STORE_HANDLER_ADDRESSES,
					multi: true,
					useValue: addresses
				}
			]
		};
	}
}
