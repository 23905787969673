import { InjectionToken, NgModule } from "@angular/core";
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { reducerFactory, TsNgStoreModule } from "@tsng/store";
import { initialTicketStore, Ticket, TicketConversion } from "./model";

export const TICKET_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<any>>("TicketReducer");

export function ticketReducerProvider() {
	return reducerFactory("ticket", initialTicketStore, Ticket, TicketConversion);
}

@NgModule({
	imports: [
		StoreModule.forFeature("ticket", TICKET_REDUCER_TOKEN),
		TsNgStoreModule.forFeature(["ticket/deleted", "ticket/updated", "ticket/created"])
	],
	providers: [
		{
			provide: TICKET_REDUCER_TOKEN,
			useFactory: ticketReducerProvider
		}
	]
})
export class TicketCoreModule {

}
