import { ActionConverter, ModelMap, StoreModel } from "@tsng/store";
import { esLocation } from "@twly/core";
import { Map, Record } from "immutable";

export type MyListItemStore = ModelMap<MyListItem>;

export const initialMyListItemStore: MyListItemStore = <MyListItemStore>Map();

export const MyListItemConversion: ActionConverter.Config = {};

export interface MyListItemModel extends StoreModel {
	userGroupId: number;
	userId: number;
	keywordId: number;
	activityId: string;
	siteActivityId: number;
	deleted: boolean;
	location: esLocation;
	timestamp: Date;
	name: string;
	city: string;
}

export class MyListItem extends Record({
	id: 0,
	rev: 0,
	userGroupId: 0,
	userId: 0,
	keywordId: 0,
	activityId: "",
	siteActivityId: 0,
	deleted: false,
	location: {},
	timestamp: Date,
	name: '',
	city: ''

}) implements MyListItemModel {

	id: number;
	rev: number;
	userGroupId: number;
	userId: number;
	keywordId: number;
	activityId: string;
	siteActivityId: number;
	deleted: boolean;
	location: esLocation;
	timestamp: Date;
	name: string;
	city: string;

	get displayName(): string {
		return this.name;
	};
}
