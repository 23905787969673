import { Action } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import {
	CompoundEntityName,
	FilterAction,
	FilterActionBody, FilterCleanableProperties,
	Sort,
	SortDirection
} from "../../action/filter";
import { FilterActionBuilder } from "../action-builder";
import { AndOperator } from "../operator/and";
import { Operator } from "../operator/operator";

export class DefaultFilterActionBuilder implements FilterActionBuilder {
	private search: string = "";
	private limit: number = 10;
	private offset: number = -1;
	private sort: Sort[] = [];
	private operators: Map<string, Operator> = new Map();
	private params?: unknown = null;
	private compound: CompoundEntityName[];
	private actionType = "internal/filter";
	private readonly logger: Logger = LoggerLocator.getLogger("DefaultFilterActionBuilder")();

	getSearch(): Readonly<string> {
		return this.search;
	}

	setSearch(value: string): this {
		return this.mutate(() => {
			this.search = value;
		});
	}

	getLimit(): Readonly<number> {
		return this.limit;
	}

	setLimit(value: number): this {
		return this.mutate(() => {
			this.limit = value;
		});
	}

	getOffset(): Readonly<number> {
		return this.offset;
	}

	setOffset(value: number): this {
		return this.mutate(() => {
			this.offset = value;
		});
	}

	getSort(): Sort[] {
		return this.sort;
	}

	setSort(value: Sort[]): this {
		return this.mutate(() => {
			this.sort = value;
		});
	}

	addSort(sort: Sort): this {
		return this.mutate(() => {
			this.sort.push(sort);
		});
	}

	adjustSort(fieldName: string, direction: SortDirection): this {
		return this.mutate(() => {
			const sortItem = this.sort.find(sort => sort.field === fieldName);
			if (sortItem == null) {
				const message = `Sort '${fieldName}' couldn't be adjusted as it doesn't exist within the current sort array`;
				this.logger.fatal(message, {
					fieldName,
					direction
				});
				throw new Error(message);
			}
			sortItem.direction = direction;
			this.sort[this.sort.findIndex(sort => sort.field === fieldName)] = sortItem;
		});
	}

	getOperators(): Readonly<Map<string, Operator>> {
		return this.operators;
	}

	setOperators(value: Map<string, Operator>): this {
		return this.mutate(() => {
			this.operators = value;
		});
	}

	addOperator(identifier: string, value: Operator): this { //todo
		return this.mutate(() => {
			this.operators.set(identifier, value);
		});
	}

	removeOperator(identifier: string): this {
		return this.mutate(() => {
			this.operators.delete(identifier);
		});
	}

	setCompounds(value: CompoundEntityName[]): this {
		return this.mutate(() => {
			this.compound = value;
		});
	}

	getCompounds(): CompoundEntityName[] {
		return this.compound;
	}

	build(): FilterAction {
		const operators = new AndOperator(Array.from(this.operators.values()));
		return new Action<FilterActionBody>(this.actionType, {
			search: this.search,
			limit: this.limit,
			offset: this.offset,
			sort: this.sort,
			filter: operators,
			compound: this.compound,
			params: this.params
		});
	}

	getActionType(): Readonly<string> {
		return this.actionType;
	}

	setActionType(value: string): this {
		return this.mutate(() => {
			this.actionType = value;
		});
	}

	getParams(): Readonly<unknown> {
		return this.params;
	}

	setParams(params: unknown): this {
		return this.mutate(() => {
			this.params = params;
		});
	}

	clone(): FilterActionBuilder {
		const clone = new DefaultFilterActionBuilder();
		clone.actionType = this.actionType;
		clone.search = this.search;
		clone.limit = this.limit;
		clone.offset = this.offset;
		clone.sort = [...this.sort];
		clone.operators = this.operators.size > 0 ? new Map(this.cloneOperators()) :new Map();
		clone.params = this.params;
		clone.compound = this.compound != null ? [...this.compound] : this.compound;
		return clone;
	}

	clean(propertiesToClean: FilterCleanableProperties = ['search', 'sort', 'operators', 'params']): this {
		const defaultSearch: string = "";
		const defaultLimit: number = 10;
		const defaultOffset: number = -1;
		const defaultSort: Sort[] = [];
		const defaultOperators: Map<string, Operator> = new Map();
		const defaultParams: unknown = null;

		return this.mutate(() => {
			propertiesToClean.forEach(property => {
				// @ts-ignore not a very neat way to do this, but it is short and concise.
				this[property] = eval(`default${property.charAt(0).toUpperCase()}${property.slice(1)}`);
			});
		});
	}

	private cloneOperators() {
		const clone = new Map();
		this.operators.forEach((value, key) => {clone.set(key, value.clone())});
		return clone;
	}

	private mutate(mutator: (this: DefaultFilterActionBuilder) => this | void): this {
		const self = Object.create(this);
		mutator.call(self);
		return self;
	}
}
