<div class="inner-container">
	<div class="files">
		<ng-container
			*ngIf="((source.countChanged() | async) + (source.queueChanged() | async).length) === 0; else elseTemplate"
		>
			<ng-container *ngTemplateOutlet="placeholderDirective.template"></ng-container>
		</ng-container>

		<ng-template #elseTemplate>
			<ng-container *ngFor="let model of (source.dataChanged() | async)?.toArray()">
				<ng-container
					*ngTemplateOutlet="serverFileDirective.template; context: {$implicit: model}"
				></ng-container>
			</ng-container>

			<ng-container *ngFor="let model of (source.queueChanged() | async)">
				<ng-container
					*ngTemplateOutlet="queuedFileDirective.template; context: {$implicit: model}"
				></ng-container>
			</ng-container>
		</ng-template>
	</div>
	<div class="label" *ngIf="fileLabelDirective != null">
		<ng-container
			*ngTemplateOutlet="fileLabelDirective.template"
		></ng-container>
	</div>
</div>
<input
	class="upload-input"
	(change)="addFiles($event.target.files)"
	type="file"
	#fileInput
	[accept]="source.getAcceptedExtensions()?.join(',')"
	[multiple]="this.source.getMaxFiles() !== 1"
>

<!--[accept]="source.getConfig()?.getAcceptedTypes()"--><!--(change)="this.addFiles($event.target.files)"--><!--[multiple]="source.getConfig()?.getMaxFiles() > 1"-->
